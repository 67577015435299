import React from "react";
import {Input} from "reactstrap";
import {hexNumberValidation} from "../../_utils/hexColorValidation";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledColorInput = styled(Input)`
  max-width: 40px;
  margin-left: 10px;
  margin-right: 10px;
`;

const StyledTextInput = styled(Input)`
  min-width: 100px;
`;

const ColorPickerWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;

const ColorPicker = ({color, onChange, disabled=false}) => {
    return (
        <ColorPickerWrapper>
            <StyledTextInput
                disabled={disabled}
                type="text"
                value={color}
                onChange={(e) => hexNumberValidation(e, onChange)}
            />
            <StyledColorInput disabled={disabled} type="color" value={color} onChange={onChange} style={{}}/>
        </ColorPickerWrapper>
    );
};

ColorPicker.propTypes = {
    color: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default ColorPicker;