import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Card, CardBody, CardHeader, CardTitle, Row, Col, Button} from "reactstrap";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import ButtonWithConfirmAlert from "components/ButtonWithConfirmAlert";
import RegionsStore from "stores/organizations/RegionsStore";
import AuthStore from "stores/AuthStore";
import {hist} from "index";

const regionsHeaders = [
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "Poštna št.",
        accessor: "postNumber",
    },
    {
        Header: "Ime",
        accessor: "name",
    },
    {
        Header: "Akcije",
        Cell: (props: any) =>
            <div>
                <Link to={`/region/${props.original.id}`}>
                    <i className="nc-icon nc-settings mr-3"/>
                </Link>
                <ButtonWithConfirmAlert successAction={() => RegionsStore.removeRegion(props.original.id)} text={"Ste prepričani da želite izbrisati regijo?"}>
                    <i style={{cursor: "pointer"}} className="nc-icon nc-simple-remove text-danger mr-3"/>
                </ButtonWithConfirmAlert>
            </div>,
        sortable: false,
    },
];

const RegionsList = observer(() => {

    useEffect(() => {
        RegionsStore.getAllRegions();
    }, []);

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row>
                        <Col md={6}>
                            <CardTitle tag="h5">Seznam regij</CardTitle>
                        </Col>
                        <Col md={6}>
                            {AuthStore.hasAdminAccess &&
                            <Button
                                className="btn-round"
                                color="default"
                                outline
                                style={{float: "right", marginBottom: "0px", marginTop: "5px"}}
                                onClick={() => hist.push(`/add-region`)}
                            >
                                + Dodaj regijo
                            </Button>
                            }
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactTable
                        // ref={ref}
                        loading={RegionsStore.isLoading}
                        data={RegionsStore.regions}
                        resizable={false}
                        filterable={false}
                        sortable
                        columns={regionsHeaders}
                        pageSize={RegionsStore.regions.length}
                        showPagination={false}
                        minRows={RegionsStore.regions && RegionsStore.regions.length > 0 ? 1 : 3}
                        className="-striped -highlight primary-pagination"

                        // Text
                        previousText={"Nazaj"}
                        nextText={"Naprej"}
                        loadingText={"Nalagam..."}
                        noDataText={"Ni zapisov"}
                        pageText={"Stran"}
                        ofText={"od"}
                        rowsText={"vrstic"}
                    />
                </CardBody>
            </Card>
        </div>
    );
});

export default RegionsList;