import React from "react";
import {FormGroup, Label} from "reactstrap";
import Switch from "react-bootstrap-switch";
import PropTypes from "prop-types";

const DynamicFormBool = (props) => {
    const handleChange = (event, value) => {
        props.handleChange(props.index, {id: props.id, value: value}, props.readingType);
    };

    return (
        <div>
            <Label>{props.title} {props.mandatory && "*"}</Label>
            <FormGroup>
                <Switch onChange={handleChange}
                    offColor="success"
                    offText={<i className="nc-icon nc-simple-remove"/>}
                    onColor="success"
                    onText={<i className="nc-icon nc-check-2"/>}
                    defaultValue={false}
                />
            </FormGroup>
        </div>
    );
};

DynamicFormBool.propTypes = {
    handleChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    readingType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mandatory: PropTypes.bool,
};

export default DynamicFormBool;
