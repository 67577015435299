import React, {useEffect, useRef} from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, CardTitle} from "reactstrap";
import NotificationsForm from "./NotificationsForm";
import NotificationAlert from "react-notification-alert";
import NotificationsStore from "../stores/notifications/NotificationsStore";
import PropTypes from "prop-types";
import ButtonWithConfirmAlert from "./ButtonWithConfirmAlert";
import Select from "react-select";
import NotificationStore from "../stores/notifications/NotificationStore";
import {observer} from "mobx-react-lite";

const ProfileNotifications = observer(({userId, userName}) => {

    useEffect(() => {
        return () => NotificationStore.clear();
    }, []);

    useEffect(() => {
        NotificationStore.title = templates[0].title;
        NotificationStore.body = templates[0].text;
    }, [userName]);

    const sendNotification = () => {
        NotificationsStore.sendNotificationToUsers({title: NotificationStore.title, body: NotificationStore.body, userId: [userId]}, onError);
        NotificationStore.title = "";
        NotificationStore.body = "";
    };

    const templates = [
        {
            templateName: "Prazno",
            title: "",
            text: "",
        },
        {
            templateName: "Meritve - Vredu",
            title: "Obvestilo o meritvah",
            text: "Pozdravljeni " + (userName ? userName : "") + ", pogledali smo vaše aktualne meritve in vam sporočamo, da so vaše meritve v mejah normale in trenutno še ni potrebna eskalacija. V vsakem primeru ob primeru nenadnega poslabšanje nas takoj kontaktirajte. Lep pozdrav, vaš zdravnik",
        },
        {
            templateName: "Meritve - Negativno",
            title: "Obvestilo o meritvah",
            text: "Pozdravljeni " + (userName ? userName : "") + ", glede na zadnje meritve, vas prosimo, da se oglasite pri nas ali nas kontaktirajte. Lep pozdrav, vaš zdravnik",
        }
    ];


    const isDisabled = () => {
        return !NotificationStore.title || !NotificationStore.body;
    };

    const notificationAlert = useRef();

    const onError = (errorMessage) => {
        const options = {
            place: "tc",
            message: (
                <div>
                    <div>
                        {errorMessage}
                    </div>
                </div>
            ),
            type: "danger",
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 7,
        };
        notificationAlert.current.notificationAlert(options);
    };

    const onTemplateChange = (e) => {
        const index = e.value;
        if (index !== undefined) {
            NotificationStore.title = templates[index].title;
            NotificationStore.body = templates[index].text;
        }
    };

    return (
        <div>
            <NotificationAlert ref={notificationAlert}/>
            <Card>
                <CardHeader>
                    <CardTitle tag="h5">Pošlji obvestilo</CardTitle>
                </CardHeader>
                <CardBody>
                    <Select
                        onChange={onTemplateChange}
                        className="react-select primary mb-3"
                        classNamePrefix="react-select"
                        id="organizations"
                        options={templates.map((template, index) => {
                            return {label: template.templateName, value: index};
                        })}
                        noOptionsMessage={() => "Ni vsebine"}
                    >
                    </Select>
                    <NotificationsForm
                        currentTab={2}
                        setTitle={(value) => NotificationStore.title = value}
                        setBody={(value) => NotificationStore.body = value}
                        title={NotificationStore.title}
                        body={NotificationStore.body}
                        users={userId}
                        isOnPatientProfile={true}/>
                </CardBody>
                <CardFooter>
                    <ButtonWithConfirmAlert
                        disabled={isDisabled()}
                        successAction={sendNotification}
                        text={"Želite poslati sporočilo?"}>
                        <Button color="primary" disabled={isDisabled()}>Pošlji
                            obvestilo</Button>
                    </ButtonWithConfirmAlert>
                </CardFooter>
            </Card>
        </div>
    );

});

ProfileNotifications.propTypes = {
    userName: PropTypes.string,
    userId: PropTypes.string,
};

export default ProfileNotifications;
