import {action, computed, observable, runInAction} from "mobx";
import httpService from "../../services/httpService";
import ResettableStore from "../ResettableStore";
import {REGION} from "../../config";

import {IRegion} from "../../interfaces/IRegion";
import CountriesStore from "./CountriesStore";

class RegionStore extends ResettableStore {
    @observable isLoading = false;
    @observable isEditing = false;
    @observable error = "";

    @observable id = "";
    @observable name = "";
    @observable countryId: string | undefined = "";
    @observable postNumber = "";

    @observable serverCopy = {
        id: "",
        name: "",
        postNumber: "",
        countryId: "",
    };

    @observable status = {
        name: "",
        postNumber: "",
    };

    constructor() {
        super();
        this.setInitialState();
    }

    @computed
    get isSameAsServerCopy() {
        if (this.id !== this.serverCopy.id) return false;
        if (this.name !== this.serverCopy.name) return false;
        if (this.postNumber !== this.serverCopy.postNumber) return false;
        if (this.countryId !== this.serverCopy.countryId) return false;
        return true;
    }

    @action
    setEditing(id: string) {
        this.id = id;
        this.isEditing = true;
        this.get(id);
    }

    @action
    async get(id: string) {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: REGION + `/${id}`,
        };

        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.mapServerToStore(response as IRegion);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    mapServerToStore(response: IRegion) {
        this.id = response.id;
        this.name = response.name;
        this.postNumber = response.postNumber;
        this.countryId = response.countryId;
        this.serverCopy = {
            id: response.id,
            name: response.name,
            postNumber: response.postNumber,
            countryId: response.countryId,
        };
    }

    @computed
    get isValid() {
        return this.name
            && this.postNumber
            && this.countryId
            && this.isPostNumberValid;
    }

    @computed
    get isPostNumberValid() {
        const regexp = CountriesStore.getPostNumberRegex(this.countryId);
        return !regexp || regexp && regexp.test(this.postNumber)
    }

    @computed
    get canSubmit() {
        if (this.isEditing) {
            return this.isValid && !this.isSameAsServerCopy;
        } else {
            return this.isValid;
        }
    }

    @action
    handleChange = (event: any) => {
        const {name, value} = event.target;
        // @ts-ignore
        this[name] = value;
    };

    @action
    changeCountry = (country: any) => {
        this.countryId = country.value;
        if(this.postNumber) this.validateField("postNumber");
    };

    @action
    async postRegion() {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: REGION,
            body: await this.mapStoreToServer(),
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.isLoading = false;
            });

            return true;
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });

            return false;
        }
    }

    mapStoreToServer() {
        const payload = {
            name: this.name,
            postNumber: this.postNumber,
            countryId: this.countryId,
        } as IRegion;

        if (this.isEditing) {
            payload.id = this.id;
        }

        return payload;
    }

    @computed
    get shouldShowWarningOnBackNavigation() {
        return !this.isSameAsServerCopy;
    }

    @action
    validateField(fieldName: string) {
        switch (fieldName) {
            case "name":
                this.status.name = !!this.name ? "" : "has-danger";
                break;
            case "postNumber":
                this.status.postNumber = !!this.postNumber && this.isPostNumberValid ? "" : "has-danger";
                break;
        }
    }
}

export default new RegionStore();