import {hist} from "../index";


interface IUrlParameters {
    topicId?: string | null
    userId?: string | null
}

enum EUrlParameters {
    TOPIC_ID = "topicId",
    USER_ID = "userId"
}

export function updateUrlWithParams(path: string, paramPath: string) {
    sessionStorage.setItem("backButtonRoute", path + paramPath);
    hist.replace(path + paramPath);
}

export function parametersToPath(basePath: string, urlParameters: IUrlParameters) {
    let returnPath = basePath;
    let hasQuery = returnPath.indexOf('?') !== -1;

    const keys = Object.keys(urlParameters) as EUrlParameters[];
    keys.forEach((key: EUrlParameters) => {
        const val = urlParameters[key];
        if (val) {
            if (!hasQuery) {
                returnPath += '?';
                hasQuery = true;
            } else {
                returnPath += '&';
            }
            returnPath += `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
        }
    });
    return returnPath;
}

export function pathToParameters(urlPath?: string) {
    if (!urlPath) urlPath = getFullPath()
    const parameters = {} as IUrlParameters;
    if (urlPath.indexOf("?") === -1) return parameters
    let split = urlPath
        .split("?")
        .slice(1)[0]
        .split('&')
        .map(substring => decodeURIComponent(substring).split("="));

    const topicId = split.filter(entry => entry[0] === EUrlParameters.TOPIC_ID);
    if (topicId && topicId.length === 1) {
        parameters[EUrlParameters.TOPIC_ID] = topicId[0][1];
    }
    const userId = split.filter(entry => entry[0] === EUrlParameters.USER_ID);
    if (userId && userId.length === 1) {
        parameters[EUrlParameters.USER_ID] = userId[0][1];
    }
    return parameters;
}

export function getFullPath() {
    return window.location.href
}

export function getRelativePath() {
    return window.location.pathname

}

export function appendToPath(appendage: string) {
    hist.push(getRelativePath() + appendage);
}

export function removeLayerFromPath() {
    hist.push(getRelativePath().split("/").slice(0, -1).join("/"))
}

export function removeLayersFromPath(count: number) {
    hist.push(getRelativePath().split("/").slice(0, -count).join("/"))
}
