import {action} from "mobx";
import httpService from "../services/httpService";
import {POST_MODULE_CSV_EXPORT} from "../config";
import {getModuleId} from "../variables/fetchHelper";
import {hist} from "../index";

class DataExportStore {

    constructor() {}

    @action
    async getModuleExportData(fromDate, toDate, primary, secondary) {
        const module = getModuleId(hist);
        const options = {
            method: "POST",
            url: POST_MODULE_CSV_EXPORT,
            body: {
                "fromDate": fromDate,
                "toDate": toDate,
                "medModuleId": module,
                "primary": primary,
                "secondary": secondary
            }
        }

        let text = await httpService.fetch(
            options
        )

        return this.formatExportDataLink(text);
    }

    @action
    async getUserExportData(fromDate, toDate, userId) {
        const module = getModuleId(hist);
        const options = {
            method: "POST",
            url: POST_MODULE_CSV_EXPORT,
            body: {
                "fromDate": fromDate,
                "toDate": toDate,
                "medModuleId": module,
                "userId": userId
            }
        }

        let text = await httpService.fetch(
            options
        )

        return this.formatExportDataLink(text);
    }

    formatExportDataLink(data) {
        //specify data with UTF BOM
        return "data:text/csv;charset=utf-8," + encodeURI('\ufeff' + data);
    }
}

// singletone
export default new DataExportStore();
