enum EPermission {
	OWNER = "owner",
	CAN_SHARE = "canShare",
	CAN_EDIT = "canEdit",
	CAN_USE = "canUse",
	CAN_DELETE = "canDelete"
};

export const getSloPermission = (angRole: string) => {
	if (angRole === EPermission.OWNER)
		return "Lastnik";
	if (angRole === EPermission.CAN_SHARE)
		return "Deljenje";
	if (angRole === EPermission.CAN_EDIT)
		return "Urejanje";
	if (angRole === EPermission.CAN_USE)
		return "Uporaba";
	if (angRole === EPermission.CAN_DELETE)
		return "Brisanje";
	else
		return angRole;

};

export default EPermission;