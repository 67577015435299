import React from "react";
import {FormGroup, Label} from "reactstrap";
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import PropTypes from "prop-types";
import DynamicFormMultiState from "./DynamicFormMultiState";
import { ETestResult, getRapidTestResult } from "enums/ETestResult";

const DynamicFormRapidTest = (props) => {

    const setImageFile = (file) => {
        props.handleChange(props.index, {id: props.id, value: {image: file}}, props.readingType);
    }

    const setInt = (int) => {
        props.handleChange(props.index, {id: props.id, value: {int: int}}, props.readingType);
    }

    const removeImage = () => {
        props.handleChange(props.index, {id: props.id, value: {image: ''}}, props.readingType);
    };

    return (
        <div>
            <Label>{props.title} {props.mandatory && "*"}</Label>
            <FormGroup className={props.validationStatus}>
                <ImageUpload setFile={setImageFile} removeFile={removeImage} />
                {props.validationStatus === "has-danger" &&
                <label className="error">
                    Polje je obvezno
                </label>}
            </FormGroup>
            <DynamicFormMultiState
                title=""
                name={props.name}
                readingType={props.readingType}
                index={props.index}
                id={props.id}
                handleChange={(index, value, reading) => { setInt(value.value); }}
                multiStateOptions={[
                    {id: ETestResult.NEGATIVE, value: getRapidTestResult(ETestResult.NEGATIVE)},
                    {id: ETestResult.POSITIVE, value: getRapidTestResult(ETestResult.POSITIVE)},
                    {id: ETestResult.INVALID, value: getRapidTestResult(ETestResult.INVALID)}]} />
        </div>
    );
};

DynamicFormRapidTest.propTypes = {
    handleChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    readingType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mandatory: PropTypes.bool,
};

export default DynamicFormRapidTest;
