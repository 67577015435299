import {action, computed, observable, runInAction} from "mobx";
import {hist} from "../index";
import {IPatient} from "../interfaces/IPatient";
import httpService from "../services/httpService";
import {GET_PATIENT_LATEST_PRIMARY_RECORDS, GET_PATIENT_LATEST_RECORDS, GET_PATIENTS_URL} from "../config";
import {IReadingRecordExt} from "../interfaces/readings/IReadingRecordExt";
import {IGetDetailsWithUserData} from "../interfaces/readings/IGetDetailsWithUserData";
import {getModuleId} from "../variables/fetchHelper";
import MedModulesStore from "./MedModulesStore";
import {IMedModule} from "./MedModuleStore";
import PatientReadingsStore from "./PatientReadingsStore";
import {IMultiStateOption} from "../interfaces/types/IMultiStateOption";

const emptyPatient = {
    id: "",
    emso: "",
    healthId: "",
    email: "",
    medModules: [],
}

class PatientProfileStore {
    @observable loadingRequests: Promise<any>[] = [];
    @observable patient:IPatient = emptyPatient;
    @observable latestReadings: IGetDetailsWithUserData = this.getClearLatestReadings();
    @observable latestPrimaryRecords: IReadingRecordExt[] = [];
    @observable error = null;

    @computed
    get isLoading(): boolean {
        return this.loadingRequests.length > 0;
    };

    getClearLatestReadings() {
        return {
            anamnesis: {},
            anamnesisReported: false,
            isEnabled: false,
            mainReading: {
                id: "",
                mandatory: false,
                type: 0,
            },
            secondaryReadingTypes: [],
            primaryReadingTypes: [],
        };
    };

    fetch(options: any) {
        const promise = httpService.fetch(
            options,
        );
        // @ts-ignore
        this.loadingRequests.push(promise);
        return promise;
    }

    requestFinished(promise: any) {
        this.loadingRequests.splice(this.loadingRequests.indexOf(promise), 1);
    }

    @action
    async getPatientProfile(id: string) {

        const options = {
            method: "GET",
            url: GET_PATIENTS_URL + `/${id}`,
        };

        const promise = this.fetch(options);
        try {
            const response = await promise;
            runInAction(async () => {
                this.patient = this.mapServerToStore(response);
                this.requestFinished(promise);
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
                this.requestFinished(promise);
            });
        }
    }

    @action
    mapServerToStore(payload: any): IPatient {
        return {
            address: payload.address,
            email: payload.email,
            emso: payload.emso,
            firstName: payload.firstName,
            healthData: payload.healthData,
            healthId: payload.healthId,
            healthMonitor: payload.healthMonitor,
            healthStatus: payload.healthStatus,
            id: payload.id,
            lastName: payload.lastName,
            medModules: payload.medModules,
            organization: payload.organization,
            personalDoctor: payload.personalDoctor,
            phone: payload.phone,
            phone1: payload.phone1,
            phone2: payload.phone2,
            phone3: payload.phone3,
            skype: payload.skype,
            viber: payload.viber,
        };
    }

    @action
    async getLatestReadingRecords(id: string) {
        this.latestReadings = this.getClearLatestReadings();

        const options = {
            method: "POST",
            url: GET_PATIENT_LATEST_RECORDS,
            body: {
                userId: id,
                medModuleId: getModuleId(hist),
            },
        };

        const promise = this.fetch(options);
        try {
            const response = await promise;
            runInAction(() => {
                // @ts-ignore
                this.latestReadings = response;
                
                //map latest secondary readings
                let sr = [];
                let secondarySections: any = this.latestReadings.secondaryReadingTypes;

                if (secondarySections && secondarySections.length > 0) {
                    for (let i=0; i<secondarySections.length; i++) {
                        let section = secondarySections[i];

                        if (section.readings && section.readings.length > 0) {
                            for (let j=0; j<section.readings.length; j++) {
                                sr.push(section.readings[j]);
                            }
                        }
                    }
                }

                this.latestReadings.secondaryReadingTypes = sr;
                this.latestReadings.secondarySections = secondarySections;

                PatientReadingsStore.setReadingIdToTypeMap(this.latestReadings);
                PatientReadingsStore.setSectionReadingIdToTypeMap(this.latestReadings.secondarySections);

                this.requestFinished(promise);
            });
        } catch (err) {
            runInAction(() => {
                this.requestFinished(promise);
            });
        }
    }

    @action
    async getLast10RecordsForPrimaryReadings(id: string, onError: any) {

        const options = {
            method: "POST",
            url: GET_PATIENT_LATEST_PRIMARY_RECORDS,
            body: {
                count: 10,
                userId: id,
                medModuleId: getModuleId(hist),
            },
        };

        const promise = this.fetch(options);
        try {
            const response = await promise as IReadingRecordExt[];
            runInAction(() => {
                this.latestPrimaryRecords = response;

                if (this.latestPrimaryRecords.length < 10) {
                    for (let i = this.latestPrimaryRecords.length; i < 10; i++) {
                        this.latestPrimaryRecords.push({});
                    }
                }
                this.requestFinished(promise);
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
                this.requestFinished(promise);
            });
            (onError && err && err.status && err.statusText) && onError(`${err.status} - ${err.statusText}`);
        }
    }

    get medModulesForDropdown() {
        return this.patient.medModules?.map((module: IMedModule) => {
            return {
                label: module.name,
                value: module.id,
            };
        });
    }

    get medModulesForAssigning() {
        const modulesIds = this.patient.medModules?.map((m) => m.id);
        return MedModulesStore.modules?.filter((module: IMedModule) => !modulesIds?.includes(module.id) && module.isEnabled).map((module: IMedModule) => {
            return {
                label: module.name,
                value: module.id,
            };
        });
    }

    @action
    clear() {
        this.loadingRequests = [];
        this.patient = emptyPatient;
        this.latestReadings = this.getClearLatestReadings();
        this.latestPrimaryRecords = [];
        this.error = null;
    }

    getMultiStateAnswer(readingId: string, answerId: string): IMultiStateOption | undefined {
        return this.latestReadings.primaryReadingTypes
            .concat(this.latestReadings.secondaryReadingTypes)
            .concat(this.latestReadings.mainReading)
            .concat(this.latestReadings.anamnesis && this.latestReadings.anamnesis.readings ? this.latestReadings.anamnesis.readings : [])
            .find(reading => reading?.id === readingId)?.multiStateOptions?.find(answer => answer?.id === answerId) ?? undefined;
    }
}

// singletone
export default new PatientProfileStore();
