import {action, computed, observable, runInAction} from "mobx";
import httpService from "../services/httpService";
import {GET_PATIENT_BY_HEALTH_ID, GET_PATIENTS_URL, PAGE_COUNT, PATIENT_UNASSIGN} from "../config";
import {getModuleId, queryParams} from "../variables/fetchHelper";
import {hist} from "../index";


class PatientsStore {
    @observable isLoading = false;
    @observable patients = [];
    @observable error = null;
    @observable currentPage = 1;
    @observable query = null;
    @observable order = null;

    constructor() {
    }

    @action
    async getPatients(query, count, page, order, moduleId) {
        const module = moduleId ? moduleId : getModuleId(hist);
        this.isLoading = true;
        const options = {
            method: "GET",
            url: GET_PATIENTS_URL + queryParams([module, query, count, page, order], ["module", "query", "count", "page", "order"]),
        };

        // eslint-disable-next-line no-useless-catch
        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.patients = response;
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    async getNextPage() {
        this.currentPage += 1;
        await this.getPatients(this.query, PAGE_COUNT, this.currentPage, this.order);
    }

    @action
    async getPreviousPage() {
        if(this.currentPage > 1){
            this.currentPage -= 1;
            await this.getPatients(this.query, PAGE_COUNT, this.currentPage, this.order);
        }
    }

    @computed
    get isFirstPage() {
        return this.currentPage === 1;
    }

    @computed
    get isLastPage() {
        return this.patients.length <= PAGE_COUNT;
    }

    @action
    async unassignPatient(healthId) {
        if (!getModuleId(hist)) return;
        this.isLoading = true;

        const options = {
            method: "POST",
            url: PATIENT_UNASSIGN,
            body: {
                healthId: healthId,
                medModuleId: getModuleId(hist),
            },
        };

        try {
            await httpService.fetch(options);

            runInAction(() => {
                this.patients = this.patients.filter(patients => patients.healthId !== healthId);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    clear() {
        this.isLoading = false;
        this.patients = [];
        this.error = null;
        this.currentPage = 1;
        this.query = "";
    }
}

// singletone
export default new PatientsStore();
