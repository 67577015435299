import React from "react";
import {Button, Card, CardBody} from "reactstrap";
import {observer} from "mobx-react-lite";
import SettingsStore from "../../stores/SettingsStore";

const ConfirmSettings = observer(() => {
    return (
        <Card>
            <CardBody>
                <Button className="btn-info" disabled={SettingsStore.InvalidData}
                        onClick={() => SettingsStore.postSettings()}>Potrdi</Button>
            </CardBody>
        </Card>
    );
});

export default ConfirmSettings;
