import {action, observable, runInAction, computed} from "mobx";
import {CMS_SCHEDULE, PAGE_COUNT} from "../config";
import httpService from "../services/httpService";
import {queryParams} from "../variables/fetchHelper";

class SchedulesStore {
    @observable isLoading = false;
    @observable schedules = [];
    @observable error = null;
    @observable currentPage = 1;
    @observable query = null;
    @observable order = null;

    @action
    async getSchedules(moduleId) {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: `${CMS_SCHEDULE}/${moduleId}`
            //url: CMS_SCHEDULE + queryParams([query, count, page, order], ["search", "count", "page", "order"]),
        };

        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.schedules = response.notifications;
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    async getNextPage() {
        this.currentPage += 1;
        await this.getSchedules(this.query, PAGE_COUNT, this.currentPage, this.order);
    }

    @action
    async getPreviousPage() {
        if(this.currentPage > 1){
            this.currentPage -= 1;
            await this.getSchedules(this.query, PAGE_COUNT, this.currentPage, this.order);
        }
    }

    @computed
    get isFirstPage() {
        return this.currentPage === 1;
    }

    @computed
    get isLastPage() {
        return this.schedules.length <= PAGE_COUNT;
    }

    @action
    async removeSchedule(moduleId, id) {
        this.isLoading = true;

        const options = {
            method: "DELETE",
            url: `${CMS_SCHEDULE}/${moduleId}/${id}`,
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.schedules = this.schedules.filter(schedule => schedule.id !== id);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    clear() {
        this.isLoading = false;
        this.schedules = [];
        this.error = null;
        this.currentPage = 1;
        this.query = "";
    }
}

export default new SchedulesStore();
