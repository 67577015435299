import ResettableStore from "../ResettableStore";
import {action, observable, computed} from "mobx";

class NotificationStore extends ResettableStore {
    @observable title = "";
    @observable body = "";
    @observable currentTab = 1;
    @observable userId = [];
    @observable androidNewVersion = false;
    @observable iosNewVersion = false;

    constructor() {
        super();

        this.setInitialState();
    }

    get isEmpty() {
        if(this.userId && this.userId.length !== 0) return false;
        if(this.androidNewVersion) return false;
        if(this.iosNewVersion) return false;
        return !this.title
            && !this.body;
    }

    @computed
    get shouldShowWarningOnBackNavigation() {

        return !this.isEmpty;
    }

    @action
    clear() {
        this.title = "";
        this.body = "";
        this.currentTab = 1;
        this.userId = [];
        this.androidNewVersion = false;
        this.iosNewVersion = false;
    }
}

export default new NotificationStore();