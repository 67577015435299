import React, {useEffect} from "react";
import StatusIcon from "components/StatusIcon";
import PatientsStore from "stores/PatientsStore";
import {Observer, observer} from "mobx-react-lite";
import {customStringSort} from "_utils/sort";
import {getAgeFromEmso, getBirthDateFromEmso, isToday, localDateTimeFormat} from "variables/dateHelpers";
import CheckmarkIcon from "components/CheckmarkIcon";
import {hist} from "index";
import PaginationTable from "components/Pagination/PaginationTable";
import {PAGE_COUNT} from "config";
import {cellStyle} from "variables/styles";
import {pageFromList} from "variables/paginationHelpers";
import {NavLink} from "react-router-dom";
import ButtonWithConfirmAlert from "components/ButtonWithConfirmAlert";
import {getModuleId} from "variables/fetchHelper";
import MedModulesStore from "stores/MedModulesStore";
import EPermission from "enums/EPermission";
import {Link, useLocation} from "react-router-dom";
import ModuleLinks from "components/ModuleLinks";

const getStatusColor = ({value, original}) => {
    const {lastReading} = original;
    let isReadingToday = false;
    if (lastReading) {
        isReadingToday = isToday(new Date(lastReading.lastDate));
    }

    if (!isReadingToday) {
        return "#D3D3D3";
    }
    if (value < 0)
        return null;
    if (value < 25) {
        return "#24BF62";
    } else if (value < 50) {
        return "#f6d437";
    } else if (value < 75) {
        return "#E69900";
    } else {
        return "#E63F3F";
    }
};

const tableHeaders = [
    {
        Header: "Pregledan",
        accessor: "lastReading.isChecked",
        Cell: props => <React.Fragment>
            {props.original.lastReading && props.original.lastReading &&
            <CheckmarkIcon isChecked={props.original.lastReading.isChecked}/>
            }
        </React.Fragment>,
        style: {...cellStyle, justifyContent: "center"}
    },
    {
        Header: "Status",
        accessor: "healthStatus",
        Cell: props => <React.Fragment>
            <StatusIcon size={16} color={getStatusColor(props)} text={""}/>
        </React.Fragment>,
        style: {...cellStyle, justifyContent: "center"}
    },
    {
        Header: "Zadnja meritev",
        accessor: "lastReading.lastDate",
        Cell: props => <React.Fragment>
            {props.original && props.original.lastReading && localDateTimeFormat(props.original.lastReading.lastDate)}
        </React.Fragment>,
        width: 180,
        sortable: false,
        style: cellStyle
    },
    {
        Header: "Št. zdr. kartice",
        accessor: "healthId",
        Cell: props => <NavLink to={hist.location.pathname + "/" + props.original.id} style={{color: "#007bff", marginBottom: "0"}}>{props.value}</NavLink>,
        width: 180,
        sortable: false,
        style: cellStyle
    },
    {
        Header: "Ime",
        accessor: "firstName",
        sortMethod: (a, b) => customStringSort(a, b),
        minWidth: 150,
        style: cellStyle
    },
    {
        Header: "Priimek",
        accessor: "lastName",
        sortMethod: (a, b) => customStringSort(a, b),
        minWidth: 150,
        style: cellStyle
    },
    {
        Header: "Starost",
        accessor: "age",
        Cell: props => <React.Fragment>{getAgeFromEmso(props.original.emso)}</React.Fragment>,
        sortMethod: (a, b) => {
            return a > b ? 1 : -1;
        },
        style: cellStyle
    },
    {
        Header: "Datum rojstva",
        accessor: "emso",
        Cell: props =>
            <React.Fragment>{getBirthDateFromEmso(props.original.emso) ? getBirthDateFromEmso(props.original.emso) : "Neveljaven datum"}</React.Fragment>,
        sortable: false,
        minWidth: 150,
        style: cellStyle
    },
    {
        Header: "Akcije",
        Cell: props =>
            <div>
                <Observer>{() =>
                    <ButtonWithConfirmAlert
                        text={"Želite odstraniti pacienta iz modula?"}
                        successAction={() => PatientsStore.unassignPatient(props.original.healthId)}
                    >
                        <i className="nc-icon nc-simple-remove text-danger mx-1" style={{cursor: "pointer"}}/>
                    </ButtonWithConfirmAlert>
                }</Observer>
            </div>,
    },
];

const PatientList = observer(() => {

    const moduleId = getModuleId(hist);
    const location = useLocation();

    useEffect(() => {
        fetchData("", PAGE_COUNT);
        return () => PatientsStore.clear();
    }, []);

    const fetchData = async (query, count, page, order) => {
        return await PatientsStore.getPatients(query, count, page, order);
    };

    const addPatient = () => {
        hist.push(`/module/${getModuleId(hist)}/assign-patient`);
    };

    return (
        <>
            <ModuleLinks tabPath={location.pathname} moduleId={moduleId}></ModuleLinks> 
            <PaginationTable
                title={"Seznam pacientov"}
                tableHeaders={tableHeaders}
                isLoading={PatientsStore.isLoading}
                data={pageFromList(PatientsStore.patients)}
                store={PatientsStore}
                fetchData={fetchData}
                showAdd={MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_USE)}
                onAdd={addPatient}
            />
        </>
    );
});

export default PatientList;
