import React, {createRef} from "react";
import {Route} from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "../routes";
import AuthStore from "../stores/AuthStore";
import NotificationAlert from "react-notification-alert";
import WarningsStore from "../stores/WarningsStore";
import styled from "styled-components";

const Wrapper = styled.div`
overflow-y: scroll;

::-webkit-scrollbar-track
{
    border-radius: 8px;
    background-color: #f4f3ef;
}

::-webkit-scrollbar
{
    width: 8px;
}

::-webkit-scrollbar-thumb
{
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
    background-color: #c8c7c6;
}
`;


class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: "black",
            activeColor: "info",
            sidebarMini: false,
        };

        this.ref = createRef();
    }

    componentDidMount() {
        AuthStore.getUserData();

        WarningsStore.setRef(this.ref);
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    componentDidUpdate(e) {
        if (e.history.action === "PUSH") {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            this.refs.mainPanel.scrollTop = 0;
        }
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (prop.layout === "/admin") {
                return (
                    <Route
                        exact
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    handleActiveClick = color => {
        this.setState({activeColor: color});
    };
    handleBgClick = color => {
        this.setState({backgroundColor: color});
    };
    handleMiniClick = () => {
        if (document.body.classList.contains("sidebar-mini")) {
            this.setState({sidebarMini: false});
        } else {
            this.setState({sidebarMini: true});
        }
        document.body.classList.toggle("sidebar-mini");
    };

    handleCloseClick = () => {
        document.documentElement.classList.remove("nav-open");
    };

    render() {
        return (
            <Wrapper className="wrapper">
                <Sidebar
                    {...this.props}
                    routes={routes}
                    bgColor={this.state.backgroundColor}
                    activeColor={this.state.activeColor}
                    handleMiniClick={this.handleMiniClick}
                />
                <div className="main-panel" ref="mainPanel" onClick={this.handleCloseClick} onDrag={this.handleCloseClick} onScroll={this.handleCloseClick} >
                    <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick}/>
                    <div className="content">
                        <NotificationAlert ref={this.ref}/>
                        {this.props.children}
                    </div>
                    {/*{// we don't want the Footer to be rendered on full screen maps page*/}
                    {/*this.props.location.pathname.indexOf("full-screen-map") !==*/}
                    {/*-1 ? null : (*/}
                    {/*  <Footer fluid />*/}
                    {/*)}*/}
                </div>
            </Wrapper>
        );
    }
}

export default Admin;
