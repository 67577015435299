import React, {useEffect} from "react";
import {Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label, Col, Row} from "reactstrap";
import {observer} from "mobx-react-lite";
import ScheduleStore from "stores/ScheduleStore";
import Loader from "components/Loader";
import AdminNavbarStore from "stores/common/AdminNavbarStore";
import {hist} from "index";
import {getModuleId} from "variables/fetchHelper";
import Select from "react-select";
import ButtonWithConfirmAlert from "components/ButtonWithConfirmAlert";
import ReactDatetime from "react-datetime";

const ScheduleForm = observer((props) => {
    const editingId = props.match.params.id;
    const profileId = props.match.params.profileId;
    const moduleId = getModuleId(hist);

    useEffect(() => {

        if (editingId) {
            ScheduleStore.setEditing(moduleId, editingId, profileId);
        }        
        return () => ScheduleStore.clear();
    }, []);

    const postSchedule = async () => {
        await ScheduleStore.postSchedule(moduleId, profileId);
        Redirect();
    };

    const Redirect = async () => {        
        if (profileId) {
            AdminNavbarStore.redirectWithoutWarning(`/module/${moduleId}/patients/${profileId}`);
        } else {
            AdminNavbarStore.redirectWithoutWarning(`/module/${moduleId}/templates`);
        }
    };

    return (
        <React.Fragment>
            <Loader isLoading={ScheduleStore.isLoading} />
            
            <Card className="col-md-10 mx-auto">
                <CardHeader>
                    <CardTitle tag="h3" className="text-center">
                        {ScheduleStore.isEditing ? "Uredi urnik" : "Dodaj urnik"}
                    </CardTitle>
                </CardHeader>
                <CardBody className="col-md-9 m-auto">
                    
                    <Label>Naslov*</Label>
                    <FormGroup className={ScheduleStore.status.title}>
                        <Input type="text"
                            name="title"
                            value={ScheduleStore.title}
                            onChange={ScheduleStore.handleChange}
                            onBlur={() => ScheduleStore.validateField("title")}
                            autoComplete="off"
                            maxLength={100}/>
                        {ScheduleStore.status.title === "has-danger" &&
                        <label className="error">
                            Naslov je obvezen
                        </label>}
                    </FormGroup>
                    
                    <Label>Opis*</Label>
                    <FormGroup className={ScheduleStore.status.text}>
                        <Input type="text"
                            name="text"
                            value={ScheduleStore.text}
                            onChange={ScheduleStore.handleChange}
                            autoComplete="off"
                            onBlur={() => ScheduleStore.validateField("text")}
                            maxLength={300}/>
                        {ScheduleStore.status.text === "has-danger" &&
                        <label className="error">
                            Opis je obvezen
                        </label>}
                    </FormGroup>

                    <Label>Ponovitve meritev vsake*</Label>
                    <FormGroup className={ScheduleStore.status.every}>
                        <Input type="number"
                            name="every"
                            value={ScheduleStore.schedule.repeat.every}
                            onChange={(event) => ScheduleStore.changeEvery(event)}
                            autoComplete="off"
                            onBlur={() => ScheduleStore.validateField("every")}
                            maxLength={5}/>
                        {ScheduleStore.status.every === "has-danger" &&
                        <label className="error">
                            Vnesite ponovitve meritev
                        </label>}
                    </FormGroup>
                    <Select
                        className="react-select primary mb-2"
                        classNamePrefix="react-select"
                        id="repeat"
                        onChange={(event) => ScheduleStore.changeUnit(event)}
                        value={ScheduleStore.selectedRepeat}
                        options={ScheduleStore.repeatUnits}
                        noOptionsMessage={() => "Ni tipov"}
                    />
                    {!profileId && <div>
                    <Label>Trajanje*</Label>
                    {ScheduleStore.duration.unit !== 'forever' && <FormGroup className={ScheduleStore.status.duration}>
                        <Input type="number"
                            name="duration"
                            value={ScheduleStore.duration.value}
                            onChange={(event) => ScheduleStore.changeDuration(event)}
                            autoComplete="off"
                            onBlur={() => ScheduleStore.validateField("duration")}
                            maxLength={300}/>
                        {ScheduleStore.status.duration === "has-danger" &&
                        <label className="error">
                            Trajanje je obvezen podatek
                        </label>}
                    </FormGroup>}
                    <Select
                        className="react-select primary mb-2"
                        classNamePrefix="react-select"
                        id="durationUnit"
                        onChange={(event) => ScheduleStore.changeDurationUnit(event)}
                        value={ScheduleStore.selectedDurationUnit}
                        options={ScheduleStore.durationUnits}
                        noOptionsMessage={() => "Ni tipov"}
                    />
                    </div>}
                    <br />
                    {profileId && <div className="form-check">
                        <Label>Veljavnost*</Label>
                        <FormGroup>
                            <Label check className="mr-3">
                                <Input checked={ScheduleStore.validForever} type="checkbox"
                                    onChange={event => ScheduleStore.changeValidForever(event.target.checked)}
                                />
                                Neskončno
                                <span className="form-check-sign"/>
                            </Label>
                        </FormGroup>
                    </div>}
                    {profileId && <div>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Od</Label>
                                    <ReactDatetime
                                        inputProps={{
                                            className: "form-control",
                                            placeholder: "Izberi datum",
                                        }}
                                        locale={navigator.language.slice(0, 2)}
                                        value={ScheduleStore.validFrom}
                                        onChange={(time) => ScheduleStore.setFromTime(time)}
                                        closeOnSelect={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                {!ScheduleStore.validForever && <FormGroup>
                                    <Label>Do</Label>
                                    <ReactDatetime
                                        inputProps={{
                                            className: "form-control",
                                            placeholder: "Izberi datum",
                                        }}
                                        locale={navigator.language.slice(0, 2)}
                                        value={ScheduleStore.validTo}
                                        onChange={(time) => ScheduleStore.setToTime(time)}
                                        closeOnSelect={true}
                                    />
                                </FormGroup>}
                            </Col>
                        </Row>
                    </div>}
                    <br />

                    {ScheduleStore.schedule.repeat.unit === 'week' &&
                    <div className="form-check">
                        <Label>Dnevi*</Label>
                        <FormGroup className={ScheduleStore.status.days}>
                            {ScheduleStore.days.map(day =>
                                <div key={day.value}>
                                    <Label check className="mr-3">
                                        <Input checked={ScheduleStore.schedule.days.includes(day.value)} type="checkbox"
                                            onChange={event => ScheduleStore.changeDays(event.target.checked, day)}
                                        />
                                        {day.label}
                                        <span className="form-check-sign"/>
                                    </Label>
                                </div>
                            )}
                            {ScheduleStore.status.days === "has-danger" &&
                            <label className="error">
                                Izberite vsaj en dan
                            </label>}
                        </FormGroup>
                    </div>}

                    {ScheduleStore.schedule.repeat.unit === 'month' &&
                    <div>
                        <Label>Dan v mesecu*</Label>
                        <FormGroup className={ScheduleStore.status.day}>
                            <Input type="number"
                                name="day"
                                value={ScheduleStore.schedule.day}
                                onChange={(event) => ScheduleStore.changeDay(event)}
                                autoComplete="off"
                                onBlur={() => ScheduleStore.validateField("day")}
                                maxLength={5}/>
                            {ScheduleStore.status.day === "has-danger" &&
                            <label className="error">
                                Vnesite dan v mesecu
                            </label>}
                        </FormGroup>
                    </div>
                    }

                    <Label>Ure*</Label>
                    &nbsp;&nbsp;
                    <Button className="btn-sm" onClick={() => ScheduleStore.addHour()}>Dodaj uro</Button>
                    {ScheduleStore.schedule.hours.map((hour, index) =>
                    <FormGroup key={index} className={ScheduleStore.status.hour}>
                        <Input type="time"
                            name="hour"
                            value={hour}
                            onChange={(event) => ScheduleStore.handleTimeChange(event, index)}
                            autoComplete="off"
                            onBlur={() => ScheduleStore.validateField("hour")}
                            maxLength={300}
                            style={{float: "left", width:"50%"}}/>
                        {ScheduleStore.schedule.hours.length > 1 && <ButtonWithConfirmAlert text={"Ste prepričani da želite odstraniti izbrano uro?"} successAction={() => ScheduleStore.removeHour(index)}>
                            <i style={{cursor: "pointer"}} className="nc-icon nc-simple-remove text-danger mr-3"/>
                        </ButtonWithConfirmAlert>}
                        {ScheduleStore.status.hour === "has-danger" &&
                        <label className="error">
                            Ura je obvezna
                        </label>}
                        <br/>
                        <br/>
                    </FormGroup>
                    )}

                    <Button disabled={!ScheduleStore.canSubmit} className="btb btn-info mt-4"
                        onClick={postSchedule}>Potrdi</Button>
                </CardBody>
            </Card>
        </React.Fragment>
    );
});

export default ScheduleForm;
