import {action, computed, observable, runInAction} from "mobx";
import {GET_PATIENT_BY_HEALTH_ID, PATIENT_ASSIGN} from "../config";
import {hist} from "../index";
import httpService from "../services/httpService";
import {getModuleId} from "../variables/fetchHelper";
import ResettableStore from "./ResettableStore";

class AssignPatientsToModuleStore extends ResettableStore {
	@observable isLoading: boolean = false;
	@observable healthId: string = "";
	@observable id: string = "";
	@observable firstName: string | undefined = "";
	@observable lastName: string | undefined = "";
	@observable error = null;
	@observable patientExists: boolean | undefined = undefined;

	constructor() {
	    super();
	    this.setInitialState();
	}

	@action
	async getPatientByHealthId(healthId: string) {
	    this.isLoading = true;

	    const options = {
	        method: "GET",
	        url: GET_PATIENT_BY_HEALTH_ID + "/" + healthId,
	    };

	    try {
	        const response = await httpService.fetch(
	            options,
	        );

	        runInAction(() => {
	            this.mapServerToStore(response);
	            this.isLoading = false;
	        });
	    } catch (err) {
	        runInAction(() => {
	            this.isLoading = false;
	            this.error = err;
	        });
	    }
	}

	@action
	handleChange = (event: any) => {
	    this.patientExists = undefined;
	    const {name, value} = event.target;
	    // @ts-ignore
	    this[name] = value;
	};

	mapServerToStore(response: any) {
	    if (response.healthId) {
	        this.patientExists = true;
	        this.healthId = response.healthId;
	        this.firstName = response.firstName;
	        this.lastName = response.lastName;
	        this.id = response.id;
	    }
	    else {
		    this.patientExists = false;
	    }
	}

	@computed
	get patientFullName() {
	    if (this.lastName || this.firstName) return `${this.firstName} ${this.lastName}`;
	    return null;
	}

	@action
	async assignStorePatient() {
	    const moduleId = getModuleId(hist);
	    this.assignPatient(moduleId, this.healthId).then(() => {
		    hist.push(`/module/${moduleId}/patients/${this.id}`);
		    this.clearPatient();
	    });
	}

	@action
	async assignPatient(moduleId: string, healthId: string) {
	    if (!moduleId) return;
	    this.isLoading = true;

	    const options = {
	        method: "POST",
	        url: PATIENT_ASSIGN,
	        body: {
	            healthId: healthId,
	            medModuleId: moduleId,
	        },
	    };

	    try {
	        const response = await httpService.fetch(options);

	        runInAction(() => {
	            this.isLoading = false;
	            return response;
	        });
	    } catch (err) {
	        runInAction(() => {
	            this.isLoading = false;
	            this.error = err;
	        });
	    }
	}

	@action
	clearPatient = () => {
	    this.patientExists = undefined;
	    this.lastName = undefined;
	    this.firstName = undefined;
	    this.healthId = "";
	    this.id = "";
	}

	@computed
	get canSearch() {
	    return this.healthId?.length === 9;
	}

}

export default new AssignPatientsToModuleStore();