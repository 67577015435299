import {action, computed, observable, runInAction} from "mobx";
import {v4 as uuidv4} from "uuid";
import httpService from "../services/httpService";
import {
    ACCESS_TOKEN,
    SESSION_ACCESS_TOKEN,
    AUTH_URL,
    CHANGE_PASSWORD, CHANGE_PASSWORD_FORCED,
    DEVICE_ID,
    FORGOT_PASSWORD,
    GET_USER_PROFILE,
    REFRESH_TOKEN,
    REFRESH_URL,
    ROLES,
} from "../config";
import AnamnesisStore from "./AnamnesisStore";
import NotificationsStore from "./notifications/NotificationsStore";
import OrganizationsStore from "./organizations/OrganizationsStore";
import PatientProfileStore from "./PatientProfileStore";
import PatientReadingsStore from "./PatientReadingsStore";
import PatientsStore from "./PatientsStore";
import ReadingsStore from "./ReadingsStore";
import SettingsStore from "./SettingsStore";
import UsersStore from "./UsersStore";
import PatientPersonalDataStore from "./PatientPersonalDataStore";
import role from "../enums/ERole";
import ChatStore from "./chat/ChatStore";
import TopicStore from "./chat/TopicStore";
import ReadingStore from "./ReadingStore";
import SosStore from "./sos/SosStore";
import MedModulesStore from "./MedModulesStore";
import {hist} from "../index";


class AuthStore {
    @observable isLoading = false;
    @observable id = "";
    @observable token = "";
    @observable roles = [];
    @observable error = null;
    @observable fullName = "";
    @observable userName = "";
    @observable email = "";
    @observable organizationId = "";
    @observable forgotPasswordStatus = "";
    @observable changePasswordStatus = "";
    tokenRequest;

    @observable cookieExpireTime = 900000;

    /**
     *
     * @param {string} [storageType] - local storage or session storage
     */
    constructor(storageType) {

        if (this.isLoading) {
            return;
        }
        
        if(!this.getCookie(ACCESS_TOKEN) || !this.getCookie(SESSION_ACCESS_TOKEN)){
            this.removeCookie(ACCESS_TOKEN);
            this.removeCookie(SESSION_ACCESS_TOKEN);
            this.removeCookie(REFRESH_TOKEN);
        }

        let token = this.getToken(ACCESS_TOKEN);
        let roles = JSON.parse(this.getToken(ROLES));

        if (token) {
            runInAction(() => {
                this.token = token;
                this.roles = roles;
            });
        }
    }

    @computed
    get hasAdminAccess() {
        this.checkRoles();
        return this.roles.includes(role.ADMIN);
    }

    @computed
    get hasOrganizationAdminAccess() {
        this.checkRoles();
        return this.roles.includes(role.ORGANIZATION_ADMIN);
    }

    @computed
    get hasMedicAccess() {
        this.checkRoles();
        return this.roles.includes(role.MEDIC);
    }

    @computed
    get hasGeneralChatAccess() {
        this.checkRoles();
        return this.roles.includes(role.GENERAL_CHAT_USER);
    }

    @computed
    get hasReadingTemplateAccess() {
        this.checkRoles();
        return this.roles.includes(role.READING_TEMPLATE);
    }

    @action
    hasRole(role) {
        this.checkRoles();
        return this.roles.includes(role);
    }

    @computed
    get isLoggedIn() {
        return this.getToken(ACCESS_TOKEN);
    }

    checkRoles() {
        if (this.roles === undefined || this.roles === null) {
            this.logout();
        }
    }

    @action
    clear() {
        this.isLoading = false;
        this.id = "";
        this.token = "";
        this.roles = [];
        this.error = null;
        this.fullName = "";
        this.userName = "";
        this.email = "";
        this.organizationId = "";
        this.tokenRequest = false;
        this.changePasswordStatus = "";
        this.forgotPasswordStatus = "";
    }

    @action
    async login(userName, password) {
        this.isLoading = true;
        this.error = null;
        const deviceId = this.getCookie(DEVICE_ID) || uuidv4();

        const refreshToken = userName ? null : this.getToken(REFRESH_TOKEN);

        const headers = new Headers();
        headers.append(DEVICE_ID, deviceId);

        const options = {
            method: "POST",
            headers,
        };

        if (refreshToken) {
            options.url = REFRESH_URL;
            options.body = {
                refreshToken,
            };
        } else {
            options.url = AUTH_URL;
            options.noAuthHeader = true;
            options.body = {
                userName,
                password,
            };
        }

        // eslint-disable-next-line no-useless-catch
        try {
            const {forcePasswordChange, token, refreshToken, user} = await httpService.fetch(
                options,
            );
            if(!forcePasswordChange && token){
                this.saveLoginData(token, refreshToken, deviceId, user);
            }
            else if(forcePasswordChange){
                this.isLoading = false;
                this.userName = user?.userName;
                hist.push("/change-password");
            }
            else {
                this.error = "Prijava ni uspela.";
                this.isLoading = false;
            }
            return true;
        } catch (err) {
            this.isLoading = false;
            if (err.toString() === "Komunikacija s strežnikom ni uspela") {
                this.error = "Komunikacija s strežnikom ni uspela";
                return false;
            }
            this.error = err.message || "Prijava ni uspela.";
            return false;
        }
    }

    /**
     *
     * @param {string} tokenType - ACCESS_TOKEN or REFRESH_TOKEN
     */
    getToken(tokenType) {
        const value = this.getCookie(tokenType);
        return value ? value : null;
    }

    @action
    saveLoginData = (token, refreshToken, deviceId, user) => {

        token ? this.setCookie(ACCESS_TOKEN, token, this.getExpiresDate()) : this.removeCookie(ACCESS_TOKEN);
        token ? this.setCookie(SESSION_ACCESS_TOKEN, "session_access") : this.removeCookie(SESSION_ACCESS_TOKEN);
        refreshToken ? this.setCookie(REFRESH_TOKEN, refreshToken, this.getExpiresDate()) : this.removeCookie(REFRESH_TOKEN);
        deviceId ? this.setCookie(DEVICE_ID, deviceId) : this.removeCookie(DEVICE_ID);

        if (user) this.saveUserData(user);

        this.token = token;
        this.isLoading = false;
        this.error = null;
        MedModulesStore.getAll();
    };

    getExpiresDate() {
        var date = new Date();
        date.setTime(date.getTime() + (this.cookieExpireTime));
        return date.toUTCString();
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

    setCookie(name, value, expires) {
        if(expires)
            document.cookie = `${name}=${value};path=/;expires=${expires}`;
        else
            document.cookie = `${name}=${value};path=/`;
    }

    refreshAuthCookies() {
        const accessToken = this.getCookie(ACCESS_TOKEN);
        const refreshToken = this.getCookie(REFRESH_TOKEN);
        const roles = this.getCookie(ROLES);
        const expires = this.getExpiresDate();
        if(accessToken){
            document.cookie = `${ACCESS_TOKEN}=${accessToken};path=/;expires=${expires}`;
            document.cookie = `${REFRESH_TOKEN}=${refreshToken};path=/;expires=${expires}`;
            document.cookie = `${ROLES}=${roles};path=/;expires=${expires}`;
        }
    }

    removeCookie(name) {
        document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
    }

    async getNewToken() {
        const refreshToken = this.getToken(REFRESH_TOKEN);
        if (refreshToken) {
            await this.login();
        }

        // setInterval(this.getNewToken(), 6000)
    }

    @action
    getUserData = async () => {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: GET_USER_PROFILE,
        };

        // eslint-disable-next-line no-useless-catch
        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.saveUserData(response);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    };

    @action
    saveUserData(user) {
        this.setCookie(ROLES, JSON.stringify(user.roles), this.getExpiresDate());

        this.userName = user.userName;
        this.fullName = user.fullName;
        this.email = user.email;
        this.roles = user.roles;
        this.id = user.id;
        this.organizationId = user.organization.id;
    };

    @action
    forgotPassword = async (email) => {
        this.isLoading = true;
        this.forgotPasswordStatus = "";

        const options = {
            method: "POST",
            url: FORGOT_PASSWORD,
            body: {email: email},
        };

        // eslint-disable-next-line no-useless-catch
        try {
            await httpService.fetch(options);
            runInAction(() => {
                this.forgotPasswordStatus = "success";
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.forgotPasswordStatus = "error";
                this.isLoading = false;
                this.error = err;
            });
        }
    };

    @action
    changePassword = async (token, password) => {
        this.isLoading = true;
        this.changePasswordStatus = "";

        const options = {
            method: "POST",
            url: CHANGE_PASSWORD,
            body: {token: token, password: password},
        };

        // eslint-disable-next-line no-useless-catch
        try {
            await httpService.fetch(options);
            runInAction(() => {
                this.isLoading = false;
                this.changePasswordStatus = "success";
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
                this.changePasswordStatus = "error";
            });
        }
    };

    @action
    forcedChangePassword = async (newPassword, password) => {
        this.isLoading = true;
        const deviceId = this.getCookie(DEVICE_ID) || uuidv4();
        const options = {
            method: "POST",
            url: CHANGE_PASSWORD_FORCED,
            body: {password: password, newPassword: newPassword, userName: this.userName},
        };

        // eslint-disable-next-line no-useless-catch
        try {
            const {token, refreshToken, user} = await httpService.fetch(
                options,
            );
            runInAction(() => {
                this.saveLoginData(token, refreshToken, deviceId, user);
                this.isLoading = false;
                if(this.hasAdminAccess)
                    hist.push("/users");
                else
                    hist.push("/add-patient");
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err.message ? err.message : "Prišlo je do napake";
            });
        }
    };

    @action
    logout() {
        this.clear();
        AnamnesisStore.clear();
        NotificationsStore.clear();
        OrganizationsStore.clear();
        PatientProfileStore.clear();
        PatientReadingsStore.clear();
        PatientsStore.clear();
        ReadingsStore.clear();
        ReadingStore.clear();
        PatientPersonalDataStore.clear();
        SettingsStore.clear();
        UsersStore.clear();
        ChatStore.clear();
        TopicStore.clear();
        SosStore.clear();
        MedModulesStore.clear();

        this.removeCookie(ACCESS_TOKEN);
        this.removeCookie(SESSION_ACCESS_TOKEN);
        this.removeCookie(REFRESH_TOKEN);
        this.removeCookie(DEVICE_ID);
        this.removeCookie(ROLES);
    }
}

// singletone
export default new AuthStore();
