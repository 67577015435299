import {IConversation} from "../../interfaces/IConversation";
import {action, computed, observable, runInAction} from "mobx";
import {ASSIGN_CONVERSATION, CLOSE_CONVERSATION, CONVERSATION, UNASSIGN_CONVERSATION} from "../../config";
import httpService from "../../services/httpService";
import {IMessage} from "../../interfaces/IMessage";
import ChatStore from "./ChatStore";

export class Conversation implements IConversation {
    @observable isLoading: boolean = false;
    @observable error: string | null = null;

    @observable answered: boolean;
    @observable firstName: string | undefined;
    @observable lastName: string | undefined;
    @observable emso: string;
    @observable healthId: string;
    @observable userId: string;

    @observable newMessage: string = "";

    @observable messages: Array<IMessage> = [];

    @observable topicId: string;
    @observable isNewConversation: boolean = false;

    constructor(conversation: IConversation, topicId: string, isNewConversation?: boolean) {
        this.answered = conversation.answered;
        this.firstName = conversation.firstName;
        this.lastName = conversation.lastName;
        this.emso = conversation.emso;
        this.healthId = conversation.healthId;
        this.userId = conversation.userId.toString();

        this.topicId = topicId;
        this.isNewConversation = !!isNewConversation;
    }

    @computed
    get isMessageValid(): boolean {
        return !!this.newMessage.trim();
    }

    @computed
    get canClose() {
        if (this.answered || this.messages.length === 0) return false;
        return true;
    }

    @action
    handleChange(value: string) {
        this.newMessage = value;
    }

    @action
    async getMessages(isInitialLoad: boolean) {
        if (this.isNewConversation) return;
        this.isLoading = isInitialLoad;

        const options = {
            method: "GET",
            url: CONVERSATION.replace("{chatId}", this.topicId) + "/" + this.userId,
        };

        try {
            const response = await httpService.fetch(options);
            ChatStore.getConversations(false);

            runInAction(() => {
                // @ts-ignore
                this.messages = response;
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    async postMessage() {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: CONVERSATION.replace("{chatId}", this.topicId) + "/" + this.userId,
            body: {
                message: this.newMessage,
            },
        };
        this.newMessage = "";

        try {
            const response = await httpService.fetch(options);
            ChatStore.getConversations(false);

            runInAction(() => {
                // @ts-ignore
                this.messages = response;
                if (this.isNewConversation) this.isNewConversation = false;
                this.isLoading = false;
            });
            ChatStore.getConversations(false);
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    setAnswered(newValue: boolean) {
        this.answered = newValue;
    }

    @action
    setIsNewConversation(newValue: boolean) {
        this.answered = newValue;
    }

    @action
    async assignConversation() {
        const options = {
            method: "GET",
            url: ASSIGN_CONVERSATION
                .replace("{chatId}", this.topicId)
                .replace("{userId}", this.userId)
                .replace("{lastId}", this.messages.slice(-1)[0].id),
        };

        try {
            const response = await httpService.fetch(options);

            runInAction(() => {
                // @ts-ignore
                this.messages = response;
            });
            ChatStore.getConversations(false);
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        }
    }

    @action
    async unAssignConversation() {
        const options = {
            method: "GET",
            url: UNASSIGN_CONVERSATION
                .replace("{chatId}", this.topicId)
                .replace("{userId}", this.userId)
                .replace("{lastId}", this.messages.slice(-1)[0].id),
        };

        try {
            const response = await httpService.fetch(options);

            runInAction(() => {
                // @ts-ignore
                this.messages = response;
            });
            ChatStore.getConversations(false);
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        }
    }

    @action
    async closeConversation() {
        const options = {
            method: "GET",
            url: CLOSE_CONVERSATION
                .replace("{chatId}", this.topicId)
                .replace("{userId}", this.userId)
                .replace("{lastId}", this.messages.slice(-1)[0].id),
        };

        try {
            const response = await httpService.fetch(options);

            runInAction(() => {
                // @ts-ignore
                this.messages = response;
            });
            ChatStore.getConversations(false);
        } catch (err) {
            runInAction(() => {
                this.error = err;
            });
        }
    }
}
