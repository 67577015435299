export enum ETestResult {
    NEGATIVE = 0,
    POSITIVE = 1,
    INVALID = 2
}

export const getRapidTestResult = (value: number) => {
    switch(value) {
        case ETestResult.NEGATIVE:
            return 'Negativen';
        case ETestResult.POSITIVE:
            return 'Pozitiven';
        default:
            return 'Neveljaven';
    }
};
