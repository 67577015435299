import {observable, runInAction} from "mobx";
import React from "react";
import PropTypes from "prop-types";
import {RECORD_IMG} from "../../config";
import httpService from "../../services/httpService";

class RecordImage extends React.Component {

    firstTime = true;

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            isLoading: false,
            url: null
        };
    }

    componentDidMount() {
      this.setState({
        file: null,
        url: this.props.url,
        isLoading: false
      })
    }

    async componentDidUpdate(prevProps, prevState) {

      if (this.props.url !== prevState.url || this.firstTime) {

        this.firstTime = false;

        this.setState({
          isLoading: false,
          file: null,
          url: this.props.url
        });

        if (this.props.url !== '/') {

          let options = {
            method: "GET",
            url: `${RECORD_IMG}/${this.props.url}`
          };

          this.setState({
            isLoading: true
          });

          let response = await httpService.fetch(options);
    
          try {
              runInAction(() => {
    
                var img = URL.createObjectURL(response);
                
                this.setState({
                  isLoading: false,
                  file: img
                });
                
              });
          } catch (err) {
              runInAction(() => {
                this.setState({
                  isLoading: false,
                  file: null,
                  url: null
                });
              });
          }
      }
    }
  }

    render() {
      return (
      <div>
        {
          (this.state.isLoading) ? 'Nalagam...' : ''
        }
        {
          (this.props.url.trim() === '/') ? <h5>/</h5> : ''
        }
        <img style={{maxWidth:this.props.maxWidth, maxHeight:this.props.maxHeight}} src={this.state.file}></img>
      </div>);
    }
}

RecordImage.propTypes = {
    url: PropTypes.string,
    maxWidth: PropTypes.string,
    maxHeight: PropTypes.string
};

export default RecordImage;
