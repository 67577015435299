import React from "react";
import classnames from "classnames";
import {Container, Navbar, Button, Card} from "reactstrap";
import LinkButton from "../LinkButton";
import {getBackButtonRoutes, getDynamicBackRoutes} from "../../routes";
import {Observer} from "mobx-react-lite";
import AdminNavbarStore from "../../stores/common/AdminNavbarStore";
import RouteLeavingGuard from "../SaveChangesAlert/RouteLeavingGuard";
import {hist} from "../../index";
import {goBack} from "../../variables/goBackHelper";

class AdminNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseOpen: false,
            color: "navbar-transparent",
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateColor);
        AdminNavbarStore.updateLocation();
    }

    componentDidUpdate(e) {
        if (
            window.outerWidth < 993 &&
            e.history.location.pathname !== e.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
        }
    }

    /**
     * function that adds color white/transparent to the navbar on resize (this is for the collapse)
     */

    updateColor = () => {
        if (window.innerWidth < 993 && this.state.collapseOpen) {
            this.setState({
                color: "bg-white",
            });
        } else {
            this.setState({
                color: "navbar-transparent",
            });
        }
    };

    /**
     * this function opens and closes the sidebar on small devices
     */
    toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
    };

    /**
     * this function opens and closes the collapse on small devices
     * it also adds navbar-transparent class to the navbar when closed
     * ad bg-white when opened
     */
    toggleCollapse = () => {
        let newState = {
            collapseOpen: !this.state.collapseOpen,
        };
        if (!this.state.collapseOpen) {
            newState["color"] = "bg-white";
        } else {
            newState["color"] = "navbar-transparent";
        }
        this.setState(newState);
    };

    handleGoBack = () => {
        if (this.props.match.params.profileId) {
            goBack(this.props.match.params.profileId);
            return;
        }

        goBack(this.props.match.params.id);
    };

    showBackButton = () => {
        const dynamicRoutes = getDynamicBackRoutes();
        const backButtonRoutes = getBackButtonRoutes();
        if(this.props.history){
            const dynamicPath = dynamicRoutes.map(route => {
                if(route.regex.test(this.props.history.location.pathname)){
                    return route.path;
                }
                else
                    return null;
            }).filter(match => match);

            if(dynamicPath.length > 0){
                const savedRoute = sessionStorage.getItem("backButtonRoute");
                if(savedRoute && !savedRoute.includes(dynamicPath[0]))
                    sessionStorage.setItem("backButtonRoute", dynamicPath[0]);
                else if(!savedRoute)
                    sessionStorage.setItem("backButtonRoute", dynamicPath[0]);
            }

            const matches = backButtonRoutes.map(route => {
                return route.regex.test(this.props.history.location.pathname);
            });
            return matches.filter(match => match === true).length > 0;
        } else return false;
    };

    render() {

        return (
          <>
              <Navbar
                  className={classnames("navbar-absolute fixed-top", this.state.color)}
                  expand="lg"
              >
                  <RouteLeavingGuard
                      when={true}
                      navigate={path => {
                          hist.push(path);
                      }}
                      shouldBlockNavigation={() => {return AdminNavbarStore.shouldShowWarningOnBack;}}
                  />
                  <Container fluid>
                      <div className="navbar-wrapper">
                          <Button
                              className="btn-icon btn-round mr-3"
                              color="default"
                              onClick={(e) => {this.props.handleMiniClick(); this.toggleSidebar(); e.stopPropagation()}}
                          >
                              <i className="fa fa fa-bars" style={{fontSize: "20px"}} />
                          </Button>
                          <Observer>{() => this.showBackButton() &&
                              <LinkButton
                                  action={this.handleGoBack}
                                  text={"NAZAJ"}
                                  iconClass={"nc-minimal-left"}/>
                          }</Observer>
                      </div>
                  </Container>
              </Navbar>
          </>
        );
    }
}

export default AdminNavbar;
