import {floatToServerValue, mapReadingTypeToServerType, ReadingType} from "../components/readingTypes";
import moment from "moment";
import PatientProfileStore from "./PatientProfileStore";
import {localNumberFormat, multipleSelectString} from "../variables/formatHelpers";
import {isEmpty} from "../_utils/commonHelperFunctions";
import {localDateFormat, localDateTimeFormat} from "../variables/dateHelpers";
import {EReadingType} from "../enums/EReadingType";
import {selectReadingType, selectReadingSensorType} from "../variables/selectHelper";
import ReadingsStore from "./ReadingsStore";

const isValueValid = (value) => {
    return value !== null && value !== undefined && value !== "";
};

export const isQuestionValid = (question) => {
    return question.title && question.type && question.type.label;
};

export const isAnswerValid = (question) => {

    if (question.type.value === EReadingType.RAPID_TEST) {
        if (question.mandatory) return (question.value.image && isValueValid(question.value.int));
    }

    if (question.type.value === EReadingType.INT ||
        question.type.value === EReadingType.FLOAT ||
        question.type.value === EReadingType.BREATHING_FREQUENCY ||
        question.type.value === EReadingType.BODY_TEMPERATURE ||
        question.type.value === EReadingType.BLOOD_SUGAR ||
        question.type.value === EReadingType.SATURATION ||
        question.type.value === EReadingType.WEIGHT) {

        if (question.mandatory && !isValueValid(question.value))
            return false;

        if (isValueValid(question.value)) {
            return (!question.webLimits ||
            (question.webLimits && question.webLimits.a && question.webLimits.a.maxValue >= parseFloat(question.value) &&
            question.webLimits.a.minValue <= parseFloat(question.value)));
        }
    }

    if (question.type.value === EReadingType.DATE ||
        question.type.value === EReadingType.DATE_TIME) {

        if (question.mandatory && !isValueValid(question.value))
            return false;

        if (isValueValid(question.value)) {
            return (!question.webLimits ||
            (question.webLimits && question.webLimits.a && moment(question.webLimits.a.maxValue).isSameOrAfter(moment(question.value)) &&
            moment(question.webLimits.a.minValue).isSameOrBefore(moment(question.value))));
        }
    }

    if (question.type.value === EReadingType.INT_PAIR ||
        question.type.value === EReadingType.FLOAT_PAIR ||
        question.type.value === EReadingType.BLOOD_PRESSURE) {

        if (!question.value) return true;

        if (question.mandatory && (!isValueValid(question.value.a) || !isValueValid(question.value.b))) {
            return false;
        }

        if ((isValueValid(question.value.a) && !isValueValid(question.value.b)) || (!isValueValid(question.value.a) && isValueValid(question.value.b))) {
            return false;
        }

        if (isValueValid(question.value.a) && isValueValid(question.value.b)) {
            return (!question.webLimits ||
            (question.webLimits && question.webLimits.a && question.webLimits.a.maxValue >= parseFloat(question.value.a) &&
            question.webLimits.a.minValue <= parseFloat(question.value.a) &&
            question.webLimits.b && question.webLimits.b.maxValue >= parseFloat(question.value.b) &&
            question.webLimits.b.minValue <= parseFloat(question.value.b)));
        }
    }

    /*if (question.type.value === ReadingType.MULTI_IMAGE) {
        return question.value.length !== 0;
    }*/

    const type = mapReadingTypeToServerType(question.type.value);
    if (!question.mandatory) return true;
    if (type === "bool") {
        return true;
    }
    if (question.type.value === ReadingType.MULTIPLE_SELECTION) return question.value.length !== 0;
    return isValueValid(question.value);
};

export const mapQuestionToRecord = (question) => {

    if (question.type.value === ReadingType.RAPID_TEST) {
        return {
            readingId: question.id,
            image: question.value.image,
            int: question.value.int
        }
    }

    const valueType = mapReadingTypeToServerType(question.type.value);
    let value = question.value;
    if (valueType === "int") value = parseInt(value);
    else if (valueType === "float") value = parseFloat(value);
    else if (valueType === "intPair") {
        if (question.value && question.value.a && question.value.b) {
            value = {a: parseInt(question.value.a), b: parseInt(question.value.b)};
        } else {
            value = null;
        }
    } else if (valueType === "floatPair") {
        if (question.value && question.value.a && question.value.b) {
            value = {a: parseFloat(question.value.a), b: parseFloat(question.value.b)};
        } else {
            value = null;
        }
    } else if (valueType === "bool") {
        value = question.value || false;
    }

    return {
        readingId: question.id,
        [valueType]: value,
    };
};

export const validateQuestion = (question, pairName) => {
    const type = mapReadingTypeToServerType(question.type.value);
    if (!question.mandatory && type !== "intPair" && type !== "floatPair") return;

    if (pairName) {
        if (!question.value) question.value = {};
        if (!question.validationStatus) question.validationStatus = {};

        if (!question.value.a && !question.value.b && !question.mandatory) return {a: "", b: ""};

        return {...question.validationStatus, [pairName]: !question.value[pairName] ? "has-danger" : "has-success"};
    } else {
        if (type === "intPair" || type === "floatPair") {
            if (question.mandatory) {
                return {
                    a: !(question.value && question.value.a) ? "has-danger" : "",
                    b: !(question.value && question.value.b) ? "has-danger" : "",
                };
            } else {
                let stateA = "";
                let stateB = "";
                if (question.value) {
                    if (!question.value.a && question.value.b) {
                        stateA = "has-danger";
                    } else if (!question.value.b && question.value.a) {
                        stateB = "has-danger";
                    }
                }
                return {a: stateA, b: stateB};
            }
        }
        if (type === "bool") {
            return true;
        }
        return !question.value ? "has-danger" : "has-success";
    }
};

export const getReadingValueByReadingType = (reading) => {
    if (!reading) return "/";

    if(reading.type === ReadingType.BOOL)
        return reading.value || ( reading.latestRecord && reading.latestRecord[mapReadingTypeToServerType(reading.type)]) ? "DA" : "NE";

    if (reading.value === undefined) {
        if (reading.latestRecord) {
            if (ReadingType.RAPID_TEST === reading.type) {
                reading.value = { int: reading.latestRecord.int, image: reading.latestRecord.image }
            } else {
                reading.value = reading.latestRecord[mapReadingTypeToServerType(reading.type)];
            }
        } else {
            return "/";
        }
    }

    if (!reading.value) return "/";

    switch (reading.type) {
        case(ReadingType.FLOAT):
        case(ReadingType.BODY_TEMPERATURE):
        case(ReadingType.BLOOD_SUGAR):
        case(ReadingType.SATURATION):
            return localNumberFormat(reading.value);
        case(ReadingType.INT_PAIR):
        case(ReadingType.BLOOD_PRESSURE):
            return `${reading.value.a} - ${reading.value.b}`;
        case(ReadingType.FLOAT_PAIR):
            return `${localNumberFormat(reading.value.a)} - ${localNumberFormat(reading.value.b)}`;
        case(ReadingType.LIKERT_SCALE_5PT):
        case(ReadingType.MULTI_STATE):
            return PatientProfileStore.getMultiStateAnswer(reading.id, reading.value)?.value ?? "/";
        case(ReadingType.DATE):
            return localDateFormat(reading.value);
        case(ReadingType.DATE_TIME):
            return localDateTimeFormat(reading.value, {hour: "2-digit", minute: "2-digit"});
        case(ReadingType.MULTIPLE_SELECTION):
            return multipleSelectString(reading.id, reading);
        default:
            return reading.value;
    }
};

export const mapToNormalReading = (reading, type, recordTimestamp) => {
    let value = getNormalValue(reading, type);
    const pair = type === ReadingType.INT_PAIR || type === ReadingType.FLOAT_PAIR || type === ReadingType.BLOOD_PRESSURE;
    return {
        createdAt: recordTimestamp ? moment(recordTimestamp) : moment(reading.created),
        readingId: reading?.readingId,
        value: value !== undefined ? value : pair ? {a: null, b: null} : null,
    };
};

const getNormalValue = (payload, type) => {
    return payload ? payload[mapReadingTypeToServerType(type)] : null;
};

export function handleChange(store, index, value, type, pairName) {

    let reading = null;

    if (type === ReadingsStore.SECONDARY_READING_ACCESSOR) {
        reading = ReadingsStore.getSecondaryReading(value.id);
    } else {
        reading = store[type][index];
    }

    if (pairName) {
        if (!reading.value) {
            reading.value = {};
        }
        reading.value[pairName] = value.value;
        // needed to refresh the observable. Proper solution would be to create a new store for each Reading
        reading.refresher = !reading.refresher;
        return;
    }

    const readingType = reading.type;

    if (readingType.value === ReadingType.SATURATION && (value.value < 0 || value.value > 100)) return;
    if (readingType.value === ReadingType.BODY_TEMPERATURE && (value.value < 0 || value.value > 999)) return;

    if (readingType.value === ReadingType.MULTIPLE_SELECTION) {
        const indexOf = reading.value.indexOf(value.value);

        if (indexOf === -1) {
            reading.value.push(value.value);
            return;

        } else {
            reading.value = reading.value.filter(item => item !== value.value);
            return;
        }
    }

    if (readingType.value === ReadingType.RAPID_TEST) {

        if (!reading.value) {
            reading.value = { image: null, int: null };
        }

        if (value.value.image != null) {
            reading.value.image = value.value.image;
        }

        if (value.value.int != null) {
            reading.value.int = value.value.int;
        }

        return;
    }

    reading.value = value.value;
}

export function handleChangeForNonArray(store, type, value, pairName) {
    if (pairName) {
        if (!store[type].value) {
            store[type].value = {};
        }
        store[type].value[pairName] = value;
        // needed to refresh the observable. Proper solution would be to create a new store for each Reading
        store[type].refresher = !store[type].refresher;
        return;
    }


    const readingType = store[type].type;

    if (readingType.value === ReadingType.SATURATION && (value < 0 || value > 100)) return;
    if (readingType.value === ReadingType.BODY_TEMPERATURE && (value < 0 || value > 999)) return;

    if (readingType.value === ReadingType.MULTIPLE_SELECTION) {
        if (!(store[type].value instanceof Array)) store[type].value = [];
        const indexOf = store[type].value.indexOf(value);

        if (indexOf === -1) {
            store[type].value.push(value);
            return;

        } else {
            store[type].value = store[type].value.splice(indexOf, 1);
            return;
        }
    }

    store[type].value = value;
}

export function cleanQuestionValue(question) {
    if(!question.value){
        question.value = null;
        return question;
    }
    if (question.type.value === ReadingType.BOOL) question.value = question.value || false;
    if (question.type.value === ReadingType.INT_PAIR || question.type.value === ReadingType.BLOOD_PRESSURE) {
        if (!question.value.a && !question.value.b) question.value = null;
    }
    if (question.type.value === ReadingType.FLOAT_PAIR) {
        if (question.value.a)
            question.value.a = floatToServerValue(question.value.a);
        if (question.value.b)
            question.value.b = floatToServerValue(question.value.b);
        if (!question.value.a && !question.value.b) question.value = null;
    }
    if (mapReadingTypeToServerType(question.type.value) === "float") question.value = floatToServerValue(question.value);

    if (question.type.value === ReadingType.DATE ||
        question.type.value === ReadingType.DATE_TIME) question.value = question.value ? question.value.toISOString() : null;

    return question;
}

export function shouldShowWarningOnBackNavigation(data) {
    return data.some(answer => {
        if (isEmpty(answer.value)) return false;
        if(answer.type.value === ReadingType.MULTIPLE_SELECTION && answer.value.length > 0) return true;
        if(answer.type.value === ReadingType.DATE || answer.type.value === ReadingType.DATE_TIME) return true;
        return !(typeof answer.value === "object" && isEmpty(answer.value.a) && isEmpty(answer.value.b));
    });
}

export function getEmptyValueByType(type, minValue=0) {
    if (type === ReadingType.INT_PAIR ||
        type === ReadingType.FLOAT_PAIR) return {a: "", b: ""};

    if (type === ReadingType.MULTIPLE_SELECTION) return [];

    if (type === ReadingType.SLIDER) return minValue;

    return "";
}

export function mapReadingQuestionForServer(reading) {
    const response = {
        id: reading.id || null,
        title: reading.title,
        description: reading.description,
        type: reading.templateId != null ? null : reading.type.value,
        sensorType: reading.sensorType.value,
        templateId: reading.templateId,
        mandatory: reading.mandatory,
        statusText: reading.statusText,
        descMedia: reading.descMedia,
        minImages: reading.minImages,
        maxImages: reading.maxImages,
        webLimits: mapReadingLimit(reading)
    };

    switch (reading.type.value) {
        case EReadingType.LIKERT_SCALE_5PT:
        case EReadingType.MULTI_STATE:
            response.multiStateOptions = mapMultiStateOptionForServer(reading);
            break;
        case EReadingType.MULTIPLE_SELECTION:
            response.multiStateOptions = mapMultipleOptionForServer(reading);
            break;
        case EReadingType.SLIDER:
            response.sliderOptions = reading.sliderOptions;
            break;
        default:
            break;
    }

    return response;
}

function mapReadingLimit(reading) {
    let webLimits = {};
    if (reading.webLimits) {
        if (reading.webLimits.a && reading.webLimits.a.minValue !== '' && reading.webLimits.a.maxValue !== '') {
            webLimits.a = reading.webLimits.a;
        }

        if (reading.webLimits.b && reading.webLimits.b.minValue !== '' && reading.webLimits.b.maxValue !== '' ) {
            webLimits.b = reading.webLimits.b;
        }

        return webLimits;
    }

    return null;
}

function mapMultiStateOptionForServer(reading) {
    return reading.multiStateOptions.map(state => {
        const response = {};

        if (state.id && !state.id.startsWith("temp-id")) response.id = state.id;
        response.value = state.value;
        response.color = state.color;
        response.intValue = state.intValue;

        return response;
    });
}

function mapMultipleOptionForServer(reading) {
    return reading.multipleSelectionOptions.map(state => {
        const response = {};

        if (state.id && !state.id.startsWith("temp-id")) response.id = state.id;
        response.value = state.value;
        response.color = state.color;
        response.intValue = state.intValue;

        return response;
    });
}

export function mapReadingQuestionFromServerToStore(reading) {
    return {
        id: reading.id,
        title: reading.title,
        description: reading.description,
        type: selectReadingType(reading.type),
        sensorType: selectReadingSensorType(reading.sensorType),
        templateId: reading.templateId,
        mandatory: reading.mandatory,
        statusText: reading.statusText,
        minImages: reading.minImages,
        maxImages: reading.maxImages,
        multiStateOptions: reading.multiStateOptions.map(state => {
            return {
                id: state.id,
                value: state.value,
                label: state.value,
                color: state.color ? state.color : "#000000",
                intValue: state.intValue,
            };
        }),
        multipleSelectionOptions:
            reading.type === ReadingType.MULTIPLE_SELECTION ||
            reading.type.value === ReadingType.MULTIPLE_SELECTION ?
                reading.multiStateOptions.map(state => {
                    return {
                        id: state.id,
                        value: state.value,
                        color: state.color,
                        intValue: state.intValue
                    };
                }) : [],
        descMedia: reading.descMedia,
        value: getEmptyValueByType(reading.type, reading.sliderOptions?.minValue),
        webLimits: reading.webLimits,
        sliderOptions: reading.sliderOptions,
    };
}

export function mapSeacondaryReadingsSectionFromServerToStore(section, index) {
    return {
        id: index.toString(),
        text: section.text,
        subText: section.subText,
        calculationType: section.calculationType,
        readings: section.readings ? section.readings.map(reading => mapReadingQuestionFromServerToStore(reading)) : []
    }
}

export function mapSeacondaryReadingsSectionForServer(section) {
    return {
        text: section.text,
        subText: section.subText,
        calculationType: section.calculationType,
        readings: section.readings ? section.readings.map(reading => mapReadingQuestionForServer(reading)) : []
    }
}
