export const cellStyle = {
    display: "flex",
    alignItems: "center",
    "& p": {
        marginBottom: "0"
    },
    whiteSpace: "break-spaces"
};

export const centerInputX = {
    backgroundPosition: "center right calc(0.375em + 0.1875rem)"
};