import React from "react";
import {FormGroup, Input, Label} from "reactstrap";
import PropTypes from "prop-types";
import "./DynamicFormMultiState.css";

const DynamicFormMultipleSelection = (props) => {
    const handleChange = (event, value) => {
        props.handleChange(props.index, {id: props.id, value: value}, props.readingType);
    };

    return (
        <React.Fragment key={props.name}>
            <Label>{props.title} {props.mandatory && "*"}</Label>
            <FormGroup className={props.validationStatus}>
                {props.multiStateOptions.map(option =>
                    <div key={option.id} className="form-check form-check-inline">
                        <Label check style={{"paddingTop": "3px"}}>
                            <Input
                                type="checkbox"
                                name={props.id}
                                id={option.id}
                                value={option.value}
                                onChange={(event) => {
                                    handleChange(event, option.id);
                                    props.validateField && props.validateField(props.index, {id: props.id, type: props.readingType});
                                }}
                            />
                            {option.value.toUpperCase()}
                            <span className="form-check-sign"/>
                        </Label>
                    </div>)
                }
                {props.validationStatus === "has-danger" &&
                <label className="error">
                    Vprašanje je obvezno
                </label>}
            </FormGroup>
        </React.Fragment>
    );
};

DynamicFormMultipleSelection.propTypes = {
    handleChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    readingType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    multiStateOptions: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    mandatory: PropTypes.bool,
    validationStatus: PropTypes.string,
    validateField: PropTypes.func,
};

export default DynamicFormMultipleSelection;
