import React from "react";
import {Col, Form} from "reactstrap";
import AnamnesisStore from "../../stores/AnamnesisStore";
import {Observer} from "mobx-react-lite";
import DynamicFormInput from "../../components/dynamicForm/DynamicFormInput";
import {getReadingMaxInputLength} from "../../enums/vitals";
import Loader from "../../components/Loader";

export class PatientRegistrationAnamnesis extends React.Component {
    componentDidMount() {
        AnamnesisStore.getAnamnesis();
    }

    isValidated = () => {
        const status = AnamnesisStore.isAnamnesisValid;
        if (!status) {
            AnamnesisStore.validateAll();
        }
        return status;
    };

    render() {
        return (
            <Observer>{() =>
                <Col md={9} style={{"margin": "auto"}}>
                    <Loader isLoading={AnamnesisStore.isLoading} />
                    <Form>
                        {AnamnesisStore.anamnesis.map((question, index) => <DynamicFormInput
                            key={question.id}
                            {...question}
                            handleChange={AnamnesisStore.handleChange}
                            index={index}
                            name={question.title}
                            readingType="anamnesis"
                            validationStatus={question.validationStatus}
                            validateField={AnamnesisStore.validateField}
                            value={AnamnesisStore.anamnesis[index].value}
                            maxlength={getReadingMaxInputLength(question)}
                        />)}
                    </Form>
                </Col>
            }</Observer>
        );
    }
}
