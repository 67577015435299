import React, { useState } from "react";
import {FormGroup, Input, Label} from "reactstrap";
import PropTypes from "prop-types";
import "./DynamicFormMultiState.css";

const DynamicFormMultiState = (props) => {

    const [checkedId, setCheckedId] = useState(-1);
    
    React.useEffect(()=> {
        if((props.checked != '' && props.checked != undefined) && props.checked != checkedId) {
        setCheckedId(props.checked);
        }
      }, [props.checked]);

    const handleChange = (event, value) => {
        props.handleChange(props.index, {id: props.id, value: value}, props.readingType);
    }

    return (
        <React.Fragment key={props.name}>
            <Label>{props.title} {props.mandatory && "*"}</Label>
            <FormGroup className={props.validationStatus}>
                {props.multiStateOptions.map(option =>
                    <div key={option.id} className="form-check-radio form-check-inline">
                        <Label check style={{"paddingTop": "3px"}}>
                            <Input
                                type="radio"
                                name={props.id}
                                id={option.id}
                                value={option.value}
                                checked={(option.id == checkedId) ? 'checked' : ''}
                                onChange={(event) => {
                                    setCheckedId(option.id);
                                    handleChange(event, option.id);
                                    props.validateField && props.validateField(props.index, {id: props.id, type: props.readingType});
                                }}
                            />
                            {option.value.toUpperCase()}
                            <span className="form-check-sign"/>
                        </Label>
                        {option.moreInfo && option.moreInfo}
                    </div>)
                }
                {props.validationStatus === "has-danger" &&
                <label className="error">
                    Vprašanje je obvezno
                </label>}
            </FormGroup>
        </React.Fragment>
    );
};

DynamicFormMultiState.propTypes = {
    handleChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    readingType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    multiStateOptions: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    mandatory: PropTypes.bool,
    checked: PropTypes.string
};

export default DynamicFormMultiState;
