import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
     ${props => props.isDark && `
        background-color:rgba(0, 0, 0, 0.4)
     `}
`;

const LoaderWrapper = styled.div`
    width: 100px;
    height: 100px;
`;

const Loader = ({isLoading, isDark}) => {
    return (
        isLoading ?
            <Overlay isDark={isDark}>
                <LoaderWrapper className={`spinner-border ${isDark? "text-light" : "text-dark"}`} role="status">
                    <span className="sr-only">Loading...</span>
                </LoaderWrapper>
            </Overlay>
            : null
    );
};

Loader.propTypes = {
    isLoading: PropTypes.bool,
    isDark: PropTypes.bool
};

export default Loader;
