import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Lottie from "lottie-react-web";
import ReadingStore from "../../stores/ReadingStore";
import {Observer} from "mobx-react-lite";
import {toJS} from "mobx";

const FileInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

const FileButtonText = styled.label`
    margin-bottom: 0 !important;
    color: white !important;
    cursor: pointer;
    pointer-events: none;
    font-size: 12px !important;
`;

const FileButton = styled.span`
    margin-bottom: 19px !important;
`;

const AnimationUpload = ({file, setFile, url, removeFile}) => {
    const [fileName, setFileName] = useState();
    const inputButton = useRef(null);

    useEffect(() => {
        if (file) {
            setFileName(file.name);
            var reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = function(evt) {
                ReadingStore.animData = JSON.parse(evt.target.result);
            };
            reader.onerror = function() {
                ReadingStore.animData = null;
            };
        }
        else {
            ReadingStore.animData = null;
            setFileName(null);
        }
    }, [file]);

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setFile(null);
            return;
        }
        setFile(e.target.files[0]);
        removeFile();
    };

    const inputClicked = () => {
        if(inputButton && inputButton.current) inputButton.current.click();
    };

    const remove = () => {
        removeFile();
        setFile(null);
        ReadingStore.animData = null;
    };

    return (
        <>
            <Observer>{() =>
                <div>
                    {ReadingStore.animData &&
                    <div style={{width: "300px"}}>
                        <Lottie
                            options={{
                                animationData: toJS(ReadingStore.animData)
                            }}
                        />
                    </div>
                    }

                    <FileInput type="file" name="file" id="file" className="inputfile" onChange={onSelectFile}/>
                    {!file && !url ?(
                        <FileButton style={{width: "170px"}} className="btn btn-raised btn-round btn-default btn-file" onClick={inputClicked}>
                            <FileButtonText ref={inputButton} htmlFor="file" className="fileinput-new">Dodaj Animacijo</FileButtonText>
                        </FileButton>
                    ):(
                        <>
                            <FileButton className="btn btn-raised btn-round btn-default btn-file" onClick={inputClicked}>
                                <FileButtonText ref={inputButton} htmlFor="file" className="fileinput-new">Spremeni Animacijo</FileButtonText>
                            </FileButton>
                            <FileButton className="btn btn-raised btn-round btn-danger btn-file" onClick={remove}>
                                <FileButtonText ref={inputButton} htmlFor="file" className="fileinput-new">Odstrani</FileButtonText>
                            </FileButton>
                        </>
                    )
                    }
                </div>
            }</Observer>
        </>
    );
};

AnimationUpload.propTypes = {
    file: PropTypes.any,
    setFile: PropTypes.func,
    url: PropTypes.string,
    removeFile: PropTypes.func,
};

export default AnimationUpload;