import {action, computed, observable, runInAction} from "mobx";
import {GET_PATIENT_READING, GET_PATIENT_READINGS, POST_UPDATE_RAPIDTEST_RECORD, REVIEW_READING} from "../config";
import httpService from "../services/httpService";
import {getReadingValueByReadingType, mapToNormalReading} from "./CommonAnamnesisReadings";
import {selectReadingType} from "../variables/selectHelper";
import {localDateFormat, localDateTimeFormat, localeTimeFormat} from "../variables/dateHelpers";
import PatientProfileStore from "./PatientProfileStore";
import AuthStore from "./AuthStore";
import {mapReadingTypeToServerType, ReadingType} from "../components/readingTypes";
import {multipleSelectString, multipleSelectSum} from "../variables/formatHelpers";
import {getModuleId} from "../variables/fetchHelper";
import {hist} from "../index";

class PatientReadingsStore {
    @observable isLoading = false;
    @observable rawData = [];
    @observable readingIdToReadingTypeMap = {};
    @observable selectedReadingId = "none";
    @observable showGraph = false;

    constructor() {
    }

    @computed
    get dropdownPlaceHolder() {
        return {
            value: "none",
            label: this.hasData ? "Vse meritve" : "Ni meritev",
            isChecked: this.rawData.filter(reading => !reading.isChecked).length === 0,
        };
    }

    @computed
    get hasData() {
        return this.rawData.length !== 0;
    }

    @computed
    get getModuleId() {
        return getModuleId(hist);
    }

    @computed
    get dataForGraphs() {
        const ret = {};

        this.rawData
            .forEach(record => {
                Object.keys(this.readingIdToReadingTypeMap).forEach(key => {
                    const id = key;
                    const type = this.readingIdToReadingTypeMap[key];

                    const reading = record.readingRecords.find(_reading => _reading.readingId === id);
                    const item = mapToNormalReading(reading, type.value, record.created);
                    if (reading) {
                        switch (type.value) {
                            case(ReadingType.LIKERT_SCALE_5PT):
                            case(ReadingType.MULTI_STATE): {
                                const multistateAnswer = (this.showGraph === true) ?
                                PatientProfileStore.getMultiStateAnswer(item.readingId, item.value)?.intValue :
                                PatientProfileStore.getMultiStateAnswer(item.readingId, item.value)?.value;

                                if (multistateAnswer)
                                    item.value = multistateAnswer;
                                break;
                            }
                            case(ReadingType.RAPID_TEST): {
                                item.value = { image: reading.image, int: reading.int };
                                break;
                            }
                            case(ReadingType.DATE):
                                if (item.value)
                                    item.value = localDateFormat(item.value);
                                break;
                            case(ReadingType.DATE_TIME):
                                if (item.value)
                                    item.value = localDateTimeFormat(item.value, {hour: "2-digit", minute: "2-digit"});
                                break;
                            case(ReadingType.MULTIPLE_SELECTION):
                                item.value = (this.showGraph === true) ?
                                multipleSelectSum(reading.readingId, item) :
                                multipleSelectString(reading.readingId, item);
                                break;
                        }
                    }
                    if (ret[id]) {
                        ret[id].push(item);
                    } else {
                        ret[id] = [item];
                    }
                });
            });
        return ret;
    }

    @computed
    get processedDataForDropdown() {
        const dates = [];

        this.rawData.forEach(reading => {
            const isoDateString = reading.created.slice(0, 10);
            if (dates.indexOf(isoDateString) === -1) dates.push(isoDateString);
        });

        if (dates.length > 1) {
            return [this.dropdownPlaceHolder]
                .concat(dates.map(date => {
                    const readings = this.rawData.filter(data => data.created.slice(0, 10) === date);

                    return {
                        label: localDateFormat(new Date(date)),
                        options: readings.map(reading => this.mapReadingForDropdown(reading, false)),
                    };
                }));
        }

        return [this.dropdownPlaceHolder]
            .concat(this.rawData.map(reading => this.mapReadingForDropdown(reading, localDateTimeFormat)));
    }

    @computed
    get selectedReadingForDropdown() {
        const id = this.selectedReadingId ?? this.dropdownPlaceHolder.value;
        let reading;
        this.processedDataForDropdown.forEach(item => {
            if (item.options) {
                item.options.forEach(option => {
                    if (option.value === id) reading = option;
                });
            } else {
                if (item.value === id) reading = item;
            }
        });
        if (reading) return reading;
        else return this.dropdownPlaceHolder;
    }

    @computed
    get selectedChecked() {
        return (this.selectedReadingId === "none" && this.dropdownPlaceHolder.isChecked) || (this.selectedReadingId !== "none" && this.selectedReadingForDropdown.isChecked);
    }

    @computed
    get readingsForDropdown() {
        return this.processedDataForDropdown;
    }

    @computed
    get selectedReadingOrLatest() {
        return this.selectedReading || this.rawData[0];
    }

    @computed
    get selectedReading() {
        return this.rawData.find(item => item.recordReadingId === this.selectedReadingId);
    }

    @computed
    get selectedPrimaryReadingsForUserDiseaseStatus() {
        if (!this.hasData) return [];

        return PatientProfileStore.latestReadings.primaryReadingTypes.map(item => this.mapReadingForUserDiseaseStatus(item));
    }

    @computed
    get selectedSecondaryReadingsForUserDiseaseStatus() {
        if (!this.hasData) return [];

        return PatientProfileStore.latestReadings.secondaryReadingTypes.map(item => this.mapReadingForSecondaryUserDiseaseStatus(item));
    }

    @computed
    get selectedSecondarySectionsReadingsForUserDiseaseStatus() {
        if (!this.hasData) return [];
        
        return PatientProfileStore.latestReadings.secondarySections?.map(item => ({
            text: item.text,
            calculationType: item.calculationType,
            calculationResult: item.calculationResult,
            readings: item.readings?.map(item => this.mapReadingForSecondaryUserDiseaseStatus(item))
        }))
    }

    @computed
    get selectedReadingTimestamp() {
        if (!this.selectedReadingId || (this.selectedReadingId === this.dropdownPlaceHolder.value)) {
            if (this.rawData.length > 0) return this.rawData[0].created;
            return "No data";
        }
        return this.selectedReadingOrLatest.created;
    }

    @computed
    get canClickNext() {
        return this.rawData.findIndex(item => item.recordReadingId === this.selectedReadingId) !== this.rawData.length - 1;
    }

    @computed
    get canClickPrevious() {
        return this.rawData.findIndex(item => item.recordReadingId === this.selectedReadingId) !== -1;
    }

    @computed
    get canReview() {
        return this.selectedReadingForDropdown.value !== "none" && !this.selectedReadingForDropdown.isChecked;
    }

    @action
    getSecondarySectionCalculationResult(recordReadingIndex, sectionIndex) {
        return this.rawData[recordReadingIndex].secondarySectionsCalculationResults[sectionIndex];
    }

    @action
    selectNextRecord() {
        let oldIndex = this.rawData.findIndex(item => item.recordReadingId === this.selectedReadingId);
        const length = this.rawData.length;

        if (oldIndex === length - 1) return;
        this.selectedReadingId = this.rawData[oldIndex + 1].recordReadingId;
    }

    @action
    selectPreviousRecord() {
        let oldIndex = this.rawData.findIndex(item => item.recordReadingId === this.selectedReadingId);

        if (oldIndex === -1) return;
        this.selectedReadingId = oldIndex !== 0 ? this.rawData[oldIndex - 1].recordReadingId : this.dropdownPlaceHolder.value;
    }

    mapReadingForUserDiseaseStatus(item) {
        const record = this.selectedReadingOrLatest.readingRecords?.find(_item => _item.readingId === item.id);
        const type = this.readingIdToReadingTypeMap[item.id].value;
        let value = undefined; 
        if (record !== undefined) {
            if (type === ReadingType.RAPID_TEST) {
                value = {image: record.image, int: record.int, string: record.string, changedBy: record.changedBy, changedAt: record.changedAt}
            } else {
                value = record[mapReadingTypeToServerType(type)];
            }
        }

        return {
            id: item.id,
            type: type,
            title: item.title,
            unit: item.unit,

            value: value,
            stringValue: getReadingValueByReadingType({value: value, type: type}),
        };
    }

    mapReadingForSecondaryUserDiseaseStatus(item) {
        const record = this.selectedReadingOrLatest.readingRecords?.find(_item => _item.readingId === item.id);
        const type = this.readingIdToReadingTypeMap[item.id].value;

        return {
            id: item.id,
            type: type,
            title: item.title,
            unit: item.unit,

            latestRecord: record,
        };
    }

    @action
    async getReadingData(patientId, readingId, fromDate, readingType, toDate) {
        this.isLoading = true;

        this.readings[readingId] = [];

        const options = {
            method: "POST",
            url: GET_PATIENT_READING,
            body: {
                "medModuleId": getModuleId(hist),
                "userId": patientId,
                "readingId": readingId,
                "fromDate": fromDate,
                "toDate": toDate,
            },
        };

        try {
            const response = await httpService.fetch(options);

            runInAction(() => {
                this.isLoading = false;
                this.readings[readingId] = response.map(reading => mapToNormalReading(reading, readingType));
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    async getReadingsData(patientId, fromDate, toDate) {
        this.isLoading = true;
        this.selectedReadingId = this.dropdownPlaceHolder.value;
        const options = {
            method: "POST",
            url: GET_PATIENT_READINGS,
            body: {
                "medModuleId": getModuleId(hist),
                "userId": patientId,
                "fromDate": fromDate,
                "toDate": toDate,
            },
        };


        try {
            const response = await httpService.fetch(options);

            runInAction(() => {
                this.rawData = response;
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    async updateRapidTest(record, patientId, int, comment) {
        this.isLoading = true;
        let recordReadingId = this.selectedReadingOrLatest.recordReadingId;

        const options = {
            method: "POST",
            url: POST_UPDATE_RAPIDTEST_RECORD,
            body: {
                "moduleId": getModuleId(hist),
                "userId": patientId,
                "recordReadingId": recordReadingId,
                "readingId": record.id,
                "int": int,
                "comment": comment
            }
        }

        try {
            const response = await httpService.fetch(options);

            runInAction(() => {
                //update selected value to new one if request is ok
                let fnd = this.rawData.find(i => i.recordReadingId == recordReadingId)
                    if(fnd) {
                      let rec = fnd.readingRecords.find(i => i.readingId == record.id)
                      if(rec) {
                        rec.int = int;
                        rec.string = comment;
                        rec.changedBy = AuthStore.fullName;
                        rec.changedAt = new Date().toISOString();
                      }
                    }
                this.isLoading = false;
            })
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            })
        }
    }

    @action
    setReadingIdToTypeMap(payload) {
        const primaryReadings = payload.primaryReadingTypes;
        const secondaryReadings = payload.secondaryReadingTypes;

        primaryReadings.concat(secondaryReadings).forEach(reading => this.readingIdToReadingTypeMap[reading.id] = selectReadingType(reading.type));
    }

    @action
    setSectionReadingIdToTypeMap(sections) {
        sections.forEach(section => { 
            section.readings.forEach(reading => this.readingIdToReadingTypeMap[reading.id] = selectReadingType(reading.type))
        })
    }

    @action
    clear() {
        this.isLoading = false;
        this.readings = {};
    }

    @action
    selectReading(item) {
        this.selectedReadingId = item.value;
    }


    mapReadingForDropdown(reading, showDate) {
        const label = reading.created ?
            showDate ?
                localDateTimeFormat(new Date(reading.created)) :
                localeTimeFormat(reading.created, {
                    hour: "2-digit",
                    minute: "2-digit",
                }) :
            "/";
        const appendix = reading.isChecked ? "" : "";

        return {
            value: reading.recordReadingId,
            label: label + appendix,
            longLabel: localDateTimeFormat(new Date(reading.created)) + appendix,
            isChecked: reading.isChecked,
        };
    }

    @action
    selectRecordByGraphIndex(index) {
        const selector = this.rawData.length - 1 - index;
        if (selector >= 0 && selector < this.rawData.length)
            this.selectedReadingId = this.rawData[selector].recordReadingId;
    }

    @action
    selectRecordByGraphExactIndex(index) {
        this.selectedReadingId = this.rawData[index].recordReadingId;
    }

    @action
    async reviewSelected(patientId) {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: REVIEW_READING,
            body: {
                recordReadingId: this.selectedReadingId,
                moduleId: getModuleId(hist),
                userId: patientId,
            },
        };

        try {
            await httpService.fetch(options);

            runInAction(() => {
                this.rawData.find(reading => reading.recordReadingId === this.selectedReadingId).isChecked = true;
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    registerEventListener() {
        window.addEventListener("keydown", this.eventListenerForReadingNavigation);
    }

    @action
    unregisterEventListener() {
        window.removeEventListener("keydown", this.eventListenerForReadingNavigation);
    }

    eventListenerForReadingNavigation = (event) => {
        if (event.key === "ArrowLeft") {
            event.preventDefault();
            this.selectNextRecord();
        } else if (event.key === "ArrowRight") {
            event.preventDefault();
            this.selectPreviousRecord();
        }
    }
}

export default new PatientReadingsStore();
