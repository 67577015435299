import React from "react";
import styled from "styled-components";

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoaderWrapper = styled.div`
    width: 100px;
    height: 100px;
`;

interface IProps {
    isLoading: boolean
}

const ChatLoader = ({isLoading}: IProps) => {
    return (
        isLoading ?
            <Overlay>
                <LoaderWrapper className={"spinner-border text-dark"} role="status">
                    <span className="sr-only">Loading...</span>
                </LoaderWrapper>
            </Overlay>
            : null
    );
};

export default ChatLoader;
