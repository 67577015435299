import React, { useState } from "react";
import {Button, FormGroup, Input, Label, Modal, ModalBody} from "reactstrap";
import PropTypes from "prop-types";
import DynamicFormMultiState from "./DynamicFormMultiState";
import { ETestResult, getRapidTestResult } from "enums/ETestResult";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import PatientReadingsStore from "stores/PatientReadingsStore";

const StyledModal = styled(Modal)`
  word-break: break-all;
`;

const StyledTooltip = styled.div`

    position: relative;
    display: inline-block;
    color: #17a2b8;
  
    .tooltiptext {
        visibility: hidden;
        width: 300px;
        background-color: #555;
        color: #fff;
        text-align: left;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -150px;
        opacity: 0;
        transition: opacity 0.3s;
        padding-left: 16px;
        font-size: 12px !important;
    }
  
    .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }
  
    :hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
`;

const DynamicFormReadingEdit = (props) => {

    const [show, setShow] = useState(false);
    const [int, setInt] = useState(0);
    const [comment, setComment] = useState('');

    React.useEffect(()=> {
        if (props.comment != comment) {
            setComment(props.comment);
        }
      }, [props.comment]);

      React.useEffect(()=> {
        if (props.value != int) {
            setInt(props.value);
        }
      }, [props.value]);

    const toggle = () => setShow(!show);

    return (
        <React.Fragment key={props.name}>
                &nbsp;&nbsp;
                <FontAwesomeIcon style={{cursor: "pointer", fontSize: "16px"}} onClick={() => setShow(true)} icon={faEdit} color="#17a2b8"/>
                <div>
                    <StyledModal isOpen={show} toggle={toggle}>
                        <div className="d-flex w-100 pl-5 justify-content-between">
                            <h5 className="pt-5">Uredi meritev</h5>
                            <i className="nc-icon nc-simple-remove m-3" style={{cursor: "pointer"}} onClick={toggle}/>
                        </div>
                        <ModalBody>
                            <DynamicFormMultiState
                                title={props.title}
                                name={'rapid-test-' + props.recordId}
                                readingType={props.recordType.toString()}
                                id={'rapid-test-' + props.recordId}
                                checked={props.value.toString()}
                                index={0}
                                handleChange={(index, value, reading) => { setInt(value.value); }}
                                multiStateOptions={[
                                    {id: ETestResult.NEGATIVE, value: getRapidTestResult(ETestResult.NEGATIVE)},
                                    {id: ETestResult.POSITIVE, value: getRapidTestResult(ETestResult.POSITIVE)},
                                    {id: ETestResult.INVALID, value: getRapidTestResult(ETestResult.INVALID), moreInfo: <StyledTooltip class="tooltip">*
                                    <span class="tooltiptext">
                                        * neveljaven test zavzema vsa ostala stanja<br/>
                                        &nbsp;&nbsp;- če se ne pokaže nobena črta na testu<br/>
                                        &nbsp;&nbsp;- če je zaradi večih črt test nejasen<br/>
                                        &nbsp;&nbsp;- slika ne vsebuje testa
                                    </span>
                                </StyledTooltip> }]}>
                            </DynamicFormMultiState>
                            <Label>Komentar</Label>
                            <FormGroup>
                                <Input type="textarea"
                                    name="comment"
                                    className="form-control"
                                    autoComplete="off"
                                    value={comment}
                                    maxLength={300}
                                    onChange={(ev) => { setComment(ev.target.value) }}
                                    onFocus={() => PatientReadingsStore.unregisterEventListener()}
                                    onBlur={() => PatientReadingsStore.registerEventListener()}
                                />
                            </FormGroup>

                            <Button className="btb btn-info mt-4" onClick={() => { toggle(); props.confirmChange(int, comment); }}>Potrdi</Button>
                        </ModalBody>
                        <br/>
                    </StyledModal>
                </div>
        </React.Fragment>
    )
}

DynamicFormReadingEdit.propTypes = {
    confirmChange: PropTypes.func.isRequired,
    recordType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    recordId: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
}

export default DynamicFormReadingEdit;
