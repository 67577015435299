import {observer} from "mobx-react-lite";
import React from "react";
import Select from "react-select";
import {Card, CardHeader, CardTitle} from "reactstrap";
import {hist} from "../index";
import MedModulesStore from "../stores/MedModulesStore";
import PatientProfileStore from "../stores/PatientProfileStore";
import {getModuleId} from "../variables/fetchHelper";

interface IModulePicker {
	patientId: string,
    medModules: [],
}

const ModulePicker = observer(({patientId}: IModulePicker) => {
    return (
        <Card>
            <CardHeader style={{display: "flex"}}>
                <CardTitle tag="h5">Modul: </CardTitle>
                <Select
                    className="react-select primary mt-1 mb-3 ml-3 w-25 bg-white"
                    classNamePrefix="react-select"
                    id="organizations"
                    placeholder="Izberi modul"
                    onChange={(module: any) => {
                        hist.push(`/module/${module.value}/patients/${patientId}`);
                    }}
                    value={MedModulesStore.selectedModule(getModuleId(hist))}
                    options={PatientProfileStore.medModulesForDropdown}
                    noOptionsMessage={() => "Ni modulov"}
                />
            </CardHeader>
        </Card>
    );
});

export default ModulePicker;