import React, {useEffect} from "react";
import ReadingSectionStore from "stores/ReadingSectionStore";
import ReadingsStore from "stores/ReadingsStore";
import {observer} from "mobx-react-lite";
import {Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label} from "reactstrap";
import Loader from "components/Loader";
import {getGoBackPath} from "variables/goBackHelper";
import AdminNavbarStore from "stores/common/AdminNavbarStore";
import Select from "react-select";
import { calculationTypes } from "enums/CalculationType";

const store = ReadingSectionStore;

const ReadingFormSecondarySection = observer((props) => {
    const id = props.match.params.id;

    useEffect(() => {
        if (id != null) {
            store.setEditing(ReadingsStore.getSecondarySection(id));
        }

        return () => store.clear();
    }, []);


    const saveSection = async () => {
        await store.saveSecondarySection((id != null) ? id : -1);
        AdminNavbarStore.redirectWithoutWarning(getGoBackPath());
    }

    return (
        <Card className="col-md-10 mx-auto">
            <Loader isLoading={store.isLoading}/>
            <CardHeader>
                <CardTitle tag="h3" className="text-center">
                    {store.isEditing ? "Uredi sekcijo" : "Dodaj sekcijo"}
                </CardTitle>
            </CardHeader>
            <CardBody className="col-md-9 m-auto">
                <Label>Tekst</Label>
                <FormGroup>
                    <Input type="text"
                        name="text"
                        value={store.text}
                        onChange={(event) => store.text = event.target.value}
                        autoComplete="off"
                        maxLength={500}/>
                </FormGroup>
                <Label>Podtekst</Label>
                <FormGroup>
                    <Input type="text"
                        name="subText"
                        value={store.subText}
                        onChange={(event) => store.subText = event.target.value}
                        autoComplete="off"
                        maxLength={500}/>
                </FormGroup>
                <Label>Tip izračuna</Label>
                <Select
                    className="react-select primary mb-2"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    options={store.calculationTypes}
                    placeholder="Tip izračuna"
                    defaultValue={store.calculationTypes[0]}
                    value={store.calculationType}
                    onChange={(event) => { store.calculationType = calculationTypes.find(ty => ty.value == event.value); }}
                />

                <br />

                <Button className="btb btn-info mt-4"
                    onClick={() => saveSection()}>Potrdi</Button>
            </CardBody>
        </Card>
    );
});

export default ReadingFormSecondarySection;
