export const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
};

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

function isPastDate(d) {
    return d < new Date();
}

export const getYearFromEmso = (emso) => {
    if (!emso || emso.length < 13)
        return null;
    let yearOfBirth = emso.slice(4, 7);
    if (emso[4] === "9")
        yearOfBirth = 1 + yearOfBirth;
    else
        yearOfBirth = 2 + yearOfBirth;
    return yearOfBirth;
};

function dateFromEmso(emso) {
    if (!emso) return null;
    let yearOfBirth = getYearFromEmso(emso);
    const month = emso.slice(2, 4) - 1;
    const day = emso.slice(0, 2);
    const date = new Date(yearOfBirth, month, day);
    if(day < 1 || day > 31 || month < 0 || month > 11) return null;
    return isValidDate(date) ? date : null;
}

export const getAgeFromEmso = (emso) => {
    let date = dateFromEmso(emso);
    const currentDate = new Date();
    if (!date) return null;
    let age = currentDate.getFullYear() - date.getFullYear();
    if (currentDate.getMonth() < date.getMonth() || currentDate.getMonth() === date.getMonth() && currentDate.getDate() < date.getDate())
        age -= 1;
    return age > 0 ? age : null;
};

export const getBirthDateFromEmso = (emso) => {
    let date = dateFromEmso(emso);
    if (date && isPastDate(date)) {
        return localDateFormat(date);
    }
    return null;
};

export const localDateFormat = (dateISO) => {
    var date = new Date(dateISO);
    return date.toLocaleDateString();
};

export const localDateTimeFormat = (dateISO, options) => {
    var date = new Date(dateISO);
    return date.toLocaleDateString(undefined,
        options
            ? options
            : {hour: "2-digit", minute: "2-digit", second: "2-digit"},
    );
};

export const localeTimeFormat = (dateISO, options) => {
    const date = new Date(dateISO);
    return date.toLocaleTimeString(undefined,
        options ? options : {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
};

export const localDateWithoutYear = (dateISO) => {
    return localDateTimeFormat(dateISO, {
        month: "numeric", day: "numeric",
        hour: "numeric", minute: "numeric",
    });
};
