import React, {SetStateAction, useEffect, useState} from "react";
import { Prompt } from "react-router-dom";
// @ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";

interface IRouteLeavingGuard {
    navigate: (pathName: string) => void,
    when: boolean,
    shouldBlockNavigation: (nextLocation: string) => void,
}

export const RouteLeavingGuard = ({navigate, when, shouldBlockNavigation}: IRouteLeavingGuard) => {
    const [modalVisible, updateModalVisible] = useState(false);
    const [lastLocation, updateLastLocation] = useState({pathname: ""});
    const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

    const showModal = (location:SetStateAction<any>) => {
        updateModalVisible(true);
        updateLastLocation(location);
    };

    const closeModal = (cb: () => void) => {
        updateModalVisible(false);
        if (cb && typeof cb === "function") {
            cb();
        }
    };

    const handleBlockedNavigation = (nextLocation: any) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            showModal(nextLocation);
            return false;
        }
        return true;
    };
    const handleConfirmNavigationClick = () => {
        closeModal(() => {
            if (lastLocation) {
                updateConfirmedNavigation(true);
            }
        });
    };

    useEffect(() => {
        if (confirmedNavigation) {
            navigate(lastLocation.pathname);
            updateConfirmedNavigation(false);
        }
    }, [confirmedNavigation]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            {modalVisible &&
                <ReactBSAlert
                    warning
                    style={{ display: "block", whiteSpace: "normal", fontSize: "8px" }}
                    title={"Ali ste prepričani, da želite zapustiti trenutno stran? Vse spremembe bodo izgubljene."}
                    onConfirm={handleConfirmNavigationClick}
                    onCancel={closeModal}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Potrdi"
                    cancelBtnText="Prekliči"
                    showCancel
                    btnSize="lg"
                    type="info"
                />
            }
        </>
    );
};

export default RouteLeavingGuard;
