import React from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Label, Row} from "reactstrap";
import styled from "styled-components";
import {ReadingType} from "./readingTypes";
import PatientProfileStore from "../stores/PatientProfileStore";
import {HEART_RATE_LIMIT, readingColor} from "../enums/vitals";
import {getReadingValueByReadingType} from "../stores/CommonAnamnesisReadings";
import DisplayReadingAnswer from "./DisplayReadingAnswer";
import {hist} from "../index";
import PatientReadingsStore from "../stores/PatientReadingsStore";
import {observer} from "mobx-react-lite";
import {localDateTimeFormat} from "../variables/dateHelpers";
import {localNumberFormat} from "../variables/formatHelpers";
import RecordImage from "components/RecordImage/RecordImage";
import ButtonWithModal from "./ButtonWithModal";
import DynamicFormReadingEdit from "./dynamicForm/DynamicFormReadingEdit";
import { getRapidTestResult } from "enums/ETestResult";
import { ECalculationType } from "enums/CalculationType";

const StyledCardHeader = styled(CardHeader)`
    display: flex;
    align-items: center;
    justify-content: left;
`;

const readingTypeToNormalValues = (reading) => {
    switch (reading.type) {
        case(ReadingType.HEART_RATE):
            return `(${HEART_RATE_LIMIT.MIN} - ${HEART_RATE_LIMIT.MAX})`;
        case(ReadingType.BREATHING_FREQUENCY):
            return "(8 - 24)";
        case(ReadingType.BLOOD_PRESSURE):
            if (PatientProfileStore.patient.healthData &&
                PatientProfileStore.patient.healthData.pressure &&
                PatientProfileStore.patient.healthData.pressure.lower &&
                PatientProfileStore.patient.healthData.pressure.lower.from
            ) {
                return "(" +
                    getPressureValue("upper", "from") + "-" +
                    getPressureValue("upper", "to") + ") - (" +
                    getPressureValue("lower", "from") + "-" +
                    getPressureValue("lower", "to") + ")";
            } else {
                return "(ni shranjeno)";
            }
        // return "Rabim od userja tlak";
        case(ReadingType.BODY_TEMPERATURE):
            return "< " + localNumberFormat(37.5) +"°C";
        case(ReadingType.SATURATION):
            return "> 95%";
        default:
            return "";
    }
};

const getPressureValue = (accessorPressureType, accessor) => {
    if (PatientProfileStore.patient.healthData &&
        PatientProfileStore.patient.healthData.pressure &&
        PatientProfileStore.patient.healthData.pressure[accessorPressureType] &&
        PatientProfileStore.patient.healthData.pressure[accessorPressureType][accessor]) {
        return PatientProfileStore.patient.healthData.pressure[accessorPressureType][accessor];
    } else {
        return "";
    }
};

const changeValue = async (record, patientId, int, comment) => {
    await PatientReadingsStore.updateRapidTest(record, patientId, int, comment);
}

const UserDiseaseStatus = observer(({patientInfo, readings, onError}) => {
    const isoDate = PatientReadingsStore.selectedReadingTimestamp;
    const prettyDate = isoDate ? localDateTimeFormat(isoDate) : "Neznana napaka";
    const mainReadingColor = readingColor(PatientProfileStore.latestReadings.mainReading);
    const mainReadingTitle = PatientProfileStore.latestReadings?.mainReading?.title;

    return (
        <Card>
            <StyledCardHeader>
                <div>
                    <div className="d-flex align-items-center mr-2">
                        <CardTitle tag="h4">{mainReadingTitle ? mainReadingTitle : "Manjkajoč podatek"}:</CardTitle>
                        {PatientProfileStore.latestReadings?.mainReading ?
                            <div className="h4 m-2" style={{color: mainReadingColor}}>
                                {getReadingValueByReadingType(PatientProfileStore.latestReadings.mainReading) === undefined ?
                                    "/" :
                                    `${getReadingValueByReadingType(PatientProfileStore.latestReadings.mainReading)} ${getReadingValueByReadingType(PatientProfileStore.latestReadings.mainReading) !== "/" && PatientProfileStore.latestReadings.mainReading.unit ? PatientProfileStore.latestReadings.mainReading.unit : ""}`
                                }
                            </div> : <div className="h4 m-2">/</div>
                        }
                    </div>
                    <Label>Meritev: {PatientReadingsStore.hasData ? prettyDate : "/"}</Label>
                </div>
                <div style={{alignSelf: "baseline"}}>
                    <Button
                        disabled={!mainReadingTitle}
                        className="btn-info mt-1 mr-2"
                        onClick={() => hist.push(`${hist.location.pathname}/addMainReading`)}
                    >Dodaj status</Button>
                </div>
            </StyledCardHeader>
            <CardBody>
                <h5>Vitalni znaki {PatientProfileStore.latestReadings.primaryCalculationType != null && <span> (Ocena: {PatientReadingsStore.selectedReadingOrLatest?.primaryCalculationResult != null ? PatientReadingsStore.selectedReadingOrLatest?.primaryCalculationResult : '/'})</span>}</h5>
                <hr/>
                <div className="button-container">
                    <Row>
                        {PatientReadingsStore.hasData ?
                            PatientReadingsStore.selectedPrimaryReadingsForUserDiseaseStatus.map((record, index) => {
                                const color = readingColor(record);
                                let value = getReadingValueByReadingType(record);
                                if(value && typeof value === "string" && record.type !== ReadingType.IMAGE && record.type !== ReadingType.MULTI_IMAGE) value = value.toUpperCase();

                                return (
                                    <Col lg="4" md="6" xs="6" key={index} className="mb-2">
                                        <Label style={{fontSize: "14px", marginBottom: "0"}}>{record.title}</Label>

                                        {(record.type === ReadingType.MULTI_IMAGE && Array.isArray(value)) && value.map((image, index) => 
                                            <div key={index} style={{marginBottom:"8px", cursor: "pointer"}}>
                                                <ButtonWithModal title="Slika" component={<RecordImage maxWidth="900px" maxHeight="900px" url={image} />}>
                                                    <i><RecordImage maxWidth="200px" maxHeight="120px" url={image} /></i>
                                                </ButtonWithModal>
                                            </div>)}

                                        {(record.type === ReadingType.IMAGE) &&
                                            <div style={{cursor: "pointer"}}>
                                                <ButtonWithModal style={{cursor: "pointer"}} title="Slika" component={<RecordImage maxWidth="900px" maxHeight="900px" url={value} />}>
                                                    <i><RecordImage maxWidth="200px" maxHeight="120px" url={value} /></i>
                                                </ButtonWithModal>
                                            </div>}

                                        {(record.type === ReadingType.RAPID_TEST) &&
                                            <div>
                                                {value.image &&
                                                    <div>
                                                        <h5>{getRapidTestResult(value.int)}
                                                        <DynamicFormReadingEdit
                                                            title={record.title}
                                                            recordId={record.id}
                                                            recordType={record.type.toString()}
                                                            value={value.int}
                                                            comment={(value.string) ? value.string : ''}
                                                            confirmChange={(int, comment) => {changeValue(record, patientInfo.id, int, comment);}}/>
                                                        </h5>

                                                        <ButtonWithModal style={{cursor: "pointer"}} title="Slika" component={<RecordImage maxWidth="900px" maxHeight="900px" url={value.image} />}>
                                                            <i style={{cursor: "pointer"}}><RecordImage maxWidth="200px" maxHeight="120px" url={value.image} /></i>
                                                        </ButtonWithModal>
                                                        {value.string &&
                                                            <div>
                                                                <span>{value.string}</span><br/>
                                                                {value.changedBy && <div style={{color: "#17a2b8"}}>
                                                                    <small>{localDateTimeFormat(value.changedAt)}</small><br/>
                                                                    <small>{value.changedBy}</small>
                                                                </div>}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {!value.image && <h5>/</h5>}
                                            </div>}
                                        
                                        {(record.type !== ReadingType.IMAGE) && <div>
                                        
                                        <h5 style={{marginBottom: "0", color: color}}>
                                            {(value === undefined || value === "/" || (Array.isArray(value) && value.length === 0)) ?
                                                "/" :
                                                (record.type !== ReadingType.MULTI_IMAGE && record.type !== ReadingType.RAPID_TEST) ? `${value} ${record.unit ? record.unit : ""}` : ""
                                            }
                                        </h5>
                                        <div style={{
                                            fondSize: "12px",
                                            marginBottom: "15px",
                                        }}>{readingTypeToNormalValues(record)}</div>
                                        </div>}
                                    </Col>
                                );
                            },
                            ) :
                            PatientProfileStore.latestReadings.primaryReadingTypes
                                .filter(reading => reading.type !== ReadingType.MULTI_STATE && reading.type !== ReadingType.BOOL && reading.type !== ReadingType.LIKERT_SCALE_5PT)
                                .map((readingType, index) => {
                                    return (
                                        <Col lg="4" md="6" xs="6" key={index} className="mb-2">
                                            <Label style={{
                                                fontSize: "14px",
                                                marginBottom: "0",
                                            }}>{readingType.title}</Label>
                                            <h5 style={{marginBottom: "0"}}>
                                                    /
                                            </h5>
                                            <div style={{
                                                fondSize: "12px",
                                                marginBottom: "15px",
                                            }}>{readingTypeToNormalValues(readingType)}</div>
                                        </Col>
                                    );
                                },
                                )}
                    </Row>
                </div>
                <h5>Opisni znaki</h5>
                <hr/>
                <div className="button-container">
                    {
                        PatientReadingsStore.selectedSecondarySectionsReadingsForUserDiseaseStatus?.map((section, index) =>
                            <div>
                                <br/>
                                <h6>
                                    {index+1}. Sekcija {section.text && <span>- {section.text}</span>}
                                </h6>
                                <h6>
                                    {section.calculationType != null && <span>{section.calculationType === ECalculationType.BIAGGI ? 'Ocena Biaggi izračuna: ' : 'Ocena: '}
                                            {PatientReadingsStore.selectedReadingOrLatest?.secondarySectionsCalculationResults != null ?
                                                PatientReadingsStore.selectedReadingOrLatest?.secondarySectionsCalculationResults[index] : '/'}
                                            <br />
                                        </span>}
                                </h6>
                                <div>
                                    {section.readings && section.readings.map(reading =>
                                        <DisplayReadingAnswer
                                            key={reading.id}
                                            reading={reading}/>)
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                {/*<h5 className="mt-3">Zadnje meritve</h5>*/}
                {/*<hr/>*/}
                {/*<Latest10Recordings id={patientInfo.id} onError={onError}/>*/}
            </CardBody>
            <CardFooter>
            </CardFooter>
        </Card>
    );
});

export default UserDiseaseStatus;
