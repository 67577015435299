import React from "react";
import {FormGroup, Label} from "reactstrap";
import ReactDateTime from "react-datetime";
import {localDateFormat} from "../../variables/dateHelpers";
import {Moment} from "moment";

const DynamicFormDate = (props: any) => {
    const handleChange = (newValue: Moment|string) => {
        props.handleChange(props.index, {id: props.id, value: newValue}, props.readingType);
    };

    return (
        <div>
            <Label>{props.title} {props.mandatory && "*"}</Label>
            <FormGroup className={props.validationStatus}>
                <ReactDateTime
                    inputProps={{
                        className: "form-control",
                        placeholder: "Izberi datum",
                    }}
                    value={props.value}
                    onChange={(newDate) => handleChange(newDate)}
                    locale={navigator.language.slice(0, 2)}
                    timeFormat={false}
                    dateFormat={"DD.MM.YYYY"}
                    closeOnSelect={true}
                />
                {props.webLimits && <Label>( dovoljeni datumi od {localDateFormat(props.webLimits.a.minValue)} do {localDateFormat(props.webLimits.a.maxValue)} )</Label>}
            </FormGroup>
        </div>
    );
};


export default DynamicFormDate;

