import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label} from "reactstrap";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import {ReadingType, readingTypes} from "../readingTypes";
import {readingSensorTypes} from "../../enums/SensorType";
import ReadingStore from "../../stores/ReadingStore";
import {Observer} from "mobx-react-lite";
import {hist} from "../../index";
import Loader from "../Loader";
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import AnimationUpload from "../../components/CustomUpload/AnimationUpload";
import ReadingsStore from "../../stores/ReadingsStore";
import MultiStateForm from "./MultiStateForm";
import {getGoBackPath} from "../../variables/goBackHelper";
import MultipleSelectionForm from "./MultipleSelectionForm";
import AdminNavbarStore from "../../stores/common/AdminNavbarStore";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactDateTime from "react-datetime";
import {numberValidation} from "../../_utils/numberValidation";
import {v4 as uuidv4} from "uuid";

const ReadingForm =({match, editingId, store, accessor}) => {

    const [modalVisible, updateModalVisible] = useState(false);

    const sectionIndex = new URLSearchParams(window.location.search).get("section");

    useEffect(() => {
        fetchData();
        return () => ReadingStore.clear();
    }, []);

    const fetchData = async () => {
        await ReadingStore.getReadings(store);
        await ReadingStore.getReadingTemplates();
        ReadingStore.readingTypes = getReadingTypes(accessor);

        if(editingId && store) {
            ReadingStore.setEditing(store.getReading(accessor, editingId));

            if (ReadingStore.templateId) {
                ReadingStore.type = ReadingStore.readingTypes.find(el => el.value === 'tmpl_' + ReadingStore.templateId);
            }

            if(!ReadingStore.webLimits) {
                ReadingStore.webLimits = {
                    a: {
                        minValue: '',
                        maxValue: ''
                    },
                    b: {
                        minValue: '',
                        maxValue: ''
                    }
                }
            }

            if(!ReadingStore.sliderOptions) {
                ReadingStore.sliderOptions = ReadingStore.defaultSliderOptions;
            }
        }
    };

    const addReading = async () => {

        if (ReadingStore.type.value === ReadingType.MULTI_IMAGE) {
            if (validateNumberOfImages()) {
                showModal();
                return;
            }

            ReadingStore.mandatory = ReadingStore.minImages > 0;
        }
        
        updateLimits();

        await ReadingStore.addReading(accessor, store, sectionIndex);
        AdminNavbarStore.redirectWithoutWarning(getGoBackPath(match?.params?.id, hist));
    };

    const updateReading = async () => {

        if (ReadingStore.type.value === ReadingType.MULTI_IMAGE) {
            if (validateNumberOfImages()) {
                showModal();
                return;
            }

            ReadingStore.mandatory = ReadingStore.minImages > 0;
        }

        updateLimits();
        
        await ReadingStore.updateReading(accessor, store);
        if (ReadingsStore.error) {
            return;
        }
        
        AdminNavbarStore.redirectWithoutWarning(getGoBackPath(match?.params?.id, hist));
    };

    const updateLimits = () => {
        if (ReadingStore.webLimits) {
            if (ReadingStore.webLimits.a != undefined && ReadingStore.webLimits.a.minValue !== '' && ReadingStore.webLimits.a.maxValue !== '') {
                ReadingStore.webLimits.a.minValue = ReadingStore.showDateLimit(ReadingStore.type.value) ? ReadingStore.webLimits.a.minValue : parseFloat(ReadingStore.webLimits.a.minValue);
                ReadingStore.webLimits.a.maxValue = ReadingStore.showDateLimit(ReadingStore.type.value) ? ReadingStore.webLimits.a.maxValue : parseFloat(ReadingStore.webLimits.a.maxValue);
            }

            if (ReadingStore.webLimits.b != undefined && ReadingStore.webLimits.b.minValue !== '' && ReadingStore.webLimits.b.maxValue !== '') {
                ReadingStore.webLimits.b.minValue = parseFloat(ReadingStore.webLimits.b.minValue);
                ReadingStore.webLimits.b.maxValue = parseFloat(ReadingStore.webLimits.b.maxValue);
            }
        }
    }

    const validateNumberOfImages = () => {
        return (ReadingStore.maxImages === '' || ReadingStore.minImages === '' || ReadingStore.maxImages === null ||
        ReadingStore.minImages === null || ReadingStore.maxImages < ReadingStore.minImages ||
        ReadingStore.maxImages > 5 || ReadingStore.maxImages < 0 || ReadingStore.minImages < 0);
    }

    const setImageFile = (file) => {
        ReadingStore.imgFile = file;
    };

    const changeMediaType = (event) => {
        ReadingStore.descMedia = event.target.value;
        ReadingStore.clearFiles();
    };

    const changeAddDescMedia = (event) => {
        ReadingStore.addDescMedia = event.state.value;
        ReadingStore.clearFiles();
    };

    const removeImage = () => {
        ReadingStore.img = null;
        ReadingStore.imgFile = null;
    };

    const getReadingTypes = (accessor) => {

        let result = [];
        result.push(...readingTypes);
        
        if (ReadingStore.readingTemplates && ReadingStore.readingTemplates.length > 0) {
            for(let i=0; i< ReadingStore.readingTemplates.length; i++) {
                let temp = ReadingStore.readingTemplates[i];
                result.push({label: 'P - ' + temp.name, value: 'tmpl_' + temp.id, isTemplate: true, readingType: temp.readingType});
            }
        }

        if (accessor === ReadingsStore.PRIMARY_READING_ACCESSOR)
            return result;
        else {
            return result.filter(i => i.value != ReadingType.IMAGE && i.value != ReadingType.MULTI_IMAGE && i.value != ReadingType.RAPID_TEST);
        }
    }

    const showModal = () => {
        updateModalVisible(true);
    };

    const closeModal = () => {
        updateModalVisible(false);
    };

    return (
        <Card className="col-md-10 mx-auto">

            <Observer>{() =>
                <>
                    <Loader isLoading={ReadingStore.isLoading} />
                    <CardHeader>
                        <CardTitle tag="h3" className="text-center">
                            {ReadingStore.isEditing ? "Uredi meritev" : "Dodaj meritev"}
                        </CardTitle>
                    </CardHeader>
                </>
            }</Observer>
            <CardBody className="col-md-9 m-auto">
                <Observer>{() =>
                    <div>
                        <Label>Naziv meritve*</Label>
                        <Input
                            value={ReadingStore.title}
                            type="text"
                            onChange={(event) => ReadingStore.title = event.target.value}
                            maxLength={255}
                        />
                        {accessor === ReadingsStore.PRIMARY_READING_ACCESSOR &&
                        <>
                            <Label>Opis meritve</Label>
                            <Input
                                value={ReadingStore.description}
                                type="text"
                                onChange={(event) => ReadingStore.description = event.target.value}
                                maxLength={500}
                            />
                        </>
                        }
                        <Label>Statusno besedilo</Label>
                        <FormGroup>
                            <Input
                                onChange={(event) => ReadingStore.statusText = event.target.value}
                                value={ReadingStore.statusText}
                                type="textarea"
                                autoComplete="off"
                                maxLength={100}
                            />
                        </FormGroup>
                        <Label>Tip odgovora*</Label>
                        <Select
                            className="react-select primary mb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            options={ReadingStore.readingTypes}
                            placeholder="Tip odgovora"
                            value={ReadingStore.type}
                            onChange={(event) => ReadingStore.changeReadingType(event)}
                        />
                        <Label>*predloge so oznečene s "P -"</Label><br/>

                        <Label>Tip senzorja</Label>
                        <Select
                            className="react-select primary mb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            options={readingSensorTypes}
                            placeholder="Tip senzorja"
                            value={ReadingStore.sensorType}
                            onChange={(event) => ReadingStore.changeSensorReadingType(event)}
                        />

                        {(ReadingStore.webLimits && ReadingStore.showLimits(ReadingStore.type.value)) &&
                        <>
                            <Label>Dovoljene vrednosti</Label>

                            {(ReadingStore.showDateLimit(ReadingStore.type.value)) &&
                            <FormGroup>
                                <Label>Datum od:</Label>
                                <ReactDateTime
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "Izberi datum",
                                    }}
                                    value={ReadingStore.getMomentDate(ReadingStore.webLimits.a.minValue)}
                                    onChange={(newDate) => ReadingStore.changeLimitsValue(newDate, 'amin')}
                                    locale={navigator.language.slice(0, 2)}
                                    timeFormat={ReadingStore.type.value === ReadingType.DATE_TIME ? "HH:mm" : false}
                                    dateFormat={"DD.MM.YYYY"}
                                    closeOnSelect={ReadingStore.type.value === ReadingType.DATE}
                                />
                                
                                <Label>Datum do:</Label>
                                <ReactDateTime
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "Izberi datum",
                                    }}
                                    value={ReadingStore.getMomentDate(ReadingStore.webLimits.a.maxValue)}
                                    onChange={(newDate) => ReadingStore.changeLimitsValue(newDate, 'amax')}
                                    locale={navigator.language.slice(0, 2)}
                                    timeFormat={ReadingStore.type.value === ReadingType.DATE_TIME ? "HH:mm" : false}
                                    dateFormat={"DD.MM.YYYY"}
                                    closeOnSelect={ReadingStore.type.value === ReadingType.DATE}
                                />
                            </FormGroup>}

                            {(!ReadingStore.showDateLimit(ReadingStore.type.value)) &&
                            <FormGroup>
                                <Label>Prva vrednost<br/>Od:</Label>
                                <Input
                                    value={ReadingStore.webLimits.a.minValue}
                                    onChange={(event) => ReadingStore.changeLimitsValue(event.target.value, 'amin')}
                                    type="number"></Input>
                                
                                <Label>Do:</Label>
                                <Input
                                    value={ReadingStore.webLimits.a.maxValue}
                                    onChange={(event) => ReadingStore.changeLimitsValue(event.target.value, 'amax')}
                                    type="number"></Input>
                            </FormGroup>}

                            {(ReadingStore.showBLimit(ReadingStore.type.value)) &&
                            <FormGroup>
                                <Label>Druga vrednost<br/>Od:</Label>
                                <Input 
                                    value={ReadingStore.webLimits.b.minValue}
                                    onChange={(event) => ReadingStore.changeLimitsValue(event.target.value, 'bmin')}
                                    type="number"></Input>
                                
                                <Label>Do:</Label>
                                <Input
                                    value={ReadingStore.webLimits.b.maxValue}
                                    onChange={(event) => ReadingStore.changeLimitsValue(event.target.value, 'bmax')}
                                    type="number"></Input>
                            </FormGroup>}
                        </>}
                        
                        {(ReadingStore.type.value === ReadingType.SLIDER) &&
                        <FormGroup>
                            
                            <Label>Spodnja labela*</Label>
                            <Input
                                value={ReadingStore.sliderOptions.minLabel}
                                onChange={(event) => ReadingStore.sliderOptions.minLabel = event.target.value}
                                type="text"></Input>
                            
                            <Label>Spodnja vrednost*</Label>
                            <Input
                                value={ReadingStore.sliderOptions.minValue}
                                onChange={(event) => numberValidation(event, (ev) => {
                                    ReadingStore.sliderOptions.minValue = ev.target.value; })
                                }
                                type="text"></Input>
                            
                            <Label>Zgornja labela*</Label>
                            <Input
                                value={ReadingStore.sliderOptions.maxLabel}
                                onChange={(event) => ReadingStore.sliderOptions.maxLabel = event.target.value}
                                type="text"></Input>
                            
                            <Label>Zgornja vrednost*</Label>
                            <Input
                                value={ReadingStore.sliderOptions.maxValue}
                                onChange={(event) => numberValidation(event, (ev) => {
                                    ReadingStore.sliderOptions.maxValue = ev.target.value; })
                                }
                                type="text"></Input>

                            <Label>Korak*</Label>
                            <Input
                                value={ReadingStore.sliderOptions.step}
                                onChange={(event) => numberValidation(event, (ev) => {
                                    ReadingStore.sliderOptions.step = ev.target.value; })
                                }
                                type="text"></Input>
                        </FormGroup>}

                        {((ReadingStore.type.value === ReadingType.MULTI_STATE || ReadingStore.type.value === ReadingType.LIKERT_SCALE_5PT)
                            || (ReadingStore.type.isTemplate && ReadingStore.type.readingType === ReadingType.MULTI_STATE)
                            || (ReadingStore.type.isTemplate && ReadingStore.type.readingType === ReadingType.LIKERT_SCALE_5PT)) &&
                            <MultiStateForm data={ReadingStore.multiStateOptions.map((state) => state)}
                                setData={(data) => {ReadingStore.multiStateOptions = data;}}
                                addClick={() => { 
                                    ReadingStore.multiStateOptions.push({
                                        "id": "temp-id" + uuidv4(),
                                        "value": "",
                                        "color": "#000000"
                                    });
                                }}
                                removeClick={(id)=> ReadingStore.multiStateOptions = ReadingStore.multiStateOptions.filter((state) => state.id !== id)}
                                disabled={ReadingStore.type.value === ReadingType.LIKERT_SCALE_5PT || ReadingStore.type.isTemplate ? true : false}
                            />
                        }
                        {(ReadingStore.type.value === ReadingType.MULTIPLE_SELECTION
                            || (ReadingStore.type.isTemplate && ReadingStore.type.readingType === ReadingType.MULTIPLE_SELECTION)) &&
                            <MultipleSelectionForm data={ReadingStore.multipleSelectionOptions.map((state) => state)}
                                setData={(data) => {ReadingStore.multipleSelectionOptions = data;}}
                                addClick={() => { 
                                    ReadingStore.multipleSelectionOptions.push({
                                        "id": "temp-id" + uuidv4(),
                                        "value": "",
                                        "color": "#000000"
                                    });
                                }}
                                removeClick={(id)=> ReadingStore.multipleSelectionOptions = ReadingStore.multipleSelectionOptions.filter((state) => state.id !== id)}
                                disabled={ReadingStore.type.isTemplate ? true : false}
                            />
                        }
                        {ReadingStore.type.value === ReadingType.MULTI_IMAGE &&
                        <div style={{paddingBottom: "5px"}}>
                            <div style={{paddingBottom: "5px"}}>
                                <Label>Minimalno število slik*</Label>
                                <Input
                                    value={ReadingStore.minImages}
                                    type="number"
                                    onChange={(event) => ReadingStore.minImages = event.target.value}
                                    maxLength={2}
                                />
                            </div>
                            <div>
                                <Label>Maksimalno število slik*</Label>
                                <Input
                                    value={ReadingStore.maxImages}
                                    type="number"
                                    onChange={(event) => ReadingStore.maxImages = event.target.value}
                                    maxLength={2}
                                />
                            </div>
                        </div>
                        }
                        <div style={{width: "fit-content"}}>
                            {ReadingStore.type.value !== ReadingType.MULTI_IMAGE && <div className="d-flex justify-content-between">
                                <Label>Obvezno</Label>
                                <div className="ml-3 d-inline">
                                    <Switch
                                        value={ReadingStore.mandatory}
                                        offColor="success"
                                        offText={<i className="nc-icon nc-simple-remove"/>}
                                        onColor="success"
                                        onText={<i className="nc-icon nc-check-2"/>}
                                        onChange={(event) => ReadingStore.mandatory = event.state.value}
                                    />
                                </div>
                            </div>}
                            {accessor === ReadingsStore.PRIMARY_READING_ACCESSOR &&
                            <div className="d-flex justify-content-between">
                                <Label>Dodaj sliko / animacijo</Label>
                                <div className="ml-3 d-inline">
                                    <Switch
                                        value={ReadingStore.addDescMedia}
                                        offColor="success"
                                        offText={<i className="nc-icon nc-simple-remove"/>}
                                        onColor="success"
                                        onText={<i className="nc-icon nc-check-2"/>}
                                        onChange={changeAddDescMedia}
                                    />
                                </div>
                            </div>
                            }
                        </div>
                        {ReadingStore.addDescMedia && accessor === ReadingsStore.PRIMARY_READING_ACCESSOR &&
                        <div>
                            <div className="d-flex">
                                <div className="form-check-radio">
                                    <Label check>
                                        <Input
                                            value={ReadingStore.IMAGE}
                                            checked={ReadingStore.descMedia === ReadingStore.IMAGE}
                                            id="descMedia"
                                            name="descMedia"
                                            type="radio"
                                            onChange={changeMediaType}
                                        />
                                        Slika <span className="form-check-sign" />
                                    </Label>
                                </div>
                                <div className="form-check-radio mx-3">
                                    <Label check>
                                        <Input
                                            value={ReadingStore.ANIMATION}
                                            checked={ReadingStore.descMedia === ReadingStore.ANIMATION}
                                            id="descMedia"
                                            name="descMedia"
                                            type="radio"
                                            onChange={changeMediaType}
                                        />
                                        Animacija <span className="form-check-sign" />
                                    </Label>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                }</Observer>

                <Observer>{() =>
                    <div>
                        {ReadingStore.addDescMedia && ReadingStore.descMedia === ReadingStore.IMAGE &&
                            <>
                                <ImageUpload setFile={setImageFile} url={ReadingStore.img} removeFile={removeImage}/>
                                <div>
                                    <label>* priporočena velikost slike je 670 x 290px</label>
                                </div>
                            </>
                        }
                        {ReadingStore.addDescMedia && ReadingStore.descMedia === ReadingStore.ANIMATION &&
                            <AnimationUpload file={ReadingStore.animFile} setFile={(file) => ReadingStore.animFile = file} url={ReadingStore.anim} removeFile={() => ReadingStore.anim = null}/>
                        }
                    </div>
                }</Observer>

                <Observer>{() =>
                    <div>
                        <br/>
                        <Button
                            disabled={!ReadingStore.canSubmit}
                            onClick={ReadingStore.isEditing ? updateReading : addReading}
                            className="btb btn-info"
                        >
                            Potrdi
                        </Button>
                        {modalVisible &&
                <ReactBSAlert
                    warning
                    style={{ display: "block", whiteSpace: "normal", fontSize: "8px" }}
                    title={"Preverite podatke za število slik, maksimalno število slik je lahko največ 5. Minimalno število slik mora biti manjše ali enako maksimalnemu številu."}
                    onConfirm={closeModal}
                    confirmBtnBsStyle="danger"
                    confirmBtnText="Ok"
                    btnSize="lg"
                    type="info"
                />
            }
                    </div>
                }</Observer>
            </CardBody>
        </Card>

    );
};

export default ReadingForm;
