import ResettableStore from "../ResettableStore";
import {action, observable, runInAction} from "mobx";
import {CHAT} from "../../config";
import httpService from "../../services/httpService";
import ChatStore from "./ChatStore";
import {ITopic} from "../../interfaces/IChat";

class TopicStore extends ResettableStore implements ITopic {
    @observable isLoading: boolean = false;
    @observable error: string | null = null;

    @observable medModuleId: string = "";
    @observable id: string = "";
    @observable name: string = "";

    constructor() {
        super();

        this.setInitialState()
    }

    @action
    async getTopic(id: string) {
        if (id === undefined) throw new Error("Invalid id");
        this.isLoading = true;

        const options = {
            method: "GET",
            url: CHAT + "/" + id,
        };

        try {
            const response = await httpService.fetch(options);

            runInAction(() => {
                // @ts-ignore
                this.id = response.id;
                // @ts-ignore
                this.name = response.name;
                // @ts-ignore
                this.medModuleId = response.medModuleId;
                this.isLoading = false
            })
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    // TODO does this create new topic? if yes will this be possible?
    @action
    async postTopic() {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: CHAT,
            body: {
                // TODO we generate this id on frontend?
                id: this.id,
                medModuleId: this.medModuleId,
                name: this.name
            }
        }

        try {
            const response = await httpService.fetch(options)

            runInAction(() => {
                this.isLoading = false;
            })
        } catch (error) {
            runInAction(() => {
                this.error = error;
                this.isLoading = false
            })
        }
    }

    @action
    async removeTopic(id: string, reloadListAfter: boolean) {
        if (id === undefined) throw new Error("Invalid id");
        this.isLoading = true;

        const options = {
            method: "DELETE",
            url: CHAT + "/" + id,
        }

        try {
            const response = await httpService.fetch(options);

            runInAction(() => {
                if (reloadListAfter) ChatStore.getTopics();

                this.isLoading = false
            })
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }
}

export default new TopicStore()
