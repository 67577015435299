export const ReadingType = {
    INT: 0,
    BREATHING_FREQUENCY: 1,
    HEART_RATE: 2,
    FLOAT: 3,
    BODY_TEMPERATURE: 4,
    BLOOD_SUGAR: 5,
    SATURATION: 6,
    BOOL: 7,
    INT_PAIR: 8,
    BLOOD_PRESSURE: 9,
    FLOAT_PAIR: 10,
    FREE_TEXT: 11,
    MULTI_STATE: 12,
    DATE: 13,
    DATE_TIME: 14,
    MULTIPLE_SELECTION: 15,
    IMAGE: 16,
    MULTI_IMAGE: 17,
    WEIGHT: 18,
    RAPID_TEST: 19,
    LIKERT_SCALE_5PT: 20,
    SLIDER: 21
};

export const readingTypes = [
    {
        value: ReadingType.INT,
        label: "Integer"
    },
    {
        value: ReadingType.BREATHING_FREQUENCY,
        label: "Breathing frequency"
    },
    {
        value: ReadingType.HEART_RATE,
        label: "Heart rate"
    },
    {
        value: ReadingType.FLOAT,
        label: "Float"
    },
    {
        value: ReadingType.BODY_TEMPERATURE,
        label: "Body temperature"
    },
    {
        value: ReadingType.BLOOD_SUGAR,
        label: "Blood sugar"
    },
    {
        value: ReadingType.SATURATION,
        label: "Saturation"
    },
    {
        value: ReadingType.BOOL,
        label: "Bool"
    },
    {
        value: ReadingType.INT_PAIR,
        label: "Integer pair"
    },
    {
        value: ReadingType.BLOOD_PRESSURE,
        label: "Blood pressure"
    },
    {
        value: ReadingType.FLOAT_PAIR,
        label: "Float pair",
    },
    {
        value: ReadingType.FREE_TEXT,
        label: "Free text",
    },
    {
        value: ReadingType.MULTI_STATE,
        label: "Multi state",
    },
    {
        value: ReadingType.DATE,
        label: "Date",
    },
    {
        value: ReadingType.DATE_TIME,
        label: "Date time",
    },
    {
        value: ReadingType.MULTIPLE_SELECTION,
        label: "Multiple selection",
    },
    {
        value: ReadingType.IMAGE,
        label: "Image",
    },
    {
        value: ReadingType.MULTI_IMAGE,
        label: "Multi image",
    },
    {
        value: ReadingType.WEIGHT,
        label: "Weight"
    },
    {
        value: ReadingType.RAPID_TEST,
        label: "Rapid test"
    },
    {
        value: ReadingType.LIKERT_SCALE_5PT,
        label: "Likert scale"
    },
    {
        value: ReadingType.SLIDER,
        label: "Slider"
    }
];

export const mapReadingTypeToServerType = (type) => {
    switch (type) {
        case(ReadingType.INT):
        case(ReadingType.HEART_RATE):
        case(ReadingType.SATURATION):
        case(ReadingType.BREATHING_FREQUENCY):
            return "int";
        case(ReadingType.FLOAT):
        case(ReadingType.BODY_TEMPERATURE):
        case(ReadingType.BLOOD_SUGAR):
        case(ReadingType.WEIGHT):
            return "float";
        case(ReadingType.BOOL):
            return "bool";
        case(ReadingType.INT_PAIR):
        case(ReadingType.BLOOD_PRESSURE):
            return "intPair";
        case(ReadingType.FLOAT_PAIR):
            return "floatPair";
        case(ReadingType.FREE_TEXT):
            return "string";
        case(ReadingType.MULTI_STATE):
        case(ReadingType.LIKERT_SCALE_5PT):
            return "multiStateId";
        case(ReadingType.MULTIPLE_SELECTION):
            return "multiStateIdList";
        case(ReadingType.DATE):
        case(ReadingType.DATE_TIME):
            return "dateTime";
        case(ReadingType.IMAGE):
            return "image";
        case(ReadingType.MULTI_IMAGE):
            return "images";
        //TODO check actual parameter
        case(ReadingType.SLIDER):
            return "int";
    }
};

export const floatToServerValue = (value) => {
    if(!value) return null;
    const dotCount = (value.match(/\./g) || []).length;
    const commaCount = (value.match(/,/g) || []).length;
    if(dotCount > 1)
        value = value.replace(/\./g, "");
    if(commaCount > 1)
        value = value.replace(/,/g, "");

    return value.replace(/,/g, ".").replace(/(.*)\./, x => x.replace(/\./g,'')+'.');
};
