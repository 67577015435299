import React from "react";
import StatusIcon from "../components/StatusIcon";

const TrafficLight = (props) => {
    const getStatusColor = () => {
        if (props.status) {
            return "#24BF62";
        } else {
            return "#E63F3F";
        }
    };

    return (
        <StatusIcon
            size={16}
            color={getStatusColor()}
            text={""}/>
    );
};

export default TrafficLight;
