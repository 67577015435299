import {Redirect, Route} from "react-router-dom";
import React from "react";
import AuthStore from "../stores/AuthStore";
import {ACCESS_TOKEN} from "../config";

export const PrivateRoute = ({ render: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            AuthStore.getToken(ACCESS_TOKEN)
                ? <Component {...props} />
                : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
        )}/>
    );
}
