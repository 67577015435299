import React, { useEffect, useRef, useState } from "react";
import DataExportStore from "stores/DataExportStore";
import { observer } from "mobx-react-lite";
import { hist } from "index";
import { getModuleId } from "variables/fetchHelper";
import { useLocation } from "react-router-dom";
import ModuleLinks from "components/ModuleLinks";
import ReactDatetime from "react-datetime";
import { Card, CardBody, CardHeader, CardTitle, Label, Col, FormGroup, Row, Input } from "reactstrap";
import moment from "moment";
import styled from "styled-components";
import MedModulesStore from "stores/MedModulesStore";
import ReadingsStore from "stores/ReadingsStore";
import Loader from "components/Loader";
import { GET_READINGS } from "config";
import httpService from "services/httpService";

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DataList = observer(() => {

    const [fromTime, setFromTime] = useState(new moment().subtract(7, "day").startOf("day"));
    const [toTime, setToTime] = useState(new moment().endOf("day"));
    const [exportPrimaryReadings, setExportPrimaryReadings] = useState(false);
    const [exportSecondaryReadings, setExportSecondaryReadings] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [showPrimary, setShowPrimary] = useState(false);
    const [showSecondary, setShowSecondary] = useState(false);

    const moduleId = getModuleId(hist);
    const location = useLocation();

    const exportLink = useRef(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {

        const options = {
            method: "GET",
            url: GET_READINGS + "/" + getModuleId(hist),
        }

        try {
            setIsLoading(true);            
            let response = await httpService.fetch(options);
            setIsLoading(false);

            if (response !== null) {
                if (response && response.primaryReadingTypes) {
                    let show = response.primaryReadingTypes.length > 0;
                    setShowPrimary(show);
                    if (show === true) {
                        setExportPrimaryReadings(true);
                    }
                }

                if (response && response.secondaryReadingTypes) {
                    let secRead = ReadingsStore.getFlatSecondaryReadings(response.secondaryReadingTypes);
                    let show = secRead && secRead.length > 0;
                    setShowSecondary(show);
                    if (show === true) {
                        setExportSecondaryReadings(true);
                    }
                }
            }

        } catch (err) {
            setIsLoading(false);
        }
    }

    const getExportData = async () => {
        let data = await DataExportStore.getModuleExportData(fromTime.toISOString(), toTime.toISOString(), exportPrimaryReadings, exportSecondaryReadings);
        exportLink.current.href=data;
        exportLink.current.download=`${Date.now()}-${MedModulesStore.selectedModule(moduleId)?.label}-${moduleId}.csv`;
        exportLink.current.click();
        exportLink.current.href='';
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <ModuleLinks tabPath={location.pathname} moduleId={moduleId}></ModuleLinks>
            <Card>
                <StyledCardHeader>
                    <CardTitle style={{ float: "left" }} tag="h4">Izvoz podatkov</CardTitle>
                </StyledCardHeader>
                <CardBody>
                    <Row>
                        <Col md={4}>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Od</Label>
                                        <ReactDatetime
                                            inputProps={{
                                                className: "form-control",
                                                placeholder: "Izberi datum",
                                            }}
                                            locale={navigator.language.slice(0, 2)}
                                            value={fromTime}
                                            onChange={(time) => setFromTime(time)}
                                            closeOnSelect={false}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Do</Label>
                                        <ReactDatetime
                                            inputProps={{
                                                className: "form-control",
                                                placeholder: "Izberi datum",
                                            }}
                                            locale={navigator.language.slice(0, 2)}
                                            value={toTime}
                                            onChange={(time) => setToTime(time)}
                                            closeOnSelect={false}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br/>
                    <div className="checkbox-radios">
                        {showPrimary && <FormGroup check>
                            <Label check>
                                <Input type="checkbox" checked={exportPrimaryReadings} onChange={event => setExportPrimaryReadings(event.target.checked)} />
                                <span className="form-check-sign" />
                                Vitalni znaki
                            </Label>
                        </FormGroup>}
                        {showSecondary && <FormGroup check>
                            <Label check>
                                <Input type="checkbox" checked={exportSecondaryReadings} onChange={event => setExportSecondaryReadings(event.target.checked)} />
                                <span className="form-check-sign" />
                                Opisne meritve
                            </Label>
                        </FormGroup>}
                        <br/>
                        <button disabled={!exportPrimaryReadings && !exportSecondaryReadings} onClick={getExportData} className="btn-info btn btn-secondary">IZVOZI CSV</button>
                            <a ref={exportLink}></a>
                    </div>
                </CardBody>
            </Card>
        </>
    )
})

export default DataList;
