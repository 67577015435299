import {ReadingType} from "../components/readingTypes";
import PatientProfileStore from "../stores/PatientProfileStore";

export const isInNormalRange = (reading) => {
    if ((reading === undefined) || (reading.value === undefined)) return true;

    switch (reading.type) {
        case(ReadingType.HEART_RATE):
            return (!reading.value || HEART_RATE_LIMIT.MIN <= reading.value && HEART_RATE_LIMIT.MAX >= reading.value);
        case(ReadingType.BREATHING_FREQUENCY):
            return (!reading.value || BREATHING_FREQUENCY_LIMIT.MIN <= reading.value && BREATHING_FREQUENCY_LIMIT.MAX >= reading.value);
        case(ReadingType.BODY_TEMPERATURE):
            return (!reading.value || BODY_TEMPERATURE_LIMIT.MAX >= reading.value);
        case(ReadingType.SATURATION):
            return (!reading.value || SATURATION_LIMIT.MIN <= reading.value);
        case(ReadingType.BLOOD_PRESSURE):
            return (!reading.value.a || BLOOD_PRESSURE.LOWER.MIN <= reading.value.a);
        default:
            return true;
    }

    return true;
};

export const readingColor = (reading) => {
    if(!reading) return "";
    switch (reading.type) {
        case(ReadingType.LIKERT_SCALE_5PT):
        case(ReadingType.MULTI_STATE):
            return PatientProfileStore.getMultiStateAnswer(reading.id, reading.value)?.color;
        default:
            return isInNormalRange(reading) ? "" : "#FF0000";
    }
};

export function getReadingMaxInputLength(reading) {
    switch (reading.type.value) {
        case(ReadingType.HEART_RATE):
        case(ReadingType.BREATHING_FREQUENCY):
        case(ReadingType.BLOOD_PRESSURE):
            return "3";
        case(ReadingType.BODY_TEMPERATURE):
            return "4";
        case(ReadingType.SATURATION):
            return "5";
        case(ReadingType.FREE_TEXT):
            return "300";
        case(ReadingType.INT_PAIR):
        case(ReadingType.INT):
            return "9";
        default:
            // default according to w3schools
            return "524288";
    }
}

export const HEART_RATE_LIMIT = {
    MIN: 50,
    MAX: 100,
};

export const BREATHING_FREQUENCY_LIMIT = {
    MIN: 8,
    MAX: 24,
};

export const BODY_TEMPERATURE_LIMIT = {
    MAX: 37.5,
};

export const SATURATION_LIMIT = {
    MIN: 95,
};

export const BLOOD_PRESSURE = {
    LOWER: {
        MIN: 100,
    },
};
