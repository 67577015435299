import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    CardTitle,
    Form, Input, InputGroup, InputGroupAddon, InputGroupText,
} from "reactstrap";
import {observer} from "mobx-react-lite";
import {hist} from "../index";
import AuthStore from "../stores/AuthStore";
import UserStore from "../stores/UserStore";
import LinkButton from "../components/LinkButton";

const ChangePassword = observer(() => {
    const [error, setError] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [isNewPasswordValid, setIsnewPasswordValid] = useState(true);
    const [repeatedPassword, setRepeatedPassword] = useState("");
    const [isRepeatedPasswordValid, setIsRepeatedPasswordValid] = useState(true);

    useEffect(() => {
    	if(!AuthStore.userName)
    		hist.push("/login");
    }, []);

    const validateRepeatedPassword = () => {
        if(UserStore.isRepeatedPasswordValid(newPassword, repeatedPassword)){
            setIsRepeatedPasswordValid(true);
            return true;
        }
        else {
            setIsRepeatedPasswordValid(false);
            setError("Gesli se ne ujemata");
            return false;
        }
    };

    const validateNewPassword = () => {
        setError("");
        if(newPassword && UserStore.isPasswordValid(newPassword)) {
            setIsnewPasswordValid(true);
            return true;
        }
        else {
            setIsnewPasswordValid(false);
            setError("Geslo mora imeti vsaj 8 znakov");
            return false;
        }
    };

    const submitResetPassword = () => {
        if(validateNewPassword() && repeatedPassword && validateRepeatedPassword())
            AuthStore.forcedChangePassword(newPassword, password);
    };

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") submitResetPassword();
    };

    return (
        <Form onKeyPress={handleKeyPress}>
            <Card className="card-signup text-center">
                <CardHeader>
                    <CardTitle tag="h4">Določite novo geslo!</CardTitle>
                </CardHeader>
                <CardBody>
	                <p className="text-danger text-center">
		                {typeof AuthStore.error !== "object" && AuthStore.error}
		                {!AuthStore.error && error}
	                </p>
	                <InputGroup>
		                <InputGroupAddon addonType="prepend">
			                <InputGroupText>
				                <i className="nc-icon nc-key-25"/>
			                </InputGroupText>
		                </InputGroupAddon>
		                <Input
			                placeholder="Staro geslo"
			                type="password"
			                autoComplete="off"
			                value={password}
			                onChange={(e) => setPassword(e.target.value)}
		                />
	                </InputGroup>
	                <InputGroup>
		                <InputGroupAddon addonType="prepend">
			                <InputGroupText>
				                <i className="nc-icon nc-key-25"/>
			                </InputGroupText>
		                </InputGroupAddon>
		                <Input
			                placeholder="Novo geslo"
			                type="password"
			                autoComplete="off"
			                value={newPassword}
			                onChange={(e) => setNewPassword(e.target.value)}
			                onBlur={validateNewPassword}
			                invalid={!isNewPasswordValid}
		                />
	                </InputGroup>
	                <InputGroup>
		                <InputGroupAddon addonType="prepend">
			                <InputGroupText>
				                <i className="nc-icon nc-key-25"/>
			                </InputGroupText>
		                </InputGroupAddon>
		                <Input
			                placeholder="Ponovi novo geslo"
			                type="password"
			                autoComplete="off"
			                value={repeatedPassword}
			                onChange={(e) => setRepeatedPassword(e.target.value)}
			                onBlur={validateRepeatedPassword}
			                invalid={!isRepeatedPasswordValid}
		                />
	                </InputGroup>
                </CardBody>
                <CardFooter>
                    <Button
                        block
                        className="btn-round mb-3"
                        color="warning"
                        onMouseDown={submitResetPassword}
                    >
						Nadaljuj
                    </Button>
                    <LinkButton action={()=> hist.push("/login") } text={"Nazaj"} />
                </CardFooter>
            </Card>
        </Form>
    );
});

export default ChangePassword;