import React from "react";
import {FormGroup, Input, Label} from "reactstrap";
import PropTypes from "prop-types";
import {numberValidation} from "../../_utils/numberValidation";

const DynamicFormInt = (props) => {
    const handleChange = (event) => {
        const {value} = event.target;
        props.handleChange(props.index, {id: props.id, value: value}, props.readingType);
    };

    return (
        <div>
            <Label>{props.title} {props.mandatory && "*"}</Label>
            <FormGroup className={props.validationStatus}>
                <Input type="text"
                       name={props.name}
                       onChange={(event) => numberValidation(event, handleChange)}
                       value={props.value}
                       onBlur={() =>{props.validateField(props.index, {id: props.id, type: props.readingType});}}
                       maxLength={props.maxlength}
                />
                {props.validationStatus === "has-danger" &&
                <label className="error">
                    Polje je obvezno
                </label>}
                {props.webLimits && props.webLimits.a && <Label>( zaloga vrednosti {props.webLimits.a.minValue} - {props.webLimits.a.maxValue} )</Label>}
            </FormGroup>
        </div>
    );
};

DynamicFormInt.propTypes = {
    handleChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    readingType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mandatory: PropTypes.bool,
    validationStatus: PropTypes.string,
    validateField: PropTypes.func
};

export default DynamicFormInt;
