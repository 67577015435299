import {action, computed, observable, runInAction} from "mobx";
import {EDIT_PATIENT, EDIT_PATIENT_IDS, GET_PATIENTS_URL, REGISTER_PATIENT} from "../config";
import httpService from "../services/httpService";
import AuthStore from "./AuthStore";
import {emailRegex, onlyDigits, phoneNumber} from "../enums/regex";
import {hist} from "../index";
import ResettableStore from "./ResettableStore";
import validateEmso from "../_utils/emsoValidator";
import {getModuleId} from "../variables/fetchHelper";

const emptyPersonalData = {
    firstName: "",
    lastName: "",
    emso: "",
    healthId: "",
    phone: "",
    email: "",
    address: "",
    pin: "",
    pin2: "",
    healthData: {
        pressure: {
            lower: {
                from: "",
                to: "",
            },
            upper: {
                from: "",
                to: "",
            },
        },
        heartRate: {
            from: "",
            to: "",
        },
        spO2: "",
        medication: "",
        allergies: "",
        diseases: "",
    },
    personalDoctor: {
        fullName: "",
        city: "",
    },
    phone1: "",
    phone2: "",
    phone3: "",
    skype: "",
    viber: "",
};

class PatientPersonalDataStore extends ResettableStore {
    @observable isLoading = false;
    @observable personalData = {
        firstName: "",
        lastName: "",
        emso: "",
        healthId: "",
        phone: "",
        email: "",
        address: "",
        pin: "",
        pin2: "",
        healthData: {
            pressure: {
                lower: {
                    from: "",
                    to: "",
                },
                upper: {
                    from: "",
                    to: "",
                },
            },
            heartRate: {
                from: "",
                to: "",
            },
            spO2: "",
            medication: "",
            allergies: "",
            diseases: "",
        },
        personalDoctor: {
            fullName: "",
            city: "",
        },
        phone1: "",
        phone2: "",
        phone3: "",
        skype: "",
        viber: "",
    };
    @observable status = {
        emso: "",
        healthId: "",
        pin: "",
        pin2: "",
        email: "",
        healthData: {
            pressure: {
                upper: {
                    from: "",
                    to: "",
                },
                lower: {
                    from: "",
                    to: "",
                },
            },
            heartRate: {
                form: "",
                to: "",
            },
        },
        personalDoctor: {
            fullName: "",
            city: "",
        },
        phone1: "",
        phone2: "",
        phone3: "",
    };
    @observable id = "";
    @observable serverCopy;
    @observable isEditing;

    constructor() {
        super();

        this.setInitialState();
    }

    @computed
    get validationFields() {
        let ret = ["emso", "healthId", "pin", "pin2", "email"];
        if (this.isEditing && !AuthStore.hasOrganizationAdminAccess) ret = ret.filter(field => field !== "emso" && field !== "healthId");
        if (this.isEditing && !this.personalData.pin) ret = ret.filter(field => field !== "pin" && field !== "pin2");
        return ret;
    }

    @computed
    get editIdValidationFields() {
        return ["emso", "healthId"];
    }

    @computed
    get isEditValid() {
        if (this.isSameAsServerCopy) return false;
        if (AuthStore.hasOrganizationAdminAccess && !this.areIdsValid) return false;
        return this.validationFields.every(fieldName => this.isFieldValid(fieldName)) &&
            this.isBloodPressureValid() &&
            this.isHeartRateValid() &&
            this.isPersonalDoctorValid();
    }

    @computed
    get areIdsValid() {
        return this.editIdValidationFields.every(fieldName => this.isFieldValid(fieldName));
    }

    @computed
    get isIdEditValid() {
        if (this.isIdSameAsServerCopy) return false;
        return this.areIdsValid;
    }

    @computed
    get isIdSameAsServerCopy() {
        if (!this.serverCopy) return false;
        if (this.serverCopy.healthId !== this.personalData.healthId) return false;
        if (this.serverCopy.emso !== this.personalData.emso) return false;
        return true;
    }

    @computed
    get isHealthDataSame() {
        if (this.serverCopy.healthData.pressure.upper.from !== this.personalData.healthData.pressure.upper.from) return false;
        if (this.serverCopy.healthData.pressure.upper.to !== this.personalData.healthData.pressure.upper.to) return false;
        if (this.serverCopy.healthData.pressure.lower.from !== this.personalData.healthData.pressure.lower.from) return false;
        if (this.serverCopy.healthData.pressure.lower.to !== this.personalData.healthData.pressure.lower.to) return false;
        if (this.serverCopy.healthData.heartRate.from !== this.personalData.healthData.heartRate.from) return false;
        if (this.serverCopy.healthData.heartRate.to !== this.personalData.healthData.heartRate.to) return false;
        if (this.serverCopy.healthData.spO2 !== this.personalData.healthData.spO2) return false;
        if (this.serverCopy.healthData.medication !== this.personalData.healthData.medication) return false;
        if (this.serverCopy.healthData.allergies !== this.personalData.healthData.allergies) return false;
        if (this.serverCopy.healthData.diseases !== this.personalData.healthData.diseases) return false;
        return true;
    }

    @computed
    get isSameAsServerCopy() {
        return this.serverCopy &&
            this.serverCopy.firstName === this.personalData.firstName &&
            this.serverCopy.lastName === this.personalData.lastName &&
            this.serverCopy.phone === this.personalData.phone &&
            this.serverCopy.email === this.personalData.email &&
            this.serverCopy.address === this.personalData.address &&
            this.serverCopy.healthData.medication === this.personalData.healthData.medication &&
            this.serverCopy.personalDoctor.fullName === this.personalData.personalDoctor.fullName &&
            this.serverCopy.personalDoctor.city === this.personalData.personalDoctor.city &&
            this.serverCopy.organization.id === this.personalData.organization.id &&
            this.serverCopy.phone1 === this.personalData.phone1 &&
            this.serverCopy.phone2 === this.personalData.phone2 &&
            this.serverCopy.phone3 === this.personalData.phone3 &&
            this.serverCopy.skype === this.personalData.skype &&
            this.serverCopy.viber === this.personalData.viber &&
            this.isHealthDataSame;
    }

    get isEmpty() {
        if (!this.personalData) return true;
        return JSON.stringify(this.personalData) === JSON.stringify(emptyPersonalData);
    }

    @computed
    get shouldShowWarningOnBackNavigation() {
        if (this.isEditing && (!this.isSameAsServerCopy || !this.isIdSameAsServerCopy)) return true;
        return !this.isEditing && !this.isEmpty;
    }

    @computed
    get isValid() {
        return (this.validationFields.map(fieldName => !this.isFieldValid(fieldName)).filter(field => field).length === 0) &&
            this.isBloodPressureValid() &&
            this.isHeartRateValid() &&
            this.isPersonalDoctorValid();
    }

    @action
    postRegistration = async () => {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: REGISTER_PATIENT,
            body: await this.mapStoreToServer(),
        };

        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.id = response.id;
                this.isLoading = false;
            });

            return true;
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
            return false;
        }
    };

    @action
    changeHealthData = (event) => {
        const {name, value} = event.target;

        if (name === "spO2" && (value < 0 || value > 100)) return;

        this.personalData.healthData[name] = value;
    };

    @action
    changeHeartRate = (event) => {
        const {name, value} = event.target;

        this.personalData.healthData.heartRate[name] = value;
    };

    @action
    changePressureData = (event) => {
        const {name, value} = event.target;

        const path = name.split(".");

        this.personalData.healthData.pressure[path[0]][path[1]] = value;
    };

    @action
    changePersonalDoctor = (event) => {
        const {name, value} = event.target;

        this.personalData.personalDoctor[name] = value;
    };

    @action
    changePersonalData = (event, enforceOnlyNumber) => {
        let {name, value} = event.target;

        if (enforceOnlyNumber && onlyDigits.test(value)) {
            value = Array.from(value).filter(char => !onlyDigits.test(char)).join("");
        }
        if ((name === "phone" || name === "phone1" || name === "phone2" || name === "phone3") && phoneNumber.test(value)) {
            value = Array.from(value).filter(char => !phoneNumber.test(char)).join("");
        }

        this.personalData[name] = value;
    };

    @action
    async getPatientPersonalDetails(id) {
        this.clear();

        this.isLoading = true;

        const options = {
            method: "GET",
            url: GET_PATIENTS_URL + `/${id}`,
        };

        try {
            const response = await httpService.fetch(
                options,
            );
            runInAction(() => {
                this.personalData = this.mapServerResponseToStore(response);
                this.serverCopy = this.mapServerResponseToStore(response);
                this.isEditing = true;
                this.isLoading = false;

                this.checkForErrors("emso");
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    validateAll = () => {
        this.validationFields.forEach(field => this.checkForErrors(field));
        this.checkForCombinedErrors("pressure");
        this.checkForCombinedErrors("heartRate");
        this.checkForCombinedErrors("personalDoctor");
    };

    @action
    checkForErrors(fieldName) {
        if (this.isLoading) return;
        this.status[fieldName] = this.isFieldValid(fieldName) ? "" : "has-danger";
        if (fieldName === "pin" && this.personalData.pin2) {
            this.status.pin2 = this.isFieldValid("pin2") ? "" : "has-danger";
        }
    };

    isFieldValid(fieldName) {
        switch (fieldName) {
            case("emso"):
                return validateEmso(this.personalData.emso);
            case("healthId"):
                return this.personalData.healthId.length === 9 && !onlyDigits.test(this.personalData.healthId);
            case("pin"):
                if (this.isEditing) {
                    if (!this.personalData.pin && !this.personalData.pin2) return true;
                }
                return this.personalData.pin && this.personalData.pin.length === 4 && !onlyDigits.test(this.personalData.pin);
            case("pin2"):
                if (this.isEditing) {
                    if (!this.personalData.pin && !this.personalData.pin2) return true;
                }
                return this.personalData.pin2 === this.personalData.pin && !onlyDigits.test(this.personalData.pin2);
            case("email"):
                return this.personalData.email && emailRegex.test(this.personalData.email.toLowerCase());
        }
    }

    isBloodPressureValid() {
        return (
            this.personalData.healthData.pressure.upper.from === "" &&
            this.personalData.healthData.pressure.upper.to === "" &&
            this.personalData.healthData.pressure.lower.from === "" &&
            this.personalData.healthData.pressure.lower.to === ""
        ) || (
            this.personalData.healthData.pressure.upper.from !== "" &&
            this.personalData.healthData.pressure.upper.to !== "" &&
            this.personalData.healthData.pressure.lower.from !== "" &&
            this.personalData.healthData.pressure.lower.to !== ""
        );
    }

    isHeartRateValid() {
        return (
            this.personalData.healthData.heartRate.from === "" &&
            this.personalData.healthData.heartRate.to === ""
        ) || (
            this.personalData.healthData.heartRate.from !== "" &&
            this.personalData.healthData.heartRate.to !== ""
        );
    }

    isPersonalDoctorValid() {
        return (
            this.personalData.personalDoctor.fullName === "" &&
            this.personalData.personalDoctor.city === ""
        ) || (
            this.personalData.personalDoctor.fullName !== "" &&
            this.personalData.personalDoctor.city !== ""
        );
    };

    checkToRemoveCombinedErrors(property) {
        switch (property) {
            case("pressure"):
                if (this.isBloodPressureValid()) {
                    this.status.healthData.pressure.upper.from = "";
                    this.status.healthData.pressure.upper.to = "";
                    this.status.healthData.pressure.lower.from = "";
                    this.status.healthData.pressure.lower.to = "";
                } else {
                    if (this.personalData.healthData.pressure.upper.from) this.status.healthData.pressure.upper.from = "";
                    if (this.personalData.healthData.pressure.upper.to) this.status.healthData.pressure.upper.to = "";
                    if (this.personalData.healthData.pressure.lower.from) this.status.healthData.pressure.lower.from = "";
                    if (this.personalData.healthData.pressure.lower.to) this.status.healthData.pressure.lower.to = "";
                }
                break;
            case("heartRate"):
                if (this.isHeartRateValid()) {
                    this.status.healthData.heartRate.form = "";
                    this.status.healthData.heartRate.to = "";
                } else {
                    if (this.personalData.healthData.heartRate.from) this.status.healthData.heartRate.form = "";
                    if (this.personalData.healthData.heartRate.to) this.status.healthData.heartRate.to = "";
                }
                break;
            case("personalDoctor"):
                if (this.isPersonalDoctorValid()) {
                    this.status.personalDoctor.fullName = "";
                    this.status.personalDoctor.city = "";
                } else {
                    if (this.personalData.personalDoctor.fullName) this.status.personalDoctor.fullName = "";
                    if (this.personalData.personalDoctor.city) this.status.personalDoctor.city = "";
                }
                break;
        }
    }

    checkForCombinedErrors(property) {
        switch (property) {
            case("pressure"):
                if (this.isBloodPressureValid()) {
                    this.status.healthData.pressure.upper.from = "";
                    this.status.healthData.pressure.upper.to = "";
                    this.status.healthData.pressure.lower.from = "";
                    this.status.healthData.pressure.lower.to = "";
                } else {
                    this.status.healthData.pressure.upper.from = this.personalData.healthData.pressure.upper.from ? "" : "has-danger";
                    this.status.healthData.pressure.upper.to = this.personalData.healthData.pressure.upper.to ? "" : "has-danger";
                    this.status.healthData.pressure.lower.from = this.personalData.healthData.pressure.lower.from ? "" : "has-danger";
                    this.status.healthData.pressure.lower.to = this.personalData.healthData.pressure.lower.to ? "" : "has-danger";
                }
                break;
            case("heartRate"):
                if (this.isHeartRateValid()) {
                    this.status.healthData.heartRate.form = "";
                    this.status.healthData.heartRate.to = "";
                } else {
                    this.status.healthData.heartRate.form = this.personalData.healthData.heartRate.from ? "" : "has-danger";
                    this.status.healthData.heartRate.to = this.personalData.healthData.heartRate.to ? "" : "has-danger";
                }
                break;
            case("personalDoctor"):
                if (this.isPersonalDoctorValid()) {
                    this.status.personalDoctor.fullName = "";
                    this.status.personalDoctor.city = "";
                } else {
                    this.status.personalDoctor.fullName = this.personalData.personalDoctor.fullName ? "" : "has-danger";
                    this.status.personalDoctor.city = this.personalData.personalDoctor.city ? "" : "has-danger";
                }
                break;
        }
    }

    mapStoreToServer = async () => {
        if (!AuthStore.organizationId) await AuthStore.getUserData();

        return {
            pin: this.personalData.pin,
            medModuleId: getModuleId(hist),
            firstName: this.personalData.firstName,
            lastName: this.personalData.lastName,
            emso: this.personalData.emso,
            healthId: this.personalData.healthId,
            phone: this.personalData.phone,
            email: this.personalData.email,
            address: this.personalData.address,
            healthData: {
                pressure: {
                    lower: {
                        from: parseInt(this.personalData.healthData.pressure.lower.from),
                        to: parseInt(this.personalData.healthData.pressure.lower.to),
                    },
                    upper: {
                        from: parseInt(this.personalData.healthData.pressure.upper.from),
                        to: parseInt(this.personalData.healthData.pressure.upper.to),
                    },
                },
                heartRate: {
                    from: parseInt(this.personalData.healthData.heartRate.from),
                    to: parseInt(this.personalData.healthData.heartRate.to),
                },
                spO2: parseFloat(this.personalData.healthData.spO2),
                medication: this.personalData.healthData.medication,
                allergies: this.personalData.healthData.allergies,
                diseases: this.personalData.healthData.diseases,
            },
            personalDoctor: {
                fullName: this.personalData.personalDoctor.fullName,
                city: this.personalData.personalDoctor.city,
            },
            organization: {
                id: AuthStore.organizationId,
            },
            phone1: this.personalData.phone1,
            phone2: this.personalData.phone2,
            phone3: this.personalData.phone3,
            skype: this.personalData.skype,
            viber: this.personalData.viber,
        };
    };

    mapStoreToServerEdit = async (id) => {
        if (!AuthStore.organizationId) await AuthStore.getUserData();

        return {
            firstName: this.personalData.firstName || "",
            lastName: this.personalData.lastName || "",
            phone: this.personalData.phone || "",
            email: this.personalData.email || "",
            address: this.personalData.address || "",
            id: id,
            healthData: {
                pressure: {
                    lower: {
                        from: parseInt(this.personalData.healthData.pressure.lower.from) || "",
                        to: parseInt(this.personalData.healthData.pressure.lower.to) || "",
                    },
                    upper: {
                        from: parseInt(this.personalData.healthData.pressure.upper.from) || "",
                        to: parseInt(this.personalData.healthData.pressure.upper.to) || "",
                    },
                },
                heartRate: {
                    from: parseInt(this.personalData.healthData.heartRate.from) || "",
                    to: parseInt(this.personalData.healthData.heartRate.to) || "",
                },
                spO2: parseFloat(this.personalData.healthData.spO2) || "",
                medication: this.personalData.healthData.medication || "",
                allergies: this.personalData.healthData.allergies || "",
                diseases: this.personalData.healthData.diseases || "",
            },
            personalDoctor: {
                fullName: this.personalData.personalDoctor.fullName || "",
                city: this.personalData.personalDoctor.city || "",
            },
            organization: {
                id: AuthStore.organizationId,
            },
            phone1: this.personalData.phone1,
            phone2: this.personalData.phone2,
            phone3: this.personalData.phone3,
            skype: this.personalData.skype,
            viber: this.personalData.viber,
        };
    };

    @action
    validateIdsAndEdit = async () => {
        this.isLoading = true;

        if (!this.isIdEditValid) {
            this.editIdValidationFields.forEach(field => this.checkForErrors(field));
        }

        const payload = {
            emso: this.personalData.emso,
            healthId: this.serverCopy.healthId,
            newHealthId: this.personalData.healthId,
        };

        const options = {
            method: "POST",
            url: EDIT_PATIENT_IDS,
            body: payload,
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.isLoading = false;
            });
            return true;
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
            return false;
        }
    };

    @action
    validateAndEdit = async (id) => {
        this.isLoading = true;

        if (!this.isEditValid) {
            this.validationFields.forEach(field => this.checkForErrors(field));
            this.checkForCombinedErrors("pressure");
            this.checkForCombinedErrors("heartRate");
            this.checkForCombinedErrors("personalDoctor");
        }

        const payload = await this.mapStoreToServerEdit(id);

        const options = {
            method: "POST",
            url: EDIT_PATIENT,
            body: payload,
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.clear();
                hist.goBack();
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    };

    mapServerResponseToStore(response) {
        return {
            firstName: response.firstName || "",
            lastName: response.lastName || "",
            phone: response.phone || "",
            email: response.email || "",
            address: response.address || "",
            emso: response.emso || "",
            healthId: response.healthId || "",
            id: response.id,
            healthData: {
                pressure: {
                    upper: {
                        from: response.healthData.pressure.upper.from && response.healthData.pressure.upper.from.toString() || "",
                        to: response.healthData.pressure.upper.to && response.healthData.pressure.upper.to.toString() || "",
                    },
                    lower: {
                        from: response.healthData.pressure.lower.from && response.healthData.pressure.lower.from.toString() || "",
                        to: response.healthData.pressure.lower.to && response.healthData.pressure.lower.to.toString() || "",
                    },
                },
                heartRate: {
                    from: response.healthData.heartRate.from && response.healthData.heartRate.from.toString() || "",
                    to: response.healthData.heartRate.to && response.healthData.heartRate.to.toString() || "",
                },
                spO2: response.healthData.spO2 && response.healthData.spO2.toString() || "",
                medication: response.healthData.medication || "",
                allergies: response.healthData.allergies || "",
                diseases: response.healthData.diseases || "",
            },
            personalDoctor: {
                fullName: response.personalDoctor.fullName || "",
                city: response.personalDoctor.city || "",
            },
            organization: {
                id: response.organization.id,
            },
            phone1: response.phone1,
            phone2: response.phone2,
            phone3: response.phone3,
            skype: response.skype,
            viber: response.viber,
        };
    }

    @action
    setHealthId = (healthId) => {
        this.personalData.healthId = healthId;
    };
}

export default new PatientPersonalDataStore();

