import React from "react";
import {FormGroup, Input, Label} from "reactstrap";
import PropTypes from "prop-types";

const DynamicFormFreeText = (props) => {
    const handleChange = (event) => {
        const {value} = event.target;

        props.handleChange(props.index, {id: props.id, value: value}, props.readingType);
    };

    return (
        <div>
            <Label>{props.title} {props.mandatory && "*"}</Label>
            <FormGroup className={props.validationStatus}>
                <Input
                    type="text" name={props.name}
                    onChange={handleChange}
                    onBlur={() => props.validateField(props.index, {id: props.id, type: props.readingType})}
                    maxLength={props.maxlength}/>
                {props.validationStatus === "has-danger" &&
                <label className="error">
                    Polje je obvezno
                </label>}
            </FormGroup>
        </div>
    );
};

DynamicFormFreeText.propTypes = {
    handleChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    readingType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mandatory: PropTypes.bool,
};

export default DynamicFormFreeText;
