import AddMainReading from "views/readingsAndAnamnesis/AddMainReading";
import MedModuleForm from "views/medModules/MedModuleForm";
import MedModuleList from "views/medModules/MedModuleList";
import AssignUserToModuleStore from "./stores/AssignUserToModuleStore";
import AssignUserToModuleForm from "views/user/AssignUserToModuleForm";
import AssignPatientsToModuleForm from "views/patient/AssignPatientsToModuleForm";
import PatientAnamnesisForm from "./views/patient/PatientAnamnesisForm";
import PatientMeasurementsForm from "./views/patient/PatientMeasurementsForm";
import PatientPersonalDetails from "./views/patient/PatientPersonalDetails";
import {PatientRegistrationWizard} from "./views/patientWizard/PatientRegistrationWizard";
import Settings from "./views/settings/Settings";
import role from "./enums/ERole";
import permission from "./enums/EPermission";
import {IBackButtonRoute} from "./interfaces/IBackButtonRoute";
import AnamnesisStore from "./stores/AnamnesisStore";
import ChatStore from "./stores/chat/ChatStore";
import MainReadingsStore from "./stores/MainReadingsStore";
import MedModulesStore from "./stores/MedModulesStore";
import MedModuleStore from "./stores/MedModuleStore";
import NotificationStore from "./stores/notifications/NotificationStore";
import OrganizationStore from "./stores/organizations/OrganizationStore";
import RegionStore from "./stores/organizations/RegionStore";
import PatientPersonalDataStore from "./stores/PatientPersonalDataStore";
import ReadingsStore from "./stores/ReadingsStore";
import ReadingStore from "./stores/ReadingStore";
import ResetPinStore from "./stores/ResetPinStore";
import SettingsStore from "./stores/SettingsStore";
import UserStore from "./stores/UserStore";
import Chat from "./views/Chat";
import PermissionsForm from "views/user/PermissionsForm";
import UserProfile from "views/user/UserProfile";
import SelectModule from "views/medModules/SelectModule";
import UsersForModuleList from "./components/UsersForModuleList";
import Notifications from "views/Notifications";
import OrganizationForm from "views/Organization/OrganizationForm";
import OrganizationsList from "views/Organization/OrganizationsList";
import RegionForm from "views/Organization/RegionForm";
import RegionsList from "views/Organization/RegionsList";
import PatientList from "views/patient/PatientList";
import DataList from "views/patient/DataList";
import PatientProfile from "views/patient/PatientProfile";
import AnamnesisForm from "views/readingsAndAnamnesis/AnamnesisForm";
import ReadingFormAnamnesis from "views/readingsAndAnamnesis/ReadingFormAnamnesis";
import ReadingFormMainReadings from "views/readingsAndAnamnesis/ReadingFormMainReadings";
import ReadingFormPrimaryReadings from "views/readingsAndAnamnesis/ReadingFormPrimaryReadings";
import ReadingFormSecondaryReadings from "views/readingsAndAnamnesis/ReadingFormSecondaryReadings";
import ReadingFormSecondarySection from "views/readingsAndAnamnesis/ReadingFormSecondarySection";
import ReadingSectionsForm from "views/readingsAndAnamnesis/ReadingSectionsForm";
import ReadingsForm from "views/readingsAndAnamnesis/ReadingsForm";
import ResetPinForm from "views/ResetPinForm";
import UserForm from "views/user/UserForm";
import UsersList from "views/user/UsersList";
import ScheduleForm from "views/schedule/ScheduleForm";
import SchedulesList from "views/schedule/SchedulesList";
import ScheduleStore from "stores/ScheduleStore";
import TemplatesList from "views/Templates/TemplatesList";
import TemplateStore from "stores/templates/TemplateStore";
import TemplateForm from "views/Templates/TemplateForm";
import MedModuleCalculationTypeForm from "views/medModules/MedModuleCalculationTypeForm";

const moduleViews = (moduleId: string | undefined) => {
    if (!moduleId) return [];
    return [
        {
            path: "/module/" + moduleId + "/patients",
            /*name: "Pacienti",
            mini: "P",*/
            component: PatientList,
            roles: [permission.CAN_USE],
            dynamicBackRoute: true,
        },
        {
            path: "/module/" + moduleId + "/data",
            /*name: "Pacienti",
            mini: "P",*/
            component: DataList,
            roles: [permission.CAN_USE],
            dynamicBackRoute: true,
        },
        {
            path: "/module/" + moduleId + "/assign-patient",
            component: AssignPatientsToModuleForm,
            roles: [permission.CAN_USE],
            backButton: ["/module/" + moduleId + "/patients"],
            dynamicBackRoute: true,
        },
        {
            path: "/module/" + moduleId + "/assign-patient/:healthId",
            component: PatientRegistrationWizard,
            roles: [permission.CAN_USE],
            confirmBackStore: PatientPersonalDataStore,
        },
        {
            path: "/module/" + moduleId + "/doctors",
            /*name: "Zdravniki",
            mini: "Z",*/
            component: UsersForModuleList,
            roles: [permission.CAN_SHARE],
            dynamicBackRoute: true,
        },
        {
            path: "/module/" + moduleId + "/doctors/:id",
            component: PermissionsForm,
            backButton: ["/module/" + moduleId + "/doctors"],
            roles: [permission.CAN_SHARE],
            dynamicBackRoute: true,
            confirmBackStore: AssignUserToModuleStore,
        },
        {
            path: "/module/" + moduleId + "/assign-doctor",
            component: AssignUserToModuleForm,
            roles: [permission.CAN_SHARE],
            backButton: ["/module/" + moduleId + "/doctors"],
            dynamicBackRoute: true,
        },
        {
            path: "/module/" + moduleId + "/templates",
            /*name: "Urniki meritev",
            mini: "U",*/
            component: SchedulesList,
            roles: [permission.CAN_USE],
            dynamicBackRoute: true,
        },
        {
            path: "/module/" + moduleId + "/templates/:id",
            component: ScheduleForm,
            backButton: ["/module/" + moduleId + "/templates"],
            roles: [permission.CAN_USE],
            dynamicBackRoute: true,
            confirmBackStore: ScheduleStore,
        },
        {
            path: "/module/" + moduleId + "/templates/:id/profile/:profileId",
            component: ScheduleForm,
            backButton: ["/module/" + moduleId + "/patients/:id"],
            roles: [permission.CAN_USE],
            confirmBackStore: ScheduleStore
        },
        {
            path: "/module/" + moduleId + "/add-template",
            component: ScheduleForm,
            roles: [permission.CAN_USE],
            backButton: ["/module/" + moduleId + "/templates"],
            dynamicBackRoute: true,
        },
        {
            path: "/module/" + moduleId + "/module-notifications",
            /*name: "Obvestila",
            mini: "O",*/
            component: Notifications,
            roles: [permission.CAN_USE],
            confirmBackStore: NotificationStore,
            dynamicBackRoute: true,
        },
        {
            path: "/module/" + moduleId + "/patients/:id/personalDetails",
            component: PatientPersonalDetails,
            backButton: ["/module/" + moduleId + "/patients/:id"],
            confirmBackStore: PatientPersonalDataStore,
        },
        {
            path: "/module/" + moduleId + "/patients/:id/resetPin",
            component: ResetPinForm,
            backButton: ["/module/" + moduleId + "/patients/:id"],
            confirmBackStore: ResetPinStore,
        },
        {
            path: "/module/" + moduleId + "/patients/:id/addAnamnesis",
            component: PatientAnamnesisForm,
            backButton: ["/module/" + moduleId + "/patients/:id"],
            confirmBackStore: AnamnesisStore,
        },
        {
            path: "/module/" + moduleId + "/patients/:id/addMeasurement",
            component: PatientMeasurementsForm,
            backButton: ["/module/" + moduleId + "/patients/:id"],
            confirmBackStore: ReadingsStore,
        },
        {
            path: "/module/" + moduleId + "/patients/:id/addMainReading",
            component: AddMainReading,
            backButton: ["/module/" + moduleId + "/patients/:id"],
            confirmBackStore: MainReadingsStore,
        },
        {
            path: "/module/" + moduleId + "/patients/:id",
            component: PatientProfile,
            backButton: ["/module/" + moduleId + "/patients", "/chat", "/sos", "/module/" + moduleId + "/assign-patient"],
            confirmBackStore: NotificationStore,
        },
        {
            path: "/module/" + moduleId + "/anamnesis",
            /*name: "Anamneza",
            mini: "A",*/
            component: AnamnesisForm,
            roles: [permission.CAN_USE],
            confirmBackStore: AnamnesisStore,
        },
        {
            path: "/module/" + moduleId + "/anamnesis/add-anamnesis",
            component: ReadingFormAnamnesis,
            roles: [permission.CAN_EDIT],
            backButton: ["/module/" + moduleId + "/anamnesis"],
            confirmBackStore: ReadingStore,
        },
        {
            path: "/module/" + moduleId + "/anamnesis/:id",
            component: ReadingFormAnamnesis,
            roles: [permission.CAN_EDIT],
            backButton: ["/module/" + moduleId + "/anamnesis"],
            confirmBackStore: ReadingStore,
        },
        {
            path: "/module/" + moduleId + "/readings",
            /*name: "Meritve",
            mini: "M",*/
            component: ReadingsForm,
            roles: [permission.CAN_USE],
        },
        {
            path: "/module/" + moduleId + "/readings/add-main-reading",
            component: ReadingFormMainReadings,
            roles: [permission.CAN_EDIT],
            backButton: ["/module/" + moduleId + "/readings"],
            confirmBackStore: ReadingStore,
        },
        {
            path: "/module/" + moduleId + "/readings/add-primary-reading",
            component: ReadingFormPrimaryReadings,
            roles: [permission.CAN_EDIT],
            backButton: ["/module/" + moduleId + "/readings"],
            confirmBackStore: ReadingStore,
        },
        {
            path: "/module/" + moduleId + "/readings/add-secondary-reading",
            component: ReadingFormSecondaryReadings,
            roles: [permission.CAN_EDIT],
            backButton: ["/module/" + moduleId + "/readings"],
            confirmBackStore: ReadingStore,
        },
        {
            path: "/module/" + moduleId + "/readings/edit-secondary-sections",
            component: ReadingSectionsForm,
            roles: [permission.CAN_EDIT],
            backButton: ["/module/" + moduleId + "/readings"],
            confirmBackStore: ReadingStore,
        },
        {
            path: "/module/" + moduleId + "/readings/edit-primary-calculation",
            component: MedModuleCalculationTypeForm,
            roles: [permission.CAN_EDIT],
            backButton: ["/module/" + moduleId + "/readings"],
            confirmBackStore: MedModuleStore,
        },
        {
            path: "/module/" + moduleId + "/readings/edit-secondary-sections/edit/:id",
            component: ReadingFormSecondarySection,
            roles: [permission.CAN_EDIT],
            backButton: ["/module/" + moduleId + "/readings/edit-secondary-sections"],
            confirmBackStore: ReadingStore,
        },
        {
            path: "/module/" + moduleId + "/readings/edit-secondary-sections-direct/edit/:id",
            component: ReadingFormSecondarySection,
            roles: [permission.CAN_EDIT],
            backButton: ["/module/" + moduleId + "/readings"],
            confirmBackStore: ReadingStore,
        },
        {
            path: "/module/" + moduleId + "/readings/edit-secondary-sections/add",
            component: ReadingFormSecondarySection,
            roles: [permission.CAN_EDIT],
            backButton: ["/module/" + moduleId + "/readings/edit-secondary-sections"],
            confirmBackStore: ReadingStore,
        },
        {
            path: "/module/" + moduleId + "/readings/main-readings/:id",
            component: ReadingFormMainReadings,
            roles: [permission.CAN_EDIT],
            backButton: ["/module/" + moduleId + "/readings"],
            confirmBackStore: ReadingStore,
        },
        {
            path: "/module/" + moduleId + "/readings/primary-readings/:id",
            component: ReadingFormPrimaryReadings,
            roles: [permission.CAN_EDIT],
            backButton: ["/module/" + moduleId + "/readings"],
            confirmBackStore: ReadingStore,
        },
        {
            path: "/module/" + moduleId + "/readings/secondary-readings/:id",
            component: ReadingFormSecondaryReadings,
            roles: [permission.CAN_EDIT],
            backButton: ["/module/" + moduleId + "/readings"],
            confirmBackStore: ReadingStore,
        },
    ];
};

const activeModuleRoute = () => {
    return MedModulesStore.modules.filter((module) => module.isEnabled && module.id == MedModulesStore.activeModuleId).map((module) => {
        return {
            collapse: true,
            name: module.name,
            icon: "nc-icon nc-app",
            state: module.name + module.id + "Collapse",
            roles: [permission.CAN_USE, permission.CAN_EDIT, permission.CAN_SHARE],
            views: moduleViews(module.id),
            path: "/module/" + module.id,
        };
    });
};

const moduleList = () => {
    return MedModulesStore.modules.filter((module) => module.isEnabled).map((module) => {
        return  {
            path: "/select-module/" + module.id,
            //name: module.name,
            //mini: module.name[0],
            component: SelectModule,
        };
    });
};

export const routes = () => [
    {
        path: "/profile",
        component: UserProfile,
        confirmBackStore: UserStore,
    },
    {
        path: "/add-patient",
        name: "Dodaj pacienta",
        icon: "nc-icon nc-simple-add",
        component: PatientRegistrationWizard,
        confirmBackStore: PatientPersonalDataStore,
    },
    {
        path: "/patients/:id/personalDetails",
        component: PatientPersonalDetails,
        backButton: ["/patients/:id"],
        confirmBackStore: PatientPersonalDataStore,
    },
    {
        path: "/patients/:id/resetPin",
        component: ResetPinForm,
        backButton: ["/patients/:id"],
        confirmBackStore: ResetPinStore,
    },
    {
        path: "/patients/:id",
        component: PatientProfile,
        backButton: ["/patients", "/chat", "/sos"],
        confirmBackStore: NotificationStore,
    },
    {
        path: "/add-medModules",
        component: MedModuleForm,        
        backButton: ["/medModules"],
        confirmBackStore: MedModuleStore,
    },
    {
        path: "/medModules/:id",
        component: MedModuleForm,
        backButton: ["/medModules"],
        confirmBackStore: MedModuleStore,
    },
    {
        collapse: false,
        name: "Moduli",
        icon: "nc-icon nc-box-2",
        state: "moduleCollapse",
        roles: MedModulesStore.canSeeModuleList() ? [role.ORGANIZATION_ADMIN, role.ADMIN, role.MEDIC] : [role.ORGANIZATION_ADMIN, role.ADMIN],
        path: "/medModules",
        component: MedModuleList,
    },
    ...moduleList(),
    //...activeModuleRoute(),
    ...moduleViews(MedModulesStore.activeModuleId?.toString()),
    {
        collapse: false,
        name: "Predloge",
        icon: "nc-icon nc-single-copy-04",
        state: "moduleCollapse",
        roles: [role.ORGANIZATION_ADMIN, role.ADMIN, role.READING_TEMPLATE],
        path: "/templates",
        component: TemplatesList
    },
    {
        path: "/add-template",
        component: TemplateForm,
        roles: [role.ORGANIZATION_ADMIN, role.ADMIN, role.READING_TEMPLATE],
        backButton: ["/templates"],
        confirmBackStore: TemplateStore,
    },
    {
        path: "/template/:id",
        component: TemplateForm,
        roles: [role.ORGANIZATION_ADMIN, role.ADMIN, role.READING_TEMPLATE],
        backButton: ["/templates"],
        confirmBackStore: TemplateStore,
    },
    {
        path: "/chat",
        name: "Klepet",
        icon: "nc-icon nc-chat-33",
        roles: MedModulesStore.canSeeChat() ? [role.ORGANIZATION_ADMIN, role.ADMIN, role.MEDIC] : [],
        component: Chat,
        dynamicBackRoute: true,
        confirmBackStore: ChatStore,
    },
    /*{
        path: "/sos",
        name: "SOS",
        icon: "nc-icon nc-ambulance",
        component: SosList,
        roles: [role.SOS_USER],
        dynamicBackRoute: true,
    },*/    
    {
        path: "/notifications",
        name: "Obvestila",
        icon: "nc-icon nc-send",
        component: Notifications,
        roles: [role.ADMIN],
        confirmBackStore: NotificationStore,
        dynamicBackRoute: true,
    },
    {
        collapse: true,
        name: "Organizacije",
        icon: "nc-icon nc-bank",
        state: "organizationCollapse",
        roles: [role.ADMIN],
        views: [
            {
                path: "/regions",
                name: "Regije",
                mini: "R",
                component: RegionsList,
                roles: [role.ADMIN],
            },
            {
                path: "/organizations",
                name: "Organizacije",
                mini: "O",
                component: OrganizationsList,
                roles: [role.ADMIN],
            },
            {
                path: "/add-region",
                component: RegionForm,
                roles: [role.ADMIN],
                backButton: ["/regions"],
                confirmBackStore: RegionStore,
            },
            {
                path: "/region/:id",
                component: RegionForm,
                roles: [role.ADMIN],
                backButton: ["/regions"],
                confirmBackStore: RegionStore,
            },
            {
                path: "/add-organization",
                component: OrganizationForm,
                roles: [role.ADMIN],
                backButton: ["/organizations"],
                confirmBackStore: OrganizationStore,
            },
            {
                path: "/organizations/:id",
                component: OrganizationForm,
                roles: [role.ADMIN],
                backButton: ["/organizations"],
                confirmBackStore: OrganizationStore,
            },
        ],
    },
    {
        collapse: false,
        name: "Uporabniki",
        icon: "nc-icon nc-circle-10",
        state: "userCollapse",
        roles: [role.ADMIN, role.ORGANIZATION_ADMIN],
        path: "/users",
        component: UsersList,
    },
    {
        path: "/add-user",
        component: UserForm,
        roles: [role.ADMIN, role.ORGANIZATION_ADMIN],
        backButton: ["/users"],
        confirmBackStore: UserStore,
    },
    {
        path: "/users/:id",
        component: UserForm,
        roles: [role.ADMIN, role.ORGANIZATION_ADMIN],
        backButton: ["/users"],
        confirmBackStore: UserStore,
    },
    {
        path: "/settings",
        name: "Nastavitve",
        icon: "nc-icon nc-settings-gear-65",
        component: Settings,
        roles: [role.ADMIN],
        confirmBackStore: SettingsStore,
    }
];

interface IRoute {
    path: string,
    backButton?: string[],
    confirmBackStore?: any,
    dynamicBackRoute?: boolean,
}

const allRoutes = (): IRoute[] => {

    return routes().concat(routes().flatMap((route: any) => route.views)).filter((route: any) => route).map((route: any) => {
        return {
            path: route.path,
            backButton: route.backButton,
            confirmBackStore: route.confirmBackStore,
            dynamicBackRoute: route.dynamicBackRoute,
        };
    });
};

export const getBackButtonRoutes = () : IBackButtonRoute[] => {
    const allRoutesBackButton = allRoutes().filter((route: IRoute) => route?.backButton);
    const routerRegex = allRoutesBackButton.map((route:IRoute) => getPathRegex(route.path));
    return allRoutesBackButton.map((route:any, index:number) => {
        return {
            backButtonPath: route.backButton,
            regex: routerRegex[index],
            isDynamic: route.backButton.length > 1,
            confirmBackStore: route.confirmBackStore,
        };
    });
};

export const getAllRoutes = () => {
    const allPathRoutes = allRoutes().filter((route: IRoute) => route && route.path);
    const routerRegex = allPathRoutes.map((route: IRoute) => getPathRegex(route.path));
    return allPathRoutes.map((route: any, index) => {
        return {
            regex: routerRegex[index],
            confirmBackStore: route.confirmBackStore,
        };
    });
};

export const getDynamicBackRoutes = () => {
    const allRoutesBackButton = allRoutes().filter((route: IRoute) => route && route.dynamicBackRoute);
    const routerRegex = allRoutesBackButton.map((route: IRoute) => getPathRegex(route.path));
    return allRoutesBackButton.map((route: any, index) => {
        return {
            path: route.path,
            regex: routerRegex[index],
        };
    });
};

export const getModuleViewsRoutes = () => {
    const allModuleRoutes = moduleViews("moduleId");
    if (!allModuleRoutes) return null;
    const routerRegex = allModuleRoutes.map((route: IRoute) => getPathRegex(route.path.replace("/moduleId", "/:id")));
    return allModuleRoutes.map((route: any, index) => {
        return {
            path: route.path,
            regex: routerRegex[index],
        };
    });
};

const getPathRegex = (path: string) => {
    return new RegExp("^" +
        path.replace(/\//g, "\\/")
            .replace(/:id/g, "\\d+")
            .replace(/:healthId/g, "\\d+")
            .replace(/:profileId/g, "\\d+")
        + "$");
};

export default routes();