export enum EReadingSensorType
{
    HeartRate = 0,
    BloodPressure = 1,
    Saturation = 2,
    BreathingFrequency = 3,
    HeartRateVariability = 4,
    PerfusionIndex = 5,
    ApneaTimes = 6,
    HypoxiaTimes = 7
}

export const readingSensorTypes = [
    {
        value: null,
        label: "Ni senzor"
    },
    {
        value: EReadingSensorType.HeartRate,
        label: "Srčni utrip"
    },
    {
        value: EReadingSensorType.BloodPressure,
        label: "Srčni pritisk"
    },
    {
        value: EReadingSensorType.Saturation,
        label: "Saturacija"
    },
    {
        value: EReadingSensorType.BreathingFrequency,
        label: "Frekvenca dihanja"
    },
    {
        value: EReadingSensorType.HeartRateVariability,
        label: "Spremenljivost srčnega utripa"
    },
    {
        value: EReadingSensorType.PerfusionIndex,
        label: "Perfuzijski indeks"
    },
    {
        value: EReadingSensorType.ApneaTimes,
        label: "Časi apneje"
    },
    {
        value: EReadingSensorType.HypoxiaTimes,
        label: "Časi hipoksije"
    }
]