import {CMS_SCHEDULE} from "../config";
import httpService from "./httpService";

class DataService {
    async getModuleSchedules(moduleId) {
        const options = {
            method: "get",
            url: `${CMS_SCHEDULE}/${moduleId}`,
        };

        return httpService.fetch(options);
    }

    async assignSchedule(data) {
        const options = {
            method: "post",
            url: `${CMS_SCHEDULE}/${data.moduleId}/${data.scheduleId}/assignto/${data.patientId}`,
            body: data
        };

        return httpService.fetch(options);
    }

    async getPatientSchedule(moduleId, patientId) {
        const options = {
            method: "get",
            url: `${CMS_SCHEDULE}/${moduleId}/profile/${patientId}`
        };

        return httpService.fetch(options);
    }

    async removePatientSchedule(data) {
        const options = {
            method: "delete",
            url: `${CMS_SCHEDULE}/${data.moduleId}/${data.scheduleId}/remove/${data.patientId}`
        };

        return httpService.fetch(options);
    }
}

// singletone
export default new DataService();
