import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Form, Input, InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import AuthStore from "stores/AuthStore";
import {observer} from "mobx-react-lite";
import UserStore from "stores/UserStore";
import styled from "styled-components";

const StyledInput = styled(Input)`
    ::-webkit-credentials-auto-fill-button {
        opacity: 0;
    }
`;

import {centerInputX} from "variables/styles";

const ResetPassword = observer(({history}) => {
    const token = new URLSearchParams(window.location.search).get("token");
    const [password, setPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [repeatedPassword, setRepeatedPassword] = useState("");
    const [isRepeatedPasswordValid, setIsRepeatedPasswordValid] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        return () => {
            AuthStore.changePasswordStatus = null;
        };
    }, []);

    const validateRepeatedPassword = () => {
        if(UserStore.isRepeatedPasswordValid(password, repeatedPassword)){
            setIsRepeatedPasswordValid(true);
            return true;
        }
        else {
            setIsRepeatedPasswordValid(false);
            setError("Gesli se ne ujemata");
            return false;
        }
    };

    const validatePassword = () => {
        setError(null);
        if(repeatedPassword)
            validateRepeatedPassword();
        if(password && UserStore.isPasswordValid(password)) {
            setIsPasswordValid(true);
            return true;
        }
        else {
            setIsPasswordValid(false);
            setError("Geslo mora imeti vsaj 8 znakov");
            return false;
        }
    };

    const submitResetPassword = () => {
        if(validatePassword() && repeatedPassword &&
            validateRepeatedPassword())
            AuthStore.changePassword(token, password);
    };

    const openLogin = () => {
        history.push("/login");
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") submitResetPassword();
    };

    useEffect(() => {
        if(AuthStore.error) {
            if(AuthStore.error.message)
                setError(AuthStore.error.message);
            else
                setError("Prišlo je do napake.");
            setPassword("");
            setRepeatedPassword("");
        }
    }, [AuthStore.error]);

    if(AuthStore.changePasswordStatus === "success"){
        return (
            <Card className="card-signup text-center">
                <CardHeader>
                    <CardTitle tag="h4">Geslo je bilo uspešno posodobljeno!</CardTitle>
                </CardHeader>
                <CardFooter>
                    <Button
                        block
                        className="btn-round mb-3"
                        color="warning"
                        onClick={openLogin}
                    >
                        Prijavi se
                    </Button>
                </CardFooter>
            </Card>
        );
    }

    return (
        <Form onKeyPress={handleKeyPress} >
            <Card className="card-signup text-center">
                <CardHeader>
                    <CardTitle tag="h4">Ponastavi geslo</CardTitle>
                </CardHeader>
                <CardBody>
                    <p className="text-danger text-center">
                        {error}
                    </p>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="nc-icon nc-key-25"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <StyledInput
                            placeholder="Novo geslo"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={validatePassword}
                            invalid={!isPasswordValid}
                            style={centerInputX}
                            autoComplete="off"
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="nc-icon nc-key-25"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <StyledInput
                            placeholder="Ponovi geslo"
                            type="password"
                            onChange={(e) => setRepeatedPassword(e.target.value)}
                            value={repeatedPassword}
                            onBlur={validateRepeatedPassword}
                            invalid={!isRepeatedPasswordValid}
                            style={centerInputX}
                            autoComplete="off"
                        />
                    </InputGroup>
                </CardBody>
                <CardFooter>
                    <Button
                        block
                        className="btn-round mb-3"
                        color="warning"
                        onMouseDown={submitResetPassword}
                    >
                            Ponastavi geslo
                    </Button>
                </CardFooter>
            </Card>
        </Form>
    );
});

export default ResetPassword;