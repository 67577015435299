import {getBackButtonRoutes} from "../routes";
import {hist} from "../index";

export const getGoBackPath = (id?: string) => {
    const backButtonRoutes = getBackButtonRoutes();
    if (history) {
        for (var index = 0; index < backButtonRoutes.length; index++) {
            if (backButtonRoutes[index].regex.test(hist.location.pathname)) {
                if (backButtonRoutes[index].isDynamic) {
                    const dynamicBackRoute = sessionStorage.getItem("backButtonRoute");
                    if (dynamicBackRoute && id) {
                        return dynamicBackRoute.replace(":id", id);
                    }
                    else if(dynamicBackRoute) {
                        return dynamicBackRoute;
                    }
                }
                else {
                    if(id)
                        return backButtonRoutes[index]?.backButtonPath[0].replace(":id", id);
                    else
                        return backButtonRoutes[index]?.backButtonPath[0];
                }
                break;
            }
        }
    }
};

export const goBack = (id:string) => {
    const backPath = getGoBackPath(id);
    if (backPath) hist.push(backPath);
};