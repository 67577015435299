export const queryParams = (params, paramKeys) => {
    if(!params || !paramKeys || params.length !== paramKeys.length) return "";
    return "?" + params.map((param, index) => param ? "&"+ paramKeys[index] +"=" + param : "").join("");
};

export const getModuleId = (history) => {
    const urlParams = history?.location?.pathname?.split("/");
    if(urlParams && urlParams.length > 2 && (urlParams[1] === "module" || urlParams[1] === "select-module"))
        return urlParams[2];
    return null;
};

export const getModuleFromRoute = (route) => {
    const urlParams = route?.split("/");
    if(urlParams && urlParams.length > 2 && urlParams[1] === "module")
        return urlParams[2];
    return null;
};