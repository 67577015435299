import {action, observable, runInAction, computed} from "mobx";
import {GET_CMS_USERS, PAGE_COUNT} from "../config";
import httpService from "../services/httpService";
import {queryParams} from "../variables/fetchHelper";

class UsersStore {
    @observable isLoading = false;
    @observable users = [];
    @observable error = null;
    @observable currentPage = 1;
    @observable query = null;
    @observable order = null;

    @action
    async getUsers(query, count, page, order) {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: GET_CMS_USERS + queryParams([query, count, page, order], ["search", "count", "page", "order"]),
        };

        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.users = response;
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    async getNextPage() {
        this.currentPage += 1;
        await this.getUsers(this.query, PAGE_COUNT, this.currentPage, this.order);
    }

    @action
    async getPreviousPage() {
        if(this.currentPage > 1){
            this.currentPage -= 1;
            await this.getUsers(this.query, PAGE_COUNT, this.currentPage, this.order);
        }
    }

    @computed
    get isFirstPage() {
        return this.currentPage === 1;
    }

    @computed
    get isLastPage() {
        return this.users.length <= PAGE_COUNT;
    }

    @action
    async removeUser(id) {
        this.isLoading = true;

        const options = {
            method: "DELETE",
            url: GET_CMS_USERS + `/${id}`,
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.users = this.users.filter(user => user.id !== id);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    async unlockUser(id) {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: GET_CMS_USERS + `/${id}/unlock`,
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.getUsers();
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    clear() {
        this.isLoading = false;
        this.users = [];
        this.error = null;
        this.currentPage = 1;
        this.query = "";
    }
}

export default new UsersStore();
