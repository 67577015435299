import {action, computed, observable, runInAction} from "mobx";
import {COUNTRIES} from "../../config";
import {ICountry} from "../../interfaces/ICountry";
import {ISelect} from "../../interfaces/ISelect";
import httpService from "../../services/httpService";
import ResettableStore from "../ResettableStore";


class CountriesStore extends ResettableStore {
	@observable isLoading = false;
	@observable error = "";
	@observable countries: Array<ICountry> = [];

	@action
	async getCountries() {
	    this.isLoading = true;

	    const options = {
	        method: "GET",
	        url: COUNTRIES,
	    };

	    try {
	        const response = await httpService.fetch(
	            options,
	        );

	        runInAction(() => {
	            this.mapCountriesToStore(response);
	            this.isLoading = false;
	        });
	    } catch (err) {
	        runInAction(() => {
	            this.isLoading = false;
	            this.error = err;
	        });
	    }
	}

	@action
	mapCountriesToStore(payload: any) {
	    this.countries = payload;
	}

	@computed
	get countriesForDropdown() {
	    return this.countries.map(country => {
	        return {
	            value: country.id,
	            label: country.title,
	        };
	    });
	}

	@action
	selectedCountryForDropdown(countryId: string | undefined): ISelect | null {
	    const selectedCountry = this.countries.filter((c) => c.id === countryId);
	    if (!countryId && selectedCountry.length === 0) return null;
	    return {value: selectedCountry[0].id.toString(), label: selectedCountry[0].title};
	}

	@action
	getPostNumberRegex (countryId:string|undefined) {
	    const result = this.countries.filter(c => c.id === countryId);
	    if(result.length > 0 && result[0].regEx) return RegExp(result[0].regEx);
	    return null;
	};
}


export default new CountriesStore();