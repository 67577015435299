import React, {useState} from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";

const ButtonWithConfirmAlert = ({children, successAction, openAction, closeAction, text, disabled, reactBSAlertProperties = {}, style}) => {
    const [alert, setAlert] =  useState(null);

    const warningWithConfirmMessage = () => {
        if(openAction) openAction();
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: "block", whiteSpace: "normal"}}
                title={text}
                onConfirm={successDelete}
                onCancel={hideAlert}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText="Potrdi"
                cancelBtnText="Prekliči"
                showCancel
                {...reactBSAlertProperties}
            />
        );
    };
    const successDelete = () => {
        successAction();
        hideAlert();
    };
    const hideAlert = () => {
        if(closeAction) closeAction();
        setAlert(null);
    };
    return (
        <div className="d-inline-block" style={{...style}}>
            {alert}
            <div onClick={disabled ? null : warningWithConfirmMessage}>
                {children}
            </div>
        </div>
    );
};

ButtonWithConfirmAlert.propTypes = {
    children: PropTypes.any,
    successAction: PropTypes.func,
    openAction: PropTypes.func,
    closeAction: PropTypes.func,
    text: PropTypes.string,
    disabled: PropTypes.bool,
    reactBSAlertProperties: PropTypes.object,
    style: PropTypes.object,
};

export default ButtonWithConfirmAlert;
