import React, {useEffect} from "react";
import {Button, Card, CardBody, CardHeader, CardTitle, Col,} from "reactstrap";
import {observer} from "mobx-react-lite";
import ReadingsStore from "stores/ReadingsStore";
import "components/readingsAndAnamnesisForms/ReadingsForm.css";
import Loader from "components/Loader";
import {Link} from "react-router-dom";
import ButtonWithConfirmAlert from "components/ButtonWithConfirmAlert";
import SortableTable from "components/readingsAndAnamnesisForms/SortableTable";
import {hist} from "index";
import MedModulesStore from "stores/MedModulesStore";
import EPermission from "enums/EPermission";
import {getModuleId} from "variables/fetchHelper";


const ReadingSectionsForm = observer(({history}) => {
    const moduleId = getModuleId(hist);
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        return await ReadingsStore.getReadings();
    };

    return (
        <React.Fragment>
            <Loader isLoading={ReadingsStore.isLoading} />
            <Col md={11}>
                <Card>
                    <CardHeader className="d-flex justify-content-between">
                        <CardTitle tag="h4">Sekcije</CardTitle>
                        {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_EDIT) &&
                            <Button
                                className="btn-round"
                                color="default"
                                outline
                                onClick={() => history.push(hist.location.pathname + "/add")}
                            >
                                + Dodaj novo sekcijo
                            </Button>
                        }
                    </CardHeader>
                    <CardBody>
                        <SortableTable
                            data={ReadingsStore.secondaryReadings}
                            setData={(data) => {ReadingsStore.secondaryReadings = data; ReadingsStore.postReadings();}}
                            headers={headers()}
                            isDragDisabled={ReadingsStore.disabledDragReorder}
                        />
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
});

const headers = () => {
    return [
        {
            Header: "Text",
            accessor: "text",
        },
        {
            Header: "Podtext",
            accessor: "subText",
        },
        {
            Header: "Tip izračuna",
            Cell: props => ReadingsStore.getCalculationType(props.original.calculationType)
        },
        ...MedModulesStore.hasPermissionForModule(getModuleId(hist), EPermission.CAN_EDIT) ? [{
            Header: "Akcije",
            Cell: props =>
                <div>
                    <Link to={`${hist.location.pathname}/edit/${props.original.id}`}>
                        <i className="nc-icon nc-settings-gear-65 mx-1"/>
                    </Link>
                    <ButtonWithConfirmAlert
                        text={"Želite izbrisati sekcijo ?"}
                        successAction={() => ReadingsStore.removeSection(props.original)}
                        openAction={() => ReadingsStore.disabledDragReorder = true}
                        closeAction={() => ReadingsStore.disabledDragReorder = false}
                    >
                        <i className="nc-icon nc-simple-remove text-danger mx-1" style={{cursor: "pointer"}}/>
                    </ButtonWithConfirmAlert>
                </div>,
        }] : [],
    ];
};

export default ReadingSectionsForm;
