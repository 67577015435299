import {decimals, floatNumberComma, floatNumberDot, onlyDigits} from "../enums/regex";
import {localNumberFormat} from "../variables/formatHelpers";

export function numberValidation(event, callback, allowDecimal) {
    let {value, name} = event.target;

    const regexp = allowDecimal ? decimals : onlyDigits;

    if (regexp.test(value)) {
        value = Array.from(value).filter(char => !regexp.test(char)).join("");
    }

    if(allowDecimal){
        const dotFormat = localNumberFormat("1.1").includes(".");
        let matchDot = value.match(dotFormat ? floatNumberDot : floatNumberComma);
        matchDot = matchDot && matchDot.length ? matchDot[0] : null;
        if(matchDot)
            value = matchDot;
    }

    callback({target: {value: value, name: name}});
}