import React from "react";

const defaultWarningSettings = {
    place: "tc",
    type: "danger",
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 7
};

class WarningsStore {
    ref;

    constructor() {
    }

    createWarning(message, type, duration) {
        let alreadyExists = false;
        if (this.ref && this.ref.current) {
            this.ref.current.state.notifyTC
                .forEach(warning => warning.props.children
                    .filter(child => child.props["data-notify"] === "message")
                    .forEach(messageDiv => {
                        if (messageDiv.props.children.props.children === message) {
                            alreadyExists = true;
                        }
                    }));

            if (!alreadyExists)
                this.ref.current.notificationAlert({
                    ...defaultWarningSettings,
                    type: type || defaultWarningSettings.type,
                    autoDismiss: duration || defaultWarningSettings.autoDismiss,
                    message: (
                        <div>
                            {message}
                        </div>
                    ),
                });
        }
    }

    setRef(reference) {
        this.ref = reference;
    }
}

export default new WarningsStore();
