import {action, computed, observable, runInAction} from "mobx";
import {ORGANIZATIONS} from "../../config";
import httpService from "../../services/httpService";
import {IRegion} from "../../interfaces/IRegion";
import {IOrganization} from "../../interfaces/IOrganization";

class OrganizationsStore {
    @observable isLoading = false;
    @observable regions = [];
    @observable organizations = [];
    @observable error = null;

    @computed
    get organizationsWithRegionsForDropdown() {
        return this.regions.map((region: IRegion) => {
            return {
                label: region.name,
                options: region.organizations.map((organization: IOrganization) => {
                    return {
                        value: organization.id,
                        label: organization.name,
                    };
                }),
            };
        });
    }

    @action
    async getAllOrganizationsWithRegions() {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: ORGANIZATIONS,
        };

        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.mapRegionsToStore(response);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    mapRegionsToStore(payload: any) {
        this.regions = payload.regions;
    }

    @action
    async getAllOrganizations() {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: ORGANIZATIONS + "/list",
        };

        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.mapOrganizationToStore(response);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    mapOrganizationToStore(payload: any) {
        this.organizations = payload;
    }

    @action
    async removeOrganization(id: string) {
        this.isLoading = true;

        const options = {
            method: "DELETE",
            url: ORGANIZATIONS + `/${id}`,
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.organizations = this.organizations.filter((organization: IOrganization) => organization.id !== id);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    clear() {
        this.isLoading = false;
        this.regions = [];
        this.organizations = [];
        this.error = null;
    }
}

export default new OrganizationsStore();
