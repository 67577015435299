import React from 'react';
import {Card, CardBody, Col, Form, FormGroup, Label, Row} from "reactstrap";
import Select from "react-select";
import ChatStore from "../../stores/chat/ChatStore";
import {observer} from "mobx-react-lite";

const ChatTopicSelector = observer(() => {
    return (
        <Card>
            <CardBody>
                <Row>
                    <Col>
                        <Form action="#" method="#">
                            <FormGroup>
                                <Label>Kategorija:</Label>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    placeholder="Seznam kategorij"
                                    options={ChatStore.topicsForDropdown}
                                    noOptionsMessage={() => ChatStore.isLoading ? "Nalagam" : "Ni vsebine"}
                                    value={ChatStore.selectedTopicForDropdown}
                                    onChange={(selectedTopic) => ChatStore.selectTopic(selectedTopic)}
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
});

export default ChatTopicSelector;
