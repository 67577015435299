import React from "react";
import {Card, Nav, NavItem, NavLink} from "reactstrap";
import MedModulesStore from "../stores/MedModulesStore";
import {hist} from "../index";
import EPermission from "enums/EPermission";

const ModuleLinks = ({tabPath, moduleId}) => {
    return (
        <>
        {(moduleId) &&
        <Card style={{backgroundColor:"white", paddingBottom:"2px"}}>
            <h5 className="text-center" style={{padding:"15px"}}>Modul - {MedModulesStore.modules.find((module) => module.isEnabled && module.id == moduleId)?.name}</h5>
            <div className="nav-tabs-navigation" style={{marginBottom: "15px"}}>
                <div className="nav-tabs-wrapper">
                    <Nav id="link-tabs" role="tablist" tabs className="justify-content-start">
                        {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_USE) && <NavItem>
                            <NavLink 
                                style={{cursor: "pointer"}}
                                aria-expanded={tabPath.indexOf('patients') != -1}
                                data-toggle="tab"
                                role="tab"
                                className={tabPath.indexOf('patients') != -1 ? "active" : ""}
                                onClick={() => { hist.push(`/module/${moduleId}/patients`) }}><h6>Pacienti</h6></NavLink>
                        </NavItem>}
                        {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_SHARE) &&
                        <NavItem>
                            <NavLink 
                                style={{cursor: "pointer"}}
                                aria-expanded={tabPath.indexOf('doctors') != -1}
                                data-toggle="tab"
                                role="tab"
                                className={tabPath.indexOf('doctors') != -1 ? "active" : ""}
                                onClick={() => { hist.push(`/module/${moduleId}/doctors`) }}><h6>Zdravniki</h6></NavLink>
                        </NavItem>}
                        {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_USE) && <NavItem>
                            <NavLink 
                                style={{cursor: "pointer"}}
                                aria-expanded={tabPath.indexOf('templates') != -1}
                                data-toggle="tab"
                                role="tab"
                                className={tabPath.indexOf('templates') != -1 ? "active" : ""}
                                onClick={() => { hist.push(`/module/${moduleId}/templates`) }}><h6>Urniki meritev</h6></NavLink>
                        </NavItem>}
                        {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_USE) && <NavItem>
                            <NavLink 
                                style={{cursor: "pointer"}}
                                aria-expanded={tabPath.indexOf('module-notifications') != -1}
                                data-toggle="tab"
                                role="tab"
                                className={tabPath.indexOf('module-notifications') != -1 ? "active" : ""}
                                onClick={() => { hist.push(`/module/${moduleId}/module-notifications`) }}><h6>Obvestila</h6></NavLink>
                        </NavItem>}
                        {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_USE) && <NavItem>
                            <NavLink 
                                style={{cursor: "pointer"}}
                                aria-expanded={tabPath.indexOf('anamnesis') != -1}
                                data-toggle="tab"
                                role="tab"
                                className={tabPath.indexOf('anamnesis') != -1 ? "active" : ""}
                                onClick={() => { hist.push(`/module/${moduleId}/anamnesis`) }}><h6>Anamneza</h6></NavLink>
                        </NavItem>}
                        {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_USE) && <NavItem>
                            <NavLink 
                                style={{cursor: "pointer"}}
                                aria-expanded={tabPath.indexOf('readings') != -1}
                                data-toggle="tab"
                                role="tab"
                                className={tabPath.indexOf('readings') != -1 ? "active" : ""}
                                onClick={() => { hist.push(`/module/${moduleId}/readings`) }}><h6>Meritve</h6></NavLink>
                        </NavItem>}
                        {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_USE) && <NavItem>
                            <NavLink 
                                style={{cursor: "pointer"}}
                                aria-expanded={tabPath.indexOf('data') != -1}
                                data-toggle="tab"
                                role="tab"
                                className={tabPath.indexOf('data') != -1 ? "active" : ""}
                                onClick={() => { hist.push(`/module/${moduleId}/data`) }}><h6>Podatki</h6></NavLink>
                        </NavItem>}
                    </Nav>
                </div>
            </div>
        </Card>
        }
        </>
    );
};

ModuleLinks.propTypes = {
    tabPath: ModuleLinks.string
};

export default ModuleLinks;
