import React, {SetStateAction} from 'react';
import {Col, Row} from "reactstrap";
import styled from "styled-components";
import {IPatient} from "../../interfaces/IPatient";
import ChatStore from "../../stores/chat/ChatStore";
import {observer} from "mobx-react-lite";

interface IProps {
    patient: IPatient,
    setIsInputFocused: (value: boolean) => SetStateAction<boolean>
}

const ClickableRow = styled(Row)`
  cursor: pointer;
  padding: 8px 0;
  &:hover {
    background-color: #f8f8f8;
  }
  border-bottom: 1px solid lightgray;
`;

const ChatSearchPatientSelector = observer(({patient, setIsInputFocused}: IProps) => {

    const isNamePresent = !!(patient.firstName && patient.lastName);

    const getDisplayName = () => {
        let name = patient.healthId;
        if (patient.firstName && patient.lastName)
            name += ` - ${patient.firstName} ${patient.lastName}`;
        return name
    };

    const onUserSelection = () => {
        setIsInputFocused(false);
        ChatStore.selectOrCreateConversationByPatient(patient);
    };

    return (
        <ClickableRow onClick={() => onUserSelection()}>
            <Col md="9" xs="9" className="d-flex justify-content-center flex-column">
                <span>{isNamePresent ? getDisplayName() : patient.healthId}</span>
            </Col>
        </ClickableRow>
    );
});

export default ChatSearchPatientSelector;
