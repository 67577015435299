import React, {useEffect, useRef, useState} from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Row, Col, Label} from "reactstrap";
import {Link} from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import ScheduleStore from "../stores/ScheduleStore";
import PropTypes from "prop-types";
import ButtonWithConfirmAlert from "./ButtonWithConfirmAlert";
import Select from "react-select";
import {observer} from "mobx-react-lite";
import dataService from "services/dataService";
import "moment/min/locales";

const PatientSchedule = observer(({moduleId, profileId}) => {
    
    const [schedules, setSchedules] = useState([]);
    const [patientSchedule, setPatientSchedule] = useState({});
    const [selectedSchedule, setSelectedSchedule]= useState(-1);

    useEffect(() => {
        async function fetchData() {            
            if (moduleId) {
                let data = await dataService.getModuleSchedules(moduleId);
                setSchedules(data.notifications);

                let scheduleData = await dataService.getPatientSchedule(moduleId, profileId)
                setPatientSchedule(scheduleData);
            }
        }

        setPatientSchedule(null);
        fetchData();

    }, [moduleId, profileId]);

    const notificationAlert = useRef();

    const assignSchedule = async () => {
        try {
            await dataService.assignSchedule({moduleId: moduleId, patientId: profileId, scheduleId: selectedSchedule });
            let scheduleData = await dataService.getPatientSchedule(moduleId, profileId)
            setPatientSchedule(scheduleData);
        } catch(err) {
            console.log(`${JSON.stringify(err)}`);
        }
    }

    const removeSchedule = async (id) => {
        try {
            await dataService.removePatientSchedule({moduleId: moduleId, patientId: profileId, scheduleId: id });
            setPatientSchedule(null);
        } catch(err) {}
        
    }

    const onScheduleChange = (e) => {
        setSelectedSchedule(e.value);
    };

    return (
        <div>
            <NotificationAlert ref={notificationAlert}/>
            <Card>
                <CardHeader>
                    <Row>
                        <Col md={9}>
                            <CardTitle tag="h5">Urnik meritev</CardTitle>
                        </Col>
                        <Col md={3}>
                            {patientSchedule && <>
                                <div className="float-right">
                                    <ButtonWithConfirmAlert
                                        successAction={() => { removeSchedule(patientSchedule.id) }}
                                        text={"Ste prepričani, da želite odstraniti urnik meritev za trenutnega pacienta ?"}>
                                        <i className="float-right nc-icon nc-simple-remove text-danger mt-2"
                                        style={{"cursor": "pointer", fontSize: "22px"}}/>
                                    </ButtonWithConfirmAlert>
                                </div>
                                <Link to={`/module/${moduleId}/templates/${patientSchedule.id}/profile/${profileId}`}>
                                    <i className="float-right nc-icon nc-settings mr-3 mt-2" style={{fontSize: "22px", color: "rgb(81, 188, 218)"}}/>
                                </Link>
                            </>}
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    {!patientSchedule && <>
                        <Label>Izberite urnik</Label>
                        <Select
                            onChange={onScheduleChange}
                            className="react-select primary mb-3"
                            classNamePrefix="react-select"
                            id="schedules"
                            options={schedules.map((schedule) => {
                                return {label: schedule.title, value: schedule.id};
                            })}
                            noOptionsMessage={() => "Ni vsebine"}
                        ></Select>
                    </>}

                    {patientSchedule &&
                    <>
                        <span>Izbran urnik "{patientSchedule.title}"</span>
                        {patientSchedule.schedule && <div>
                            {patientSchedule.schedule.repeat.every === 1 ? 'Vsak ' : `Vsak ${patientSchedule.schedule.repeat.every}. `}
                            {ScheduleStore.getStringForUnit(patientSchedule.schedule.repeat.unit)}

                            {patientSchedule.schedule.repeat.unit === 'month' && ` - ${patientSchedule.schedule.day}. v mesecu`}

                            {patientSchedule.schedule.repeat.unit === 'week' && <div>
                                    {patientSchedule.schedule.days.map((day, index) =>
                                        <span key={index}>{index > 0 && ', '}{ScheduleStore.dayString(day)}</span>
                                    )}
                                </div>
                            }
                            <div>
                                <span>Ob </span>
                                {patientSchedule.schedule.hours.map((hour, index) =>
                                    <span key={index}>{index > 0 && ', '}{hour}</span>
                                )}
                            </div>
                        </div>}
                    </>
                    }
                    
                </CardBody>
                <CardFooter>
                    {!patientSchedule && <Button className="btn-info mt-1 mr-2 btn btn-secondary" disabled={selectedSchedule === -1} onClick={assignSchedule} color="primary">Shrani urnik</Button>}
                </CardFooter>
            </Card>
        </div>
    );

});

PatientSchedule.propTypes = {
    moduleId: PropTypes.string
};

export default PatientSchedule;
