import {readingTypes} from "../components/readingTypes";
import {readingSensorTypes} from "../enums/SensorType";

export const selectReadingType = (index) => {
    return readingTypes.find(type => type.value == index);
};

export const selectReadingSensorType = (value) => {
    return readingSensorTypes.find(type => type.value == value);
};
