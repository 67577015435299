import React, {useEffect} from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col} from "reactstrap";
import {hist} from "../../index";
import AnamnesisStore from "../../stores/AnamnesisStore";
import {PatientRegistrationAnamnesis} from "../patientWizard/PatientRegistrationAnamnesis";
import {Observer, observer} from "mobx-react-lite";
import Loader from "../../components/Loader";
import {getGoBackPath} from "../../variables/goBackHelper";
import AdminNavbarStore from "../../stores/common/AdminNavbarStore";

const PatientAnamnesisForm = observer((props) => {
    const id = props.match.params.id;

    useEffect(() => {
        AnamnesisStore.getAnamnesis();
        return () => AnamnesisStore.clear();
    }, []
    );

    async function postPatientReadings(id) {
        const result = await AnamnesisStore.postPatientReadings(id);
        if (result) AdminNavbarStore.redirectWithoutWarning(getGoBackPath(id));
    }

    return (
        <div className="content">
            <Loader isLoading={AnamnesisStore.isLoading} />
            <Card>
                <CardHeader>
                    <Col md={9} style={{"margin": "auto"}}>
                        <CardTitle>
                            <h5>Dodajanje anamneze</h5>
                        </CardTitle>
                    </Col>
                </CardHeader>
                <CardBody>
                    <PatientRegistrationAnamnesis/>
                </CardBody>
                <CardFooter>
                    <Col md={9} style={{"margin": "auto"}}>
                        <Observer>{() =>
                            <Button className="float-right btn-info"
                                disabled={!AnamnesisStore.isAnamnesisValid}
                                onClick={() => postPatientReadings(id)}>Dodaj</Button>}
                        </Observer>
                    </Col>
                </CardFooter>
            </Card>
        </div>
    );
});

export default PatientAnamnesisForm;
