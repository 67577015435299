import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    CardTitle,
    Form, Input, InputGroup, InputGroupAddon, InputGroupText,
} from "reactstrap";
import {emailRegex} from "../enums/regex";
import AuthStore from "../stores/AuthStore";
import {observer} from "mobx-react-lite";
import LinkButton from "../components/LinkButton";
import {centerInputX} from "../variables/styles";

const ForgotPassword = observer(({history}) => {
    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [error, setError] = useState(null);
    const submitForgotPassword = () => {
        if(validateEmail()) {
            AuthStore.forgotPassword(email);
        }
    };

    const validateEmail = () => {
        setError(null);
        if(emailRegex.test(email.toLowerCase())) {
            setIsValid(true);
            return true;
        }
        else {
            setIsValid(false);
            setError("Email ni veljaven");
            return false;
        }
    };

    const openLogin = () => {
        history.push("/login");
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        submitForgotPassword();
    };

    useEffect(() => {
        AuthStore.error = null;
        return () => {
            AuthStore.forgotPasswordStatus = null;
        };
    }, []);

    useEffect(() => {
        if(AuthStore.error) {
            if(AuthStore.error.message)
                setError(AuthStore.error.message);
            else
                setError("Prišlo je do napake.");
            setEmail("");
        }
    }, [AuthStore.error]);

    if(AuthStore.forgotPasswordStatus === "success"){
        return (
            <Card className="card-signup text-center">
                <CardHeader>
                    <CardTitle tag="h4">Povezava poslana</CardTitle>
                </CardHeader>
                <CardBody>
                    <p className="mb-4"> Na e-mail <b>{email}</b> smo vam poslali povezavo za spremembo gesla. V kolikor elektronske pošte niste prejeli, preverite, če ni morda končala med spam sporočili.</p>
                </CardBody>
                <CardFooter>
                    <Button
                        block
                        className="btn-round mb-3"
                        color="warning"
                        onClick={openLogin}
                    >
                            Prijavi se
                    </Button>
                </CardFooter>
            </Card>
        );
    }
    return (
        <Form onSubmit={handleSubmit}>
            <Card className="card-signup text-center">
                <CardHeader>
                    <CardTitle tag="h4">Pozabljeno geslo?</CardTitle>
                </CardHeader>
                <CardBody>
                    <p className="text-danger text-center">
                        {AuthStore.error && AuthStore.error.message ? AuthStore.error.message : error}
                    </p>
                    <p className="mb-4">Vnesite svoj e-mail, kamor vam bomo poslali povezavo za spremembo gesla.</p>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="nc-icon nc-single-02"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="E-mail"
                            type="text"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            onBlur={validateEmail}
                            invalid={!isValid}
                            style={centerInputX}
                        />
                    </InputGroup>
                </CardBody>
                <CardFooter>
                    <Button
                        block
                        className="btn-round mb-3"
                        color="warning"
                        onMouseDown={submitForgotPassword}
                    >
                            Nadaljuj
                    </Button>
                    <LinkButton action={openLogin} text={"Nazaj"} />
                </CardFooter>
            </Card>
        </Form>
    );
});

export default ForgotPassword;