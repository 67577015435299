import React, {useEffect} from "react";
import MedModuleStore from "../../stores/MedModuleStore";
import {observer} from "mobx-react-lite";
import {Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label} from "reactstrap";
import Switch from "react-bootstrap-switch";
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import Loader from "../../components/Loader";

const store = MedModuleStore;

const MedModuleForm = observer((props) => {
    const id = props.match.params.id;

    useEffect(() => {
        if (id) {
            store.get(id);
        } else {
            store.clear();
        }
    }, [props.match.params.id]);


    const setImageFile = (file) => {
        store.imgFile = file;
    };


    const removeImage = () => {
        store.image = null;
        store.imgFile = null;
    };


    return (
        <Card className="col-md-10 mx-auto">
            <Loader isLoading={store.isLoading}/>
            <CardHeader>
                <CardTitle tag="h3" className="text-center">
                    {store.isEditing ? "Uredi modul" : "Dodaj modul"}
                </CardTitle>
            </CardHeader>
            <CardBody className="col-md-9 m-auto">
                <Label>Ime*</Label>
                <FormGroup className={store.status.name}>
                    <Input
                        type="text"
                        name="name"
                        value={store.name}
                        onChange={(event) => store.handleChange(event)}
                        onBlur={() => store.validateField("name")}
                        autoComplete="off"
                        maxLength={100}/>
                    {store.status.name === "has-danger" &&
                    <label className="error">
                        Ime je obvezno
                    </label>}
                </FormGroup>
                <Label>Opis</Label>
                <FormGroup className={store.status.description}>
                    <Input type="text"
                        name="description"
                        value={store.description}
                        onChange={(event) => store.handleChange(event)}
                        onBlur={() => store.validateField("description")}
                        autoComplete="off"
                        maxLength={500}/>
                    {store.status.description === "has-danger" &&
                    <label className="error">
                        Opis je obvezen
                    </label>}
                </FormGroup>
                <Label>Tekst</Label>
                <FormGroup className={store.status.text}>
                    <Input type="text"
                        name="text"
                        value={store.text}
                        onChange={(event) => store.handleChange(event)}
                        onBlur={() => store.validateField("text")}
                        autoComplete="off"
                        maxLength={500}/>
                    {store.status.description === "has-danger" &&
                    <label className="error">
                        Text je obvezen
                    </label>}
                </FormGroup>
                <Label>Podtekst</Label>
                <FormGroup>
                    <Input type="text"
                        name="subText"
                        value={store.subText}
                        onChange={(event) => store.handleChange(event)}
                        onBlur={() => store.validateField("subText")}
                        autoComplete="off"
                        maxLength={500}/>
                    {store.status.description === "has-danger" &&
                    <label className="error">
                        Podtext je obvezen
                    </label>}
                </FormGroup>
                <Label>Informacije - naslov</Label>
                <FormGroup className={store.status.infoHeader}>
                    <Input
                        type="textarea"
                        name="infoHeader"
                        value={store.infoHeader}
                        onChange={(event) => store.handleChange(event)}
                        onBlur={() => store.validateField("infoHeader")}
                        autoComplete="off"
                        maxLength={2000}/>
                    {store.status.infoHeader === "has-danger" &&
                    <label className="error">
                        Opis je obvezen
                    </label>}
                </FormGroup>
                <Label>Informacije - vsebina</Label>
                <FormGroup className={store.status.infoContent}>
                    <Input
                        style={{maxHeight: "200px", height: "200px"}}
                        type="textarea"
                        name="infoContent"
                        value={store.infoContent}
                        onChange={(event) => store.handleChange(event)}
                        onBlur={() => store.validateField("infoContent")}
                        autoComplete="off"
                        maxLength={6000}/>
                    {store.status.infoContent === "has-danger" &&
                    <label className="error">
                        Opis je obvezen
                    </label>}
                </FormGroup>
                <Label>Aktiven?</Label>
                <FormGroup>
                    <Switch
                        value={store.isEnabled}
                        offColor="success"
                        offText={<i className="nc-icon nc-simple-remove"/>}
                        onColor="success"
                        onText={<i className="nc-icon nc-check-2"/>}
                        onChange={(event) => store.setEnabled(event.state.value)}
                    />
                </FormGroup>

                <ImageUpload setFile={setImageFile} url={store.image} removeFile={removeImage} />

                <br />

                <Button disabled={!store.canSubmit} className="btb btn-info mt-4"
                    onClick={() => store.submit()}>Potrdi</Button>
            </CardBody>
        </Card>
    );
});

export default MedModuleForm;
