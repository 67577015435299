import React from "react";
import GdprForm from "./GdprForm";
import {Button, Col} from "reactstrap";
import PatientPersonalDataStore from "../../stores/PatientPersonalDataStore";
import {hist} from "../../index";
import Loader from "../../components/Loader";
import {Observer} from "mobx-react-lite";
import AdminNavbarStore from "../../stores/common/AdminNavbarStore";


export class PatientRegistrationGdpr extends React.Component {
    getFullName = () => {
        if (PatientPersonalDataStore.personalData.firstName && PatientPersonalDataStore.personalData.lastName) {
            return PatientPersonalDataStore.personalData.firstName +
                " " +
                PatientPersonalDataStore.personalData.lastName;
        }
        return false;
    };

    getBirthDate = () => {
        const emso = PatientPersonalDataStore.personalData.emso;
        if (!emso) return false;
        const lastThreeDigits = emso.slice(4, 7);
        const year = lastThreeDigits > 800 ? "1" + lastThreeDigits : "2" + lastThreeDigits;
        return emso.slice(0, 2) + "." + emso.slice(2, 4) + "." + year;
    };

    render() {
        return (
            <Observer>{() =>
                <div>
                    <Loader isLoading={PatientPersonalDataStore.isLoading} />
                    <Col md={9} style={{"margin": "auto"}}>
                        <GdprForm
                            fullName={this.getFullName()}
                            birthDate={this.getBirthDate()}
                        />
                    </Col>
                </div>
            }</Observer>
        );
    };
}

export default PatientRegistrationGdpr;
