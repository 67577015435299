import React, {useEffect, useState} from "react";
import {Form, FormGroup, Input, Label} from "reactstrap";
import Select from "react-select";
import PatientsStore from "../stores/PatientsStore";
import {observer} from "mobx-react-lite";
import PatientReadingsStore from "../stores/PatientReadingsStore";
import {getModuleId} from "../variables/fetchHelper";
import {hist} from "../index";
import debounce from "lodash/debounce";
import AssignPatientsToModuleStore from "../stores/AssignPatientsToModuleStore";

const NotificationsForm = observer(({currentTab, title, body, users, androidNewVersion, iosNewVersion, setTitle, setBody, setUsers, setAndroidNewVersion, setIosNewVersion, isOnPatientProfile}) => {
    const moduleId = getModuleId(hist);

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if(moduleId)
            fetchPatients();
        return () => {
            PatientsStore.clear();
            AssignPatientsToModuleStore.clear();
        };
    }, []);

    const fetchPatients = debounce(async (query) => {
        await PatientsStore.getPatients(query);
        setOptions(
            PatientsStore.patients.map(patient => {
                return {
                    value: patient.id,
                    label: `${patient.healthId} ${patient.firstName && patient.lastName ? ` - ${patient.firstName} ${patient.lastName}` : ""}`,
                };
            }),
        );
    }, 500);

    const getPatient = async (healthId) => {
        await AssignPatientsToModuleStore.getPatientByHealthId(healthId);
        setOptions([
            {
                value: AssignPatientsToModuleStore.id,
                label: `${AssignPatientsToModuleStore.healthId} ${AssignPatientsToModuleStore.firstName && AssignPatientsToModuleStore.lastName ? ` - ${AssignPatientsToModuleStore.firstName} ${AssignPatientsToModuleStore.lastName}` : ""}`,
            }
        ]);
    };

    const findPatient = (value) => {
        if (!moduleId && value.length === 9)
            getPatient(value);
        else if (moduleId) {
            fetchPatients(value);
        }
    };

    return (
        <Form action="#" method="#">
            <label>Naslov</label>
            <FormGroup>
                <Input
                    type="text"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                    onFocus={() => isOnPatientProfile && PatientReadingsStore.unregisterEventListener()}
                    onBlur={() => isOnPatientProfile && PatientReadingsStore.registerEventListener()}
                    maxLength={100}
                />
            </FormGroup>
            {currentTab === 2 && setUsers &&
            <React.Fragment>
                <label>Uporabniki</label>
                <FormGroup>
                    <Select
                        isMulti
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        placeholder={moduleId ? "Seznam uporabnikov..." : "Številka zdravstvene izkaznice"}
                        options={options}
                        onInputChange={findPatient}
                        noOptionsMessage={() => "Ni vsebine"}
                        value={users}
                        onChange={(value) => setUsers(value)}
                    />
                </FormGroup>
            </React.Fragment>
            }
            <label>Vsebina</label>
            <FormGroup>
                <Input
                    onChange={(event) => setBody(event.target.value)}
                    value={body}
                    type="textarea"
                    autoComplete="off"
                    onFocus={() => isOnPatientProfile && PatientReadingsStore.unregisterEventListener()}
                    onBlur={() => isOnPatientProfile && PatientReadingsStore.registerEventListener()}
                    maxLength={500}/>
            </FormGroup>
            {!moduleId && currentTab === 3 &&
            <FormGroup check className="mt-3" style={{display: "flex", alignItems: "center"}}>
                <FormGroup check style={{marginRight: "16px"}}>
                    <Label check>
                        <Input
                            checked={androidNewVersion} type="checkbox"
                            onChange={(event) => setAndroidNewVersion(event.target.checked)}/>
                        Android (nova verzija)
                        <span className="form-check-sign"/>
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            checked={iosNewVersion} type="checkbox"
                            onChange={(event) => setIosNewVersion(event.target.checked)}/>
                        iOS (nova verzija)
                        <span className="form-check-sign"/>
                    </Label>
                </FormGroup>
            </FormGroup>
            }
        </Form>
    );
});

export default NotificationsForm;
