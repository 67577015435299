export const ECalculationType = {
    AVERAGE: 0,
    BIAGGI: 1
}

export const calculationTypes = [
    {
        value: -1,
        label: "Brez računanja"
    },
    {
        value: ECalculationType.AVERAGE,
        label: "Povprečje"
    },
    {
        value: ECalculationType.BIAGGI,
        label: "Biaggi"
    }
]