import AuthStore from "../stores/AuthStore";
import {AUTH_URL, CHANGE_PASSWORD_FORCED, DEVICE_ID, REFRESH_TOKEN, REFRESH_URL} from "../config";
import WarningsStore from "../stores/WarningsStore";

class ClientHttpService {
    /**
     * Wrapper for a request to an API endpoint.
     *
     * @param {Object} options - Arguments passed to the fetch function.
     * @param {string} options.url - The full URL.
     * @param {string} options.method - 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD'.
     * @param {Headers} [options.headers] - A Headers() object.
     * @param {AbortSignal} [options.signal] - The AbbotSignal to cancel a fetch request.
     * @param {body} [options.signal] - The AbbotSignal to cancel a fetch request.
     * @param {noAuthHeader} [options.boolean] - Most API routes are protected so the
     * Authorization header is enforces, if not disabled explicitly
     *
     * @returns {Object}
     */
    async fetch(options) {
        let response;
        try {
            response = await this._execFetch(options);
        } catch (err) {
            throw err;
        }

        if (response.status === 401 && options.url !== AUTH_URL && options.url !== CHANGE_PASSWORD_FORCED) {
            if (!AuthStore.tokenRequest) AuthStore.tokenRequest = this.renewToken();

            await AuthStore.tokenRequest;

            response = await this._execFetch(options);

            AuthStore.tokenRequest = null;
        }

        // Only valid if FetchAPI is used
        if (!response.ok) {
            let errorJson;
            try {
                errorJson = await response.json();
            } catch (err) {
                if (response.status === 403) {
                    WarningsStore.createWarning("Ni dostopa");
                }
                throw err;
            }
            if (options.url !== AUTH_URL) {
                WarningsStore.createWarning(errorJson && errorJson.message || "Neznana napaka");
            }
            throw errorJson || response;
        }        

        const contentType = response.headers.get("content-type");

        if (!contentType && (response.status === 204 || response.status === 200)) {
            return null;
        }

        const image = contentType.startsWith('image/');
        const text = contentType.startsWith("text/plain") || contentType.startsWith("application/csv");

        if (
            !["HEAD", "OPTIONS"].includes(options.method) &&
            contentType && (text || image)
        ) {
            try {
                if(text) {
                    return await response.text();
                } else if(image) {
                    return await response.blob();
                }
            } catch (err) {
                return  err;
            }
        }

        let json = null;
        if (
            !["HEAD", "OPTIONS"].includes(options.method) &&
            contentType &&
            (contentType.startsWith("application/json") ||
                contentType.startsWith("application/hal+json"))
        ) {
            // eslint-disable-next-line no-useless-catch
            try {
                json = await response.json();
            } catch (err) {
                throw err;
            }
        }

        return json;
    }

    /**
     * Wrapper for a request function that returns a blob object
     *
     * @param {Object} options - Arguments passed to the fetch function.
     * @param {string} options.url - The full URL.
     * @param {string} options.method - 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD'.
     * @param {Headers} [options.headers] - A Headers() object.
     * @param {AbortSignal} [options.signal] - The AbbotSignal to cancel a fetch request.
     * @param {body} [options.signal] - The AbbotSignal to cancel a fetch request.
     * @param {noAuthHeader} [options.boolean] - Most API routes are protected so the
     * Authorization header is enforces, if not disabled explicitly
     *
     * @returns {Blob}
     */
    async fetchBlob(options) {
        const response = await this._execFetch(options);

        if (!response.ok) {
            throw await this._getError(options, response);
        }

        // eslint-disable-next-line no-useless-catch
        try {
            return await response.blob();
        } catch (err) {
            throw err;
        }
    }

    /**
     * Wrapper for a the underlying request function. In this case Fetch API
     *
     * @param {Object} options - Arguments passed to the fetch function.
     * @param {string} options.url - The full URL.
     * @param {string} options.method - 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD'.
     * @param {Headers} [options.headers] - A Headers() object.
     * @param {AbortSignal} [options.signal] - The AbbotSignal to cancel a fetch request.
     * @param {body} [options.signal] - The AbbotSignal to cancel a fetch request.
     * @param {noAuthHeader} [options.boolean] - Most API routes are protected so the
     * Authorization header is enforces, if not disabled explicitly
     *
     * @returns {Promise}
     */
    async _execFetch(options) {
        const init = {
            method: options.method || "GET",
            headers: options.headers || new Headers(),
            signal: options.signal,
        };

        const headers = init.headers;

        // Disable caching because of IE11
        headers.append("Cache-Control", "no-cache");
        headers.append("Pragma", "no-cache");
        headers.append("Expires", "-1");
        headers.append("Accept-Language", "sl-SI,sl;q=0.9,en-GB;q=0.8,en;q=0.7");

        if (!options.noAuthHeader) {
            headers.append("Authorization", `Bearer ${AuthStore.token}`);
        }

        if (options.body) {
            if (typeof options.body === "string") {
                headers.append("Content-Type", "application/x-www-form-urlencoded");
                init.body = options.body;
            } else if (options.body instanceof FormData) {
                init.body = options.body;
            } else {
                headers.append("Content-Type", "application/json");
                init.body = JSON.stringify(options.body);
            }
        }

        // eslint-disable-next-line no-useless-catch
        try {
            return await fetch(options.url, init);
        } catch (err) {
            // https://github.com/axios/axios/issues/383
            if (!err.status) {
                WarningsStore.createWarning("Komunikacija s strežnikom ni uspela");
                throw("Komunikacija s strežnikom ni uspela");
            }
            console.log(err);
            throw err;
        }
    }

    async _getError(options, response) {
        let body = {};
        let message = "";

        const contentType = response.headers.get("content-type");

        if (
            contentType.startsWith("application/json") ||
            contentType.startsWith("application/hal+json")
        ) {
            body = await response.json();
        } else if (contentType.startsWith("text/html")) {
            body = await response.text();
        }

        const authErrorHeaders = response.headers.get("WWW-Authenticate");

        // TODO: add translations
        if (Object.keys(response).length === 0) {
            message = "Request could not be processed due to a network error.";
        } else if (response.status === 401 || authErrorHeaders) {
            this.logout();
            message = "Unauthorized";
        } else if (response.status === 403) {
            message = "Forbidden";
        } else if (response.status === 404) {
            message = "Not Found";
        } else if (response.status === 422) {
            message = "Unprocessable Entry";
        } else if (response.status === 500) {
            message = "Server could not process the request.";
        } else if (response.status === 502) {
            message = "Bad gateway";
        } else if (response.status === 503) {
            message = "The service is unavailable.";
        } else if (response.status === 504) {
            message = "Gateway Timeout";
        }

        return new Error(message, body);
    }

    logout() {
        throw Error("Not implemented yet.");
    }

    async renewToken() {
        const deviceId = AuthStore.getToken(DEVICE_ID);
        const rToken = AuthStore.getToken(REFRESH_TOKEN);

        if (!rToken) {
            const history = new History();
            history.push("/login");
        }

        const headers = new Headers();
        headers.append(DEVICE_ID, deviceId);

        const _options = {
            method: "POST",
            url: REFRESH_URL,
            headers,
            body: {refreshToken: rToken}
        };

        const _response = await this._execFetch(_options);
        const _json = await _response.json();
        const {token, refreshToken} = _json;

        if (token) {
            AuthStore.saveLoginData(token, refreshToken, deviceId);
        } else {
            AuthStore.logout();
            location.reload();
            throw await _response;
        }
    }
}

// singletone
export default new ClientHttpService();
