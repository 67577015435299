import React from "react";
import classnames from "classnames";
import {
    NavbarBrand,
    Navbar,
    Container
} from "reactstrap";
import styled from "styled-components";

const StyledNavbarBrand = styled(NavbarBrand)`
    cursor: default;
`;

class AuthNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseOpen: false,
            color: "navbar-transparent"
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateColor);
    }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
      let newState = {
          collapseOpen: !this.state.collapseOpen
      };
      if (!this.state.collapseOpen) {
          newState["color"] = "bg-white";
      } else {
          newState["color"] = "navbar-transparent";
      }
      this.setState(newState);
  };
  render() {
      return (
          <Navbar
              className={classnames("navbar-absolute fixed-top", this.state.color)}
              expand="lg"
          >
              <Container>
                  <div className="navbar-wrapper">
                      <StyledNavbarBrand onClick={e => e.preventDefault()} >
                          iMEDIS CMS
                      </StyledNavbarBrand>
                  </div>
              </Container>
          </Navbar>
      );
  }
}

export default AuthNavbar;
