import React, {useState} from "react";
import {Card, CardBody, CardHeader, Form, FormGroup, Input, Label} from "reactstrap";
import {observer} from "mobx-react-lite";
import SettingsStore from "../../stores/SettingsStore";

const DeviceVersionSettings = observer((props) => {
    const [latestVersionInvalid, setLatestVersionInvalid] = useState(false);
    const [maxVersionInvalid, setMaxVersionInvalid] = useState(false);
    const handleChange = (event) => {
        const {name, value} = event.target;
        SettingsStore.setDeviceVersion(props.deviceType, name, value);
    };

    const handleChangeLatest = () => {
        setLatestVersionInvalid(!SettingsStore.isInputVersionValid(props.deviceType, "latestVersion"));
    };

    const handleChangeMax = () => {
        setMaxVersionInvalid(!SettingsStore.isInputVersionValid(props.deviceType, "minVersion"));
    };

    const handleKeyDown = (e) => {
        if(![190, 8, 46, 37, 39].includes(e.keyCode) && !(e.keyCode >= 48 && e.keyCode <= 57) && !((e.keyCode >= 96 && e.keyCode <= 105)))
            e.preventDefault();
    };

    return (
        <Card>
            <CardHeader>
                {props.label}
            </CardHeader>
            <CardBody>
                <Form>
                    <Label>Najnovejša verzija</Label>
                    <FormGroup className={latestVersionInvalid ? "has-danger" : ""}>
                        <Input onKeyUp={handleChangeLatest} placeholder="X.X.X.X" type="text" name="latestVersion" value={props.values.latestVersion}
                            onChange={handleChange} onKeyDown={handleKeyDown} autoComplete="off"/>
                        {latestVersionInvalid ? (
                            <label className="error">
                                Nepravilen format
                            </label>
                        ) : null}
                    </FormGroup>
                    <Label>Minimalna verzija</Label>
                    <FormGroup className={maxVersionInvalid ? "has-danger" : ""}>
                        <Input onKeyUp={handleChangeMax} placeholder="X.X.X.X" type="text" name="minVersion" value={props.values.minVersion}
                            onChange={handleChange} autoComplete="off"/>
                        {maxVersionInvalid ? (
                            <label className="error">
                                Nepravilen format
                            </label>
                        ) : null}
                    </FormGroup>
                </Form>
            </CardBody>
        </Card>
    );
});

export default DeviceVersionSettings;
