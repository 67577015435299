import {action, observable, runInAction} from "mobx";
import {USER_UNASSIGN, USERS_FOR_MODULE_LIST} from "../config";
import {getSloPermission} from "../enums/EPermission";
import {hist} from "../index";
import {IPermissions} from "../interfaces/IPermissions";
import {IUser} from "../interfaces/IUser";
import httpService from "../services/httpService";
import {getModuleId} from "../variables/fetchHelper";
import ResettableStore from "./ResettableStore";

class UsersForModuleStore extends ResettableStore {
	@observable isLoading: boolean = false;
	@observable error = null;
	@observable users: IUser[] = [];

	@action
	async getUsersForModule() {
	    this.isLoading = true;

	    const options = {
	        method: "GET",
	        url: USERS_FOR_MODULE_LIST + "/" + getModuleId(hist),
	    };

	    try {
	        const response = await httpService.fetch(
	            options,
	        );

	        runInAction(() => {
	            this.mapToStore(response);
	            this.isLoading = false;
	        });
	    } catch (err) {
	        runInAction(() => {
	            this.isLoading = false;
	            this.error = err;
	        });
	    }
	}

	@action
	async unassignUser(userId: number) {
	    if (!getModuleId(hist)) return;
	    this.isLoading = true;

	    const options = {
	        method: "POST",
	        url: USER_UNASSIGN,
	        body: {
	            webProfileId: userId,
	            medModuleId: getModuleId(hist),
	        },
	    };

	    try {
	        await httpService.fetch(options);

	        runInAction(() => {
	            this.users = this.users.filter(user => user.id !== userId);
	            this.isLoading = false;
	        });
	    } catch (err) {
	        runInAction(() => {
	            this.isLoading = false;
	            this.error = err;
	        });
	    }
	}

	@action
	mapToStore(payload: any) {
	    this.users = payload?.users;
	}

	@action
	getPermissionsString(permissions: IPermissions) {
	    // @ts-ignore
	    return Object.keys(permissions).filter(p => permissions[p]).map(p => getSloPermission(p)).join(", ");
	}

	getUserWithPermissions = (userId: number) => {
	    const filteredUsers = this.users.filter(user => user.id === userId);
	    if (filteredUsers.length > 0)
	        return filteredUsers[0];
	    return null;
	};
}

export default new UsersForModuleStore();