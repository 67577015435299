import {action, computed, observable, runInAction} from "mobx";
import {IPermissions} from "../interfaces/IPermissions";
import ResettableStore from "./ResettableStore";
import {GET_MED_MODULE, POST_MED_MODULE, UPLOAD_IMAGE} from "../config";
import httpService from "../services/httpService";
import React from "react";
import MedModulesStore from "./MedModulesStore";
import AdminNavbarStore from "./common/AdminNavbarStore";
import {calculationTypes} from "../enums/CalculationType";
import {getGoBackPath} from "variables/goBackHelper";

export interface IMedModule {
    id: string | undefined,
    name: string | undefined,
    description: string | undefined,
    image: string | undefined,
    isEnabled: boolean,
    infoHeader: string | undefined,
    infoContent: string | undefined,
    permissions: IPermissions | undefined
    text: string | undefined,
    subText: string | undefined,
    primaryCalculationType: any
}

export class MedModuleStore extends ResettableStore implements IMedModule {
    @observable isLoading: boolean = false;
    @observable id: string | undefined;
    @observable name: string = "";
    @observable description: string = "";
    @observable image: string = "";
    @observable imgFile: string = "";
    @observable isEnabled: boolean = false;
    @observable infoContent: string = "";
    @observable infoHeader: string = "";
    @observable permissions: IPermissions | undefined;
    @observable text: string = "";
    @observable subText: string = "";
    @observable primaryCalculationType: any = calculationTypes[0];

    @observable isEditing: boolean = false;
    @observable error = null;
    @observable status = {
        name: "",
        description: "",
        image: "",
        infoHeader: "",
        infoContent: "",
    };

    @observable serverCopy?: IMedModule;

    constructor(payload?: IMedModule) {
        super();

        this.setInitialState()

        if (payload) {
            this.id = payload.id;
            this.name = payload.name || "";
            this.description = payload.description || "";
            this.image = payload.image || "";
            this.isEnabled = payload.isEnabled;
            this.infoHeader = payload.infoHeader || "";
            this.infoContent = payload.infoContent || "";
            this.permissions = payload.permissions || undefined;
            this.primaryCalculationType = payload.primaryCalculationType;

            this.serverCopy = payload
        }
    }

    @computed
    get isSameAsServerCopy() {
        if (!this.serverCopy) return false;
        if (this.serverCopy.name !== this.name) return false;
        if (this.serverCopy.description !== this.description) return false;
        if (this.serverCopy.isEnabled !== this.isEnabled) return false;
        if (this.serverCopy.image !== this.image) return false;
        if (this.imgFile) return false;
        if (this.serverCopy.infoHeader !== this.infoHeader) return false;
        if (this.serverCopy.infoContent !== this.infoContent) return false;
        if (this.serverCopy.text !== this.text) return false;
        if (this.serverCopy.subText !== this.subText) return false;
        if (this.serverCopy.primaryCalculationType !== this.primaryCalculationType?.value) return false;
        return true;
    }

    @computed
    get canUpdate() {
        return !this.isSameAsServerCopy
    }

    @computed
    get canCreate() {
        return !!this.name
    }

    @computed
    get isValid() {
        return this.isEditing ? this.canUpdate : this.canCreate
    }

    @computed
    get canSubmit() {
        return this.isValid
    }

    @action
    async get(id: string) {
        this.isLoading = true;
        const options = {
            method: "GET",
            url: GET_MED_MODULE.replace("{id}", id),
        }

        try {
            const response = await httpService.fetch(options)

            runInAction(() => {
                this.mapServerToStore(response);
                this.isEditing = true;
                this.isLoading = false;
            })
        } catch (error) {
            runInAction(() => {
                this.error = error;
                this.isLoading = false;
            })
        }
    }

    @action
    mapServerToStore(payload: any) {
        this.id = payload.id;
        this.name = payload.name || "";
        this.description = payload.description || "";
        this.isEnabled = payload.isEnabled;
        this.image = payload.image || "";
        this.infoHeader = payload.infoHeader || "";
        this.infoContent = payload.infoContent || "";
        this.text = payload.text || "";
        this.subText = payload.subText || "";
        this.primaryCalculationType = (payload.primaryCalculationType === null ? calculationTypes[0] : calculationTypes.find(type => type.value === payload.primaryCalculationType))

        this.serverCopy = payload;
    }

    @action
    async post() {
        this.isLoading = true;
        const options = {
            method: "POST",
            url: POST_MED_MODULE,
            body: this.mapStoreToServer()
        }

        try {
            await httpService.fetch(options)
            this.isLoading = false;
            MedModulesStore.getAll();
            AdminNavbarStore.redirectWithoutWarning(getGoBackPath());
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
                this.error = error
            })
        }
    }

    mapStoreToServer() {
        const payload = {
            name: this.name,
            description: this.description,
            isEnabled: this.isEnabled,
            image: this.image,
            infoHeader: this.infoHeader,
            infoContent: this.infoContent,
            text: this.text,
            subText: this.subText,
            primaryCalculationType: (!this.primaryCalculationType || this.primaryCalculationType.value === -1) ? null : this.primaryCalculationType.value
        } as IMedModule

        if (this.isEditing) {
            payload.id = this.id
        }

        return payload;
    }

    @action
    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;

        // @ts-ignore
        this[name] = value;
    }

    @action
    validateField(fieldName: string) {
        switch (fieldName) {
            case "name":
                this.status.name = !!this.name ? "" : "has-danger"
        }
    }

    @action
    uploadFile = async () => {
        this.isLoading = true;

        let formData = new FormData();
        formData.append("file", this.imgFile);

        const options = {
            method: "POST",
            url: UPLOAD_IMAGE + "?type=MedModule",
            body: formData,
            contentType: "multipart/form-data",
        };

        const response = await httpService.fetch(options);
        try {
            runInAction(() => {
                this.image = response.toString();
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
                this.isLoading = false;
            });
        }
    };

    @action
    submit = async () => {
        if(this.imgFile) {
            await this.uploadFile();
        }
        this.post()
    };

    @action
    setEnabled(value: boolean) {
        this.isEnabled = value
    }

    get isEmpty() {
        if(this.imgFile) return false;
        if(this.isEnabled) return false;
        return !this.name
            && !this.description
            && !this.infoHeader
            && !this.infoContent;
    }

    @computed
    get shouldShowWarningOnBackNavigation() {
        if(this.isEditing && !this.isSameAsServerCopy) return true;
        return !this.isEditing && !this.isEmpty;
    }
}

export default new MedModuleStore();
