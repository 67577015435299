import React from "react";
import {IMessage} from "../../interfaces/IMessage";
import styled from "styled-components";
import {observer} from "mobx-react-lite";
import logo from "../../assets/img/logo.png";
import {localDateTimeFormat} from "../../variables/dateHelpers";

interface IProps {
    message: IMessage,
    initialLetter?: string
}


const ChatWindowMessageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: ${(props: IProps) => props.message.isUserMessage ? "flex-start" : "flex-end"};
  margin-top: 8px;
`;

const ImageCircle = styled.img`
  border: 1px solid lightgray;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  min-height: 35px;
  min-width: 35px;
`;

const Circle = styled.div`
  border: 1px solid lightgray;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  min-height: 35px;
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
`;

const ChatBubble = styled.div`
  background-color: ${(props: IProps) => props.message.isUserMessage ? "#274C96" : "#E2E5EC"};
  color: ${(props: IProps) => props.message.isUserMessage ? "white" : "black"};;
  padding: 16px;
  margin: 0 12px;
  border-radius: ${(props: IProps) => props.message.isUserMessage ? "20px 20px 20px 0;" : "20px 20px 0 20px;"};
  white-space: pre-line;
  overflow: auto;
`;

const TimeStamp = styled.div`
  color: lightgray;
  font-size: 10px;
  text-align: ${(props: IProps) => props.message.isUserMessage ? "left" : "right"};
  margin: ${(props: IProps) => props.message.isUserMessage ? "2px 0 0 47px" : "2px 47px 0 0"};
`;

const ClosedMessage = styled.div`
  font-style: italic;
`;


const ChatWindowMessage = observer(({message, initialLetter}: IProps) => {

    const directMessage = message.closed ?
        <ClosedMessage><b>{message.closedBy ? message.closedBy.fullName : ""}</b> je zaključil
            pogovor</ClosedMessage> : message.message;

    return (
        <React.Fragment>
            <ChatWindowMessageContainer message={message}>
                {message.isUserMessage && <Circle>{initialLetter}</Circle>}
                <ChatBubble message={message}>{directMessage}</ChatBubble>
                {!message.isUserMessage && <ImageCircle src={logo}/>}
            </ChatWindowMessageContainer>
            <TimeStamp message={message}>{localDateTimeFormat(message.created)}</TimeStamp>
        </React.Fragment>
    );
});

export default ChatWindowMessage;
