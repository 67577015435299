import React, {useEffect, useState, useRef} from "react";
import moment from "moment";
import PatientReadingsStore from "../stores/PatientReadingsStore";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Row} from "reactstrap";
import Label from "reactstrap/lib/Label";
import ReactDatetime from "react-datetime";
import Select, {components} from "react-select";
import {Observer} from "mobx-react-lite";
import "moment/min/locales";
import styled from "styled-components";
import DataExportStore from "stores/DataExportStore";
import MedModulesStore from "stores/MedModulesStore";
import PatientProfileStore from "stores/PatientProfileStore";
import { getModuleId } from "variables/fetchHelper";
import { hist } from "index";

const StyledSelect = styled(Select)`
    ${props => !props.isChecked && `
         > div {
            border-color: #ef8157 !important;
        }
    `}
`;


const SingleValue = (props) => {
    return (
        <components.SingleValue {...props}>
            <div style={{display: "flex"}}>
                <div>
                    {/* eslint-disable-next-line react/prop-types */}
                    {props.data.longLabel || props.data.label}
                </div>
            </div>
        </components.SingleValue>
    );
};

const Option = (props) => (
    <components.Option {...props}>
        <Row>
            {/* eslint-disable-next-line react/prop-types */}
            <div>{props.data.label}</div>
            {/* eslint-disable-next-line react/prop-types */}
            {!props.data.isChecked &&
            <div style={{marginLeft: "auto", marginRight: "8px", fontWeight: "bold", color: "red"}}>
                {/* eslint-disable-next-line react/prop-types */}
                {!props.data.isChecked && "!"}
            </div>
            }
        </Row>
    </components.Option>
);

const PatientProfileReadingDateTimePicker = (props) => {
    const [fromTime, setFromTime] = useState(new moment().subtract(7, "day").startOf("day"));
    const [toTime, setToTime] = useState(new moment().endOf("day"));

    const exportLink = useRef(null);
    const moduleId = getModuleId(hist);

    useEffect(() => {
        fetchData(fromTime, toTime);
    }, [fromTime, toTime]);

    const fetchData = (fromTime, toTime) => {
        if (!(fromTime && toTime)) return;
        PatientReadingsStore
            .getReadingsData(props.patientId, fromTime.toISOString(), toTime.toISOString());
    };

    const resetFilters = () => {
        setFromTime(new moment().subtract(7, "day").startOf("day"));
        setToTime(new moment().endOf("day"));
    };


    useEffect(() => {
        PatientReadingsStore.registerEventListener();
        return () => PatientReadingsStore.unregisterEventListener();
    }, []);

    const getExportData = async () => {
        let data = await DataExportStore.getUserExportData(fromTime.toISOString(), toTime.toISOString(), props.patientId);
        exportLink.current.href=data;
        exportLink.current.download=`${Date.now()}-${MedModulesStore.selectedModule(moduleId)?.label}-${moduleId}-${PatientProfileStore.patient.healthId}.csv`;
        exportLink.current.click();
        exportLink.current.href='';
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h4">Meritve</CardTitle>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={5}>
                        <FormGroup>
                            <Label>Od</Label>
                            <ReactDatetime
                                inputProps={{
                                    className: "form-control",
                                    placeholder: "Izberi datum",
                                }}
                                locale={navigator.language.slice(0, 2)}
                                value={fromTime}
                                onChange={(time) => setFromTime(time)}
                                closeOnSelect={false}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={5}>
                        <FormGroup>
                            <Label>Do</Label>
                            <ReactDatetime
                                inputProps={{
                                    className: "form-control",
                                    placeholder: "Izberi datum",
                                }}
                                locale={navigator.language.slice(0, 2)}
                                value={toTime}
                                onChange={(time) => setToTime(time)}
                                closeOnSelect={false}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <FormGroup>
                            <Label>Izberi meritev</Label>
                            <Observer>{() =>
                                <StyledSelect
                                    isChecked={PatientReadingsStore.selectedChecked}
                                    className={"react-select primary " + (PatientReadingsStore.selectedChecked ? "" : "danger")}
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    placeholder="Izberi meritev"
                                    options={PatientReadingsStore.readingsForDropdown}
                                    noOptionsMessage={() => PatientReadingsStore.isLoading ? "Nalagam" : "Ni vsebine"}
                                    value={PatientReadingsStore.selectedReadingForDropdown}
                                    onChange={(reading) => PatientReadingsStore.selectReading(reading)}
                                    isDisabled={!PatientReadingsStore.hasData}
                                    components={{SingleValue, Option}}
                                />
                            }</Observer>
                        </FormGroup>
                    </Col>
                    <Observer>{() =>
                        <Col style={{display: "flex", alignSelf: "flex-end", flexWrap: "wrap"}}>
                            <Button
                                onClick={() => PatientReadingsStore.selectNextRecord()}
                                className="btn-info"
                                disabled={!PatientReadingsStore.canClickNext}>
                                <i className="nc-icon nc-minimal-left"/>
                            </Button>
                            <Button
                                onClick={() => PatientReadingsStore.selectPreviousRecord()}
                                className="btn-info"
                                disabled={!PatientReadingsStore.canClickPrevious}>
                                <i className="nc-icon nc-minimal-right"/>
                            </Button>
                            <Button
                                onClick={() => resetFilters()}
                                className="btn-info">Ponastavi filtre</Button>
                            <Observer>{() =>
                                <Button
                                    onClick={() => PatientReadingsStore.reviewSelected(props.patientId)}
                                    disabled={!PatientReadingsStore.canReview}
                                    className="btn-info">Označi kot preverjeno</Button>
                            }</Observer>
                            {/*&nbsp;
                            <button onClick={getExportData} className="btn-info btn btn-secondary">CSV</button>
                        <a ref={exportLink}></a>*/}
                        </Col>
                    }</Observer>
                </Row>
            </CardBody>
        </Card>
    );
};

PatientProfileReadingDateTimePicker.propTypes;

export default PatientProfileReadingDateTimePicker;
