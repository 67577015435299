import React from "react";
import { Button, Label } from "reactstrap";
import PropTypes from "prop-types";

class DynamicFormMultiImage extends React.Component {
    
    files = [];

    constructor(props) {
        super(props);
        this.state = {
            files: []
        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.files.push({file: file, imagePreviewUrl: reader.result});
            this.setState({
                files: this.files
            });
            this.props.handleChange(this.props.index, {id: this.props.id, value: this.files}, this.props.readingType);
        };
        if(file)
            reader.readAsDataURL(file);
    }

    handleSubmit(e) {
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    }

    handleRemove(index) {

        this.files.splice(index, 1);

        this.setState({
            files: this.files
        });
        this.props.handleChange(this.props.index, {id: this.props.id, value: this.files}, this.props.readingType);
    }

    handleClick() {
        this.refs.fileInput.value = null;
        this.refs.fileInput.click();
    }

    render() {
        return (
            <div>
                <div className="fileinput">
                    <Label>{this.props.title} {this.props.minImages > 0 && "*"} ({this.props.minImages} - {this.props.maxImages})</Label>
                    <Button disabled={this.files.length === this.props.maxImages} style={{padding: "8px 10px 8px 10px", marginLeft: "10px", fontSize: "10px"}} className="btn-round" onClick={() => this.handleClick()}>
                        Dodaj Sliko
                    </Button>
                    <input type="file" onChange={this.handleImageChange} ref="fileInput" />
                </div>
                <div style={{paddingTop: "10px", paddingBottom: "10px"}}>
                    {
                    this.state.files.map((file, index) => (
                        <div key={index} style={{paddingTop: "5px"}} className="thumbnail">
                            <div style={{position: "relative", width: "fit-content"}}>
                                <Button style={{position: "absolute", right: "5px", top: "5px", margin: "0px", padding: "0px", width: "25px", height: "25px"}} color="danger" className="btn-round" onClick={() => this.handleRemove(index)}>
                                    <i className="fa fa-times" style={{margin: "0", padding: "0"}} />
                                </Button>
                                <img style={{ maxHeight: "150px"}} src={file.imagePreviewUrl} alt="..." />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };
}

DynamicFormMultiImage.propTypes = {
    handleChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    readingType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mandatory: PropTypes.bool
};

export default DynamicFormMultiImage;
