import {action, computed, observable, runInAction} from "mobx";
import {REGION} from "../../config";
import httpService from "../../services/httpService";
import {IRegion} from "../../interfaces/IRegion";

class RegionsStore {
    @observable isLoading = false;
    @observable regions = [];
    @observable error = null;

    @computed
    get regionsForDropdown() {
        return this.regions.map((region: IRegion) => {
            return {
                value: region.id,
                label: region.postNumber + " - " + region.name,
            };
        });
    }

    @action
    async getAllRegions() {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: REGION,
        };

        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.mapRegionsToStore(response);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    mapRegionsToStore(payload: any) {
        this.regions = payload;
    }

    @action
    async removeRegion(id: string) {
        this.isLoading = true;

        const options = {
            method: "DELETE",
            url: REGION + `/${id}`,
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.regions = this.regions.filter((region: IRegion) => region.id !== id);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    clear() {
        this.isLoading = false;
        this.regions = [];
        this.error = null;
    }
}

export default new RegionsStore();
