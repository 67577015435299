import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";

import {Button, Card, CardBody, CardFooter, Col} from "reactstrap";
import PatientPersonalDataStore from "../../stores/PatientPersonalDataStore";
import {goBack} from "../../variables/goBackHelper";
import PatientRegistrationForm from "../patientWizard/PatientRegistrationForm";
import PatientIdEditForm from "./PatientIdEditForm";

const PatientPersonalDetails = observer((props) => {
    const id = props.match.params.id;

    useEffect(() => {
        void PatientPersonalDataStore.getPatientPersonalDetails(id);
    }, []);

    async function editPatientInfo(id) {
        const result = await PatientPersonalDataStore.validateAndEdit(id);
        if (result) goBack(id);
    }

    return (
        <div className="content">
            <PatientIdEditForm match={props.match} id={id}/>
            <Card>
                <CardBody>
                    <PatientRegistrationForm isEditing={true}/>
                </CardBody>
                <CardFooter>
                    <Col md={9} style={{"margin": "auto"}}>
                        <Button
                            className="float-right btn-info"
                            disabled={!PatientPersonalDataStore.isEditValid}
                            onClick={() => editPatientInfo(id)}
                        >Shrani</Button>
                    </Col>
                </CardFooter>
            </Card>
        </div>
    );
});

export default PatientPersonalDetails;
