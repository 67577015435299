import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import * as firebase from "firebase/app";
import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import {Observer} from "mobx-react-lite";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "app.css";
import {PrivateRoute} from "./components/PrivateRoute";
import {routes} from "./routes";
import {ACCESS_TOKEN, firebaseConfig} from "./config";
import Login from "./views/Login";
import ResetPassword from "./views/ResetPassword";
import ForgotPassword from "./views/ForgotPassoword";
import LockScreen from "./views/LockScreen";
import AuthStore from "./stores/AuthStore";
import useInactiveUserLogout from "./hooks/useInacitveUserLogout";
import MedModulesStore from "./stores/MedModulesStore";
import ModuleLoader from "./components/ModuleLoader";
import ChangePassword from "./views/ChangePassword";

const Chart = require("chart.js");

export const hist = createBrowserHistory();

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Chart-js
Chart.pluginService.register({
    beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
            //Get ctx from string
            var ctx = chart.chart.ctx;

            //Get options from the center object in options
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || "Arial";
            var txt = centerConfig.text;
            var color = centerConfig.color || "#000";
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
            //Start with a base font of 30px
            ctx.font = "30px " + fontStyle;

            //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = chart.innerRadius * 2;

            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(newFontSize, elementHeight);

            //Set font settings to draw it correctly.
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color;

            //Draw text in center
            ctx.fillText(txt, centerX, centerY);
        }
    }
});

const routeToComponent = (route) =>
    <PrivateRoute
        key={route.path}
        exact
        path={route.path}
        render={(props) => (<AdminLayout {...props}>
            <route.component {...props}/>
        </AdminLayout>)}/>;

const Index = () => {
    useInactiveUserLogout();

    useEffect(() => {
        fetch();
        return () => MedModulesStore.clear();
    }, []);

    const fetch = async () => {
        if(AuthStore.isLoggedIn){
            await MedModulesStore.getAll();
        }
    };

    return (
        <Router history={hist}>
            <Observer>{() =>
                <Switch>
                    <Route
                        exact
                        path="/login"
                        render={(props) =>
                            <AuthLayout {...props} >
                                <LockScreen>
                                    <Login history={props.history}/>
                                </LockScreen>
                            </AuthLayout>
                        }
                    />
                    <Route
                        exact
                        path="/reset-passsword"
                        render={(props) =>
                            <AuthLayout {...props} >
                                <LockScreen>
                                    <ResetPassword history={props.history}/>
                                </LockScreen>
                            </AuthLayout>
                        }
                    />
                    <Route
                        exact
                        path="/forgot-password"
                        render={(props) =>
                            <AuthLayout {...props} >
                                <LockScreen>
                                    <ForgotPassword history={props.history}/>
                                </LockScreen>
                            </AuthLayout>
                        }
                    />
                    <Route
                        exact
                        path="/change-password"
                        render={(props) =>
                            <AuthLayout {...props} >
                                <LockScreen>
                                    <ChangePassword history={props.history}/>
                                </LockScreen>
                            </AuthLayout>
                        }
                    />
                    {routes().filter(route => !route.collapse).map(route => routeToComponent(route))}
                    {routes().filter(route => route.collapse).map(route => route.views.map(subRoute => routeToComponent(subRoute)))}
                    {AuthStore.getToken(ACCESS_TOKEN) &&
                        <Route
                            path="/module/:moduleId"
                            render={(props) =>
                                <AdminLayout {...props}>
                                    <ModuleLoader {...props}/>
                                </AdminLayout>
                            }
                        />
                    }
                    {AuthStore.hasAdminAccess && <Redirect to="/users"/>}
                    {AuthStore.hasOrganizationAdminAccess && <Redirect to="/users"/>}
                    {AuthStore.hasMedicAccess && <Redirect to="/add-patient"/>}
                    <Redirect to="/login"/>
                </Switch>
            }</Observer>
        </Router>
    );
};
ReactDOM.render(<Index/>, document.getElementById("root"),);
