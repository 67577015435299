import React from "react";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {numberValidation} from "../../_utils/numberValidation";

const DynamicFormFloatPair = (props) => {
    const handleChange = (event) => {
        const {value, name} = event.target;

        const pairName = name.slice(-1);

        props.handleChange(props.index, {id: props.id, value: value}, props.readingType, pairName);
    };

    return (
        <div>
            <Label>{props.title} {props.mandatory && "*"}</Label>
            <Row>
                <Col sm={4} xs={6}>
                    <FormGroup className={props.validationStatus && props.validationStatus.a}>
                        <Input type="text"
                               name={props.id + "a"}
                               onChange={(event) => numberValidation(event, handleChange, true)}
                               value={props.value && props.value.a || ""}
                               placeholder={"zgornji"}
                               onBlur={(event) => props.validateField(props.index, {id: props.id, type: props.readingType}, event.target.name.slice(-1))}
                               maxLength={props.maxlength}/>
                        {props.validationStatus && props.validationStatus.a === "has-danger" &&
                        <label className="error">
                            Polje je obvezno 
                        </label>}
                        {props.webLimits && props.webLimits.a && <Label>( zaloga vrednosti {props.webLimits.a.minValue} - {props.webLimits.a.maxValue} )</Label>}
                    </FormGroup>
                </Col>
                <Col sm={4} xs={6}>
                    <FormGroup className={props.validationStatus && props.validationStatus.b}>
                        <Input type="text"
                               name={props.id + "b"}
                               onChange={(event) => numberValidation(event, handleChange, true)}
                               value={props.value && props.value.b || ""}
                               placeholder={"spodnji"}
                               onBlur={(event) => props.validateField(props.index, {id: props.id, type: props.readingType}, event.target.name.slice(-1))}
                               maxLength={props.maxlength}/>
                        {props.validationStatus && props.validationStatus.b === "has-danger" &&
                        <label className="error">
                            Polje je obvezno 
                        </label>}
                        {props.webLimits && props.webLimits.b && <Label>( zaloga vrednosti {props.webLimits.b.minValue} - {props.webLimits.b.maxValue} )</Label>}
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
};

export default DynamicFormFloatPair;
