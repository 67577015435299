import React from "react";
import {ReadingType} from "../readingTypes";
import DynamicFormInt from "./DynamicFormInt";
import DynamicFormFloat from "./DynamicFormFloat";
import DynamicFormBool from "./DynamicFormBool";
import DynamicFormIntPair from "./DynamicFormIntPair";
import DynamicFormFloatPair from "./DynamicFormFloatPair";
import DynamicFormFreeText from "./DynamicFormFreeText";
import DynamicFormMultiState from "./DynamicFormMultiState";
import DynamicFormImage from "./DynamicFormImage";
import DynamicFormMultiImage from "./DynamicFormMultiImage";
import PropTypes from "prop-types";
import DynamicFormMultipleSelection from "./DynamicFormMultipleSelection";
import DynamicFormDate from "./DynamicFormDate";
import DynamicFormDateTime from "./DynamicFormDateTime";
import DynamicFormRapidTest from "./DynamicFormRapidTest";
import DynamicFormSlider from "./DynamicFormSlider";

const DynamicFormInput = (props) => {
    const mapToComponent = (type) => {
        switch (type.value) {
            case(ReadingType.INT):
            case(ReadingType.HEART_RATE):
            case(ReadingType.BREATHING_FREQUENCY):
                return <DynamicFormInt {...props}/>;
            case(ReadingType.FLOAT):
            case(ReadingType.BODY_TEMPERATURE):
            case(ReadingType.SATURATION):
            case(ReadingType.BLOOD_SUGAR):
            case(ReadingType.WEIGHT):
                return <DynamicFormFloat  {...props}/>;
            case(ReadingType.BOOL):
                return <DynamicFormBool  {...props}/>;
            case(ReadingType.INT_PAIR):
            case(ReadingType.BLOOD_PRESSURE):
                return <DynamicFormIntPair  {...props}/>;
            case(ReadingType.FLOAT_PAIR):
                return <DynamicFormFloatPair  {...props}/>;
            case(ReadingType.FREE_TEXT):
                return <DynamicFormFreeText  {...props}/>;
            case(ReadingType.MULTI_STATE):
                return <DynamicFormMultiState  {...props}/>;
            case(ReadingType.DATE):
                return <DynamicFormDate {...props}/>;
            case(ReadingType.DATE_TIME):
                return <DynamicFormDateTime {...props}/>;
            case(ReadingType.MULTIPLE_SELECTION):
                return <DynamicFormMultipleSelection {...props}/>;
            case(ReadingType.IMAGE):
                return <DynamicFormImage {...props}/>;
            case(ReadingType.MULTI_IMAGE):
                return <DynamicFormMultiImage {...props}/>;
            case(ReadingType.RAPID_TEST):
                return <DynamicFormRapidTest {...props}/>;
            case(ReadingType.LIKERT_SCALE_5PT):
                return <DynamicFormMultiState {...props}/>;
            case(ReadingType.SLIDER):
                return <DynamicFormSlider {...props}/>;
        }
    };

    return (
        <div>
            {mapToComponent(props.type)}
        </div>
    );
};

DynamicFormInput.propTypes = {
    type: PropTypes.object.isRequired
};

export default DynamicFormInput;
