import React, {useEffect, useRef, useState} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import ChatUserSelector from "./ChatUserSelector";
import {observer} from "mobx-react-lite";
import ChatStore from "../../stores/chat/ChatStore";
import PatientsStore from "../../stores/PatientsStore";
import ChatSearchPatientSelector from "./ChatSearchPatientSelector";
import styled from "styled-components";
import debounce from "lodash/debounce";
import {RefreshIcon} from "./ChatWindow";
import {CHAT_POOL_INTERVAL} from "../../config";
import {useOutsideClickDetector} from "../../hooks/useOutsideClickDetector";
import ChatLoader from "./ChatLoader";
import {numberValidation} from "../../_utils/numberValidation";

const StyledCard = styled(Card)`
  height: 100%;
`;

const ChatPatientListWrapper = styled.div`
  max-height: 480px; 
  height: 480px; 
  overflow: auto;
  padding: 0 15px 10px 10px;
`;

const StyledCardTitle = styled(CardTitle)`
  margin-right: 12px;
`;

const ChatConversationsList = observer(({updateScroll}) => {
    let interval;
    useEffect(() => {
        interval = setInterval(() => {
            ChatStore.getConversations(false);
        }, CHAT_POOL_INTERVAL);
        return () => clearInterval(interval);
    }, []);

    const [isInputFocused, setIsInputFocused] = useState(false);

    const onInputFocus = () => {
        setIsInputFocused(true);
    };

    const selectedList = isInputFocused ? <ChatSearchPatientList setIsInputFocused={setIsInputFocused}/> :
        <ChatPatientList/>;

    const refreshChat = async () => {
        ChatStore.getConversations(true);

        let selectedCoversation = ChatStore.selectedConversation;

        if (selectedCoversation) {
            await ChatStore.selectedConversation.getMessages(true);
        }

        updateScroll();
    };

    return (
        <StyledCard>
            <CardHeader>
                <div className="d-flex align-items-center">
                    <StyledCardTitle tag="h5">Pogovori</StyledCardTitle>
                    <RefreshIcon className="nc-icon nc-refresh-69"
                        onClick={() => refreshChat()}/>
                </div>
                <ChatSearchPatientForm onInputFocus={onInputFocus}/>
            </CardHeader>
            <CardBody>
                <ChatPatientListWrapper>
                    <ul className="list-unstyled team-members mb-0 h-100">
                        {selectedList}
                    </ul>
                </ChatPatientListWrapper>
            </CardBody>
        </StyledCard>
    );
});

export const ChatPatientList = observer(() => {
    const isEmpty = ChatStore.conversations.length <= 0 && !ChatStore.isLoading;

    if (isEmpty)
        return "Ni aktivnih pogovorov";

    return (
        ChatStore.isLoading ?
            <ChatLoader isLoading={ChatStore.isLoading}/>
            :
            ChatStore.conversations.map(conversation =>
                <li key={conversation.healthId}>
                    <ChatUserSelector conversationInfo={conversation}
                        selectedUserId={ChatStore.selectedConversationUserId}/>
                </li>
            )
    );
});

export const ChatSearchPatientList = observer(({setIsInputFocused}) => {
    const isEmpty = ChatStore.selectedTopic?.medModuleId && PatientsStore.patients.length <= 0
        || !ChatStore.selectedTopic?.medModuleId && ChatStore.filteredConversations.length <= 0;

    const wrapperRef = useRef(null);
    useOutsideClickDetector(wrapperRef, () => setIsInputFocused(false), ["searchPatient"]);

    if (isEmpty)
        return <div ref={wrapperRef}>Ni pacientov</div>;

    return (
        <React.Fragment>
            <h6>Pacienti:</h6>
            <div ref={wrapperRef}>
                {ChatStore.selectedTopic?.medModuleId && PatientsStore.patients.map(patient =>
                    <li key={patient.id}>
                        <ChatSearchPatientSelector patient={patient} setIsInputFocused={setIsInputFocused}/>
                    </li>
                )}
                {!ChatStore.selectedTopic?.medModuleId && ChatStore.filteredConversations.map(conversation => {
                    const patient = {
                        id:conversation.userId,
                        firstName: conversation.firstName,
                        lastName: conversation.lastName,
                        healthId: conversation.healthId,
                    };
                    return (<li key={conversation.userId}>
                        <ChatSearchPatientSelector patient={patient} setIsInputFocused={setIsInputFocused}/>
                    </li>);
                })}
            </div>
        </React.Fragment>
    );
});

export const ChatSearchPatientForm = observer(({onInputFocus}) => {

    const [previousQuery, setPreviousQuery] = useState("");
    const [value, setValue] = useState("");

    useEffect(() => {
        fetchData();
        return () => {
            PatientsStore.clear();
        };
    }, []);

    useEffect(() => {
        setValue("");
    }, [ChatStore.selectedTopic?.medModuleId]);

    const fetchData = async (query) => {
        if(ChatStore.selectedTopic?.medModuleId)
            return await PatientsStore.getPatients(query, 50, 1, null, ChatStore.selectedTopic?.medModuleId);
        else {
            ChatStore.searchFilter = query;
        }
    };

    const search = debounce((value) => {
        setPreviousQuery(value);
        fetchData(value);
    }, 500);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setValue(value);
        if (value.length > 2) {
            search(value);
        } else if (value.length <= 2 && previousQuery.length > 2) {
            search("");
        }
    };

    return (
        <Form onSubmit={e => e.preventDefault()}>
            <InputGroup className="no-border">
                <Input
                    value={value}
                    placeholder={ChatStore.selectedTopic?.medModuleId ? "Poišči pacienta..." : "Številka zdravstvene izkaznice"}
                    type="text"
                    onFocus={() => onInputFocus()}
                    onChange={(event) => handleSearchChange(event)}
                    id="searchPatient"
                    autoComplete="off"
                />
                <InputGroupAddon addonType="append">
                    <InputGroupText>
                        <i className="nc-icon nc-zoom-split"/>
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </Form>
    );

});


export default ChatConversationsList;
