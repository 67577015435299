import {PermissionTypes} from "../interfaces/IPermissions";
import AuthStore from "./AuthStore";
import ResettableStore from "./ResettableStore";
import {action, observable, runInAction} from "mobx";
import {IMedModule, MedModuleStore} from "./MedModuleStore";
import {MED_MODULES} from "../config";
import httpService from "../services/httpService";

class MedModulesStore extends ResettableStore {
    @observable isLoading: boolean = false;
    @observable modules: Array<MedModuleStore> = [];
    @observable disabledDragReorder = false;
    @observable error = null;
    @observable activeModuleId: number | undefined;

    constructor() {
        super();

        this.setInitialState();
    }

    @action
    async getAll() {
        this.isLoading = true;
        const options = {
            method: "GET",
            url: MED_MODULES + "/GetAll",
        };

        try {
            const response = await httpService.fetch(options);

            runInAction(() => {
                this.modules = this.mapModulesFromServer(response);
                this.isLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.error = error;
                this.isLoading = false;
            });
        }
    }

    @action
    async saveOrder() {
        this.isLoading = true;
        const options = {
            method: "POST",
            url: MED_MODULES + "/Order",
            body: this.modules.map((module) => module.id)
        };

        try {
            const response = await httpService.fetch(options);

            runInAction(() => {
                this.isLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.error = error;
                this.isLoading = false;
            });
        }
    }

    @action
    async removeModule(id: string) {
        this.isLoading = true;

        const options = {
            method: "DELETE",
            url: MED_MODULES + `/${id}`,
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.modules = this.modules.filter(module => module.id !== id);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    hasPermissionForModule(moduleId: string, permission: PermissionTypes) {
        const module = this.modules.filter(module => module.id === moduleId);
        if(module.length > 0 && module[0].permissions) {
            return module[0].permissions[permission];
        }
        else {
            return false;
        }
    }

    @action
    canSeeModuleList() {
        const module = this.modules.filter(module => module.permissions?.canEdit);
        return module.length > 0;
    }

    @action
    canSeeChat() {
        const module = this.modules.filter(module => module.permissions?.canUse);
        return module.length > 0 || AuthStore.hasGeneralChatAccess;
    }

    @action
    selectedModule(moduleId: string) {
        const selected = this.modules.filter((model: IMedModule) => model.id === moduleId);
        if(selected?.length > 0)
            return {value: selected[0].id, label: selected[0].name};
        return null;
    }

    mapModulesFromServer = (response: any) => {
        return response.medModules.map((module: IMedModule) => new MedModuleStore(module));
    }
}

export default new MedModulesStore();
