import React, {useEffect} from "react";
import {hist} from "../index";
import MedModulesStore from "../stores/MedModulesStore";
import {getModuleId} from "../variables/fetchHelper";
import Loader from "./Loader";
import {getModuleViewsRoutes} from "../routes";
import AuthStore from "../stores/AuthStore";

const ModuleLoader = ({history}: any) => {

    useEffect(() => {
        const allModuleRoutes = getModuleViewsRoutes();
        const moduleId = getModuleId(hist);
        if(moduleId)
            MedModulesStore.activeModuleId = moduleId;
        if(allModuleRoutes && history && history.location.pathname){
            if(allModuleRoutes.filter(route => route.regex.test(history.location.pathname)).length === 0)
                if(AuthStore.isLoggedIn && AuthStore.hasMedicAccess)
                    history.push("/add-patient");
                else if(AuthStore.isLoggedIn)
                    history.push("/users");
                else
                    history.push("/login");

        }
    }, []);

    return (
        <div>
            <Loader isLoading={true}/>
        </div>
    );
};

export default ModuleLoader;