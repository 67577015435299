import React, {useEffect} from "react";
import OrganizationsStore from "stores/organizations/OrganizationsStore";
import {observer} from "mobx-react-lite";
import {Card, CardBody, CardHeader, CardTitle, Button} from "reactstrap";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import ButtonWithConfirmAlert from "components/ButtonWithConfirmAlert";
import AuthStore from "stores/AuthStore";
import {hist} from "index";

const organizationHeaders = [
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "Ime",
        accessor: "name",
    },
    {
        Header: "Naslov",
        accessor: "address",
    },
    {
        Header: "Organizacija",
        accessor: "region.postNumber",
        Cell: (props: any) => <div>{props.original.region.postNumber} - {props.original.region.name}</div>,
    },
    {
        Header: "Akcije",
        Cell: (props: any) =>
            <div>
                <Link to={`/organizations/${props.original.id}`}>
                    <i className="nc-icon nc-settings mr-3"/>
                </Link>
                <ButtonWithConfirmAlert successAction={() => OrganizationsStore.removeOrganization(props.original.id)} text={"Ste prepričani da želite izbrisati organizacijo?"}>
                    <i style={{cursor: "pointer"}} className="nc-icon nc-simple-remove text-danger mr-3"/>
                </ButtonWithConfirmAlert>
            </div>,
        sortable: false,
    },
];

const OrganizationsList = observer(() => {

    useEffect(() => {
        OrganizationsStore.getAllOrganizations();
    }, []);

    return (
        <div>
            <Card>
                <CardHeader>
                    <CardTitle style={{float: "left"}} tag="h5">Seznam organizacij</CardTitle>
                    {AuthStore.hasAdminAccess &&
                        <Button
                            className="btn-round"
                            color="default"
                            outline
                            style={{float: "right", marginBottom: "0px", marginTop: "5px"}}
                            onClick={() => hist.push(`/add-organization`)}
                        >
                            + Dodaj organizacijo
                        </Button>
                    }
                </CardHeader>
                <CardBody>
                    <ReactTable
                        // ref={ref}
                        loading={OrganizationsStore.isLoading}
                        data={OrganizationsStore.organizations}
                        resizable={false}
                        filterable={false}
                        sortable
                        columns={organizationHeaders}
                        pageSize={OrganizationsStore.organizations.length}
                        showPagination={false}
                        minRows={OrganizationsStore.organizations && OrganizationsStore.organizations.length > 0 ? 1 : 3}
                        className="-striped -highlight primary-pagination"

                        // Text
                        previousText={"Nazaj"}
                        nextText={"Naprej"}
                        loadingText={"Nalagam..."}
                        noDataText={"Ni zapisov"}
                        pageText={"Stran"}
                        ofText={"od"}
                        rowsText={"vrstic"}
                    />
                </CardBody>
            </Card>
        </div>
    );
});

export default OrganizationsList;