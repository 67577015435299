import React, {cloneElement, useState} from "react";
import {Modal, ModalBody} from "reactstrap";
import PropTypes from "prop-types";
import styled from "styled-components";


const StyledModal = styled(Modal)`
  word-break: break-all;
`;

const ButtonWithModal = ({children, title, text, component}) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
        <div>
            {cloneElement(children, {onClick: toggle})}
            <StyledModal isOpen={modal} toggle={toggle}>
                <div className="d-flex w-100 pl-5 justify-content-between">
                    <h5 className="pt-5">{title}</h5>
                    <i className="nc-icon nc-simple-remove m-3" style={{cursor: "pointer"}} onClick={toggle}/>
                </div>
                <ModalBody>
                    <div>
                        {text}
                        {component}
                    </div>
                </ModalBody>
                <br/>
            </StyledModal>
        </div>
    );
};

ButtonWithModal.propTypes = {
    children: PropTypes.element,
    title: PropTypes.string,
    text: PropTypes.string,
    component: PropTypes.element,
};


export default ButtonWithModal;