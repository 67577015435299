import { action, observable, runInAction } from "mobx";
import { TEMPLATES, TEMPLATE } from "../../config";
import httpService from "../../services/httpService";
import { ITemplate } from "interfaces/ITemplate";
import { selectReadingType } from "variables/selectHelper";

class TemplatesStore {
    @observable isLoading = false;
    @observable templates: Array<ITemplate> = [];
    @observable error: any = null;

    @action
    mapTemplatesToStore(payload: any) {
        this.templates = payload.map((temp: ITemplate) => {
            return {id: temp.id, name: temp.name, readingType: selectReadingType(temp.readingType)} as ITemplate
        })
    }

    @action
    async getAllTemplates() {
        this.isLoading = true;
        this.error = null;

        const options = {
            method: "GET",
            url: TEMPLATES,
        };

        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.mapTemplatesToStore(response);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    async removeTemplate(id: string) {
        this.isLoading = true;

        const options = {
            method: "DELETE",
            url: TEMPLATE + `/${id}`,
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.templates = this.templates.filter((template: ITemplate) => template.id !== id);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    clear() {
        this.isLoading = false;
        this.templates = [];
        this.error = null;
    }
}

export default new TemplatesStore();
