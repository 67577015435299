import React, {useEffect} from "react";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Input, Label, Row} from "reactstrap";
import {observer, Observer} from "mobx-react-lite";
import AnamnesisStore from "stores/AnamnesisStore";
import ReadingsStore from "stores/ReadingsStore";
import "components/readingsAndAnamnesisForms/ReadingsForm.css";
import Loader from "components/Loader";
import SortableTable from "components/readingsAndAnamnesisForms/SortableTable";
import {Link} from "react-router-dom";
import ButtonWithConfirmAlert from "components/ButtonWithConfirmAlert";
import {hist} from "index";
import MedModulesStore from "stores/MedModulesStore";
import EPermission from "enums/EPermission";
import {getModuleId} from "variables/fetchHelper";
import ModuleLinks from "components/ModuleLinks";

const ReadingsForm = observer(({history}) => {
    const moduleId = getModuleId(hist);
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await ReadingsStore.getAllTypes();
        return await AnamnesisStore.getAnamnesis();
    };

    return (
        <React.Fragment>
            <Loader isLoading={AnamnesisStore.isLoading}/>
            <ModuleLinks tabPath={location.pathname} moduleId={moduleId}></ModuleLinks>
                <Card>
                    <CardHeader className="d-flex justify-content-between">
                        <CardTitle tag="h4">Anamneza</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Label>Naziv</Label>
                                <Input
                                    type="text"
                                    value={AnamnesisStore.name}
                                    onChange={(event) => AnamnesisStore.name = event.target.value}
                                    maxLength={100}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Label>Opis</Label>
                                <Input
                                    type="text"
                                    value={AnamnesisStore.description}
                                    onChange={(event) => AnamnesisStore.description = event.target.value}
                                    maxLength={500}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Button onClick={() => AnamnesisStore.postReadings()}
                                    className="btn-round"
                                    color="info"
                                    type="submit"
                                    disabled={!AnamnesisStore.isFormValid}>
                                    Shrani
                                </Button>
                            </Col>
                            <Col md={6}>
                            {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_EDIT) &&
                                <Button
                                    className="btn-round"
                                    color="default"
                                    outline
                                    style={{float: "right"}}
                                    onClick={() => history.push(hist.location.pathname + "/add-anamnesis")}
                                >
                                    + Dodaj novo meritev
                                </Button>
                            }
                            </Col>
                        </Row>
                        <SortableTable
                            data={AnamnesisStore.anamnesis}
                            setData={(data) => {
                                AnamnesisStore.anamnesis = data;
                                AnamnesisStore.postReadings();
                            }}
                            headers={headers()}
                            isDragDisabled={AnamnesisStore.disabledDragReorder}
                        />
                    </CardBody>
                </Card>
        </React.Fragment>
    );
});

const headers = () => {
    return [
        {
            Header: "Naziv meritve",
            accessor: "title",
        },
        {
            Header: "Statusni tekst",
            Cell: props => <div style={{"whiteSpace": "break-spaces"}}>{props.original.statusText}</div>,
        },
        {
            Header: "Tip odgovora",
            Cell: props => <div>{ReadingsStore.getReadingTypeLabel(props.original)}</div>
        },
        {
            Header: "Obvezno",
            Cell: props => <div>{props.original.mandatory ? "DA" : "NE"}</div>,
        },
        ...MedModulesStore.hasPermissionForModule(getModuleId(hist), EPermission.CAN_EDIT) ? [{
            Header: "Akcije",
            Cell: props =>
                <div>
                    <Link to={hist.location.pathname + "/" + props.original.id}>
                        <i className="nc-icon nc-settings-gear-65 mx-1"/>
                    </Link>
                    <Observer>{() =>
                        <ButtonWithConfirmAlert
                            text={"Želite izbrisati meritev?"}
                            successAction={() => AnamnesisStore.removeItem("anamnesis", props.original)}
                            openAction={() => AnamnesisStore.disabledDragReorder = true}
                            closeAction={() => AnamnesisStore.disabledDragReorder = false}
                        >
                            <i className="nc-icon nc-simple-remove text-danger mx-1" style={{cursor: "pointer"}}/>
                        </ButtonWithConfirmAlert>
                    }</Observer>
                </div>,
        }] : [],
    ];
};


export default ReadingsForm;
