import {action, computed, observable, runInAction} from "mobx";
import {IConversation} from "../../interfaces/IConversation";
import {ISelect} from "../../interfaces/ISelect";
import PatientsStore from "../PatientsStore";
import ResettableStore from "../ResettableStore";
import {CHAT, CONVERSATION} from "../../config";
import httpService from "../../services/httpService";
import {ITopic} from "../../interfaces/IChat";
import {Conversation} from "./Conversation";
import {getRelativePath, parametersToPath, pathToParameters, updateUrlWithParams} from "../../_utils/UrlHelper";
import {IPatient} from "../../interfaces/IPatient";

class ChatStore extends ResettableStore {
    @observable error: string | null = null;
    @observable isLoading: boolean = false;

    @observable selectedTopicId: string | null = null;
    @observable selectedConversationUserId: string | null = null;

    @observable userId: string = "";
    @observable message: string = "";
    @observable topics: Array<ITopic> = [];
    @observable conversations: Array<Conversation> = [];
    @observable searchFilter: string = "";

    constructor() {
        super();

        this.setInitialState();
    }

    @computed
    get topicsForDropdown(): ISelect[] {
        return this.topics.map((topic) => {
            return {value: topic.id, label: topic.name} as ISelect
        })
    }

    @computed
    get selectedTopicForDropdown(): ISelect | null {
        if (!this.selectedTopic) return null
        return {value: this.selectedTopic.id, label: this.selectedTopic.name} as ISelect
    }

    @computed
    get selectedConversation(): Conversation | null {
        if (!this.selectedConversationUserId) return null
        const filtered = this.conversations.filter(conversation => conversation.userId === this.selectedConversationUserId)
        if (filtered.length !== 1) return null
        return this.conversations.filter(conversation => conversation.userId === this.selectedConversationUserId)[0]
    }

    @computed
    get selectedTopic(): ITopic | null {
        if (!this.selectedTopicId) return null
        const selectedTopic = this.topics.filter(topic => topic.id === this.selectedTopicId)
        if (!selectedTopic || selectedTopic.length !== 1) return null
        return selectedTopic[0]
    }

    @action
    getDataFromUrl() {
        const parameters = pathToParameters()
        if (parameters.topicId) this.selectTopicById(parameters.topicId)
        if (parameters.userId) this.setConversationUserId(parameters.userId)
    }

    @action
    async getTopics() {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: CHAT
        }

        try {
            const response = await httpService.fetch(options)

            runInAction(() => {
                // @ts-ignore
                this.topics = response.chats;
                this.isLoading = false;
            })
        } catch (error) {
            runInAction(() => {
                this.error = error;
                this.isLoading = false
            })
        }
    }

    @action
    selectTopicById(id: string) {
        if (id === this.selectedTopicId) return
        this.clearConversations()
        this.selectedTopicId = id
        this.handleUrl()
        this.getConversations(true)
    }

    @action
    clearConversations() {
        this.conversations = [];
        this.selectedConversationUserId = null;
    }

    @action
    selectTopic(select: ISelect) {
        PatientsStore.clear();
        const id = select.value;
        this.selectTopicById(id);
        PatientsStore.getPatients("", 50, 1, null, this.selectedTopic?.medModuleId);
    };

    @action
    async getConversations(isInitialLoad: boolean) {
        if (!this.selectedTopicId) throw new Error("No selected topic");

        this.isLoading = isInitialLoad;

        const id = this.selectedTopicId;

        const options = {
            method: "GET",
            url: CONVERSATION.replace("{chatId}", id)
        }

        try {
            const response = await httpService.fetch(options)

            runInAction(() => {
                // @ts-ignore
                this.updateConversations(response)
                this.isLoading = false;
            })
        } catch (error) {
            runInAction(() => {
                this.error = error;
                this.isLoading = false
            })
        }
    }

    @action
    updateConversations(payload: IConversation[]) {
        payload.forEach(newConversation => {
            const filtered = this.conversations.find(conversation => conversation.userId === newConversation.userId.toString())
            if (filtered) {
                if (newConversation.answered !== filtered.answered) {
                    filtered.setAnswered(newConversation.answered)
                }
                if (filtered.isNewConversation) {
                    filtered.setIsNewConversation(false)
                }
            } else {
                this.conversations.push(new Conversation(newConversation, this.selectedTopicId!))
            }
        })

        // @ts-ignore
        this.conversations.replace(this.conversations.slice()
            .sort((a, b) => {
                return payload.indexOf(payload.find(conversation => conversation.userId.toString() === a.userId.toString())!) -
                    payload.indexOf(payload.find(conversation => conversation.userId.toString() === b.userId.toString())!)
            }))
    }

    @action
    setConversationUserId(userId: string) {
        if(this.selectedConversation?.newMessage){
            this.selectedConversation.newMessage = "";
        }
        this.selectedConversationUserId = userId;

        this.handleUrl()
    }

    handleUrl() {
        updateUrlWithParams("", parametersToPath(getRelativePath(), {
            topicId: this.selectedTopicId,
            userId: this.selectedConversationUserId,
        }))
    }

    @action
    async selectOrCreateConversationByPatient(patient: IPatient) {
        const filtered = this.conversations.filter(conversation => conversation.userId === patient.id)
        if (filtered.length === 0) {
            this.conversations.unshift(new Conversation({
                answered: false,
                userId: patient.id,
                healthId: patient.healthId,
                emso: patient.emso,
                firstName: patient.firstName,
                lastName: patient.lastName
            }, this.selectedTopicId!, true))
        }
        this.setConversationUserId(patient.id)
    }

    @computed
    get filteredConversations() {
        if(!this.searchFilter) return this.conversations;

        const regex = new RegExp(this.searchFilter.toLocaleLowerCase());
        return this.conversations.filter((conversation) =>
            regex.test(conversation.healthId)
            || regex.test(conversation.firstName?.toLocaleLowerCase() + " " + conversation.lastName?.toLocaleLowerCase()));
    }

    @computed
    get hasUnsentMessage() {
        return this.selectedConversation?.newMessage;
    }

    @computed
    get shouldShowWarningOnBackNavigation() {
        return this.selectedConversation?.newMessage;
    }
}

export default new ChatStore();

