import React from "react";
import "./readingsAndAnamnesisForms/ReadingsForm.css";
import ReactTable from "react-table";
import PropTypes from "prop-types";

const FormTable = ({headers, data, isLoading, minRows}) => {
    return <ReactTable
        loading={isLoading}
        data={data}
        resizable={false}
        filterable={false}
        sortable={false}
        columns={headers}
        pageSize={data?.length}
        showPagination={false}
        minRows={minRows ? minRows : data && data.length > 0 ? 1 : 3 }
        className="-striped -highlight primary-pagination"

        // Text
        previousText={"Nazaj"}
        nextText={"Naprej"}
        loadingText={"Nalagam..."}
        noDataText={"Ni zapisov"}
        pageText={"Stran"}
        ofText={"od"}
        rowsText={"vrstic"}

    />;
};

FormTable.propTypes = {
    headers: PropTypes.array,
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    minRows: PropTypes.number,
};

export default FormTable;
