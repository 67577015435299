import React, { useEffect } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label } from "reactstrap";
import Loader from "components/Loader";
import { observer } from "mobx-react-lite";
import TemplatesStore from "stores/templates/TemplatesStore";
import Select from "react-select";
import TemplateStore from "stores/templates/TemplateStore";
import AdminNavbarStore from "stores/common/AdminNavbarStore";
import { ReadingType, readingTypes } from "components/readingTypes";
import MultipleSelectionForm from "components/readingsAndAnamnesisForms/MultipleSelectionForm";
import MultiStateForm from "components/readingsAndAnamnesisForms/MultiStateForm";

interface IProps {
    match: any,
}

const TemplateForm = observer(({ match }: IProps) => {
    const editingId = match.params.id;

    useEffect(() => {
        fetchData();
        return () => TemplateStore.clear();

    }, []);

    const fetchData = async () => {
        if (!TemplatesStore.templates || TemplatesStore.templates.length === 0) {
            await TemplatesStore.getAllTemplates();
        }
        if (editingId) {
            TemplateStore.setEditing(editingId);
        }
    };

    const postTemplate = async () => {
        const response = await TemplateStore.postTemplate();
        if (response) AdminNavbarStore.redirectWithoutWarning("/templates");
    };

    const getReadingTypes = () => {
        return readingTypes.filter(i => i.value === ReadingType.MULTIPLE_SELECTION
            || i.value === ReadingType.MULTI_STATE || i.value === ReadingType.LIKERT_SCALE_5PT);
    }

    const templateId = match.params.id;
    return (
        <Card className="col-md-10 mx-auto">
            <Loader isLoading={false} />
            <CardHeader>
                <CardTitle tag="h3" className="text-center">
                    {templateId ? "Uredi predlogo" : "Dodaj predlogo"}
                </CardTitle>
            </CardHeader>
            <CardBody className="col-md-9 m-auto">
                <Label>Ime*</Label>
                <FormGroup className={TemplateStore.status.name}>
                    <Input
                        name="name"
                        value={TemplateStore.name}
                        onChange={TemplateStore.handleChange}
                        onBlur={() => TemplateStore.validateField("name")}
                        autoComplete="off"
                        maxLength={100}
                    />
                    {TemplateStore.status.name === "has-danger" &&
                        <label className="error">
                            Ime je obvezno
                        </label>}
                </FormGroup>

                <Label>Tip odgovora*</Label>
                <Select
                    className="react-select primary mb-2"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    options={getReadingTypes()}
                    placeholder="Tip odgovora"
                    value={TemplateStore.readingType}
                    onChange={(event) => { TemplateStore.readingType = readingTypes.find(ty => ty.value == event.value); }}
                />

                {(TemplateStore.readingType.value === ReadingType.MULTI_STATE || TemplateStore.readingType.value === ReadingType.LIKERT_SCALE_5PT) &&
                    <MultiStateForm data={TemplateStore.items.map(item => item)}
                        setData={TemplateStore.overrideMultipleSelection}
                        addClick={TemplateStore.addMultipleOption}
                        removeClick={TemplateStore.removeMultipleSelectOption} />
                }

                {TemplateStore.readingType.value === ReadingType.MULTIPLE_SELECTION &&
                    <MultipleSelectionForm data={TemplateStore.items.map(item => item)}
                        setData={TemplateStore.overrideMultipleSelection}
                        addClick={TemplateStore.addMultipleOption}
                        removeClick={TemplateStore.removeMultipleSelectOption} />
                }

                <Button disabled={!TemplateStore.canSubmit} className="btb btn-info mt-4"
                    onClick={postTemplate}>Potrdi</Button>
            </CardBody>
        </Card>
    );
});

export default TemplateForm;