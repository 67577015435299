import React from 'react';
import {Card, CardBody, CardHeader, FormGroup, Label} from "reactstrap";
import Switch from "react-bootstrap-switch";
import SettingsStore from "../../stores/SettingsStore";
import {observer} from "mobx-react-lite";

const RegistrationSettings = observer(() => {
    const handleChangeSwitch = (event, value) => {
        SettingsStore.setRegistration(value)
    };

    return (
        <Card>
            <CardHeader>
                Registracija
            </CardHeader>
            <CardBody>
                <Label>Omogoči registracijo novih uporabnikov</Label>
                <FormGroup>
                    <Switch onChange={handleChangeSwitch}
                            offColor="success"
                            offText={<i className="nc-icon nc-simple-remove"/>}
                            onColor="success"
                            onText={<i className="nc-icon nc-check-2"/>}
                            value={SettingsStore.registrationEnabled}
                    />
                </FormGroup>
            </CardBody>
        </Card>
    );
});

export default RegistrationSettings;
