import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from "reactstrap";
import {numberValidation} from "_utils/numberValidation";
import {hist} from "index";
import AssignPatientsToModuleStore from "stores/AssignPatientsToModuleStore";
import Loader from "components/Loader";

const AssignPatientsToModuleForm = observer(() => {

    useEffect(() => {
        return () => AssignPatientsToModuleStore.clear();
    }, []);

    const findPatient = async () => {
        await AssignPatientsToModuleStore.getPatientByHealthId(AssignPatientsToModuleStore.healthId);
    };

    const assignPatient = async () => {
        await AssignPatientsToModuleStore.assignStorePatient();
    };

    return (
        <Row>
            <Loader isLoading={AssignPatientsToModuleStore.isLoading}/>
            <Col className="mx-auto" md={10}>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h3" className="text-center">
							Dodaj pacienta
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row className="d-flex justify-content-center mb-3">
                            <InputGroup className="no-border w-25 mb-2 mr-2">
                                <Input
                                    name="healthId"
                                    value={AssignPatientsToModuleStore.healthId}
                                    onChange={(event) => numberValidation(event, AssignPatientsToModuleStore.handleChange)}
                                    autoComplete="off"
                                    placeholder="Številka zdravstvene izkaznice"
                                    type="text"
                                    maxLength={9}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                        <i className="nc-icon nc-zoom-split"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                            <Button disabled={!AssignPatientsToModuleStore.canSearch}
                                className="btn-info m-0 mb-2 d-inline" onClick={findPatient}>Išči</Button>
                        </Row>
                        {AssignPatientsToModuleStore.patientExists &&
                        <Row className="d-flex justify-content-center align-items-center flex-column">
                            <h6 className="mb-4">Najden pacient:</h6>
                            <h5>{AssignPatientsToModuleStore.healthId} {AssignPatientsToModuleStore.patientFullName && " - " + AssignPatientsToModuleStore.patientFullName}</h5>

                            <Button onClick={assignPatient} className="btn-info m-0 my-2 d-inline">Dodaj</Button>
                        </Row>
                        }
                        {AssignPatientsToModuleStore.patientExists === false &&
                        <Row className="d-flex justify-content-center align-items-center flex-column">
                            <h6 className="mb-4">Pacient še ne obstaja</h6>
                            <Button
                                onClick={() => hist.push(hist.location?.pathname + "/" + AssignPatientsToModuleStore.healthId)}
                                className="btn-info m-0 mb-2 d-inline">Registriraj pacienta</Button>
                        </Row>
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
});

export default AssignPatientsToModuleForm;