import {observer} from "mobx-react-lite";
import React from "react";
import {Input, Label} from "reactstrap";
import {getSloPermission} from "../enums/EPermission";
import {PermissionTypes} from "../interfaces/IPermissions";
import AssignUserToModuleStore from "../stores/AssignUserToModuleStore";

interface IPermissionCheckbox {
	checkboxPermission: PermissionTypes,
	isChecked: boolean,
	disabled?: boolean,
}

const PermissionCheckbox = observer(({checkboxPermission, isChecked, disabled}: IPermissionCheckbox) => {
    return (
        <Label check className="mr-3" style={{opacity: disabled ? "0.6" : "1"}}>
            <Input disabled={disabled} checked={isChecked}
			       onChange={() => AssignUserToModuleStore.togglePermission(checkboxPermission)} type="checkbox"/>
            {getSloPermission(checkboxPermission)}
            <span className="form-check-sign"/>
        </Label>
    );
});

export default PermissionCheckbox;