import React from "react";
import {localDateTimeFormat} from "../variables/dateHelpers";
import styled from "styled-components";
import ButtonWithModal from "./ButtonWithModal";
import {IReadingGraphData} from "../interfaces/IReadingGraphData";
import PatientReadingsStore from "../stores/PatientReadingsStore";
import {observer} from "mobx-react-lite";
import { ReadingType } from "./readingTypes";
import RecordImage from "components/RecordImage/RecordImage";
import { getRapidTestResult } from "enums/ETestResult";

const Row = styled.div`
    display: grid;
    grid-template-columns: 4fr 6fr;
    cursor: pointer;
`;

const RowText = styled.div`
    font-size: 12px;
    color: gray;
    color: ${(props: {color: string}) => props.color ? props.color : "gray"};
`;

const ModalButton = styled.div`
    font-size: 12px;
    cursor: pointer;
    text-align: center;
`;

interface IPropsList {
    data: IReadingGraphData[],
    dataLength?: number,
    type: number
}

const ModalList = observer(({data, dataLength, type}:IPropsList) => {
    if(!data) return null;
    const allDataLength = dataLength ? dataLength : data.length;    
    return (
        <div>
            {data.map((reading: IReadingGraphData, index) => {
                const isSelected = PatientReadingsStore.selectedReading?.created.slice(0, 19) === reading.createdAt.toISOString().slice(0, 19);
                return (
                    <Row key={reading.createdAt.toISOString()} onClick={() => {PatientReadingsStore.selectRecordByGraphIndex(allDataLength - 1 - index);}}>
                        <RowText color={isSelected ? "black" : "gray"}>{localDateTimeFormat(reading.createdAt.toISOString())}</RowText>
                        <RowText color={isSelected ? "black" : "#51bcda"} className="ml-4 text-uppercase">
                            { type !== ReadingType.IMAGE && type !== ReadingType.RAPID_TEST && type !== ReadingType.MULTI_IMAGE && (reading.value ? reading.value : "/" )}
                            
                            { (type === ReadingType.IMAGE) && (reading.value ? 
                                <ButtonWithModal title="Slika" component={<RecordImage maxWidth="900px" maxHeight="900px" url={reading.value} />}>
                                    <i className="fa fa-picture-o" style={{margin: "0", padding: "0"}} />
                                </ButtonWithModal> : "/")}

                             { (type === ReadingType.RAPID_TEST) && (reading.value && reading.value.image ?
                                <div>
                                    <span>{getRapidTestResult(reading.value.int)}</span>
                                    <div style={{display: "inline-block", marginLeft:"10px"}}>
                                        <ButtonWithModal title="Slika" component={<RecordImage maxWidth="900px" maxHeight="900px" url={reading.value.image} />}>
                                            <i className="fa fa-picture-o" style={{margin: "0", padding: "0"}} />
                                        </ButtonWithModal>
                                    </div>
                                </div> : "/")
                                
                            }
                            { type === ReadingType.MULTI_IMAGE && (reading.value && reading.value.length > 0 ?
                                <div>{JSON.parse(JSON.stringify(reading.value) as string).map((sl: string, ind: number) =>
                                    <div key={ind} style={{float: "left", marginLeft: "3px"}}><ButtonWithModal title="Slika" component={<RecordImage maxWidth="900px" maxHeight="900px" url={sl} />}>
                                        <i className="fa fa-picture-o" style={{margin: "0", padding: "0"}} />
                                    </ButtonWithModal></div>)}
                                </div>: "/")
                            }
                        </RowText>
                    </Row>
                );}
            )}
        </div>
    );
});

interface IProps {
    data: IReadingGraphData[],
    label: string,
    type: number
}

const TextGraph = observer(({data, label, type}: IProps) => {
    if(! data || data.length === 0) return null;
    const lastRecords = [...data].splice(0, 8);
    return (
        <div>
            <ModalList data={lastRecords} dataLength={data.length} type={type}/>
            {data.length > 8 &&
                <ButtonWithModal title={label} component={<ModalList data={data} type={type} />}>
                    <div className="d-flex align-items-center justify-content-center mt-3" style={{cursor: "pointer"}}>
                        <ModalButton>Pokaži več</ModalButton>
                        <i className={"nc-icon nc-minimal-down ml-2"}/>
                    </div>
                </ButtonWithModal>
            }

        </div>
    );
});

export default TextGraph;