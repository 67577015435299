import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

class ImageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            imagePreviewUrl: this.props.url ? this.props.url : this.props.avatar ? defaultAvatar : null
        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }
    handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
            this.props.setFile(file);
        };
        if(file)
            reader.readAsDataURL(file);
    }
    handleSubmit(e) {
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    }
    handleClick() {
        this.refs.fileInput.click();
    }
    handleRemove() {
        this.setState({
            file: null,
            imagePreviewUrl: this.props.avatar ? defaultAvatar : null
        });
        this.props.removeFile();
        this.refs.fileInput.value = null;
    }

    componentDidUpdate(previousProps) {
        if (previousProps.url !== this.props.url) {
            this.setState({
                file: null,
                imagePreviewUrl: this.props.url ? this.props.url : this.props.avatar ? defaultAvatar : null
            });
        }
    }

    render() {
        return (
            <div className="fileinput text-center">
                <input type="file" onChange={this.handleImageChange} ref="fileInput" />
                {this.state.imagePreviewUrl &&
          <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
              <img src={this.state.imagePreviewUrl} alt="..." />
          </div>
        }
        <div>
          {this.state.file === null && !this.props.url ? (
            <Button style={{width: "170px"}} className="btn-round" onClick={() => this.handleClick()}>
              Dodaj Sliko
            </Button>
          ) : (
            <span>
              <Button className="btn-round" onClick={() => this.handleClick()}>
                Spremeni Sliko
              </Button>
              {this.props.avatar ? <br /> : null}
              <Button
                color="danger"
                className="btn-round"
                onClick={() => this.handleRemove()}
              >
                <i className="fa fa-times" />
                Odstrani
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
    avatar: PropTypes.bool,
    setFile: PropTypes.func,
    removeFile: PropTypes.func,
    url: PropTypes.string,
};

export default ImageUpload;
