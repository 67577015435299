import React from 'react';
import styled from 'styled-components';

const Icon = styled.div`
    border-radius: 50%;
    ${props => props.color && props.size &&`
        border: 1px solid ${props.color};
        width: ${props.size}px;
        height: ${props.size}px;
        color: ${props.color};
        background: ${props.color};
    `}
`;

const StatusIcon = (props) => {
    return (
        <Icon {...props} />
    );
};

export default StatusIcon;
