import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const ButtonWrapper = styled.div`
    cursor: pointer;   
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover > p {
        text-decoration: underline;    
    }  
`;

const ButtonText = styled.p`
    font-size: 12px !important;
    margin-bottom: 0;
`;

const IconBehind = styled.i`
    font-size: 12px;
    margin-left: 8px;
`;

const LinkButton = ({action, iconClass, text, appendIconClass}) => {
    return (
        <ButtonWrapper
            onClick={action}
        >
            {iconClass && <i className={`nc-icon ${iconClass} text-center`}/>}
            <ButtonText>{text}</ButtonText>
            {appendIconClass && <IconBehind className={`nc-icon ${appendIconClass} text-center`}/>}
        </ButtonWrapper>
    );
};

LinkButton.propTypes = {
    action: PropTypes.func,
    iconClass: PropTypes.string,
    text: PropTypes.string,
    appendIconClass: PropTypes.string,
};

export default LinkButton;
