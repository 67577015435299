import {action, computed, observable, runInAction} from "mobx";
import httpService from "../services/httpService";
import {GET_READINGS, POST_PATIENT_READINGS, POST_READINGS, TEMPLATES} from "../config";
import {v4 as uuidv4} from "uuid";
import PatientPersonalDataStore from "./PatientPersonalDataStore";
import {
    cleanQuestionValue,
    handleChange,
    isAnswerValid,
    isQuestionValid,
    mapQuestionToRecord,
    mapReadingQuestionForServer,
    mapReadingQuestionFromServerToStore,
    mapSeacondaryReadingsSectionForServer,
    mapSeacondaryReadingsSectionFromServerToStore,
    shouldShowWarningOnBackNavigation,
    validateQuestion,
} from "./CommonAnamnesisReadings";
import WarningsStore from "./WarningsStore";
import {getModuleId} from "../variables/fetchHelper";
import {hist} from "../index";
import { readingTypes } from "components/readingTypes";
import { calculationTypes } from "enums/CalculationType";

class ReadingsStore {
    PRIMARY_READING_ACCESSOR = "primaryReadings";
    SECONDARY_READING_ACCESSOR = "secondaryReadings";
    MAIN_READING_ACCESSOR = "mainReadings";
    @observable isLoading = false;
    @observable mainReadings = [];
    @observable primaryReadings = [];
    @observable secondaryReadings = [{ id: 0, readings:[] }];
    @observable deepCopy = {
        primaryReadings: [],
        secondaryReadings: [{ id: 0, readings:[] }],
        mainReadings: [],
    };
    @observable error = null;
    @observable disabledDragReorder = false;
    @observable selectedSecondarySection = 0;
    @observable allTypes = [];

    constructor() {
    }

    @computed
    get isValid() {
        return this.primaryReadings.concat(this.getFlatSecondaryReadings(this.secondaryReadings)).concat(this.mainReadings)
            .filter(question => !isAnswerValid(question)).length === 0;
    };

    @computed
    get isReadingsFormValid() {
        return this.primaryReadings.concat(this.getFlatSecondaryReadings(this.secondaryReadings)).concat(this.mainReadings)
            .filter(question => !isQuestionValid(question)).length === 0;
    };

    @action
    async getAllTypes() {

        const options = {
            method: "GET",
            url: TEMPLATES,
        };

        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.allTypes = [];
                this.allTypes.push(...readingTypes);
                
                this.allTypes.push(...response.map((temp) => {
                    return { value: 'tmpl_' + temp.id, label: 'P - ' + temp.name };
                }))
            });
        } catch (err) {
            runInAction(() => {
                this.allTypes = [];
                this.allTypes.push(...readingTypes);
            });
        }
    }

    @action
    getReadingTypeLabel(reading) {

        let type = null;

        if (reading.templateId != null) {
            type = this.allTypes.find(el => el.value == 'tmpl_' + reading.templateId);
        } else {
            type = this.allTypes.find(el => el.value === reading.type.value);
        }

        return (type) ? type.label : '';
    }

    @action
    async getReadings() {
        this.isLoading = true;
        this.error = null;
        this.selectedSecondarySection = 0;

        const options = {
            method: "GET",
            url: GET_READINGS + "/" + getModuleId(hist),
        };

        // eslint-disable-next-line no-useless-catch
        try {
            const response = await httpService.fetch(
                options,
            );

            if (response !== null) {
                const mappedResponse = this.mapReadingsFromServer(response);

                runInAction(() => {
                    this.deepCopy = {
                        primaryReadings: mappedResponse.primaryReadings,
                        secondaryReadings: mappedResponse.secondaryReadings,
                        mainReadings: mappedResponse.mainReadings,
                    };
                    this.primaryReadings = mappedResponse.primaryReadings;
                    this.secondaryReadings = mappedResponse.secondaryReadings;
                    this.mainReadings = mappedResponse.mainReadings;
                    this.isLoading = false;
                });
            } else {
                WarningsStore.createWarning("Ni vsebine");
            }

        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    setReadings(response) {
        const mappedResponse = this.mapReadingsFromServer(response);

        this.deepCopy = {
            primaryReadings: mappedResponse.primaryReadings,
            secondaryReadings: mappedResponse.secondaryReadings,
            mainReadings: mappedResponse.mainReadings,
        };
        this.primaryReadings = mappedResponse.primaryReadings;
        this.secondaryReadings = mappedResponse.secondaryReadings;
        this.mainReadings = mappedResponse.mainReadings;
    }

    @action
    async postReadings() {
        this.isLoading = true;
        this.error = null;

        const options = {
            method: "POST",
            url: POST_READINGS,
            body: this.mapReadingsFromStoreToServer(),
        };

        // eslint-disable-next-line no-useless-catch
        try {
            await httpService.fetch(options);

            runInAction(() => {
                this.getReadings();
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    };

    @action
    async removeItem(accessor, element) {
        if (accessor === this.SECONDARY_READING_ACCESSOR) {
            for(let i = 0; i < this.secondaryReadings.length; i++) {
                let removeIndex = this.secondaryReadings[i].readings.findIndex(a => a.id === element.id);
                if (removeIndex > -1) {
                    this.secondaryReadings[i].readings.splice(removeIndex, 1);
                }
            }
        } else {
            this[accessor] = this[accessor].filter(a => a !== element);
        }

        await this.postReadings();
    };

    @action
    async removeSection(element) {
        this.secondaryReadings = this.secondaryReadings.filter(a => a !== element);
        await this.postReadings();
    };

    @action
    handleChange = (index, value, type, pairName) => {
        handleChange(this, index, value, type, pairName);
    };

    mapReadingsFromStoreToServer = () => {
        const primaryReadings = this.primaryReadings.map(reading => mapReadingQuestionForServer(reading));
        const secondaryReadings = this.secondaryReadings.map(section => mapSeacondaryReadingsSectionForServer(section));
        const mainReadings = this.mainReadings.length > 0 ? mapReadingQuestionForServer(this.mainReadings[0]) : null;
        return {
            medModuleId: getModuleId(hist),
            name: "",
            primaryReadingTypes: primaryReadings,
            secondaryReadingTypes: secondaryReadings,
            mainReading: mainReadings,
        };
    };

    mapReadingsFromServer = (response) => {
        const primaryReadings = response.primaryReadingTypes ? response.primaryReadingTypes.map(reading => mapReadingQuestionFromServerToStore(reading)) : [];
        const secondaryReadings = response.secondaryReadingTypes ? response.secondaryReadingTypes.map((section, index) => mapSeacondaryReadingsSectionFromServerToStore(section, index)) : [];
        const mainReadings = response.mainReading ? [response.mainReading].map(reading => mapReadingQuestionFromServerToStore(reading)) : [];
        return {
            primaryReadings,
            secondaryReadings,
            mainReadings,
        };
    };

    @action
    postPatientReadings = async (id) => {
        this.isLoading = true;
        this.error = null;

        let formData = new FormData();

        let patientReadings = this.mapPatientReadings(id);

        let fileSize = 0;

        let i=0;
        patientReadings.records.forEach(reading => {
            if (reading.image) {
                let fn = `file${i}`;
                formData.append(fn, reading.image);
                fileSize += reading.image.size;
                reading.image = fn;
                i++;
            } 
            
            if (reading.images && reading.images.length > 0) {
                for(let j=0; j<reading.images.length; j++) {
                    let img = reading.images[j];
                    let fn1 = `file${i}`;
                    formData.append(fn1, img.file);
                    fileSize += img.file.size;
                    reading.images[j] = fn1;
                    i++;
                }
            }
        });

        if (fileSize/1024/1024 > 95.9) {
            runInAction(() => {
                this.isLoading = false;
            });
            WarningsStore.createWarning("Skupna dovoljena velikost slik je presežena (96MB).");
            return false;
        }

        formData.append('data', `${JSON.stringify(patientReadings)}`);

        const options = {
            method: "POST",
            url: POST_PATIENT_READINGS,
            body: formData,
            contentType: "multipart/form-data",
        };

        // eslint-disable-next-line no-useless-catch
        try {
            await httpService.fetch(options);

            runInAction(() => {
                this.isLoading = false;
            });

            return true;
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
            return false;
        }
    };

    mapPatientReadings = (id) => {
        return {
            userId: id || PatientPersonalDataStore.id,
            moduleId: getModuleId(hist),
            recordReadingId: uuidv4(),
            records: this.primaryReadings
                .concat(this.getFlatSecondaryReadings(this.secondaryReadings))
                .concat(this.mainReadings)
                .map(question => cleanQuestionValue(question))
                .filter(reading => reading.value !== null && reading.value !== undefined && reading.value !== "")
                .map(question => mapQuestionToRecord(question)),
        };
    };

    @action
    validateField = (index, value, pairName) => {

        let reading = null;
        if (this.SECONDARY_READING_ACCESSOR === value.type) {
            reading = this.getSecondaryReading(value.id);
        } else {
            reading = this[value.type][index];
        }

        reading.validationStatus = validateQuestion(reading, pairName);
    };

    @action
    validateAll = () => {
        this.primaryReadings
            .concat(this.getFlatSecondaryReadings(this.secondaryReadings))
            .concat(this.mainReadings)
            .forEach((question) => question.validationStatus = validateQuestion(question));
    };

    @action
    getReading(accessor, id) {
        if (accessor === this.SECONDARY_READING_ACCESSOR) {
            return this.getSecondaryReading(id);
        }

        return this[accessor].find(a => a.id === id);
    }

    @action
    addReading = async (accessor, reading, sectionIndex = -1) => {
        if (accessor === this.SECONDARY_READING_ACCESSOR && sectionIndex != -1) {
            this.secondaryReadings[sectionIndex].readings.push(reading);
        } else {
            this[accessor].push(reading);
        }

        await this.postReadings();
    };

    @action
    updateReading = async (accessor, reading) => {

        if (accessor === this.SECONDARY_READING_ACCESSOR) {

            for(let i = 0; i < this.secondaryReadings.length; i++) {
                const index = this.secondaryReadings[i].readings.indexOf(this.getReading(accessor, reading.id));
                if (index > -1) {
                    this.secondaryReadings[i].readings[index] = reading;
                    await this.postReadings();
                    return;
                }
            }

        } else {

            const index = this[accessor].indexOf(this.getReading(accessor, reading.id));
            if (index > -1) {
                this[accessor][index] = reading;
            }

            await this.postReadings();
        }
    };

    @action
    saveSecondarySection = async (section) => {
        
        if (!this.secondaryReadings)
        {
            this.secondaryReadings = [];
        }

        if (section.index < 0) {
            this.secondaryReadings.push(section);
        } else {
            let s = this.secondaryReadings[section.index];
            s.text = section.text;
            s.subText = section.subText;
            s.calculationType = section.calculationType;
        }

        await this.postReadings();
    }

    @action
    getSecondarySection(index) {
        return this.secondaryReadings[index];
    }

    @action
    clear() {
        this.isLoading = false;
        this.mainReadings = [];
        this.primaryReadings = [];
        this.secondaryReadings = [{ readings:[] }];
        this.deepCopy = {
            primaryReadings: [],
            secondaryReadings: [{ readings:[] }],
            mainReadings: [],
        };
        this.error = null;
    }

    @action
    hideMainReadings() {
        this.mainReadings = [];
    }

    @computed
    get shouldShowWarningOnBackNavigation() {
        return shouldShowWarningOnBackNavigation(this.primaryReadings.concat(this.secondaryReadings));
    }

    getSecondaryReading(id) {
        for(let i = 0; i < this.secondaryReadings.length; i++) {
            let read = this.secondaryReadings[i].readings.find(a => a.id === id);
            if (read) {
                return read;
            }
        }

        return null;
    }

    getFlatSecondaryReadings(sections) {
        let sr = [];

        if (sections && sections.length > 0) {
            for (let i=0; i<sections.length; i++) {
                let section = sections[i];
                if (section.readings && section.readings.length > 0) {
                    for (let j=0; j<section.readings.length; j++) {
                        sr.push(section.readings[j]);
                    }
                }
            }
        }

        return sr;
    }

    getCalculationType(val) {
        let find = calculationTypes.find(i => i.value === val);
        if(find)
            return find.label;

        return '/';
    }
}

// singleton
export default new ReadingsStore();
