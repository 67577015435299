import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import {Card, CardBody, CardHeader, CardTitle, Button} from "reactstrap";
import {customStringSort} from "../_utils/sort";
import {hist} from "../index";
import UsersForModuleStore from "../stores/UsersForModuleStore";
import {getModuleId} from "../variables/fetchHelper";
import {cellStyle} from "../variables/styles";
import ButtonWithConfirmAlert from "./ButtonWithConfirmAlert";
import MedModulesStore from "../stores/MedModulesStore";
import EPermission from "enums/EPermission";
import ModuleLinks from "./ModuleLinks";

const tableHeaders = [
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "Ime",
        accessor: "fullName",
        sortMethod: (a: string, b: string) => customStringSort(a, b),
        style: cellStyle,
        minWidth: 150,
    },
    {
        Header: "Uporabniško Ime",
        accessor: "email",
        sortMethod: (a: string, b: string) => customStringSort(a, b),
        style: cellStyle,
        minWidth: 150,
    },
    {
        Header: "Pravice",
        accessor: "medModulePersmissinons",
        Cell: (props: any) => UsersForModuleStore.getPermissionsString(props.original.medModulePersmissinons),
        minWidth: 150,
    },
    {
        Header: "Akcije",
        accessor: "akcije",
        Cell: (props: any) =>
            <div>
                {!props.original.medModulePersmissinons.owner
                    ? <Link to={`/module/${getModuleId(hist)}/doctors/${props.original.id}`}>
                        <i className="nc-icon nc-settings-gear-65 mx-2"/>
                    </Link>
                    : <i style={{opacity: "0.3"}} className="nc-icon nc-settings-gear-65 mx-2"/>
                }
                {!props.original.medModulePersmissinons.owner
                    ? <ButtonWithConfirmAlert successAction={() => UsersForModuleStore.unassignUser(props.original.id)}
					                          text={"Želite odstraniti zdravnika iz modula?"}>
                        <i style={{cursor: "pointer"}} className="nc-icon nc-simple-remove text-danger mr-3"/>
                    </ButtonWithConfirmAlert>
                    : <i style={{opacity: "0.3"}} className="nc-icon nc-simple-remove text-danger mr-3"/>
                }
            </div>,
        sortable: false,
    },
];

const UsersForModuleList = observer(() => {
    useEffect(() => {
        UsersForModuleStore.getUsersForModule();
    }, []);

    return (
        <div>
            <ModuleLinks tabPath={location.pathname} moduleId={getModuleId(hist)}></ModuleLinks>
            <Card>
                <CardHeader>
                    <CardTitle style={{float: "left"}} tag="h5">Seznam zdravnikov</CardTitle>
                    {MedModulesStore.hasPermissionForModule(getModuleId(hist), EPermission.CAN_SHARE) &&
                        <Button
                            className="btn-round"
                            color="default"
                            outline
                            style={{float: "right", marginBottom: "0px", marginTop: "5px"}}
                            onClick={() => hist.push(`/module/${getModuleId(hist)}/assign-doctor`)}
                        >
                            + Dodaj zdravnika
                        </Button>
                    }
                </CardHeader>
                <CardBody>
                    <ReactTable
                        loading={UsersForModuleStore.isLoading}
                        data={UsersForModuleStore.users}
                        resizable={false}
                        filterable={false}
                        sortable
                        columns={tableHeaders}
                        pageSize={UsersForModuleStore.users.length}
                        showPagination={false}
                        minRows={UsersForModuleStore.users && UsersForModuleStore.users.length > 0 ? 1 : 3}
                        className="-striped -highlight primary-pagination"

                        // Text
                        previousText={"Nazaj"}
                        nextText={"Naprej"}
                        loadingText={"Nalagam..."}
                        noDataText={"Ni zapisov"}
                        pageText={"Stran"}
                        ofText={"od"}
                        rowsText={"vrstic"}
                    />
                </CardBody>
            </Card>
        </div>
    );
});

export default UsersForModuleList;