import React from "react";
import {FormGroup, Label} from "reactstrap";
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import PropTypes from "prop-types";

const DynamicFormImage = (props) => {
    const setImageFile = (file) => {
        props.handleChange(props.index, {id: props.id, value: file}, props.readingType);
    };

    const removeImage = () => {
        props.handleChange(props.index, {id: props.id, value: null}, props.readingType);
    };

    return (
        <div>
            <Label>{props.title} {props.mandatory && "*"}</Label>
            <FormGroup className={props.validationStatus}>
                <ImageUpload setFile={setImageFile} removeFile={removeImage} />
                {props.validationStatus === "has-danger" &&
                <label className="error">
                    Polje je obvezno
                </label>}
            </FormGroup>
        </div>
    );
};

DynamicFormImage.propTypes = {
    handleChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    readingType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mandatory: PropTypes.bool,
};

export default DynamicFormImage;
