import {action, computed, observable, runInAction} from "mobx";
import {PATIENTS_RESET_PIN} from "../config";
import httpService from "../services/httpService";
import ResettableStore from "./ResettableStore";

class ResetPinStore extends ResettableStore{
    @observable isLoading = false;
    @observable error = null;
    @observable newPin = "";
    @observable repeatedPin = "";

    @observable status = {
        newPin: "",
        repeatedPin: "",
    };

    constructor() {
        super();
        this.setInitialState();
    }

    @action
    handleChange = (event: any) => {
        const {name, value} = event.target;
        // @ts-ignore
        this[name] = value;
    };

    isPinValid(pin: string) {
        return pin?.length === 4;
    }

    isRepeatedPinValid(pin: string, repeatedPin: string) {
        return pin === repeatedPin;
    }

    @action
    validateField(field: string) {
        switch (field) {
            case("newPin"):
                this.status.newPin = !this.isPinValid(this.newPin) ? "has-danger" : "";
                break;
            case("repeatedPin"):
                this.status.repeatedPin = !this.isRepeatedPinValid(this.newPin, this.repeatedPin) ? "has-danger" : "";
        }
    }

    @action
    async postResetPin(healthId: string){
        this.isLoading = true;

        const options = {
            method: "POST",
            url: PATIENTS_RESET_PIN,
            body: {
                pin: this.newPin,
                healthId,
            }
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {

                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @computed
    get isSubmittable() {
        return (this.isRepeatedPinValid(this.newPin, this.repeatedPin) && this.isPinValid(this.newPin));
    }

    @computed
    get shouldShowWarningOnBackNavigation() {
        return this.newPin || this.repeatedPin;
    }
}

export default new ResetPinStore();