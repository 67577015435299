import {observer, Observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import {Button, Card, CardBody, CardFooter, Col, FormGroup, Input, Label} from "reactstrap";
import {IMatch} from "../../interfaces/IMatch";
import AuthStore from "../../stores/AuthStore";
import AdminNavbarStore from "../../stores/common/AdminNavbarStore";
import PatientPersonalDataStore from "../../stores/PatientPersonalDataStore";
import {getGoBackPath} from "../../variables/goBackHelper";

interface IProps {
	id: string,
	match: IMatch,
}

const PatientIdEditForm = observer((props: IProps) => {
    async function updatePatientIds() {
        const response = await PatientPersonalDataStore.validateIdsAndEdit();

        if (response && PatientPersonalDataStore.isSameAsServerCopy) {
            AdminNavbarStore.redirectWithoutWarning(getGoBackPath(props.match.params.id));
        }
    }

    useEffect(() => {
        return () => PatientPersonalDataStore.clear();
    }, []);

    return (
        <Card>
            <CardBody>
                <Col md={9} style={{"margin": "auto"}}>
                    <h5 className="formTitle firstTitle">IDENTIFIKACIJSKE ŠTEVILKE</h5>
                    <Label>EMŠO*</Label>
                    <FormGroup className={PatientPersonalDataStore.status.emso}>
                        <Input
                            type="text"
                            name="emso"
                            onChange={(event) => PatientPersonalDataStore.changePersonalData(event, true)}
                            value={PatientPersonalDataStore.personalData.emso}
                            onBlur={() => PatientPersonalDataStore.checkForErrors("emso")}
                            autoComplete="off"
                            maxLength={13}
                            disabled={!AuthStore.hasOrganizationAdminAccess}
                        />
                        {PatientPersonalDataStore.status.emso === "has-danger" ? (
                            <label className="error">
								EMŠO ni veljaven
                            </label>
                        ) : null}
                    </FormGroup>
                    <Label>Št. zdravstvene izkaznice*</Label>
                    <FormGroup className={PatientPersonalDataStore.status.healthId}>
                        <Input
                            type="text"
                            name="healthId"
                            onChange={(event) => PatientPersonalDataStore.changePersonalData(event, true)}
                            onBlur={() => PatientPersonalDataStore.checkForErrors("healthId")}
                            value={PatientPersonalDataStore.personalData.healthId}
                            autoComplete="off"
                            maxLength={9}
                            disabled={!AuthStore.hasOrganizationAdminAccess}
                        />
                        {PatientPersonalDataStore.status.healthId === "has-danger" ? (
                            <label className="error">
								Št. zdravstvene izkaznice mora imeti 9 številk
                            </label>
                        ) : null}
                    </FormGroup>
                </Col>
            </CardBody>
            <CardFooter>
                {AuthStore.hasOrganizationAdminAccess &&
                <Observer>{() =>
                    <Col md={9} style={{"margin": "auto"}}>
                        <Button
                            className="float-right btn-info"
                            disabled={!PatientPersonalDataStore.isIdEditValid}
                            onClick={() => updatePatientIds()}
                        >Shrani</Button>
                    </Col>
                }</Observer>
                }
            </CardFooter>
        </Card>
    );
});

export default PatientIdEditForm;
