import React, {useEffect} from "react";
import UsersStore from "stores/UsersStore";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import ButtonWithConfirmAlert from "components/ButtonWithConfirmAlert";
import {getSloRole} from "enums/ERole";
import AuthStore from "stores/AuthStore";
import {customStringSort, customStringSortForObject} from "_utils/sort";
import {PAGE_COUNT} from "config";
import PaginationTable from "components/Pagination/PaginationTable";
import {cellStyle} from "variables/styles";
import {pageFromList} from "variables/paginationHelpers";
import { hist } from "index";

const tableHeaders = [
    {
        Header: "ID",
        accessor: "id",
        sortable: false,
        style: cellStyle,
    },
    {
        Header: "Ime",
        accessor: "fullName",
        sortMethod: (a, b) => customStringSort(a, b),
        style: cellStyle,
        minWidth: 150,
    },
    {
        Header: "Uporabniško Ime",
        accessor: "userName",
        sortMethod: (a, b) => customStringSort(a, b),
        style: cellStyle,
        minWidth: 150,
    },
    {
        Header: "Organizacija",
        accessor: "organization.name",
        Cell: props => props.original.organization.name,
        sortMethod: (a, b) => customStringSortForObject(a, b, "name"),
        style: cellStyle,
        minWidth: 150,
    },
    {
        Header: "Tip",
        Cell: props => props.original.roles.map(r => getSloRole(r)).sort().join(", "),
        accessor: "roles",
        sortable: false,
        style: cellStyle,
    },
    {
        Header: "Akcije",
        Cell: props =>
            <div>
                <Link to={`/users/${props.original.id}`}>
                    <i className="nc-icon nc-settings mr-3"/>
                </Link>
                {AuthStore.id !== props.original.id
                    ? <ButtonWithConfirmAlert text={"Ste prepričani?"} successAction={async () => UsersStore.removeUser(props.original.id)}>
                        <i style={{cursor: "pointer"}} className="nc-icon nc-simple-remove text-danger mr-3"/>
                    </ButtonWithConfirmAlert>
                    : <i style={{cursor: "pointer", opacity: "0.3"}} className="nc-icon nc-simple-remove text-danger mr-3"/>
                }
                {props.original.isLocked &&
                <ButtonWithConfirmAlert text={"Želite odkleniti uporabnika?"} successAction={() => UsersStore.unlockUser(props.original.id)}>
                    <i className="nc-icon nc-lock-circle-open mr-3" style={{cursor: "pointer"}}/>
                </ButtonWithConfirmAlert>
                }
            </div>,
        sortable: false,
    },
];

const UsersList = observer(() => {
    useEffect(() => {
        fetchData("", PAGE_COUNT);
        return () => UsersStore.clear();
    }, []);

    const fetchData = async (value, count, page, order) => {
        await UsersStore.getUsers(value, count, page, order);
    };

    const addUser = () => {
        hist.push(`/add-user`)
    }

    return (
        <PaginationTable
            title={"Seznam uporabnikov"}
            tableHeaders={tableHeaders}
            isLoading={UsersStore.isLoading}
            data={pageFromList(UsersStore.users)}
            store={UsersStore}
            fetchData={fetchData}
            manual={false}
            showAdd={AuthStore.hasAdminAccess || AuthStore.hasOrganizationAdminAccess}
            onAdd={addUser}
        />
    );
});


export default UsersList;
