import React from "react";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {Line} from "react-chartjs-2";
import PatientReadingsStore from "../stores/PatientReadingsStore";
import {observer} from "mobx-react-lite";
import {ReadingType} from "./readingTypes";
import {
    BLOOD_PRESSURE,
    BODY_TEMPERATURE_LIMIT,
    BREATHING_FREQUENCY_LIMIT,
    HEART_RATE_LIMIT,
    SATURATION_LIMIT,
} from "../enums/vitals";
import PatientProfileStore from "../stores/PatientProfileStore";
import {localDateWithoutYear} from "../variables/dateHelpers";
import TextGraph from "./TextGraph";
import {localNumberFormat} from "../variables/formatHelpers";


const options = {
    legend: {display: false},
    tooltips: {
        filter: (toolTipItem) => {
            return toolTipItem.datasetIndex === 0 || toolTipItem.datasetIndex === 1;
        },
    },
    layout: {
        padding: {
            bottom: 25,
        }
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    fontColor: "#9f9f9f",
                    beginAtZero: false,
                    maxTicksLimit: 5,
                    padding: 20,
                    callback: function (value) {
                        return localNumberFormat(value);
                    }
                },
                gridLines: {
                    drawBorder: true,
                },
            },
        ],
        xAxes: [
            {
                barPercentage: 1.6,
                gridLines: {
                    drawBorder: false,
                    color: "rgba(255,255,255,0.1)",
                    zeroLineColor: "transparent",
                    display: false,
                },
                ticks: {
                    padding: 20,
                    fontColor: "#9f9f9f",
                    callback: (label, index, labels) => {
                        if (Object.keys(PatientReadingsStore.dataForGraphs).length === 0) return "";
                        return "   " + label + "  ";
                    },
                },

            },
        ],
    },
    onClick: (event, elements) => {
        if (elements.length > 0) {
            PatientReadingsStore.selectRecordByGraphIndex(elements[0]._index);
        }
    },
};

const defaultDataSetOptions = {
    label: "",
    borderColor: "#51bcda",
    pointRadius: 2,
    pointHoverRadius: 6,
    pointHoverBackgroundColor: "#51bcda",
    fill: false,
    borderWidth: 3,
};

const testDataSetOptions = {
    label: "",
    borderColor: "black",
    pointRadius: 3,
    pointHoverRadius: 8,
    pointHoverBackgroundColor: "#51bcda",
    fill: false,
    borderWidth: 3,
    tooltips: {enabled: false},
};

const redLineDataSettings = {
    label: "",
    borderColor: "#E63F3F",
    pointRadius: 0,
    pointHoverRadius: 0,
    fill: false,
    borderWidth: 1,
    datasetStroke: true,
};

const defaultMissingDataSetOptions = {
    label: "",
    borderColor: "#51bcda",
    pointRadius: 0,
    pointHoverRadius: 0,
    pointHoverBackgroundColor: "#51bcda",
    fill: false,
    borderWidth: 3,
    borderDash: [2, 4],
};

const model = {
    options: options,
};

const getBorders = (type, length) => {
    const data = [];

    switch (type) {
        case(ReadingType.HEART_RATE):
            data.push(Array(length).fill(HEART_RATE_LIMIT.MIN));
            data.push(Array(length).fill(HEART_RATE_LIMIT.MAX));
            break;
        case(ReadingType.BREATHING_FREQUENCY):
            data.push(Array(length).fill(BREATHING_FREQUENCY_LIMIT.MIN));
            data.push(Array(length).fill(BREATHING_FREQUENCY_LIMIT.MAX));
            break;
        case(ReadingType.BODY_TEMPERATURE):
            data.push(Array(length).fill(BODY_TEMPERATURE_LIMIT.MAX));
            data.push([]);
            break;
        case(ReadingType.SATURATION):
            data.push(Array(length).fill(SATURATION_LIMIT.MIN));
            data.push([]);
            break;
        case(ReadingType.BLOOD_PRESSURE):
            data.push(Array(length).fill(BLOOD_PRESSURE.LOWER.MIN));
            data.push([]);
            break;
        default:
            data.push([]);
            data.push([]);
    }

    return [
        {
            ...redLineDataSettings,
            data: data[0],
            label: "lowerLimit",
        }, {
            ...redLineDataSettings,
            data: data[1],
            label: "upperLimit",
        },
    ];
};

function getReadingLabel(readingId) {
    let ret;

    PatientProfileStore.latestReadings.primaryReadingTypes
        .concat(PatientProfileStore.latestReadings.secondaryReadingTypes)
        .filter(item => item.id === readingId)
        .map(item => ret = item.title);

    return ret;
}

function findMissingRange(payload) {
    if (payload.length === 0) return [];
    const targetIndexes = [];
    payload.forEach((item, index) => {
        if (item === null) targetIndexes.push(index);
    });

    const groups = [];
    let checked = -1;
    targetIndexes.forEach((indexOfInputArray, indexOfTargetIndexes) => {
        if (indexOfTargetIndexes <= checked) return;

        checked = indexOfTargetIndexes;
        const group = [indexOfInputArray === 0 ? indexOfInputArray : indexOfInputArray - 1];
        let currentMax = indexOfInputArray;

        for (let j = 1; j + indexOfTargetIndexes < targetIndexes.length; j++) {
            if (targetIndexes[indexOfTargetIndexes + j] === indexOfInputArray + j) {
                currentMax = indexOfInputArray + j;
                checked = indexOfTargetIndexes + j;
            }
        }

        group.push(currentMax === payload.length - 1 ? currentMax : currentMax + 1);
        groups.push(group);
    });
    return groups;
}

const getMissingLines = (payload, labelSuffix) => {
    const odd = Array(payload.length).fill(null);
    const even = Array(payload.length).fill(null);
    const missingIndexes = findMissingRange(payload);

    missingIndexes.forEach((missingIndexGroup, indexGroupIndex) => {
        let start = payload[missingIndexGroup[0]];
        let end = payload[missingIndexGroup[1]];
        if (start === null && end === null) {
            odd.forEach((_, index) => odd[index] = 0);
        } else {
            if (start === null) start = end;
            else if (end === null) end = start;
            const rangeLength = missingIndexGroup[1] - missingIndexGroup[0];
            const values = getAverageValues(start, end, rangeLength);
            for (let i = 0; i <= rangeLength; i++) {
                if (indexGroupIndex % 2 === 0) {
                    odd[missingIndexGroup[0] + i] = values[i];
                } else {
                    even[missingIndexGroup[0] + i] = values[i];
                }
            }
        }
    });

    return [{
        ...defaultMissingDataSetOptions,
        data: odd,
        label: "missingDataOdd" + labelSuffix,
    }, {
        ...defaultMissingDataSetOptions,
        data: even,
        label: "missingDataEven" + labelSuffix,
    }];
};

const getAverageValues = (startValue, endValue, length) => {
    const deltaPerStep = (startValue - endValue) / length;
    const ret = [];
    for (let i = 0; i <= length; i++) {
        ret.push(startValue - (i * deltaPerStep));
    }
    return ret;
};

const VitalsGraph = observer((props) => {
    const mapToGraphData = () => {
        if (!PatientReadingsStore.dataForGraphs[props.readingId] || PatientReadingsStore.dataForGraphs[props.readingId].length === 0) return {};
        const data = PatientReadingsStore.dataForGraphs[props.readingId].slice().sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf());

        const type = PatientReadingsStore.readingIdToReadingTypeMap[props.readingId].value;
        const pair = type === ReadingType.INT_PAIR || type === ReadingType.FLOAT_PAIR || type === ReadingType.BLOOD_PRESSURE;

        const ret = {
            labels: [],
            datasets: [
                {
                    ...defaultDataSetOptions,
                    data: [],
                    label: getReadingLabel(props.readingId) + (pair ? " - zgornji" : ""),
                },
                {
                    ...defaultDataSetOptions,
                    data: [],
                    label: getReadingLabel(props.readingId) + (pair ? " - spodnji" : "x"),
                }],
        };


        if (PatientReadingsStore.selectedReadingId !== "none") {
            ret.datasets.push({
                ...testDataSetOptions,
                label: "selected-a",
                data: [],
            });

            ret.datasets.push({
                ...testDataSetOptions,
                label: "selected-b",
                data: [],
            });

        }

        data.forEach((reading, index) => {
            ret.labels.push(localDateWithoutYear(reading.createdAt));
            if (pair && reading.value) {
                ret.datasets[0].data.push(reading.value.a);
                ret.datasets[1].data.push(reading.value.b);
            } else {
                ret.datasets[0].data.push(reading.value);
                ret.datasets[1].data.push(null);
            }
            if (PatientReadingsStore.selectedReading?.created.slice(0, 19) === reading.createdAt.toISOString().slice(0, 19)) {

                if (pair) {
                    ret.datasets[2].data = Array(index).fill(null).concat(reading.value.a);
                    ret.datasets[3].data = Array(index).fill(null).concat(reading.value.b);
                } else {
                    ret.datasets[2].data = Array(index).fill(null).concat(reading.value);
                }
            }
        });

        if (pair) {
            const missingLines = getMissingLines(data.map(item => item.value?.a), "zgornji")
                .concat(getMissingLines(data.map(item => item.value?.b), "spodnji"));
            missingLines.forEach(item => ret.datasets.push(item));

        } else {
            const missingLines = getMissingLines(data.map(item => item.value), "");
            missingLines.forEach(item => ret.datasets.push(item));
        }


        const borders = getBorders(props.readingType, data.length);
        borders.forEach(border => ret.datasets.push(border));

        if (props.readingId === PatientReadingsStore.selectedReadingId) {
            const index = PatientReadingsStore.rawData.findIndex(item => item.recordReadingId === props.readingId);
            const value = 100;
            if (index) {
                ret.datasets.push(
                    {
                        ...testDataSetOptions,
                        data: [null, null, value],
                        label: "selected",
                    },
                );
            }
        }

        return ret;
    };

    const type = PatientReadingsStore.readingIdToReadingTypeMap[props.readingId].value;

    return (
        <Col xl={4} md={6}>
            <Card style={{height: "93%"}}>
                <CardHeader>
                    <Row>
                        <Col>
                            <h6 className="mt-2 pull-left">{props.label}
                                {(type === ReadingType.MULTI_STATE || type === ReadingType.LIKERT_SCALE_5PT || type === ReadingType.MULTIPLE_SELECTION)  && <span>&nbsp;(<span style={{cursor: "pointer", color: "#51bcda"}} onClick={() => { PatientReadingsStore.showGraph = true; }}>Graf </span>/
                                    <span style={{cursor: "pointer", color: "#51bcda"}} onClick={() => { PatientReadingsStore.showGraph = false; }}> Opis</span>)
                                </span>}
                            </h6>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    {type === ReadingType.FREE_TEXT ||
                    (type === ReadingType.LIKERT_SCALE_5PT && PatientReadingsStore.showGraph === false) ||
                    (type === ReadingType.MULTI_STATE && PatientReadingsStore.showGraph === false) ||
                    (type === ReadingType.MULTIPLE_SELECTION && PatientReadingsStore.showGraph === false) ||
                    type === ReadingType.DATE || type === ReadingType.DATE_TIME ||
                    type === ReadingType.IMAGE || type === ReadingType.MULTI_IMAGE ||
                    type === ReadingType.RAPID_TEST
                        ? <TextGraph label={props.label} data={PatientReadingsStore.dataForGraphs[props.readingId]} type={type}/>
                        : <Line
                            data={mapToGraphData()}
                            options={model.options}
                            height={380}
                            width={826}
                        />
                    }
                </CardBody>
            </Card>
        </Col>
    );
});

export default VitalsGraph;
