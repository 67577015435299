import {action, computed, observable, runInAction} from "mobx";
import httpService from "../services/httpService";
import {GET_READINGS, POST_ANAMNESIS, POST_PATIENT_ANAMNESIS} from "../config";
import {v4 as uuidv4} from "uuid";
import PatientPersonalDataStore from "./PatientPersonalDataStore";
import {
    cleanQuestionValue,
    handleChange,
    isAnswerValid,
    mapQuestionToRecord,
    mapReadingQuestionForServer,
    mapReadingQuestionFromServerToStore,
    shouldShowWarningOnBackNavigation,
    validateQuestion,
} from "./CommonAnamnesisReadings";
import ReadingsStore from "./ReadingsStore";
import {getModuleId} from "../variables/fetchHelper";
import {hist} from "../index";

class AnamnesisStore {
    ANAMNESIS_ACCESSOR = "anamnesis";
    @observable isLoading = false;
    @observable name = "";
    @observable description = "";
    @observable anamnesis = [];
    @observable deepCopy = {};
    @observable error = null;
    @observable disabledDragReorder = false;
    @observable multipleSelectionOptions = [];
    anamnesisId = "";

    constructor() {
    }

    @action
    async getAnamnesis() {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: GET_READINGS + "/" + getModuleId(hist),
        };

        // eslint-disable-next-line no-useless-catch
        try {
            const response = await httpService.fetch(
                options,
            );

            ReadingsStore.setReadings(response);
            const mappedResponse = this.mapReadingsFromServer(response);

            runInAction(() => {
                this.deepCopy = {
                    name: response.anamnesis.name,
                    description: response.anamnesis.description,
                    anamnesis: mappedResponse
                };
                this.anamnesis = mappedResponse;
                this.name = response.anamnesis.name;
                this.description = response.anamnesis.description;
                this.anamnesisId = response.anamnesis.id;
                this.isLoading = false;
            });
        } catch (err) {
            this.clear();
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    async postReadings() {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: POST_ANAMNESIS,
            body: this.mapReadingsFromStoreToServer(),
        };

        // eslint-disable-next-line no-useless-catch
        try {
            await httpService.fetch(options);

            runInAction(() => {
                this.getAnamnesis();
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    };

    @action
    postPatientReadings = async (patientId) => {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: POST_PATIENT_ANAMNESIS,
            body: this.mapRecordsForServer(patientId)
        };

        // eslint-disable-next-line no-useless-catch
        try {
            await httpService.fetch(options);

            runInAction(() => {
                this.isLoading = false;
            });

            return true;
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
            return false;
        }
    };

    @action
    async removeItem(accessor, element) {
        this[accessor] = this[accessor].filter(a => a !== element);
        this.postReadings();
    }

    @action
    handleChange = (index, value, type, pairName) => {
        handleChange(this, index, value, type, pairName);
    };

    @computed
    get isAnamnesisValid() {
        return this.anamnesis.filter(question => !isAnswerValid(question)).length === 0;
    };

    @computed
    get isSameAsServerCopy() {
        if(!this.name && !this.deepCopy.name && !this.description && !this.deepCopy.description) return true;
        if(!this.deepCopy) return false;
        return this.name === this.deepCopy.name && this.description === this.deepCopy.description;
    };

    @computed
    get isFormValid() {
        return ((this.name && this.description) || (this.name == '' && this.description == '')) && !this.isSameAsServerCopy;
    };

    mapReadingsFromStoreToServer = () => {
        const anamnesis = this.anamnesis.map(reading => mapReadingQuestionForServer(reading));
        return {
            medModuleId: getModuleId(hist),
            anamnesis: {
                id: this.anamnesisId || null,
                name: this.name,
                description: this.description,
                readings: anamnesis,
            },
        };
    };

    mapReadingsFromServer = (response) => {
        return response.anamnesis && response.anamnesis.readings ? response.anamnesis.readings.map(reading => mapReadingQuestionFromServerToStore(reading)) : [];
    };

    mapRecordsForServer = (patientId) => {
        return {
            userId: patientId || PatientPersonalDataStore.id,
            anamnesisId: this.anamnesisId,
            recordReadingId: uuidv4(),
            records: this.anamnesis
                .map(question => cleanQuestionValue(question))
                .filter(reading => reading.value !== null && reading.value !== undefined && reading.value !== "")
                .map(question => mapQuestionToRecord(question))
        };
    };

    @action
    validateField = (index, value, pairName) => {
        let reading = null;
        if (this.SECONDARY_READING_ACCESSOR === value.type) {
            reading = this.getSecondaryReading(value.id);
        } else {
            reading = this[value.type][index];
        }

        reading.validationStatus = validateQuestion(reading, pairName);
    };

    @action
    validateAll = () => {
        this.anamnesis.forEach((question) => question.validationStatus = validateQuestion(question));
    };

    @action
    getReadings = async () => {
        await this.getAnamnesis();
    };

    @action
    getReading(accessor, id) {
        return this[accessor].find(a => a.id === id);
    }

    @action
    addReading = async (accessor, reading) => {
        this[accessor].push(reading);
        await this.postReadings();
    };

    @action
    updateReading = async (accessor, reading) => {
        const index = this[accessor].indexOf(this.getReading(accessor, reading.id));
        if(index > -1){
            this[accessor][index] = reading;
        }
        await this.postReadings();
    };

    @action
    clear() {
        this.isLoading = false;
        this.name = "";
        this.description = "";
        this.anamnesis = [];
        this.deepCopy = {};
        this.error = null;
        this.anamnesisId = "";
    }

    @computed
    get shouldShowWarningOnBackNavigation() {
        return !this.isSameAsServerCopy;
    }

    @action
    addMultipleOption = () => {
        this.multipleSelectionOptions.push({
            id: "temp-id-" + uuidv4(),
            value: "",
        });
    };

    @action
    overrideMultipleSelection = (data) => {
        this.multipleSelectionOptions = data;
    };

    @action
    removeMultipleSelectOption = (id) => {
        this.multipleSelectionOptions = this.multipleSelectionOptions.filter(item => item.id !== id);
    };
}

// singleton
export default new AnamnesisStore();
