import React from "react";

const GdprForm = (props) => {
    return (
        <div className="content">
            {/*<p><b>*/}
            {/*    Prostovoljna in zavestna privolitev k raziskavi iMEDIS in zbiranju ter obdelavi osebnih podatkov v*/}
            {/*    okviru oddaljenega spremljanja in komunikacije z bolnikom, potencialno ali potrjeno okuženim s*/}
            {/*    COVID-19.*/}
            {/*</b></p>*/}
            {/*<p>*/}
            {/*    {props.fullName || "_________________"} rojen/a {props.birthDate || "__.__.____"}, sem bil/a pisno in*/}
            {/*    ustno seznanjen/a s potekom, namenom*/}
            {/*    in cilji raziskave:<b> iMEDIS – oddaljeno spremljanje in komunikacija z bolnikom, potencialno ali*/}
            {/*    potrjeno okuženim s COVID-19.</b>*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    S strani raziskovalca Univerzitetni klinični center Maribor sem seznanjen/a kako bo poskrbljeno za*/}
            {/*    mojo varnost v raziskavi in da lahko kadarkoli zaprosim za dodatne informacije. Prav tako mi je bilo*/}
            {/*    pojasnjeno, da lahko privolitev prekličem, ne bi moral/a preklic utemeljiti in ne da bi prenehanje*/}
            {/*    sodelovanja v raziskavi okrnilo mojo siceršnjo zdravstveno obravnavo.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    S strinjanjem prostovoljno potrjujem svojo pripravljenost za sodelovanje v raziskavi.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    V skladu s Splošno Uredbo (EU) o varstvu osebnih podatkov ter Zakonom o varstvu osebnih podatkov*/}
            {/*    (ZVOP-1) in Zakonom o pacientovih pravicah (ZPacP) izjavljam, da dajem upravljavcu Univerzitetnemu*/}
            {/*    kliničnemu centru Maribor izrecno privolitev za zbiranje naslednjih podatkov v aplikaciji iMEDIS:*/}
            {/*</p>*/}
            {/*<ul>*/}
            {/*    - Osebni podatki (ime in priimek, datum rojstva, naslov prebivališča, številka zdravstvenega*/}
            {/*    zavarovanja in enotna matična številka državljana, kontaktni elektronski naslov in mobilna*/}
            {/*    številka);*/}
            {/*</ul>*/}
            {/*<ul>*/}
            {/*    - Podatki o izbranem osebnem zdravniku (ime in priimek ter ustanova delovanja).*/}
            {/*</ul>*/}
            {/*<ul>*/}
            {/*    - Podatki potrebni za anamnezo (kronične bolezni, pretekle diagnoze, življenjski stil, splošni*/}
            {/*    relevantni podatki).*/}
            {/*</ul>*/}
            {/*<ul>*/}
            {/*    - Podatki o trenutnem zdravstvenem stanju v realnem času (dnevne meritve fizioloških*/}
            {/*    parametrov, podatki o počutju, podatki o pojavnosti izbranih simptomov).*/}
            {/*</ul>*/}
            {/*<ul>*/}
            {/*    - Podatki o moji lokaciji v realnem času.*/}
            {/*</ul>*/}
            {/*<ul>*/}
            {/*    - Podatki o kontaktih osebah v primeru urgentnih stanj.*/}
            {/*</ul>*/}
            {/*<p>*/}
            {/*    Dovoljujem uporabo in obdelavo zgoraj opredeljenih zbranih podatkov za naslednje namene:*/}
            {/*</p>*/}
            {/*<ul>*/}
            {/*    - Obveščanje in komuniciranje z osebjem zdravstvenega zavoda in drugih pristojnih*/}
            {/*    zdravstveno varstvenih inštitucij;*/}
            {/*</ul>*/}
            {/*<ul>*/}
            {/*    - Diagnosticiranje in zdravljenje;*/}
            {/*</ul>*/}
            {/*<ul>*/}
            {/*    - Raziskave in razvoj (Podatki uporabljeni v anonimizirani obliki).*/}
            {/*</ul>*/}
            {/*<p>*/}
            {/*    Seznanjen sem, da lahko v vsakem trenutku spremenim svojo odločitev ali prekličem privolitev,*/}
            {/*    kadarkoli pisno zahtevam dostop do svojih osebnih podatkov, popravek netočnih osebnih podatkov,*/}
            {/*    izbris osebnih podatkov v raziskavi ali pri Informacijskem pooblaščencu vložim pritožbo.*/}
            {/*    Privolitev dajete upravljavcu Univerzitetni klinični center Maribor. Osebne podatke iz zbirke lahko za*/}
            {/*    zgoraj označene namene obdelujejo tudi družbe, s katerimi ima upravljalec sklenjeno pogodbo o*/}
            {/*    obdelavi osebnih podatkov. Upravljalec vaše osebne podatke, ki jih obdeluje na podlagi vaše*/}
            {/*    privolitve in za zgoraj opredeljene namene hrani do preklica privolitve z vaše strani. Enako velja tudi*/}
            {/*    za obdelavo teh podatkov v povezanih družbah, katerim so bili, z vašo privolitvijo, osebni podatki*/}
            {/*    posredovani za isti namen obdelave.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    Posameznik ima pravico vložiti pritožbo pri Informacijskem pooblaščencu Republike Slovenije, če*/}
            {/*    meni, da se njegovi osebni podatki obdelujejo v nasprotju z veljavnimi predpisi, ki urejajo varstvo*/}
            {/*    osebnih podatkov. Pooblaščeno osebo za varstvo podatkov pri upravljalcu lahko kontaktirate preko*/}
            {/*    elektronskega naslova: <a href="mailto:dpo@ukc-mb.si">dpo@ukc-mb.si</a>.*/}
            {/*</p>*/}
            <p><b>Prostovoljna in zavestna privolitev k raziskavi in zbiranju terobdelavi osebnih podatkov</b></p>
            <p>
                Podpisani/podpisana {props.fullName || "_________________"} rojen/a {props.birthDate || "__.__.____"},
                sem bil/a pisno in ustno seznanjen/a s potekom, namenom in cilji raziskave:
            </p>
            <p><b>iMEDIS – oddaljeno spremljanje in komunikacija z bolnikom, potencialnoali potrjeno okuženim s
                COVID-19.</b></p>
            <p>
                S podpisom prostovoljno potrjujem svojo pripravljenost za sodelovanje v raziskavi.
            </p>
            <p>
                S strani raziskovalca UKC Maribor, Ljubljanska ulica 5, 2000 Maribor, sem seznanjen/a (v nadaljevanju je
                uporabljena enotna oblika za oba spola), kako bo poskrbljeno za mojo varnost v raziskavi in da lahko
                kadarkoli zaprosim za dodatne informacije. Prav tako mi je bilo pojasnjeno, da lahko privolitev
                prekličem, ne da bi moral/a preklic utemeljiti in ne da bi prenehanje sodelovanja v raziskavi okrnilo
                mojo siceršnjo zdravstveno obravnavo.
            </p>
            <p>
                V skladu s Splošno Uredbo (EU) o varstvu osebnih podatkov ter Zakonom o varstvu osebnih podatkov
                (ZVOP-1) in Zakonom o pacientovih pravicah (ZPacP)
                izjavljam, da dajem upravljavcu Univerzitetnemu kliničnemu centru Maribor izrecno privolitev za zbiranje
                naslednjih podatkov v aplikaciji iMEDIS:
            </p>
            <p>
                - Osebni podatki (ime in priimek, datum rojstva, naslov prebivališča, številka zdravstvenega zavarovanja
                in enotna matična številka državljana, kontaktni elektronski naslov in mobilna številka);
            </p>
            <p>
                - Podatki o izbranem osebnem zdravniku (ime in priimek ter ustanova delovanja).
            </p>
            <p>
                - Podatki potrebni za anamnezo (kronične bolezni, pretekle diagnoze, življenjski stil, splošni
                relevantni podatki).
            </p>
            <p>
                - Podatki o trenutnem zdravstvenem stanju v realnem času (dnevne meritve fizioloških parametrov, podatki
                o počutju, podatki o pojavnosti izbranih simptomov).
            </p>
            <p>
                - Podatki o kontaktih osebah v primeru urgentnih stanj.
            </p>
            <p>
                Dovoljujem uporabo in obdelavo zgoraj opredeljenih zbranih podatkov za naslednje namene:
            </p>
            <p>
                - Obveščanje in komuniciranje z osebjem zdravstvenega zavoda in drugih pristojnih zdravstveno varstvenih
                inštitucij;
            </p>
            <p>
                - Diagnosticiranje in zdravljenje;
            </p>
            <p>
                - Raziskave in razvoj (podatki uporabljeni v anonimizirani obliki).
            </p>
            <p>
                Seznanjen sem, da lahko v vsakem trenutku spremenim svojo odločitev ali prekličem privolitev, kadarkoli
                pisno zahtevam dostop do svojih osebnih podatkov, popravek netočnih osebnih podatkov, izbris osebnih
                podatkov v raziskavi ali pri Informacijskem pooblaščencu vložim pritožbo.
            </p>
            <p>
                Privolitev dajete upravljavcu Univerzitetnemu kliničnemu centru Maribor, Ljubljanska ulica 5, 2000
                Maribor. Osebne podatke iz zbirke lahko za zgoraj označene namene obdelujejo tudi družbe, s katerimi ima
                upravljalec sklenjeno pogodbo o obdelavi osebnih podatkov. Upravljalec vaše osebne podatke, ki jih
                obdeluje na podlagi vaše privolitve in za zgoraj opredeljene namene hrani do preklica privolitve z vaše
                strani. Enako velja tudi za obdelavo teh podatkov v povezanih družbah, katerim so bili, z vašo
                privolitvijo, osebni podatki posredovani za isti namen obdelave.
                Posameznik ima pravico vložiti pritožbo pri Informacijskem pooblaščencu Republike Slovenije, če meni, da
                se njegovi osebni podatki obdelujejo v nasprotju z veljavnimi predpisi, ki urejajo varstvo osebnih
                podatkov. Pooblaščeno osebo za varstvo podatkov pri upravljalcu lahko kontaktirate preko elektronskega
                naslova: dpo@ukc-mb.si.
            </p>
            <p>
                V primeru nujnih zdravstvenih stanj in potrebnih dodatnih informacij o COVID-19 lahko pokličete na
                telefonsko številko UKC Maribor 031 653 929, vse dni v tednu, med 7:00-22:00. Izven tega časa pokličite
                številko za nujne primere 112.
            </p>
        </div>
    );
};

export default GdprForm;
