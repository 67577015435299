import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
// @ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
import {Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label} from "reactstrap";
import EPermission, {getSloPermission} from "enums/EPermission";
import ERole, {getSloRole} from "enums/ERole";
import AuthStore from "stores/AuthStore";
import MedModulesStore from "stores/MedModulesStore";
import UserStore from "stores/UserStore";
import {cellStyle} from "variables/styles";
import FormTable from "components/FormTable";
import Loader from "components/Loader";
import ButtonWithConfirmAlert from "components/ButtonWithConfirmAlert";

const headersModulePermissions = [
    {
        Header: "Modul",
        Cell: (props: any) => <b>{props.original.name}</b>,
        minWidth: 160,
    },
    ...Object.values(EPermission).map((permission) => {
        return {
            Header: getSloPermission(permission),
            Cell: (props: any) =>
                <p>{props.original.permissions && props.original.permissions[permission] ? "DA" : "NE"}</p>,
            style: {...cellStyle, justifyContent: "center"},
        };
    }),
];

const headersUserRoles = [
    ...Object.values(ERole).map((role) => {
        return {
            Header: <div style={{whiteSpace: "break-spaces"}}>{getSloRole(role)}</div>,
            Cell: (props: any) =>
                <p>{props.original[role] ? "DA" : "NE"}</p>,
            style: {...cellStyle, justifyContent: "center"},
        };
    }),
];

const UserProfile = observer(() => {
    const [profileSaved, setProfileSaved] = useState(false);
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        UserStore.setEditingFromAuthStore();

	    const rolesObject = {};
	    Object.values(ERole).map((role) => {
		    // @ts-ignore
		    rolesObject[role] = AuthStore.hasRole(role);
	    });
	    // @ts-ignore
	    setUserRoles([rolesObject]);

        return () => UserStore.clear();
    }, []);

    const postUser = async () => {
        const response = await UserStore.postUserProfile();
        if (response) {
            await AuthStore.getUserData();
            await UserStore.setEditingFromAuthStore();
            setProfileSaved(true);
        }
    };

    return (
        <React.Fragment>
            <Loader isLoading={UserStore.isLoading}/>
            {profileSaved &&
            <ReactBSAlert
                success
                style={{display: "block"}}
                title={UserStore.onlyPasswordWasChanged
	                ? "Geslo je bilo uspešno posodobljeno."
	                : UserStore.onlyUserDataWasChanged ? "Profil je bil uspešno posodobljen." : "Podatki so bili uspešno posodobljeni."}
                onConfirm={() => {
                	setProfileSaved(false);
                	UserStore.onlyPasswordWasChanged = false;
	                UserStore.onlyUserDataWasChanged = false;
                }}
                confirmBtnBsStyle="info"
            />
            }
            <Card>
                <CardHeader>
                    <table style={{width:"100%"}}>
                        <tr>
                            <td style={{width:"200px"}}></td>
                            <td>
                                <CardTitle tag="h3" className="text-center">Profil</CardTitle>
                            </td>
                            <td style={{width:"200px"}}>
                                <ButtonWithConfirmAlert style={{float: "right", display: "inline-block"}} text={"Ali ste prepričani, da se želite odjaviti iz sistema?"} successAction={async () => AuthStore.logout()}>
                                    <Button
                                        className="btn-round"
                                        color="default"
                                        outline
                                        style={{float: "right", marginBottom: "0px", marginTop: "5px", marginRight:"20px", fontSize:"12px"}}
                                    >
                                        Odjavi
                                    </Button>
                                </ButtonWithConfirmAlert>
                            </td>
                        </tr>
                    </table>
                </CardHeader>
                <CardBody className="col-md-9 m-auto pb-5">
                    <Label>Ime in priimek*</Label>
                    <FormGroup className={UserStore.status.fullName}>
                        <Input type="text"
						       name="fullName"
						       value={UserStore.fullName}
						       onChange={UserStore.handleChange}
						       onBlur={() => UserStore.validateField("fullName")}
						       autoComplete="off"/>
                        {UserStore.status.fullName === "has-danger" &&
                        <label className="error">
                            Ime in priimek sta obvezna
                        </label>}
                    </FormGroup>
                    <Label>E-mail*</Label>
                    <FormGroup className={UserStore.status.email}>
                        <Input type="text"
						       name="email"
						       value={UserStore.email}
						       onChange={UserStore.handleChange}
						       onBlur={() => UserStore.validateField("email")}
						       autoComplete="off"/>
                        {UserStore.status.email === "has-danger" &&
                        <label className="error">
                            Email ni veljaven
                        </label>}
                    </FormGroup>
                    <FormGroup className="mt-3" check>
                        <Label check>
                            <Input checked={UserStore.isChangingPassword} type="checkbox"
							       onChange={event => UserStore.setChangingPassword(event.target.checked)}/>
							Spremeni geslo
                            <span className="form-check-sign"/>
                        </Label>
                    </FormGroup>
                    {(UserStore.isChangingPassword) &&
                    <>
                        <Label>{UserStore.isEditing ? "Novo geslo*" : "Geslo*"}</Label>
                        <FormGroup className={UserStore.status.password}>
                            <Input type="password"
                                name="password"
                                onChange={UserStore.handleChange}
                                onBlur={() => UserStore.validateField("password")}
                                autoComplete="new-password"
                                value={UserStore.password}
                            />
                            {UserStore.status.password === "has-danger" &&
                            <label className="error">
                                Geslo mora imeti vsaj 8 znakov
                            </label>}
                        </FormGroup>
                        <Label>Ponovi geslo*</Label>
                        <FormGroup className={UserStore.status.repeatedPassword}>
                            <Input type="password"
                                name="repeatedPassword"
                                onChange={UserStore.handleChange}
                                onBlur={() => UserStore.validateField("repeatedPassword")}
                                autoComplete="off"
                                value={UserStore.repeatedPassword}/>
                            {UserStore.status.repeatedPassword === "has-danger" &&
                            <label className="error">
                                Gesli se ne ujemata
                            </label>}
                        </FormGroup>
                    </>
                    }
                    <Button disabled={!UserStore.canSubmitProfile} onClick={postUser} className="btb btn-info mt-4">Potrdi
						spremembe</Button>
                    <hr/>
                    <CardTitle tag="h3" className="text-center mt-4">Tip uporabnika</CardTitle>
	                <FormTable headers={headersUserRoles} data={userRoles} isLoading={AuthStore.isLoading} minRows={1} />
                    <CardTitle tag="h3" className="text-center mt-4">Pravice za uporabo modulov</CardTitle>
	                <FormTable headers={headersModulePermissions} data={MedModulesStore.modules} isLoading={MedModulesStore.isLoading} minRows={1} />
                </CardBody>
            </Card>
        </React.Fragment>
    );
});

export default UserProfile;