import React from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PropTypes from "prop-types";
import {observer} from "mobx-react-lite";

const RowItem = styled.div`
  border-bottom: 1px solid #C0C0C0;
  margin-bottom: 0px;
  background-color: white;
  display: flex;
  display: grid;
  column-gap: 10px;
  align-items: center;
  -webkit-tap-highlight-color: #C0C0C0 !important;
  outline: none;
  ${props => props.dark &&`
    background: rgba(0, 0, 0, 0.03);
  `}
  ${props => props.header &&`
    border-top: 1px solid #C0C0C0;
  `}
   grid-template-columns: ${(props) => props.columns ? props.columns: "5fr 25fr 25fr 15fr 15fr 13fr auto"};
   overflow-wrap: anywhere;
`;

const HeaderItem = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 12px;
  overflow-wrap: anywhere;
`;

const ColumnItem = styled.div`
  padding: 12px;
`;

const ScrollableWrapper = styled.div`
    overflow-x: auto;
    ::-webkit-scrollbar-track
    {
        border-radius: 8px;
        background-color: #f4f3ef;
    }
    
    ::-webkit-scrollbar
    {
        height: 8px;
    }
    
    ::-webkit-scrollbar-thumb
    {
        border-radius: 8px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
        background-color: #c8c7c6;
    }
`;

const TableWrapper = styled.div`
   min-width: ${(props) => props.minWidth ? props.minWidth: "900px"};
`;


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

function Row({ rowData, headers, draggable }) {
    return (
            <>
                {draggable ?
                    <ColumnItem className="d-flex flex-column mr-2 justify-content-center">
                        <i className="nc-icon nc-simple-delete" style={{marginBottom: "-5px"}} />
                        <i className="nc-icon nc-simple-delete" style={{marginTop: "-5px"}} />
                    </ColumnItem> : <div/>

                }
                {headers.map((header) =>{
                    const accessors = header.accessor ? header.accessor.split(".") : "";
                    if(!rowData) return;
                    let value = rowData[header.accessor];
                    if(accessors.length > 1){
                        value = rowData[accessors[0]][accessors[1]];
                    }
                    if(header.Cell){
                        value = header.Cell({original: rowData});
                    }
                    return <ColumnItem  key={header.Header} style={{pointer:"default !important"}}>{value}</ColumnItem>;
                })}
            </>
    );
}

const DraggableRow = observer(({ rowData, index, headers, isDragDisabled, columnsTemplate}) =>{

    return (
        <Draggable draggableId={rowData.id} index={index} isDragDisabled={isDragDisabled}>
            {provided => (
                <RowItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    dark={index % 2 === 0}
                    columns={columnsTemplate}
                >
                    <Row rowData={rowData} headers={headers} draggable={true}/>
                </RowItem>
            )}
        </Draggable>
    );
});

const List = React.memo(function list({ listData, headers, isDragDisabled, columnsTemplate }) {
    return listData.map((data, index) => (
        <DraggableRow rowData={data} index={index} key={data.id} headers={headers} isDragDisabled={isDragDisabled} columnsTemplate={columnsTemplate}/>
    ));
});

const SortableTable = observer(({data, setData, headers, isDragDisabled, columnsTemplate, minWidth}) => {
    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const newData = reorder(
            data,
            result.source.index,
            result.destination.index
        );
        setData(newData);
    }

    return (
        <ScrollableWrapper>
            <TableWrapper minWidth={minWidth}>
                <RowItem header={true} columns={columnsTemplate}>
                    <div/>
                    {headers.map((header) =>
                        <HeaderItem key={header.Header}>{header.Header}</HeaderItem>
                    )}
                </RowItem>
                {data.length === 1 &&
                    <RowItem dark={true} columns={columnsTemplate}>
                        <Row rowData={data[0]} headers={headers}  draggable={false}/>
                    </RowItem>
                }
                {data.length > 1 &&
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="list">
                            {provided => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    <List listData={data} headers={headers} isDragDisabled={isDragDisabled} columnsTemplate={columnsTemplate}/>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                }
            </TableWrapper>
        </ScrollableWrapper>
    );
});

Row.propTypes = {
    rowData: PropTypes.object,
    headers: PropTypes.array,
    draggable: PropTypes.bool,
};

DraggableRow.propTypes = {
    rowData: PropTypes.object,
    index: PropTypes.number,
    headers: PropTypes.array,
    isDragDisabled:PropTypes.bool,
    columnsTemplate: PropTypes.string,
};

List.propTypes = {
    listData: PropTypes.array,
    headers: PropTypes.array,
    isDragDisabled:PropTypes.bool,
    columnsTemplate: PropTypes.string,
};

SortableTable.propTypes = {
    data: PropTypes.array,
    setData: PropTypes.func,
    headers: PropTypes.array,
    isDragDisabled:PropTypes.bool,
    columnsTemplate: PropTypes.string,
};

export default SortableTable;