import React from "react";
import {FormGroup, Input, Label} from "reactstrap";
import PropTypes from "prop-types";

const DynamicFormSlider = (props) => {
    const handleChange = (event) => {
        const {value} = event.target;
        props.handleChange(props.index, {id: props.id, value: value}, props.readingType);
    };

    return (
        <div>
            <Label>{props.title} {props.mandatory && "*"}</Label>
            <FormGroup className={props.validationStatus}>
                <div style={{textAlign: 'center'}} class="slidecontainer">
                    {props.value}
                    <Label style={{float:'left'}}>{props.sliderOptions.minLabel} ({props.sliderOptions.minValue})</Label>
                    <Label style={{float:'right'}}>{props.sliderOptions.maxLabel} ({props.sliderOptions.maxValue})</Label>
                    <Input type="range"
                        min={props.sliderOptions.minValue}
                        max={props.sliderOptions.maxValue}
                        value={props.value}
                        step={props.sliderOptions.step}
                        onChange={(event) => handleChange(event)}
                        class="slider">
                    </Input>
                </div>
            </FormGroup>
        </div>
    );
};

DynamicFormSlider.propTypes = {
    handleChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    readingType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mandatory: PropTypes.bool,
    validationStatus: PropTypes.string,
    validateField: PropTypes.func
};

export default DynamicFormSlider;
