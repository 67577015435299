import React, {useEffect, useRef} from "react";
import {Col, Row} from "reactstrap";
import UserInfo from "components/UserInfo";
import UserDiseaseStatus from "components/UserDiseaseStatus";
import UserVitalsInfo from "components/UserVitalsInfo";
import {observer} from "mobx-react-lite";
import PatientProfileStore from "stores/PatientProfileStore";
import ProfileNotifications from "components/ProfileNotifications";
import PatientSchedule from "components/PatientSchedule";
import Loader from "components/Loader";
import NotificationsStore from "stores/notifications/NotificationsStore";
import PatientAnamnesisRecord from "components/patientProfile/PatientAnamnesisRecord";
import PatientProfileReadingDateTimePicker from "components/PatientProfileReadingDateTimePicker";
import SidebarStore from "stores/common/SidebarStore";
import ModulePicker from "components/ModulePicker";
import {getModuleId} from "variables/fetchHelper";
import {hist} from "index";
import ModuleAssigner from "components/ModuleAssigner";
import AssignPatientsToModuleStore from "stores/AssignPatientsToModuleStore";

const PatientProfile = observer((props) => {
    const id = props.match.params.id;
    const moduleId = getModuleId(hist);

    useEffect(() => {
        fetchPatientInfoData(id);
        if(moduleId){
            fetchPatientLatestReadings(id);
            fetchPatientLatestPrimaryRecords(id);
        }
        SidebarStore.closeSidebar();
        return () =>  SidebarStore.openSidebar();
    }, []);

    const fetchPatientInfoData = async (id) => {
        return await PatientProfileStore.getPatientProfile(id);
    };

    const fetchPatientLatestReadings = async (id) => {
        return await PatientProfileStore.getLatestReadingRecords(id);
    };

    const fetchPatientLatestPrimaryRecords = async (id) => {
        await PatientProfileStore.getLast10RecordsForPrimaryReadings(id, onError);
    };

    const notificationAlert = useRef();

    const onError = (errorMessage) => {
        const options = {
            place: "tc",
            message: (
                <div>
                    <div>
                        {errorMessage}
                    </div>
                </div>
            ),
            type: "danger",
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 7,
        };
        notificationAlert.current.notificationAlert(options);
    };

    return (
        <React.Fragment>
            <Loader isLoading={PatientProfileStore.isLoading || NotificationsStore.isLoading || AssignPatientsToModuleStore.isLoading}/>
            <Row>
                <Col md="4">
                    <UserInfo moduleId={moduleId} patient={PatientProfileStore.patient} patientId={id}/>
                    <PatientSchedule
                        moduleId={moduleId}
                        profileId={id}/>
                    <ProfileNotifications
                        userId={PatientProfileStore.patient.id}
                        userName={PatientProfileStore.patient.firstName}/>
                </Col>
                <Col md="8">
                    <ModulePicker patientId={id}/>
                    {!moduleId && <ModuleAssigner patient={PatientProfileStore.patient}/>}
                    {moduleId &&
                        <>
                            <PatientProfileReadingDateTimePicker patientId={id}/>
                            <UserDiseaseStatus
                                patientInfo={PatientProfileStore.patient}
                                onError={onError}/>
                        </>
                    }
                </Col>
            </Row>
            {moduleId &&
                <>
                    <Row>
                        <Col>
                            <PatientAnamnesisRecord/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <UserVitalsInfo patientId={id}/>
                        </Col>
                    </Row>
                </>
            }
        </React.Fragment>
    );
});

export default PatientProfile;
