import {getSloRole} from "../enums/ERole";

export const customStringSort = (a, b) => {
    if (!a)
        return 1;
    if (!b)
        return -1;
    return a.localeCompare(b);
};

export const customArrayStringSort = (a, b) => {
    if (!a)
        return 1;
    if (!b)
        return -1;
    return a.map(r => getSloRole(r)).sort().join(", ").localeCompare(b.map(r => getSloRole(r)).sort().join(", "));
};

export const customStringSortForObject = (a, b, accessor) => {
    if (!a)
        return 1;
    if (!b)
        return -1;
    if (!a[accessor])
        return 1;
    if (!b[accessor])
        return -1;
    if (a.removed)
        return 1;
    if (b.removed)
        return -1;
    return a[accessor].localeCompare(b[accessor]);
};

export const customNumberSorting = (a, b) => {
    return Number(a)-Number(b);
};

export const customBooleanSort = (a, b) => {
    return (a === b) ? 0 : a ? -1 : 1;
};
