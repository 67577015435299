import React, {useEffect} from "react";
import MedModuleStore from "../../stores/MedModuleStore";
import {observer} from "mobx-react-lite";
import {Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label} from "reactstrap";
import { calculationTypes } from "enums/CalculationType";
import Select from "react-select";
import Loader from "../../components/Loader";
import {hist} from "../../index";
import {getModuleId} from "../../variables/fetchHelper";

const store = MedModuleStore;

const MedModuleCalculationTypeForm = observer((props) => {
    const id = getModuleId(hist);

    useEffect(() => {
        if (id) store.get(id);
        return () => store.clear();
    }, []);

    return (
        <Card className="col-md-10 mx-auto">
            <Loader isLoading={store.isLoading}/>
            <CardHeader>
                <CardTitle tag="h3" className="text-center">Uredi tip izračuna vitalnih znakov</CardTitle>
            </CardHeader>
            <CardBody className="col-md-9 m-auto">
                <Label>Tip izračuna</Label>
                <Select
                    className="react-select primary mb-2"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    options={calculationTypes}
                    placeholder="Tip izračuna"
                    defaultValue={calculationTypes[0]}
                    value={store.primaryCalculationType}
                    onChange={(event) => { store.primaryCalculationType = calculationTypes.find(ty => ty.value == event.value); }}
                />

                <br />

                <Button disabled={!store.canSubmit} className="btb btn-info mt-4"
                    onClick={() => store.submit()}>Potrdi</Button>
            </CardBody>
        </Card>
    );
});

export default MedModuleCalculationTypeForm;
