import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import {Button, Card, CardBody, CardHeader, CardTitle, FormGroup} from "reactstrap";
import EPermission from "enums/EPermission";
import {hist} from "index";
import {IMatch} from "interfaces/IMatch";
import AssignUserToModuleStore from "stores/AssignUserToModuleStore";
import AdminNavbarStore from "stores/common/AdminNavbarStore";
import MedModulesStore from "stores/MedModulesStore";
import UsersForModuleStore from "stores/UsersForModuleStore";
import {getModuleId} from "variables/fetchHelper";
import {getGoBackPath} from "variables/goBackHelper";
import Loader from "components/Loader";
import PermissionCheckbox from "components/PermissionCheckbox";

interface IProps {
	match: IMatch,
}

const PermissionsForm = observer(({match}: IProps) => {
    const moduleId = getModuleId(hist);
    const userId = parseInt(match.params.id);

    useEffect(() => {
        fetch();
        return () => AssignUserToModuleStore.clear();
    }, []);

    const fetch = async () => {
        if (UsersForModuleStore.users?.length == 0)
            await UsersForModuleStore.getUsersForModule();
        let user = UsersForModuleStore.getUserWithPermissions(userId);
        AssignUserToModuleStore.setUser(user);
    };

    const submitPermissions = async () => {
        const result = await AssignUserToModuleStore.assignUser();
        if (result) AdminNavbarStore.redirectWithoutWarning(getGoBackPath());
    };

    return (
        <Card className="col-md-7 mx-auto">
            <Loader isLoading={UsersForModuleStore.isLoading || AssignUserToModuleStore.isLoading}/>
            <CardHeader>
                <CardTitle tag="h5">Spremeni pravice</CardTitle>
            </CardHeader>
            <CardBody>
                <FormGroup check className="mb-3">
                    <PermissionCheckbox
                        checkboxPermission={EPermission.CAN_USE}
                        isChecked={AssignUserToModuleStore.canUse || AssignUserToModuleStore.canEdit || AssignUserToModuleStore.canShare}
                        disabled={AssignUserToModuleStore.canEdit || AssignUserToModuleStore.canShare}
                    />
                    <PermissionCheckbox
                        checkboxPermission={EPermission.CAN_EDIT}
                        isChecked={AssignUserToModuleStore.canEdit || AssignUserToModuleStore.canShare}
                        disabled={AssignUserToModuleStore.canShare}
                    />
                    {MedModulesStore.hasPermissionForModule(moduleId, EPermission.OWNER) &&
                    <PermissionCheckbox
                        checkboxPermission={EPermission.CAN_SHARE}
                        isChecked={AssignUserToModuleStore.canShare}
                    />
                    }
                </FormGroup>
                <Button
                    color="primary"
                    disabled={!AssignUserToModuleStore.canUpdate}
                    onClick={submitPermissions}
                >Potrdi</Button>
            </CardBody>
        </Card>
    );
});

export default PermissionsForm;