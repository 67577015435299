import {action, computed, observable} from "mobx";
import {getAllRoutes} from "../../routes";
import {hist} from "index";
import {ICurrentRoute} from "../../interfaces/ICurrentRoute";

/**
 * Used to determine which routes should display a warning if part of the form is edited
 * in routes.ts set the property "confirmBackStore" with a Mobx store that has a bool property "shouldShowWarningOnBackNavigation"
 * if that property is false this will show a popup a warning on AdminNavbar back button
 */
class AdminNavbarStore {
    @observable currentRoute: ICurrentRoute | undefined;
    @observable ignoreWarning: boolean = false;

    /**
     * Returns whether clicking back in @class {AdminNavbar} should show a pupup
     */
    @computed
    get shouldShowWarningOnBack(): boolean {
        if(this.ignoreWarning) return false;
        if (this.currentRoute?.confirmBackStore) {
            return this.currentRoute?.confirmBackStore.shouldShowWarningOnBackNavigation;
        } else {
            return false;
        }
    }

    @action
    updateLocation() {
        this.currentRoute = getAllRoutes().find(route => route.regex.test(hist.location.pathname));
    }

    @action
    redirectWithoutWarning(route: string | undefined) {
        if(!route) return;
        this.ignoreWarning = true;
        hist.push(route);
        this.ignoreWarning = false;
    }
}

/**
 * Used to determine which routes should display a warning if part of the form is edited
 * in routes.ts set the property "confirmBackStore" with a Mobx store that has a bool property "shouldShowWarningOnBackNavigation"
 * if that property is false this will show a popup a warning on AdminNavbar back button
 */
export default new AdminNavbarStore();
