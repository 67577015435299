import React from "react";
import Button from "reactstrap/lib/Button";
import styled from "styled-components";

const CustomPagination = styled.div`
    display: flex;
    justify-content: space-between;
`;

interface IProps {
   store: any,
}

const Pagination = ({store}:IProps) => {
    const nextPage = async () => {
        await store.getNextPage();
    };

    const previousPage = async () => {
        await store.getPreviousPage()
    };

    return (
        <CustomPagination>
            <Button disabled={store && store.isFirstPage} className="btn-info" onClick={previousPage}>Nazaj</Button>
            <Button disabled={store && store.isLastPage} className="btn-info" onClick={nextPage}>Naprej</Button>
        </CustomPagination>
    );
};


export default Pagination;