import React from "react";
import {Col, Form} from "reactstrap";
import DynamicFormInput from "../../components/dynamicForm/DynamicFormInput";
import ReadingsStore from "../../stores/ReadingsStore";
import {Observer} from "mobx-react-lite";
import {getReadingMaxInputLength} from "../../enums/vitals";
import Loader from "../../components/Loader";

const readingsTypes = ["mainReadings", "primaryReadings"];

export class PatientRegistrationVitals extends React.Component {
    isValidated = () => {
        const status = ReadingsStore.isValid;
        if (!status) {
            ReadingsStore.validateAll();
        }
        return status;
    };

    render() {
        return (
            <Observer>{() =>
                <Col md={9} style={{"margin": "auto"}}>
                    <Loader isLoading={ReadingsStore.isLoading} />
                    <h5 className="formTitle firstTitle">ZDRAVSTVENI PARAMETRI</h5>
                    <Form>{
                        readingsTypes.map(type => ReadingsStore[type].map((question, index) => <DynamicFormInput
                            key={question.id}
                            {...question}
                            handleChange={ReadingsStore.handleChange}
                            index={index}
                            name={question.title}
                            readingType={type}
                            validationStatus={question.validationStatus}
                            validateField={ReadingsStore.validateField}
                            value={ReadingsStore[type][index].value}
                            maxlength={getReadingMaxInputLength(question)}
                            minImages={question.minImages}
                            maxImages={question.maxImages}
                        />))
                    }

                    {
                        ReadingsStore.secondaryReadings.map((section, index) => 
                            <div>
                                <p>{index+1}. Sekcija</p>
                                <p>{section.text}</p>
                                <p>{section.subText}</p>
                                { section.readings.map((question, index) => <DynamicFormInput
                                    key={question.id}
                                    {...question}
                                    handleChange={ReadingsStore.handleChange}
                                    index={index}
                                    name={question.title}
                                    readingType={"secondaryReadings"}
                                    validationStatus={question.validationStatus}
                                    validateField={ReadingsStore.validateField}
                                    value={question.value}
                                    maxlength={getReadingMaxInputLength(question)}
                                    minImages={question.minImages}
                                    maxImages={question.maxImages}
                                />)
                                }
                            </div>
                        )
                    }                        
                    </Form>
                </Col>
            }</Observer>
        );
    }
}
