import {hist} from "../index";
import {getModuleId} from "../variables/fetchHelper";
import ResettableStore from "./ResettableStore";
import {action, computed, observable, runInAction} from "mobx";
import {GET_READINGS, POST_PATIENT_READINGS} from "../config";
import httpService from "../services/httpService";
import ReadingsStore from "./ReadingsStore";
import {selectReadingType} from "../variables/selectHelper";
import {
    cleanQuestionValue,
    handleChangeForNonArray, isAnswerValid,
    mapQuestionToRecord,
    validateQuestion,
} from "./CommonAnamnesisReadings";
import {v4 as uuidv4} from "uuid";

// TODO use interface from MYM-503
interface IMainReading {
    id?: string,
    title?: string,
    description?: string,
    descMedia?: {
        anim: string,
        img: string,
        thumb: string
    },
    statusText?: string,
    type?: IReadingType,
    icon?: string,
    unit?: string,
    mandatory?: boolean,
    multiStateOptions?:
        {
            id: string,
            value: string,
            color: string,
            label?: string
        }[],
    value?: string|string[]
}

interface IReadingType {
    value: number,
    label: string
}

class MainReadingsStore extends ResettableStore {
    @observable reading: IMainReading = {}
    @observable error = null;
    @observable isLoading = false;
    @observable validationStatus = "";

    constructor() {
        super();

        this.setInitialState()
    }

    @computed
    get isSubmittable() {
        if(this.reading?.id) return isAnswerValid(this.reading)
    }

    @action
    async getMainReadings() {
        this.isLoading = true;
        const options = {
            method: "GET",
            url: GET_READINGS + "/" + getModuleId(hist),
        };

        try {
            const response = await httpService.fetch(
                options,
            ) as { mainReading: IMainReading }

            ReadingsStore.setReadings(response);
            const mappedResponse = this.mapReadingsFromServer(response);

            runInAction(() => {
                this.reading = mappedResponse
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
                this.isLoading = false;
            });
        }
    }

    mapReadingsFromServer = (response: { mainReading: IMainReading }): IMainReading => {
        return {
            id: response.mainReading.id,
            title: response.mainReading.title,
            type: selectReadingType(response.mainReading.type)!,
            statusText: response.mainReading.statusText,
            mandatory: response.mainReading.mandatory,
            multiStateOptions: response.mainReading.multiStateOptions?.map(state => {
                return {
                    id: state.id,
                    value: state.value,
                    label: state.value,
                    color: state.color
                };
            })
        };
    };

    @action
    async postReading(id: string) {
        this.isLoading = true;

        let patientReadings = this.mapPatientReadingsForServer(id);
      
        let formData = new FormData();
        formData.append('data', `${JSON.stringify(patientReadings)}`);

        const options = {
            method: "POST",
            url: POST_PATIENT_READINGS,
            body: formData,
            contentType: "multipart/form-data",
        };

        try {
            await httpService.fetch(options);
            runInAction(() => {
                this.reading.value = undefined
                this.isLoading = false;
            })
            return true
        } catch (err) {
            runInAction(() => {
                this.error = err;
                this.isLoading = false;
            });
            return false
        }
    }

    @action
    handleChange = (index: number, id: string) => {
        handleChangeForNonArray(this, "reading", id);
    }

    mapPatientReadingsForServer(id: string) {
        return {
            userId: id,
            moduleId: getModuleId(hist),
            recordReadingId: uuidv4(),
            records: [mapQuestionToRecord(cleanQuestionValue(this.reading))]
        };
    };

    @action
    validateField = () => {
        this.validationStatus = validateQuestion(this.reading);
    };

    @computed
    get shouldShowWarningOnBackNavigation() {
        return !!this.reading.value;
    }
}


export default new MainReadingsStore();
