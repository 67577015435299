import React from "react";
import {Col, Container, Row} from "reactstrap";
import {Observer} from "mobx-react-lite";
import Loader from "../components/Loader";
import AuthStore from "../stores/AuthStore";

class LockScreen extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("register-page");
    }

    componentWillUnmount() {
        document.body.classList.toggle("register-page");
    }

    render() {
        return (
            <Observer>{() =>
                <div className="register-page">
                    <Loader isLoading={AuthStore.isLoading} isDark={true} />
                    <Container>
                        <Row>
                            <Col className="ml-auto" lg="5" md="5">
                                <div className="info-area info-horizontal mt-5">
                                    <div className="icon icon-primary">
                                        <i className="nc-icon nc-tv-2"/>
                                    </div>
                                    <div className="description">
                                        <h5 className="info-title">Spremljanje</h5>
                                        <p className="description">
                                            Spremljate informacije o pacientih in njihovih meritvah
                                        </p>
                                    </div>
                                </div>
                                <div className="info-area info-horizontal">
                                    <div className="icon icon-primary">
                                        <i className="nc-icon nc-html5"/>
                                    </div>
                                    <div className="description">
                                        <h5 className="info-title">Ukrepajte</h5>
                                        <p className="description">
                                            Izvajajte ukrepe glede na ponujene informacije
                                        </p>
                                    </div>
                                </div>
                                <div className="info-area info-horizontal">
                                    <div className="icon icon-info">
                                        <i className="nc-icon nc-atom"/>
                                    </div>
                                    <div className="description">
                                        <h5 className="info-title">Združujemo</h5>
                                        <p className="description">
                                            Združujemo vse paciente Slovenije v enoten sistem
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="mr-auto" lg="4" md="6">
                                {this.props.children}
                            </Col>
                        </Row>
                    </Container>
                    <div
                        className="full-page-background"
                        style={{
                            backgroundImage: `url(${require("assets/img/bg/jan-sendereks.jpg")})`
                        }}
                    />
                </div>
            }</Observer>
        );
    }
}

export default LockScreen;