import React, {useEffect} from "react";
import NotificationsStore from "../stores/notifications/NotificationsStore";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import {localDateTimeFormat} from "../variables/dateHelpers";
import PaginationTable from "./Pagination/PaginationTable";
import ButtonWithModal from "./ButtonWithModal";
import {cellStyle} from "../variables/styles";
import {pageFromList} from "../variables/paginationHelpers";
import {getModuleId} from "../variables/fetchHelper";
import {hist} from "../index";

const tableHeaders = [
    {
        Header: "Datum",
        accessor: "created",
        style: cellStyle,
        Cell: props => <span>{localDateTimeFormat(props.value)}</span>,

    },
    {
        Header: "Zadeva",
        accessor: "topic",
        style: cellStyle,
        sortable: false,
    },
    {
        Header: "Uporabniki",
        accessor: "user",
        sortable: false,
        width: 250,
        style: {...cellStyle, whiteSpace: "unset"},
        Cell: props => props.value ?
            <React.Fragment>
                {props.value.removed ? "Uporabnik je bil odstranjen"
                    : getModuleId(hist)
                        ? <Link to={`/module/${getModuleId(hist)}/patients/${props.value.userId}`} style={{color: "#007bff"}}>
                            {`${props.value.healthId}
                        ${props.value.firstName ? ` - ${props.value.firstName}` : ""}
                        ${props.value.lastName ? ` ${props.value.lastName}` : ""}
                        `}
                        </Link>
                        : <Link to={`/patients/${props.value.userId}`} style={{color: "#007bff"}}>
                            {`${props.value.healthId}
                            ${props.value.firstName ? ` - ${props.value.firstName}` : ""}
                            ${props.value.lastName ? ` ${props.value.lastName}` : ""}
                            `}
                        </Link>
                }
            </React.Fragment>
            : null,
    },
    {
        Header: "Naslov",
        accessor: "title",
        style: cellStyle,
        minWidth: 120,
        sortable: false,
    },
    {
        Header: "Vsebina",
        accessor: "body",
        Cell: props =>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                <div style={{
                    maxWidth: "150px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                }}>{props.original.body}</div>
                <ButtonWithModal title={props.original.title} text={props.original.body}>
                    <i className="nc-icon nc-single-copy-04 mx-3" style={{cursor: "pointer"}}/>
                </ButtonWithModal>
            </div>,
        style: {justifyContent: "start"},
        width: 200,
        sortable: false,
    },
];

const NotificationsList = observer(() => {

    useEffect(() => {
        fetchData();
        return () => NotificationsStore.clear();
    }, []);

    const fetchData = async (query, count, page, order) => {
        return await NotificationsStore.getNotifications(query, count, page, order);
    };

    return (
        <PaginationTable
            title={"Seznam obvestil"}
            tableHeaders={tableHeaders}
            isLoading={NotificationsStore.isLoading}
            data={pageFromList(NotificationsStore.notifications)}
            store={NotificationsStore}
            fetchData={fetchData}
            hideSearch={getModuleId(hist)}
        />
    );
});

export default NotificationsList;
