import {action, computed, observable, runInAction} from "mobx";
import isEqual from "lodash/isEqual";
import {sortBy} from "lodash";
import {TEMPLATES, UPLOAD_ANIMATION, UPLOAD_IMAGE_AND_CREATE_THUMBNAIL} from "../config";
import httpService from "services/httpService";
import ResettableStore from "./ResettableStore";
import {ReadingType, readingTypes} from "components/readingTypes";
import {readingSensorTypes} from "../enums/SensorType";
import {v4 as uuidv4} from "uuid";
import moment from "moment";

class ReadingStore extends ResettableStore {
    IMAGE = "img";
    ANIMATION = "anim";
    @observable error = null;
    @observable isEditing = false;
    @observable loadingRequests = [];

    @observable id = "";
    @observable title = "";
    @observable description = "";
    @observable statusText = "";
    @observable type = "";
    @observable mandatory = true;
    @observable multiStateOptions = [];
    @observable multipleSelectionOptions = [];
    @observable addDescMedia = false;
    @observable descMedia = this.IMAGE;
    @observable img = null;
    @observable imgFile = null;
    @observable anim = null;
    @observable animFile = null;
    @observable animData = null;
    @observable thumb = null;
    @observable minImages = 1;
    @observable maxImages = 3;
    @observable readingTypes = [];
    @observable templateId = null;
    @observable sensorType = readingSensorTypes[0];

    @observable serverCopy = {
        title: "",
        description: "",
        statusText: "",
        type: "",
        mandatory: "",
        multiStateOptions: [],
        multipleSelectionOptions: [],
        addDescMedia: false,
        sensorType: ""
    };

    @observable webLimits = {
        a: {
            minValue: '',
            maxValue: ''
        },
        b: {
            minValue: '',
            maxValue: ''
        }
    }

    defaultLimits = {
        a: {
            minValue: '',
            maxValue: ''
        },
        b: {
            minValue: '',
            maxValue: ''
        }
    }

    @observable sliderOptions = this.defaultSliderOptions

    defaultSliderOptions = {
        step: 1,
        minLabel: '',
        maxLabel: '',
        minValue: 0,
        maxValue: 10
    }

    constructor() {
        super();

        this.setInitialState();
    }

    getLimits() {
        return this.webLimits;
    }

    fetch(options) {
        const promise = httpService.fetch(
            options,
        );
        this.loadingRequests.push(promise);
        return promise;
    }

    requestFinished(promise) {
        this.loadingRequests.splice(this.loadingRequests.indexOf(promise),1);
    }

    @computed
    get isLoading() {
        return this.loadingRequests.length > 0;
    };

    get isSameAsServerCopy() {
        if (this.title !== this.serverCopy.title) return false;
        if (this.description !== this.serverCopy.description) return false;
        if (this.statusText !== this.serverCopy.statusText) return false;
        if (!this.templateId && this.type.value !== this.serverCopy.type.value) return false;
        if (this.sensorType.value !== this.serverCopy.sensorType.value) return false;
        if (this.templateId !== this.serverCopy.templateId) return false;
        if (this.mandatory !== this.serverCopy.mandatory) return false;
        if (this.imgFile) return false;
        if (this.animFile) return false;
        if (this.addDescMedia !== this.serverCopy.addDescMedia) return false;
        if (!this.isMultiStateEqual(this.multiStateOptions, this.serverCopy.multiStateOptions)) return false;
        if (!this.isMultiStateEqual(this.multipleSelectionOptions, this.serverCopy.multipleSelectionOptions)) return false;
        if (this.minImages !== this.serverCopy.minImages || this.maxImages !== this.serverCopy.maxImages) return false;
        if (!this.isLimitEqual(this.webLimits, this.serverCopy.webLimits)) return false;
        if (!this.isSliderOptionEqual(this.sliderOptions, this.serverCopy.sliderOptions)) return false;
        return true;
    }

    isMultiStateEqual(arr1, arr2) {
        if (arr1.length === arr2.length)
        {
            for(let i=0; i<arr1.length; i++) {
                let cur1 = arr1[i];
                let cur2 = arr2[i];
                let res = false;

                if (cur1.value === cur2.value &&
                   (cur1.intValue == cur2.intValue || (cur1.intValue.trim() === '' && !cur2.intValue))
                   && cur1.color === cur2.color) {
                       res = true;
                }

                if (!res) {
                    return false;
                }
            }
        } else {
            return false;
        }

        return true;
    }

    isLimitEqual(limit1, limit2) {
        if (!limit1 && !limit2)
            return true;
        
        if (limit1 && limit2) {

            if (!limit1.a && !limit2.a)
                return true;

            if (limit1.a && limit2.a && limit1.a.minValue == limit2.a.minValue && limit1.a.maxValue == limit2.a.maxValue) {
                if (!limit1.b && !limit2.b)
                return true;

                if (limit1.b && limit2.b && limit1.b.minValue == limit2.b.minValue && limit1.b.maxValue == limit2.b.maxValue) {
                    return true;
                }
            } else return false;
        }

        return false;
    }

    isSliderOptionEqual(option1, option2) {

        if (this.type.value != ReadingType.SLIDER)
            return true;

        if (!option1 && !option2)
            return true;
        
        if (option1 && option2) {

            if (option1.step == option2.step && option1.maxValue == option2.maxValue &&
                option1.minValue == option2.minValue && option1.minLabel == option2.minLabel &&
                option1.maxLabel == option2.maxLabel)
                return true;
        }

        return false;
    }

    @computed
    get isValid() {
        if (!this.title) return false;
        else if (!this.type) return false;
        else if (this.addDescMedia && !this.imgFile && !this.img && !this.animFile && !this.anim) return false;
        else if (this.addDescMedia && this.descMedia === this.IMAGE && !this.img && !this.imgFile) return false;
        else if (this.addDescMedia && this.descMedia === this.ANIMATION && !this.animFile && !this.anim) return false;
        else if (this.type.value === ReadingType.MULTI_STATE && this.multiStateOptions.length === 0) return false;
        else if (this.type.value === ReadingType.LIKERT_SCALE_5PT && this.multiStateOptions.length === 0) return false;
        else if (this.multiStateOptions.filter((option) => !option.value || !option.color || (option.color.length < 7)).length !== 0) return false;
        else if (this.type.value === ReadingType.MULTIPLE_SELECTION && this.multipleSelectionOptions.length === 0) return false;
        else if (this.multipleSelectionOptions.filter((option) => !option.value).length !== 0) return false;
        else if (this.type.value === ReadingType.MULTI_IMAGE && this.maxImages === null || this.minImages === null || this.minImages.length === 0 || this.maxImages.length === 0) return false;
        else if (!this.validateLimits()) return false;
        else if (!this.validateSliderOptions()) return false;
        return true;
    }

    @computed
    get canSubmit() {
        if (this.isEditing) {
            return this.isValid && !this.isSameAsServerCopy;
        } else {
            return this.isValid;
        }
    }

    @action
    setEditing(reading) {
        if (!reading) return;
        this.isEditing = true;
        this.id = reading.id;
        this.title = reading.title;
        this.description = reading.description;
        this.statusText = reading.statusText;
        this.type = reading.type;
        this.sensorType = reading.sensorType;
        this.templateId = reading.templateId;
        this.mandatory = reading.mandatory;
        this.minImages = reading.minImages ? reading.minImages : 0;
        this.maxImages = reading.maxImages ? reading.maxImages : 0;

        if (reading.type.value === ReadingType.MULTI_STATE || reading.type.value === ReadingType.LIKERT_SCALE_5PT) this.multiStateOptions = reading.multiStateOptions;
        if (reading.type.value === ReadingType.MULTIPLE_SELECTION) this.multipleSelectionOptions = reading.multiStateOptions;
        this.serverCopy = {
            id: reading.id,
            title: reading.title,
            description: reading.description,
            statusText: reading.statusText,
            type: reading.type,
            sensorType: reading.sensorType,
            templateId: reading.templateId,
            mandatory: reading.mandatory,
            multiStateOptions: (reading.type.value === ReadingType.MULTI_STATE || reading.type.value === ReadingType.LIKERT_SCALE_5PT) ? JSON.parse(JSON.stringify(reading.multiStateOptions)) : [],
            multipleSelectionOptions: reading.type.value === ReadingType.MULTIPLE_SELECTION ? JSON.parse(JSON.stringify(reading.multiStateOptions)) : [],
            addDescMedia: !!reading.descMedia,
            minImages: reading.minImages ? reading.minImages : 0,
            maxImages: reading.maxImages ? reading.maxImages : 0,
            webLimits: reading.webLimits ? JSON.parse(JSON.stringify(reading.webLimits)) : this.defaultLimits,
            sliderOptions: reading.sliderOptions ? JSON.parse(JSON.stringify(reading.sliderOptions)) : this.defaultSliderOptions
        };
        this.addDescMedia = !!reading.descMedia && (!!reading.descMedia.img || !!reading.descMedia.anim);
        this.descMedia = reading.descMedia && reading.descMedia.anim ? this.ANIMATION : this.IMAGE;
        this.img = reading.descMedia && reading.descMedia.img ? reading.descMedia.img : null;
        this.anim = reading.descMedia && reading.descMedia.anim ? reading.descMedia.anim : null;
        this.thumb = reading.descMedia && reading.descMedia.thumb ? reading.descMedia.thumb : null;
        if(this.anim) this.getAnimation(this.anim);
        this.webLimits = reading.webLimits;
        this.sliderOptions = reading.sliderOptions;
    }

    @action
    getReading() {
        const descMedia = this.addDescMedia ? {
            img: this.descMedia === this.IMAGE ? this.img : null,
            thumb: this.descMedia === this.IMAGE ? this.thumb : null,
            anim: this.descMedia === this.ANIMATION ? this.anim : null,
        }: null;
        return {
            id: this.id,
            title: this.title,
            description: this.description,
            statusText: this.statusText,
            type: this.type,
            sensorType: this.sensorType,
            templateId: this.templateId,
            mandatory: this.mandatory,
            multiStateOptions: this.multiStateOptions,
            multipleSelectionOptions: this.multipleSelectionOptions,
            descMedia: descMedia,
            minImages: this.minImages,
            maxImages: this.maxImages,
            webLimits: this.webLimits,
            sliderOptions: this.sliderOptions
        };
    }

    @action
    getReadings = async (store) => {
        if(!store) return;
        const promise = store.getReadings();
        this.loadingRequests.push(promise);
        await promise;
        this.requestFinished(promise);
    };

    @action
    uploadFile = async (accessor, store) => {
        if(!store && accessor) return;
        if(this.addDescMedia && this.descMedia === this.IMAGE && this.imgFile) {
            await this.uploadImageAndCreateThumbnail(this.IMAGE);
        }
        if(this.addDescMedia && this.descMedia === this.ANIMATION && this.animFile) {
            await this.uploadAnimation(this.ANIMATION);
        }
    };

    @action
    addReading = async (accessor, store, sectionIndex = -1) => {
        await this.uploadFile(accessor, store);
        const promise = store.addReading(accessor, this.getReading(), sectionIndex);
        this.loadingRequests.push(promise);
        await promise;
        this.requestFinished(promise);
    };

    @action
    updateReading = async (accessor, store) => {
        await this.uploadFile(accessor, store);
        let read = this.getReading();
        const promise = store.updateReading(accessor, this.getReading());
        this.loadingRequests.push(promise);
        await promise;
        this.requestFinished(promise);
    };

    @action
    uploadImageAndCreateThumbnail = async () => {
        let formData = new FormData();
        formData.append("file",  this.imgFile);

        const options = {
            method: "POST",
            url: UPLOAD_IMAGE_AND_CREATE_THUMBNAIL,
            body: formData,
            contentType: "multipart/form-data",
        };

        const promise = this.fetch(options);
        try {
            const response = await promise;

            runInAction(() => {
                this.img = response.img;
                this.thumb = response.thumb;
                this.requestFinished(promise);
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
                this.requestFinished(promise);
            });
        }
    };

    @action
    getReadingTemplates = async () => {
        const options = {
            method: "GET",
            url: TEMPLATES
        };

        const promise = this.fetch(options);
        try {
            const response = await promise;

            runInAction(() => {
                this.readingTemplates = response;
                this.requestFinished(promise);
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
                this.requestFinished(promise);
            });
        }
    };

    @action
    uploadAnimation = async () => {
        let formData = new FormData();
        formData.append("file", this.animFile);

        const options = {
            method: "POST",
            url: UPLOAD_ANIMATION + "?type=Reading",
            body: formData,
            contentType: "multipart/form-data",
        };

        const promise = this.fetch(options);
        try {
            const response = await promise;

            runInAction(() => {
                this.anim = response;
                this.requestFinished(promise);
            });
        } catch (err) {
            runInAction(() => {
                this.error = err;
                this.requestFinished(promise);
            });
        }
    };

    @action
    getAnimation = async (url) => {
        const promise = fetch(url);
        this.loadingRequests.push(promise);
        try {
            const response = await promise;
            const data = await response.json();

            runInAction(() => {
                this.animData =  data;
                this.requestFinished(promise);
                return response;
            });
        } catch (err) {
            runInAction(() => {
                this.requestFinished(promise);
                this.error = err;
            });
        }
    };

    @action
    clearFiles() {
        this.imgFile = null;
        this.animFile = null;
        this.img = null;
        this.anim = null;
    }

    get isEmpty() {
        if(this.imgFile) return false;
        if(this.animFile) return false;
        if(!this.mandatory) return false;
        if(this.addDescMedia) return false;
        return !this.title
            && !this.description
            && !this.statusText
            && !this.type;
    }

    @computed
    get shouldShowWarningOnBackNavigation() {
        if(this.isEditing && !this.isSameAsServerCopy) return true;
        return !this.isEditing && !this.isEmpty;
    }

    @action
    addMultipleOption = () => {
        this.multipleSelectionOptions.push({
            id: "temp-id-" + uuidv4(),
            value: "",
        });
    };

    @action
    overrideMultipleSelection = (data) => {
        this.multipleSelectionOptions = data;
    };

    @action
    removeMultipleSelectOption = (id) => {
        this.multipleSelectionOptions = this.multipleSelectionOptions.filter(item => item.id !== id);
    };

    @action
    changeReadingType(event) {
        
        this.type = this.readingTypes.find(ty => ty.value == event.value);

        if (this.type.isTemplate) {
            this.templateId = this.type.value.replace('tmpl_', '');

            let temp = this.readingTemplates.find(item => item.id == this.templateId);
            if (temp) {
                if (temp.readingType === ReadingType.MULTI_STATE || temp.readingType === ReadingType.LIKERT_SCALE_5PT) {
                    this.multiStateOptions = temp.items;
                }

                if (temp.readingType === ReadingType.MULTIPLE_SELECTION) {
                    this.multipleSelectionOptions = temp.items;
                }
            }
        } else {
            this.templateId = null;
        }

        if (this.type.value === ReadingType.LIKERT_SCALE_5PT && !this.type.isTemplate) {
            this.multiStateOptions = [];
            this.multiStateOptions.push({
                "id": "temp-id" + uuidv4(),
                "value": "Value 1",
                "color": "#000000",
                "intValue": "1"
            });
            this.multiStateOptions.push({
                "id": "temp-id" + uuidv4(),
                "value": "Value 2",
                "color": "#000000",
                "intValue": "2"
            });
            this.multiStateOptions.push({
                "id": "temp-id" + uuidv4(),
                "value": "Value 3",
                "color": "#000000",
                "intValue": "3"
            });
            this.multiStateOptions.push({
                "id": "temp-id" + uuidv4(),
                "value": "Value 4",
                "color": "#000000",
                "intValue": "4"
            });
            this.multiStateOptions.push({
                "id": "temp-id" + uuidv4(),
                "value": "Value 5",
                "color": "#000000",
                "intValue": "5"
            });
        }

        this.webLimits = this.defaultLimits;
    }

    @action
    changeSensorReadingType(event) {
        this.sensorType = readingSensorTypes.find(ty => ty.value == event.value);
    }

    @action
    changeLimitsValue(value, prop)
    {
        switch(prop) {
            case 'amin': {
                this.webLimits.a.minValue = value;
                break;
            }
            case 'amax': {
                this.webLimits.a.maxValue = value;
                break;
            }
            case 'bmin': {
                this.webLimits.b.minValue = value;
                break;
            }
            case 'bmax': {
                this.webLimits.b.maxValue = value;
                break;
            }
        }
    }

    validateLimits() {

        if (!this.webLimits)
            return true;

        if ((this.showALimit(this.type.value) || this.showDateLimit(this.type.value)) && this.webLimits.a) {
            return this.validateLimitPair(this.webLimits.a);
        }

        if (this.showBLimit(this.type.value) && this.webLimits.a && this.webLimits.b) {

            if (this.webLimits.a.minValue === '' && this.webLimits.a.maxValue === '' && this.webLimits.b.minValue === '' && this.webLimits.b.maxValue === '') {
                return true;
            }

            if (this.webLimits.a.minValue !== '' && this.webLimits.a.maxValue !== '' && this.webLimits.b.minValue !== '' && this.webLimits.b.maxValue !== '') {
                return this.validateLimitPair(this.webLimits.a) && this.validateLimitPair(this.webLimits.b);
            } return false;
        }

        return true;
    }

    validateLimitPair(pair) {
        if (pair.minValue === '' && pair.maxValue === '') {
            return true;
        }
        
        if (pair.minValue === '' || pair.maxValue === '') {
            return false;
        }

        if (this.type.value === ReadingType.DATE || this.type.value === ReadingType.DATE_TIME) {
            
            let minDate = moment(pair.minValue);
            let maxDate = moment(pair.maxValue);

            if (minDate.isAfter(maxDate) || minDate.isSame(maxDate)) {
                return false;
            }
        } else {

            let min = parseFloat(pair.minValue);
            let max = parseFloat(pair.maxValue);

            if (min >= max) {
                return false;
            }
        }

        return true;
    }

    validateSliderOptions() {
        if (this.type.value === ReadingType.SLIDER) {
            if (!this.sliderOptions) {
                return true;
            }

            if (this.sliderOptions.minLabel === '' || this.sliderOptions.maxLabel === '' ||
                this.sliderOptions.step === '' || this.sliderOptions.minValue === '' || this.sliderOptions.maxValue === '') {
                return false;
            }

            if (parseInt(this.sliderOptions.step) < 1)
                return false;
        }

        return true;
    }

    showLimits(type) {
        return this.showALimit(type) || this.showBLimit(type) || this.showDateLimit(type);
    }

    showALimit(type) {
        return (type === ReadingType.INT || type === ReadingType.BREATHING_FREQUENCY ||
            type === ReadingType.FLOAT || type === ReadingType.BODY_TEMPERATURE ||
            type === ReadingType.SATURATION || type === ReadingType.WEIGHT ||
            type === ReadingType.BLOOD_SUGAR || type === ReadingType.HEART_RATE);
    }

    showBLimit(type) {
        return (type === ReadingType.INT_PAIR || type === ReadingType.FLOAT_PAIR ||
            type === ReadingType.BLOOD_PRESSURE);
    }
    
    showDateLimit(type) {
        return (type === ReadingType.DATE || type === ReadingType.DATE_TIME);
    }

    getMomentDate(val) {
        if (val) {
            return moment(val);
        }

        return val;
    }
}

export default new ReadingStore();
