import React, {useEffect} from "react";
import TemplatesStore from "stores/templates/TemplatesStore";
import {observer} from "mobx-react-lite";
import {Card, CardBody, CardHeader, CardTitle, Button} from "reactstrap";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import ButtonWithConfirmAlert from "components/ButtonWithConfirmAlert";
import AuthStore from "stores/AuthStore";
import {hist} from "index";

const templateHeaders = [
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "Ime",
        accessor: "name",
    },
    {
        Header: "Tip",
        accessor: "readingType.label",
    },
    {
        Header: "Akcije",
        Cell: (props: any) =>
            <div>
                <Link to={`/template/${props.original.id}`}>
                    <i className="nc-icon nc-settings mr-3"/>
                </Link>
                <ButtonWithConfirmAlert successAction={() => TemplatesStore.removeTemplate(props.original.id)} text={"Ste prepričani da želite izbrisati predlogo?"}>
                    <i style={{cursor: "pointer"}} className="nc-icon nc-simple-remove text-danger mr-3"/>
                </ButtonWithConfirmAlert>
            </div>,
        sortable: false,
    },
];

const TemplatesList = observer(() => {

    useEffect(() => {
        TemplatesStore.getAllTemplates();
    }, []);

    return (
        <div>
            <Card>
                <CardHeader>
                    <CardTitle style={{float: "left"}} tag="h5">Seznam predlog</CardTitle>
                    {(AuthStore.hasAdminAccess || AuthStore.hasOrganizationAdminAccess || AuthStore.hasReadingTemplateAccess) &&
                        <Button
                            className="btn-round"
                            color="default"
                            outline
                            style={{float: "right", marginBottom: "0px", marginTop: "5px"}}
                            onClick={() => hist.push(`/add-template`)}
                        >
                            + Dodaj predlogo
                        </Button>
                    }
                </CardHeader>
                <CardBody>
                    <ReactTable
                        // ref={ref}
                        loading={TemplatesStore.isLoading}
                        data={TemplatesStore.templates}
                        resizable={false}
                        filterable={false}
                        sortable
                        columns={templateHeaders}
                        pageSize={TemplatesStore.templates.length}
                        showPagination={false}
                        minRows={TemplatesStore.templates && TemplatesStore.templates.length > 0 ? 1 : 3}
                        className="-striped -highlight primary-pagination"

                        // Text
                        previousText={"Nazaj"}
                        nextText={"Naprej"}
                        loadingText={"Nalagam..."}
                        noDataText={"Ni zapisov"}
                        pageText={"Stran"}
                        ofText={"od"}
                        rowsText={"vrstic"}
                    />
                </CardBody>
            </Card>
        </div>
    );
});

export default TemplatesList;