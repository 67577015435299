import React, {useEffect} from "react";
import SchedulesStore from "stores/SchedulesStore";
import {observer} from "mobx-react-lite";
import {Card, CardBody, CardHeader, CardTitle, Button} from "reactstrap";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import ButtonWithConfirmAlert from "components/ButtonWithConfirmAlert";
import {PAGE_COUNT} from "config";
import {cellStyle} from "variables/styles";
import {hist} from "index";
import {getModuleId} from "variables/fetchHelper";
import MedModulesStore from "stores/MedModulesStore";
import EPermission from "enums/EPermission";
import ModuleLinks from "components/ModuleLinks";

const tableHeaders = [
    {
        Header: "ID",
        accessor: "id",
        sortable: false,
        style: cellStyle,
    },
    {
        Header: "Naslov",
        accessor: "title",
        sortable: false,
        style: cellStyle,
        minWidth: 150,
    },
    {
        Header: "Opis",
        accessor: "text",
        sortable: false,
        style: cellStyle,
        minWidth: 150,
    },
    {
        Header: "Akcije",
        Cell: props =>
            <div>
                <Link to={`/module/${getModuleId(hist)}/templates/${props.original.id}`}>
                    <i className="nc-icon nc-settings mr-3"/>
                </Link>
                <ButtonWithConfirmAlert text={"Ste prepričani?"} successAction={async () => SchedulesStore.removeSchedule(getModuleId(hist), props.original.id)}>
                    <i style={{cursor: "pointer"}} className="nc-icon nc-simple-remove text-danger mr-3"/>
                </ButtonWithConfirmAlert>
            </div>,
        sortable: false,
    },
];

const SchedulesList = observer(() => {

    const moduleId = getModuleId(hist);

    useEffect(() => {
        fetchData("", PAGE_COUNT);
        return () => SchedulesStore.clear();
    }, []);

    const fetchData = async () => {
        await SchedulesStore.getSchedules(moduleId);
    };

    return (
        <div>
            <ModuleLinks tabPath={location.pathname} moduleId={moduleId}></ModuleLinks>
            <Card>
                <CardHeader>
                    <CardTitle style={{float: "left"}} tag="h5">Seznam urnikov</CardTitle>
                    {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_USE) &&
                        <Button
                            className="btn-round"
                            color="default"
                            outline
                            style={{float: "right", marginBottom: "0px", marginTop: "5px"}}
                            onClick={() => hist.push(`/module/${moduleId}/add-template`)}
                        >
                            + Dodaj urnik
                        </Button>
                    }
                </CardHeader>
                <CardBody>
                    <ReactTable
                        loading={SchedulesStore.isLoading}
                        data={SchedulesStore.schedules}
                        resizable={false}
                        filterable={false}
                        sortable={false}
                        columns={tableHeaders}
                        pageSize={SchedulesStore.schedules.length}
                        showPagination={false}
                        minRows={SchedulesStore.schedules && SchedulesStore.schedules.length > 0 ? 1 : 3}
                        className="-striped -highlight primary-pagination"
                        loadingText={"Nalagam..."}
                        noDataText={"Ni zapisov"}
                    />
                </CardBody>
            </Card>
        </div>
    );
});


export default SchedulesList;
