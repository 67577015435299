import React from "react";

import AuthNavbar from "../components/Navbars/AuthNavbar.jsx";

class Pages extends React.Component {
    render() {
        return (
      <>
        <AuthNavbar />
        <div className="wrapper wrapper-full-page" ref="fullPages">
            <div className="full-page section-image">
                {this.props.children}
            </div>
        </div>
      </>
        );
    }
}

export default Pages;
