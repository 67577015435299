import React, {useEffect, useRef} from 'react';
import RegistrationSettings from "./RegistrationSettings";
import SettingsStore from "../../stores/SettingsStore";
import {Col} from "reactstrap";
import ConfirmSettings from "./ConfirmSettings";
import DeviceVersionSettings from "./DeviceVersionSettings";
import {observer} from "mobx-react-lite";
import deviceType from "../../enums/deviceType";
import Loader from "../../components/Loader";

const Settings = observer(() => {
    useEffect(() => {
        async function fetchData() {
            return await SettingsStore.getSettings();
        }

        fetchData()
    }, []);

    return (
        <React.Fragment>
            <Loader isLoading={SettingsStore.isLoading} />
            <Col lg={4}>
                <RegistrationSettings/>
                <DeviceVersionSettings label="Android" values={SettingsStore.android}
                                       deviceType={deviceType.ANDROID}
                />
                <DeviceVersionSettings label="iOS" values={SettingsStore.ios}
                                       deviceType={deviceType.IOS}
                />
                <ConfirmSettings/>
            </Col>
        </React.Fragment>
    );
});

export default Settings;
