// @ts-ignore
import debounce from "lodash/debounce";
import {observer} from "mobx-react-lite";
import React from "react";
import ReactTable, {Column} from "react-table";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Button
} from "reactstrap";
import styled from "styled-components";
import {PAGE_COUNT} from "../../config";
import {ISosSignalList} from "../../interfaces/sos/ISosSignalList";
import Pagination from "./Pagination";

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface IProps {
	title: string,
	clickableRow?: any,
	tableHeaders: Column<any>[],
	isLoading: boolean,
	data: [] | ISosSignalList[],
	store: any,
	fetchData: (query: string, count: number, page: number, order: string) => Promise<void>,
	hideSearch?: boolean,
    showAdd?: boolean,
    onAdd?: () => Promise<void>,
}

const PaginationTable = observer(({title, clickableRow, tableHeaders, isLoading, data, store, fetchData, hideSearch, showAdd, onAdd}: IProps) => {

    const search = debounce((value: string, count: number) => {
        store.query = value;
        store.currentPage = 1;
        fetchData(value, count, store.currentPage, "");
    }, 500);

    const handleSearchChange = (event: any) => {
        const value = event.target.value;
        if (value.length > 2) {
            search(value);
        } else if (value.length <= 2 && store.query && store.query.length > 2) {
            store.query = "";
            search("", PAGE_COUNT);
        }
    };

    const handleSortChange = (sortProperties: any) => {
        if (sortProperties.length > 0) {
            store.currentPage = 1;
            let sortKey = sortProperties[0].id + `${sortProperties[0].desc ? ".desc" : ""}`;
            if (sortProperties[0].id === "age")
                sortKey = `${sortProperties[0].desc ? "birthyear" : "birthyear.desc"}`;
            store.order = sortKey;
            fetchData(store.query, PAGE_COUNT, store.currentPage, sortKey);
        }
    };

    const paginationComponent = () => {
        return (<Pagination store={store}/>);
    };


    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <StyledCardHeader>
                            <div>
                                <CardTitle style={{float:"left"}} tag="h4">{title}</CardTitle>
                                {showAdd &&
                                    <Button
                                        className="btn-round"
                                        color="default"
                                        outline
                                        style={{float:"left", marginLeft:"20px", marginTop:"7px", marginBottom:"0px"}}
                                        onClick={() => { if (onAdd) onAdd()}}
                                    >
                                        + Dodaj
                                    </Button>
                                }
                            </div>
                            {!hideSearch &&
                            <Form onSubmit={e => e.preventDefault()}>
                                <InputGroup className="no-border">
                                    <Input defaultValue="" placeholder="Iskanje..." type="text"
                                        onChange={handleSearchChange}/>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <i className="nc-icon nc-zoom-split"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Form>
                            }
                        </StyledCardHeader>
                        <CardBody>
                            <ReactTable
                                getTrProps={clickableRow}
                                loading={isLoading}
                                data={data}
                                resizable={false}
                                filterable={false}
                                sortable
                                columns={tableHeaders}
                                pageSize={PAGE_COUNT}
                                showPagination={true}
                                minRows={data && data.length > 0 ? 1 : 3}
                                className="-striped -highlight primary-pagination"
                                onSortedChange={handleSortChange}
                                PaginationComponent={paginationComponent}
                                manual
                                noDataText="Ni najdenih rezultatov"
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
});

export default PaginationTable;
