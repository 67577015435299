import React, {useEffect} from "react";
import {Observer, observer} from "mobx-react-lite";
import {hist} from "../../index";
import ReadingsStore from "../../stores/ReadingsStore";
import {Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col} from "reactstrap";
import {PatientRegistrationVitals} from "../patientWizard/PatientRegistrationVitals";
import Loader from "../../components/Loader";
import {getGoBackPath} from "../../variables/goBackHelper";
import AdminNavbarStore from "../../stores/common/AdminNavbarStore";

const PatientMeasurementsForm = observer((props) => {
    const id = props.match.params.id;

    useEffect(() => {
        const get = async () => {
            await ReadingsStore.getReadings();
            ReadingsStore.hideMainReadings();
        };
        get();
        return () => ReadingsStore.clear();
    }, [],
    );

    async function postPatientReadings(id) {
        const result = await ReadingsStore.postPatientReadings(id);
        if (result) AdminNavbarStore.redirectWithoutWarning(getGoBackPath(id));
    }

    return (
        <div className="content">
            <Loader isLoading={ReadingsStore.isLoading}/>
            <Card>
                <CardHeader>
                    <Col md={9} style={{"margin": "auto"}}>
                        <CardTitle>
                            <h5>Dodajanje meritev</h5>
                        </CardTitle>
                    </Col>
                </CardHeader>
                <CardBody>
                    <PatientRegistrationVitals/>
                </CardBody>
                <CardFooter>
                    <Col md={9} style={{"margin": "auto"}}>
                        <Observer>{() =>
                            <Button className="float-right btn-info"
                                disabled={!ReadingsStore.isValid}
                                onClick={() => postPatientReadings(id)}>Dodaj</Button>}
                        </Observer>
                    </Col>
                </CardFooter>
            </Card>
        </div>
    );
});

export default PatientMeasurementsForm;
