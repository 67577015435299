import React, {useEffect} from "react";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {Bar} from "react-chartjs-2";
import PatientReadingsStore from "../stores/PatientReadingsStore";
import {observer} from "mobx-react-lite";
import {localDateWithoutYear} from "../variables/dateHelpers";

const options = {
    tooltips: {
        tooltipFillColor: "rgba(0,0,0,0.5)",
        tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        tooltipFontSize: 14,
        tooltipFontStyle: "normal",
        tooltipFontColor: "#fff",
        tooltipTitleFontFamily:
            "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        tooltipTitleFontSize: 14,
        tooltipTitleFontStyle: "bold",
        tooltipTitleFontColor: "#fff",
        tooltipYPadding: 6,
        tooltipXPadding: 6,
        tooltipCaretSize: 8,
        tooltipCornerRadius: 6,
        tooltipXOffset: 10,
        filter: (toolTipItem) => {
            return toolTipItem.datasetIndex > 1;
        },
        custom: (tooltip) => {
            if (tooltip.body && tooltip.body.length > 0 && tooltip.body[0].lines.length > 0) {
                const numericAnswer = tooltip.dataPoints[0].yLabel;
                const answer = numericAnswer === -1 ? "Ne" : numericAnswer === 1 ? "Da" : "Ni odgovora";
                tooltip.body[0].lines[0] = `Odgovor: ${answer}`;
                if(numericAnswer === -1 || numericAnswer === 1)
                    tooltip.width = tooltip.width + 10;
                else
                    tooltip.width = tooltip.width + 50;
            }
        },
    },
    legend: {
        display: false,
    },
    layout: {
        padding: {
            bottom: 30,
        }
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    fontColor: "#9f9f9f",
                    fontStyle: "bold",
                    beginAtZero: true,
                    maxTicksLimit: 3,
                    padding: 20,
                    callback: (label, index) => {
                        if (index === 0) return "DA";
                        if (index === 1) return "Ø";
                        if (index === 2) return "NE";
                    },
                    min: -1,
                    max: 1
                },
                gridLines: {
                    color: "transparent",
                    display: true,
                    drawBorder: false,
                },
                position: "left",
                id: "id1"
            },
            {
                ticks: {
                    fontColor: "#9f9f9f",
                    fontStyle: "bold",
                    beginAtZero: true,
                    maxTicksLimit: 3,
                    padding: 20,
                    callback: (label, index) => {
                        return "";
                    },
                    min: -0.75,
                    max: 1.25
                },
                gridLines: {
                    color: "transparent",
                    display: false,
                    drawBorder: false,
                },
                position: "right",
                id: "id2",

            },
        ],
        xAxes: [
            {
                barPercentage: 0.6,
                gridLines: {
                    zeroLineColor: "white",
                    display: false,
                    drawBorder: false,
                    color: "transparent",
                },
                ticks: {
                    padding: 20,
                    fontColor: "#9f9f9f",
                    fontStyle: "bold",
                    callback: (label, index, labels) => {
                        if (Object.keys(PatientReadingsStore.dataForGraphs).length === 0) return "";
                        return label;
                    },
                },
                stacked: true,
            },
        ],
    },
    animation: false,
    onClick: (event, elements) => {
        if (elements.length > 0) {
            PatientReadingsStore.selectRecordByGraphIndex(elements[0]._index);
        }
    },
};

const defaultDataSetOptions = {
    label: "Data",
    borderColor: "#51bcda",
    fill: true,
    backgroundColor: "#51bcda",
    hoverBorderColor: "#51bcda",
    borderWidth: 0,
};

const selectedDataOptions = {
    label: "",
    borderColor: "black",
    fill: true,
    backgroundColor: "#51bcda",
    borderWidth: 1,
    borderSkipped: "none",
    tooltips: {enabled: false},
};

const SecondaryReadingsGraph = observer((props) => {
    useEffect(() => {
        mapToGraphData();
    }, []);

    const mapToGraphData = () => {
        if (!PatientReadingsStore.dataForGraphs[props.readingId] || PatientReadingsStore.dataForGraphs[props.readingId].length === 0) return {};
        const data = PatientReadingsStore.dataForGraphs[props.readingId].slice().sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf());

        const ret = {
            labels: [],
            datasets: [
                {
                    ...selectedDataOptions,
                    data: [],
                    label: "selected",
                },
                {
                    ...selectedDataOptions,
                    backgroundColor: "#51bcda33",
                    data: [],
                    label: "selectedRight",
                    yAxisID: "id2"
                },
                {
                    ...defaultDataSetOptions,
                    data: [],
                    label: "Odgovor",
                },
                {
                    ...defaultDataSetOptions,
                    backgroundColor: "#51bcda33",
                    data: [],
                    label: "Odgovor2",
                    yAxisID: "id2"
                },

            ],
        };

        data.forEach((reading, index) => {
            ret.labels.push(" " + localDateWithoutYear(reading.createdAt) + " ");
            let valueLeftAxis;
            let valueRightAxis;

            if (reading.value === undefined || reading.value === null) {
                valueRightAxis = 0.5;
                valueLeftAxis = 0;
            } else if (reading.value === false) {
                valueRightAxis = null;
                valueLeftAxis = -1;
            } else {
                valueRightAxis = null;
                valueLeftAxis = 1;
            }

            if (PatientReadingsStore.selectedReading?.created.slice(0, 19) === reading.createdAt.toISOString().slice(0, 19)) {
                ret.datasets[0].data = Array(index).fill(null).concat(valueLeftAxis);
            }

            if (valueRightAxis && PatientReadingsStore.selectedReading?.created.slice(0, 19) === reading.createdAt.toISOString().slice(0, 19)) {
                ret.datasets[1].data = Array(index).fill(null).concat(valueRightAxis);
            }

            ret.datasets[2].data.push(valueLeftAxis);
            ret.datasets[3].data.push(valueRightAxis);
        });

        return ret;
    };

    return (
        <Col xl={4} md={6}>
            <Card style={{height: "93%"}}>
                <CardHeader>
                    <Row>
                        <Col>
                            <h6 className="mt-2 pull-left">{props.label}</h6>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Bar
                        data={mapToGraphData()}
                        options={options}
                        height={250}
                        width={826}
                    />
                </CardBody>
            </Card>
        </Col>
    );
});

export default SecondaryReadingsGraph;
