enum ERole {
    MEDIC = "Medic",
    ORGANIZATION_ADMIN = "OrganizationAdmin",
    ADMIN = "Admin",
    GENERAL_CHAT_USER = "GeneralChatUser",
    SOS_USER = "SosUser",
    READING_TEMPLATE = "ReadingTemplate"
};

export const getSloRole = (angRole: string) => {
    if (angRole === ERole.MEDIC)
        return "Zdravnik";
    if (angRole === ERole.ORGANIZATION_ADMIN)
        return "Admin Organizacije";
    if (angRole === ERole.GENERAL_CHAT_USER)
        return "Dostop do splošnega klepeta";
    if (angRole === ERole.SOS_USER)
        return "Dostop do SOS";
    if (angRole === ERole.READING_TEMPLATE)
        return "Dostop do predlog";
    else
        return angRole;

};

export default ERole;
