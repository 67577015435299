import React, {useEffect} from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, CardTitle} from "reactstrap";
import MainReadingsStore from "stores/MainReadingsStore";
import DynamicFormInput from "components/dynamicForm/DynamicFormInput";
import {observer} from "mobx-react-lite";
import Loader from "components/Loader";
import AdminNavbarStore from "stores/common/AdminNavbarStore";
import {getGoBackPath} from "variables/goBackHelper";

const store = MainReadingsStore;

const AddMainReading = observer((props) => {
    const id = props.match.params.id;
    useEffect(() => {
        store.getMainReadings();
        return () => store.clear();
    }, []);

    async function postPatientReadings() {
        const result = await store.postReading(id);
        if (result) AdminNavbarStore.redirectWithoutWarning(getGoBackPath(id));
    }

    return (
        <Card>
            <Loader isLoading={store.isLoading}/>
            <CardHeader>
                <CardTitle tag="h5">{store.reading.title}</CardTitle>
            </CardHeader>
            <CardBody>
                {store.reading.type &&
                <DynamicFormInput
                    type={store.reading.type}
                    multiStateOptions={store.reading.multiStateOptions}
                    handleChange={store.handleChange}
                    id={store.reading.id}
                    name={store.reading.title}
                    readingType="main"
                    validationStatus={store.validationStatus}
                    validateField={store.validateField}
                    title={""}
                    index={0}
                />
                }
            </CardBody>
            <CardFooter>
                <Button
                    color="primary"
                    disabled={!store.isSubmittable}
                    onClick={postPatientReadings}
                >Dodaj meritev</Button>
            </CardFooter>
        </Card>
    );
});

export default AddMainReading;
