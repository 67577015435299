import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
// @ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from "reactstrap";
import EPermission from "enums/EPermission";
import {hist} from "index";
import AssignUserToModuleStore from "stores/AssignUserToModuleStore";
import MedModulesStore from "stores/MedModulesStore";
import {getModuleId} from "variables/fetchHelper";
import Loader from "components/Loader";
import PermissionCheckbox from "components/PermissionCheckbox";

const AssignUserToModuleForm = observer(() => {
    const moduleId = getModuleId(hist);

    const [userAssigned, setUserAssigned] = useState(false);

    useEffect(() => {
        return () => AssignUserToModuleStore.clear();
    }, []);

    const AssignUser = async () => {
        const result = await AssignUserToModuleStore.assignUser();
        if (result) setUserAssigned(true);
    };

    return (
        <Row>
            <Loader isLoading={AssignUserToModuleStore.isLoading}/>
            {userAssigned &&
            <ReactBSAlert
                success
                style={{display: "block"}}
                title="Zdravnik je bil uspešno dodan."
                confirmBtnBsStyle="info"
                onConfirm={() => {
                    setUserAssigned(false);
                }}
                onCancel={() => {
                    setUserAssigned(false);
                }}
            />
            }
            <Col className="mx-auto" md={10}>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h3" className="text-center">
							Dodaj zdravnika
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row className="d-flex justify-content-center mb-3">
                            <InputGroup className="no-border w-25 mb-2 mr-2">
                                <Input
                                    name="email"
                                    value={AssignUserToModuleStore.email}
                                    onChange={AssignUserToModuleStore.handleChange}
                                    autoComplete="off"
                                    placeholder="E-mail"
                                    type="text"
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                        <i className="nc-icon nc-zoom-split"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                            <Button disabled={!AssignUserToModuleStore.canSearch}
							        onClick={() => AssignUserToModuleStore.getUserByEmail()}
							        className="btn-info m-0 mb-2 d-inline">Išči</Button>
                        </Row>
                        {AssignUserToModuleStore.userExists &&
                        <Row className="d-flex justify-content-center align-items-center flex-column">
                            <h6 className="mb-4">Najden zdravnik:</h6>
                            <h5>{AssignUserToModuleStore.email} - {AssignUserToModuleStore.fullName}</h5>
                            <h6 className="my-4">Pravice za uporabo modula*:</h6>
                            <FormGroup check>
                                <PermissionCheckbox
                                    checkboxPermission={EPermission.CAN_USE}
                                    isChecked={AssignUserToModuleStore.canUse || AssignUserToModuleStore.canEdit || AssignUserToModuleStore.canShare}
                                    disabled={AssignUserToModuleStore.canEdit || AssignUserToModuleStore.canShare}
                                />
                                <PermissionCheckbox
                                    checkboxPermission={EPermission.CAN_EDIT}
                                    isChecked={AssignUserToModuleStore.canEdit || AssignUserToModuleStore.canShare}
                                    disabled={AssignUserToModuleStore.canShare}
                                />
                                {MedModulesStore.hasPermissionForModule(moduleId, EPermission.OWNER) &&
                                <PermissionCheckbox
                                    checkboxPermission={EPermission.CAN_SHARE}
                                    isChecked={AssignUserToModuleStore.canShare}
                                />
                                }
                            </FormGroup>
                            <Button disabled={!AssignUserToModuleStore.canAssign} onClick={AssignUser}
                                className="btn-info m-0 my-4 d-inline">Dodaj</Button>
                        </Row>
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
});

export default AssignUserToModuleForm;