import React, {useEffect} from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
} from "reactstrap";
import NotificationsForm from "components/NotificationsForm";
import NotificationsList from "components/NotificationsList";
import NotificationsStore from "stores/notifications/NotificationsStore";
import AuthStore from "stores/AuthStore";
import Loader from "components/Loader";
import {observer} from "mobx-react-lite";
import NotificationStore from "stores/notifications/NotificationStore";
import {getModuleId} from "variables/fetchHelper";
import {hist} from "index";
import ModuleLinks from "components/ModuleLinks";

const Notifications = observer(() => {
    const moduleId = getModuleId(hist);

    useEffect(() => {
        return () => NotificationStore.clear();
    }, []);

    const sendNotification = () => {
        if (AuthStore.hasAdminAccess && NotificationStore.currentTab === 1 && !moduleId) {
            NotificationsStore.sendNotificationToAll({
                title: NotificationStore.title,
                body: NotificationStore.body,
            }).then(() => {
                if (!NotificationsStore.error) clearState();
            });
        }
        else if (AuthStore.hasAdminAccess && NotificationStore.currentTab === 3 && !moduleId) {
            NotificationsStore.sendNotificationForUpdate({
                title: NotificationStore.title,
                body: NotificationStore.body,
                androidNewVersion: NotificationStore.androidNewVersion,
                iosNewVersion: NotificationStore.iosNewVersion,
            }).then(() => {
                if (!NotificationsStore.error) clearState();
            });
        }
        else if (NotificationStore.currentTab === 1 && moduleId) {
            NotificationsStore.sendNotificationOnModule({
                title: NotificationStore.title,
                body: NotificationStore.body,
                medModuleId: moduleId,
            }).then(() => {
                if (!NotificationsStore.error) clearState();
            });
        }
        else if(moduleId) {
            NotificationsStore.sendNotificationToUsers({
                title: NotificationStore.title,
                body: NotificationStore.body,
                userId: NotificationStore.userId.map(user => user.value),
                medModuleId: moduleId,
            }).then(() => {
                if (!NotificationsStore.error) clearState();
            });
        }
        else {
            NotificationsStore.sendNotificationToUsers({
                title: NotificationStore.title,
                body: NotificationStore.body,
                userId: NotificationStore.userId.map(user => user.value)
            }).then(() => {
                if (!NotificationsStore.error) clearState();
            });
        }
    };

    const clearState = () => {
        NotificationStore.clear();
    };


    const isDisabled = () => {
        if (NotificationStore.currentTab === 1) {
            return !NotificationStore.title || !NotificationStore.body;
        } else if (NotificationStore.currentTab === 2) {
            return (NotificationStore.userId && NotificationStore.userId.length === 0) || !NotificationStore.title || !NotificationStore.body;
        }
        else if (NotificationStore.currentTab === 3) {
            return (!NotificationStore.title || !NotificationStore.body) || (!NotificationStore.androidNewVersion && !NotificationStore.iosNewVersion);
        }
        return false;
    };

    return (
        <React.Fragment>
            <Loader isLoading={NotificationsStore.isLoading} />
            <ModuleLinks tabPath={location.pathname} moduleId={moduleId}></ModuleLinks>
            <Card>
                <CardHeader>
                    <CardTitle tag="h5">Pošlji obvestilo</CardTitle>
                </CardHeader>
                <CardBody>
                    <div className="nav-tabs-navigation">
                        <div className="nav-tabs-wrapper">
                            <Nav id="tabs" role="tablist" tabs className="justify-content-start">
                                <NavItem>
                                    <NavLink
                                        style={{cursor: "pointer"}}
                                        aria-expanded={NotificationStore.currentTab === 1}
                                        data-toggle="tab"
                                        role="tab"
                                        className={NotificationStore.currentTab === 1 ? "active" : ""}
                                        onClick={() => NotificationStore.currentTab = 1}
                                    >
                                        {moduleId ? "Obvestilo vsem pacientom modula" : "Obvestilo vsem pacientom"}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{cursor: "pointer"}}
                                        aria-expanded={NotificationStore.currentTab === 2}
                                        data-toggle="tab"
                                        role="tab"
                                        className={NotificationStore.currentTab === 2 ? "active" : ""}
                                        onClick={() => NotificationStore.currentTab = 2}
                                    >
                                        Obvestilo izbranim pacientom
                                    </NavLink>
                                </NavItem>
                                {!moduleId &&
                                <NavItem>
                                    <NavLink
                                        style={{cursor: "pointer"}}
                                        aria-expanded={NotificationStore.currentTab === 3}
                                        data-toggle="tab"
                                        role="tab"
                                        className={NotificationStore.currentTab === 3 ? "active" : ""}
                                        onClick={() => NotificationStore.currentTab = 3}
                                    >
                                        Obvestilo o posodobitvi
                                    </NavLink>
                                </NavItem>
                                }
                            </Nav>
                        </div>
                    </div>
                    <TabContent
                        id="my-tab-content"
                        activeTab={NotificationStore.currentTab}
                    >
                        <NotificationsForm
                            currentTab={NotificationStore.currentTab}
                            setTitle={(value) => NotificationStore.title = value}
                            setBody={(value) => NotificationStore.body = value}
                            setUsers={(value) => NotificationStore.userId = value}
                            setAndroidNewVersion={(value) => NotificationStore.androidNewVersion = value}
                            setIosNewVersion={(value) => NotificationStore.iosNewVersion = value}
                            title={NotificationStore.title}
                            body={NotificationStore.body}
                            users={NotificationStore.userId}
                            androidNewVersion={NotificationStore.androidNewVersion}
                            iosNewVersion={NotificationStore.iosNewVersion}/>
                    </TabContent>

                </CardBody>
                <CardFooter>
                    <Button color="primary" onClick={sendNotification} disabled={isDisabled()}>Pošlji
                        obvestilo</Button>
                </CardFooter>
            </Card>
            <NotificationsList/>
        </React.Fragment>
    );

});

export default Notifications;
