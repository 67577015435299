import React, {useEffect} from "react";
import Loader from "components/Loader";
import {Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label} from "reactstrap";
import ResetPinStore from "stores/ResetPinStore";
import {observer} from "mobx-react-lite";
import {numberValidation} from "_utils/numberValidation";
import PatientPersonalDataStore from "stores/PatientPersonalDataStore";
import AdminNavbarStore from "stores/common/AdminNavbarStore";
import {getGoBackPath} from "variables/goBackHelper";
import {IMatch} from "interfaces/IMatch";

interface IResetPinForm {
    match: IMatch,
}

const ResetPinForm = observer(({match}:IResetPinForm) => {
    const patientId = match.params.id;

    useEffect(() => {
        if(!PatientPersonalDataStore.personalData.healthId) PatientPersonalDataStore.getPatientPersonalDetails(patientId);
        return () => {PatientPersonalDataStore.clear(); ResetPinStore.clear();};
    }, []);

    const resetPin = async () => {
        const result = ResetPinStore.postResetPin(PatientPersonalDataStore.personalData.healthId);
        if (result) AdminNavbarStore.redirectWithoutWarning(getGoBackPath(patientId));
    };

    return (
        <Card className="col-md-7 mx-auto">
            <Loader isLoading={ResetPinStore.isLoading}/>
            <CardHeader>
                <CardTitle tag="h5">Spremeni PIN</CardTitle>
            </CardHeader>
            <CardBody>
                <Label>Novi PIN*</Label>
                <FormGroup className={ResetPinStore.status.newPin}>
                    <Input type="password" name="newPin"
                        value={ResetPinStore.newPin}
                        onChange={(event) => numberValidation(event, ResetPinStore.handleChange)}
                        maxLength={4}
                        autoComplete="off"
                        onBlur={() => ResetPinStore.validateField("newPin")}
                    />
                    {ResetPinStore.status.newPin === "has-danger" ? (
                        <label className="error">
                            PIN mora imeti 4 številke
                        </label>
                    ) : null}
                </FormGroup>
                <Label>Ponovi PIN*</Label>
                <FormGroup className={ResetPinStore.status.repeatedPin}>
                    <Input type="password"
                        name="repeatedPin" value={ResetPinStore.repeatedPin}
                        onChange={(event) => numberValidation(event, ResetPinStore.handleChange)}
                        maxLength={4}
                        autoComplete="off"
                        onBlur={() => ResetPinStore.validateField("repeatedPin")}
                    />
                    {ResetPinStore.status.repeatedPin === "has-danger" ? (
                        <label className="error">
                            PIN-a se ne ujemata
                        </label>
                    ) : null}
                </FormGroup>
                <Button
                    color="primary"
                    disabled={!ResetPinStore.isSubmittable}
                    onClick={resetPin}
                >Potrdi</Button>
            </CardBody>
        </Card>
    );
});

export default ResetPinForm;
