import React from "react";
import {Card, CardHeader, CardTitle, CardBody, Col, Row} from "reactstrap";
import VitalsGraph from "./VitalsGraph";
import PatientProfileStore from "../stores/PatientProfileStore";
import PatientReadingsStore from "../stores/PatientReadingsStore";
import {observer} from "mobx-react-lite";
import {ReadingType} from "./readingTypes";
import SecondaryReadingsGraph from "./SecondaryReadingsGraph";
import styled from "styled-components";
import {localDateTimeFormat} from "../variables/dateHelpers";
import {ECalculationType} from "../enums/CalculationType";
import ButtonWithModal from "./ButtonWithModal";

const Header = styled(CardHeader)`
        padding: 3px 15px 0 15px !important;
`;

const ReadingGraph = ({reading}) => {
    if(reading.type === ReadingType.BOOL){
        return <SecondaryReadingsGraph
            readingId={reading.id}
            key={reading.id}
            label={reading.title}
            readingType={reading.type}
        />;
    }
    else {
        return <VitalsGraph
            readingId={reading.id}
            key={reading.id}
            label={reading.title}
            readingType={reading.type}
        />;
    }
}

const RowDiv = styled.div`
    display: grid;
    grid-template-columns: 4fr 6fr;
    cursor: pointer;
`;

const RowText = styled.div`
    font-size: 12px;
    color: gray;
    color: ${(props) => props.color ? props.color : "gray"};
`;

const ModalButton = styled.div`
    font-size: 12px;
    cursor: pointer;
    text-align: center;
`;

const UserVitalsInfo = observer(() => {
    const lastRecords = [...PatientReadingsStore.rawData].splice(0, 8)
    return (
        <React.Fragment>
            <Card>
                <Header>
                    <CardTitle tag="h4">Vitalni znaki</CardTitle>
                </Header>
            </Card>
            <Row>
                {PatientProfileStore.latestReadings.primaryReadingTypes.map(reading =>
                    <ReadingGraph key={reading.id} reading={reading}/>
                )}
            </Row>
            {PatientProfileStore.latestReadings.secondarySections?.map((section, index) =>
            <div>
            <Card>
                <Header>
                    <CardTitle tag="h4">Opisni znaki - {index+1}. Sekcija {section.text && <span> ({section.text})</span>}</CardTitle>
                </Header>
            </Card>
            <Row>
                {/*Zgodovina Biaggi izračuna*/}
                {(section.readings && section.calculationType === ECalculationType.BIAGGI) &&
                    <Col xl={4} md={6}>
                        <Card style={{height: "93%"}}>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h6 className="mt-2 pull-left">Ocena Biaggi izračuna</h6>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                            {lastRecords.map((date, dateIndex) => {
                                const isSelected = PatientReadingsStore.selectedReading?.created.slice(0, 19) === date.created.slice(0, 19); 
                                return (
                                    <RowDiv key={date.created} onClick={() => {PatientReadingsStore.selectRecordByGraphExactIndex(dateIndex);}}>
                                        <RowText color={isSelected ? "black" : "gray"}>{localDateTimeFormat(date.created)}</RowText>
                                        <RowText color={isSelected ? "black" : "#51bcda"} className="ml-4 text-uppercase">
                                            { date.secondarySectionsCalculationResults[index] ? date.secondarySectionsCalculationResults[index] : "/" }
                                        </RowText>
                                    </RowDiv>
                                )})
                            }
                            {PatientReadingsStore.rawData.length > 8 &&
                                <ButtonWithModal title="Ocena Biaggi izračuna" component={
                                    <div>
                                        {PatientReadingsStore.rawData.map((date, dateIndex) => {
                                            const isSelected = PatientReadingsStore.selectedReading?.created.slice(0, 19) === date.created.slice(0, 19); 
                                            return (
                                                <RowDiv key={date.created} onClick={() => {PatientReadingsStore.selectRecordByGraphExactIndex(dateIndex);}}>
                                                    <RowText color={isSelected ? "black" : "gray"}>{localDateTimeFormat(date.created)}</RowText>
                                                    <RowText color={isSelected ? "black" : "#51bcda"} className="ml-4 text-uppercase">
                                                        { date.secondarySectionsCalculationResults[index] ? date.secondarySectionsCalculationResults[index] : "/" }
                                                    </RowText>
                                                </RowDiv>
                                            )})
                                        }
                                    </div>
                                }>
                                    <div className="d-flex align-items-center justify-content-center mt-3" style={{cursor: "pointer"}}>
                                        <ModalButton>Pokaži več</ModalButton>
                                        <i className={"nc-icon nc-minimal-down ml-2"}/>
                                    </div>
                                </ButtonWithModal>
                            }
                            </CardBody>
                        </Card>
                    </Col>
                }

                {section.readings &&
                    section.readings.map(reading =>
                        <ReadingGraph key={reading.id} reading={reading}/>
                    )
                }
            </Row>
            </div>)}

        </React.Fragment>
    )
})

export default UserVitalsInfo;
