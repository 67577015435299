import React from 'react';

interface IProps {
    isChecked: boolean
}

const CheckmarkIcon = ({isChecked}: IProps) => {
    return isChecked
        ? <i className="fa fa-check-square mx-2" style={{fontSize: "20px"}}/>
        : <i className="fa fa-square-o mx-2" style={{fontSize: "20px"}}/>
};

export default CheckmarkIcon;
