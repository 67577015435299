import React, {useEffect} from "react";
import {Col, Row} from "reactstrap";
import ChatStore from "stores/chat/ChatStore";
import {observer} from "mobx-react-lite";
import ChatConversationsList from "components/Chat/ChatConversationsList";
import ChatWindow from "components/Chat//ChatWindow";
import ChatTopicSelector from "components/Chat/ChatTopicSelector";

const Chat = observer(() => {
    useEffect(() => {
        ChatStore.getDataFromUrl();
        ChatStore.getTopics();
        return () => ChatStore.clear();
    }, []);

    const updateScroll = () => {
        const chatWrapper = document.getElementById("chatWrapper");
        if (chatWrapper) {
            chatWrapper.scrollTop = chatWrapper.scrollHeight;
        }
    };

    return (
        <div>
            <ChatTopicSelector/>
            {ChatStore.selectedTopic &&
            <Row>
                <Col md={4} xl={3} className="mb-3">
                    <ChatConversationsList updateScroll={updateScroll}/>
                </Col>
                <Col md={8} xl={9} className="mb-3">
                    {ChatStore.selectedConversation &&
                    <ChatWindow conversation={ChatStore.selectedConversation} updateScroll={updateScroll}/>
                    }
                </Col>
            </Row>
            }
        </div>
    );
});

export default Chat;
