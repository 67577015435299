import React from "react";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {Observer} from "mobx-react-lite";
import PatientPersonalDataStore from "../../stores/PatientPersonalDataStore";
import {numberValidation} from "../../_utils/numberValidation";
import Loader from "../../components/Loader";
import PropTypes from "prop-types";

export class PatientRegistrationForm extends React.Component {
    constructor(props) {
        super(props);
    }

    isValidated = () => {
        PatientPersonalDataStore.validateAll();
        return PatientPersonalDataStore.isValid;
    };

    render() {
        return (
            <Observer>{() => <Col md={9} style={{"margin": "auto"}}>
                <Loader isLoading={PatientPersonalDataStore.isLoading}/>
                <h5 className="formTitle firstTitle">OSEBNI PODATKI</h5>
                <Label>Ime</Label>
                <FormGroup>
                    <Input
                        type="text"
                        name="firstName"
                        onChange={PatientPersonalDataStore.changePersonalData}
                        autoComplete="off"
                        value={PatientPersonalDataStore.personalData.firstName}
                        disabled={PatientPersonalDataStore.isLoading}
                        maxLength={100}
                    />
                </FormGroup>
                <Label>Priimek</Label>
                <FormGroup>
                    <Input
                        type="text" name="lastName"
                        onChange={PatientPersonalDataStore.changePersonalData}
                        autoComplete="off"
                        value={PatientPersonalDataStore.personalData.lastName}
                        disabled={PatientPersonalDataStore.isLoading}
                        maxLength={100}/>
                </FormGroup>
                {!this.props.isEditing &&
                <React.Fragment>
                    <Label>EMŠO*</Label>
                    <FormGroup className={PatientPersonalDataStore.status.emso}>
                        <Input
                            type="text"
                            name="emso"
                            onChange={(event) => PatientPersonalDataStore.changePersonalData(event, true)}
                            value={PatientPersonalDataStore.personalData.emso}
                            onBlur={() => PatientPersonalDataStore.checkForErrors("emso")}
                            autoComplete="off"
                            maxLength="13"
                            disabled={PatientPersonalDataStore.isEditing || PatientPersonalDataStore.isLoading}
                        />
                        {PatientPersonalDataStore.status.emso === "has-danger" ? (
                            <label className="error">
                                EMŠO ni veljaven
                            </label>
                        ) : null}
                    </FormGroup>
                </React.Fragment>
                }
                {!this.props.isEditing &&
                <React.Fragment>
                    <Label>Št. zdravstvene izkaznice*</Label>
                    <FormGroup className={PatientPersonalDataStore.status.healthId}>
                        <Input
                            type="text"
                            name="healthId"
                            onChange={(event) => PatientPersonalDataStore.changePersonalData(event, true)}
                            onBlur={() => PatientPersonalDataStore.checkForErrors("healthId")}
                            value={PatientPersonalDataStore.personalData.healthId}
                            autoComplete="off"
                            maxLength="9"
                            disabled={PatientPersonalDataStore.isEditing || PatientPersonalDataStore.isLoading}
                        />
                        {PatientPersonalDataStore.status.healthId === "has-danger" ? (
                            <label className="error">
                                Št. zdravstvene izkaznice mora imeti 9 številk
                            </label>
                        ) : null}
                    </FormGroup>
                </React.Fragment>
                }
                <Label>Telefonska št.</Label>
                <FormGroup>
                    <Input
                        type="text"
                        name="phone"
                        onChange={PatientPersonalDataStore.changePersonalData}
                        autoComplete="off"
                        value={PatientPersonalDataStore.personalData.phone}
                        disabled={PatientPersonalDataStore.isLoading}
                        maxLength={20}/>
                </FormGroup>
                <Label>E-mail*</Label>
                <FormGroup className={PatientPersonalDataStore.status.email}>
                    <Input
                        type="text"
                        name="email"
                        onChange={PatientPersonalDataStore.changePersonalData}
                        autoComplete="off"
                        onBlur={() => PatientPersonalDataStore.checkForErrors("email")}
                        value={PatientPersonalDataStore.personalData.email}
                        disabled={PatientPersonalDataStore.isLoading}
                        maxLength={200}
                    />
                    {PatientPersonalDataStore.status.email === "has-danger" ? (
                        <label className="error">
                            {PatientPersonalDataStore.personalData.email ? "Email ni veljaven" : "Email je obvezen"}
                        </label>
                    ) : null}
                </FormGroup>
                <Label>Naslov</Label>
                <FormGroup>
                    <Input
                        type="text" name="address" onChange={PatientPersonalDataStore.changePersonalData}
                        autoComplete="off"
                        value={PatientPersonalDataStore.personalData.address}
                        disabled={PatientPersonalDataStore.isLoading}
                        maxLength={500}
                    />
                </FormGroup>
                <Label>Telefon 1</Label>
                <FormGroup>
                    <Input
                        type="text"
                        name="phone1"
                        onChange={PatientPersonalDataStore.changePersonalData}
                        autoComplete="off"
                        value={PatientPersonalDataStore.personalData.phone1}
                        disabled={PatientPersonalDataStore.isLoading}
                        maxLength={20}/>
                </FormGroup>
                <Label>Telefon 2</Label>
                <FormGroup>
                    <Input
                        type="text"
                        name="phone2"
                        onChange={PatientPersonalDataStore.changePersonalData}
                        autoComplete="off"
                        value={PatientPersonalDataStore.personalData.phone2}
                        disabled={PatientPersonalDataStore.isLoading}
                        maxLength={20}/>
                </FormGroup>
                <Label>Telefon 3</Label>
                <FormGroup>
                    <Input
                        type="text"
                        name="phone3"
                        onChange={PatientPersonalDataStore.changePersonalData}
                        autoComplete="off"
                        value={PatientPersonalDataStore.personalData.phone3}
                        disabled={PatientPersonalDataStore.isLoading}
                        maxLength={20}/>
                </FormGroup>
                <Label>Skype</Label>
                <FormGroup>
                    <Input
                        type="text"
                        name="skype"
                        onChange={PatientPersonalDataStore.changePersonalData}
                        autoComplete="off"
                        value={PatientPersonalDataStore.personalData.skype}
                        disabled={PatientPersonalDataStore.isLoading}
                        maxLength={100}/>
                </FormGroup>
                <Label>Viber</Label>
                <FormGroup>
                    <Input
                        type="text"
                        name="viber"
                        onChange={PatientPersonalDataStore.changePersonalData}
                        autoComplete="off"
                        value={PatientPersonalDataStore.personalData.viber}
                        disabled={PatientPersonalDataStore.isLoading}
                        maxLength={100}/>
                </FormGroup>
                {!PatientPersonalDataStore.isEditing && <React.Fragment>
                    <Label>PIN*</Label>
                    <FormGroup className={PatientPersonalDataStore.status.pin}>
                        <Input
                            type="password" name="pin"
                            onChange={(event) => PatientPersonalDataStore.changePersonalData(event, true)}
                            onBlur={() => PatientPersonalDataStore.checkForErrors("pin")}
                            value={PatientPersonalDataStore.personalData.pin || ""}
                            autoComplete="new-password"
                            maxLength="4"
                            disabled={PatientPersonalDataStore.isLoading}
                        />
                        {PatientPersonalDataStore.status.pin === "has-danger" ? (
                            <label className="error">
                                PIN mora imeti 4 številke
                            </label>
                        ) : null}
                    </FormGroup>
                    <Label>Ponovi PIN*</Label>
                    <FormGroup className={PatientPersonalDataStore.status.pin2}>
                        <Input
                            type="password" name="pin2"
                            onChange={(event) => PatientPersonalDataStore.changePersonalData(event, true)}
                            onBlur={() => PatientPersonalDataStore.checkForErrors("pin2")}
                            value={PatientPersonalDataStore.personalData.pin2 || ""}
                            autoComplete="new-password"
                            maxLength="4"
                            disabled={PatientPersonalDataStore.isLoading}
                        />
                        {PatientPersonalDataStore.status.pin2 === "has-danger" ? (
                            <label className="error">
                                PIN-a se ne ujemata
                            </label>
                        ) : null}
                    </FormGroup>
                </React.Fragment>}
                <h5>NEOBVEZNI ZDRAVSTVENI PODATKI</h5>
                <Label>Kakšne so vaše normalne vrednosti krvnega tlaka?</Label>
                <br/>
                <Label>Zgornji tlak</Label>
                <Row>
                    <Col lg={3} sm={3} xs={6}>
                        {PatientPersonalDataStore.status.healthData.pressure.upper.from}
                        <FormGroup className={PatientPersonalDataStore.status.healthData.pressure.upper.from}>
                            <Input
                                type="text"
                                name="upper.from"
                                placeholder="od"
                                onChange={(event) => numberValidation(event, PatientPersonalDataStore.changePressureData)}
                                value={PatientPersonalDataStore.personalData.healthData.pressure.upper.from || ""}
                                maxLength="3"
                                autoComplete="off"
                                onBlur={() => PatientPersonalDataStore.checkToRemoveCombinedErrors("pressure")}
                                disabled={PatientPersonalDataStore.isLoading}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                        <FormGroup className={PatientPersonalDataStore.status.healthData.pressure.upper.to}>
                            <Input
                                type="text"
                                name="upper.to"
                                placeholder="do"
                                onChange={(event) => numberValidation(event, PatientPersonalDataStore.changePressureData)}
                                value={PatientPersonalDataStore.personalData.healthData.pressure.upper.to || ""}
                                maxLength="3"
                                autoComplete="off"
                                onBlur={() => PatientPersonalDataStore.checkToRemoveCombinedErrors("pressure")}
                                disabled={PatientPersonalDataStore.isLoading}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Label>Spodnji tlak</Label>
                <Row>
                    <Col lg={3} sm={3} xs={6}>
                        <FormGroup className={PatientPersonalDataStore.status.healthData.pressure.lower.from}>
                            <Input
                                type="text"
                                name="lower.from"
                                placeholder="od"
                                onChange={(event) => numberValidation(event, PatientPersonalDataStore.changePressureData)}
                                value={PatientPersonalDataStore.personalData.healthData.pressure.lower.from || ""}
                                maxLength="3"
                                autoComplete="off"
                                onBlur={() => PatientPersonalDataStore.checkToRemoveCombinedErrors("pressure")}
                                disabled={PatientPersonalDataStore.isLoading}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                        <FormGroup className={PatientPersonalDataStore.status.healthData.pressure.lower.to}>
                            <Input
                                type="text"
                                name="lower.to"
                                placeholder="do"
                                onChange={(event) => numberValidation(event, PatientPersonalDataStore.changePressureData)}
                                value={PatientPersonalDataStore.personalData.healthData.pressure.lower.to || ""}
                                maxLength="3"
                                autoComplete="off"
                                onBlur={() => PatientPersonalDataStore.checkToRemoveCombinedErrors("pressure")}
                                disabled={PatientPersonalDataStore.isLoading}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(
                            PatientPersonalDataStore.status.healthData.pressure.upper.from === "has-danger" ||
                            PatientPersonalDataStore.status.healthData.pressure.upper.to === "has-danger" ||
                            PatientPersonalDataStore.status.healthData.pressure.lower.from === "has-danger" ||
                            PatientPersonalDataStore.status.healthData.pressure.lower.to === "has-danger") ? (
                                <FormGroup className="has-danger">
                                    <label className="error">
                                    Za shranjevanja tlaka so potrebna vsa polja
                                    </label>
                                </FormGroup>
                            ) : null}
                    </Col>
                </Row>
                <Label>Kakšen je vaš običajni pulz?</Label>
                <Row>
                    <Col lg={3} sm={3} xs={6}>
                        <FormGroup className={PatientPersonalDataStore.status.healthData.heartRate.from}>
                            <Input
                                type="text"
                                name="from"
                                placeholder="od"
                                onChange={(event) => numberValidation(event, PatientPersonalDataStore.changeHeartRate)}
                                value={PatientPersonalDataStore.personalData.healthData.heartRate.from || ""}
                                maxLength="3"
                                autoComplete="off"
                                onBlur={() => PatientPersonalDataStore.checkToRemoveCombinedErrors("heartRate")}
                                disabled={PatientPersonalDataStore.isLoading}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                        <FormGroup className={PatientPersonalDataStore.status.healthData.heartRate.to}>
                            <Input
                                type="text"
                                name="to"
                                placeholder="do"
                                onChange={(event) => numberValidation(event, PatientPersonalDataStore.changeHeartRate)}
                                value={PatientPersonalDataStore.personalData.healthData.heartRate.to || ""}
                                maxLength="3"
                                autoComplete="off"
                                onBlur={() => PatientPersonalDataStore.checkToRemoveCombinedErrors("heartRate")}
                                disabled={PatientPersonalDataStore.isLoading}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(
                            PatientPersonalDataStore.status.healthData.heartRate.from === "has-danger" ||
                            PatientPersonalDataStore.status.healthData.heartRate.to === "has-danger") ? (
                                <FormGroup className="has-danger">
                                    <label className="error">
                                    Za shranjevanja pulza sta potrebna oba polja
                                    </label>
                                </FormGroup>
                            ) : null}
                    </Col>
                </Row>
                <Label>Kakšna je vaša normalna saturacija krvi s kisikom v mirovanju(SpO2)?</Label>
                <FormGroup>
                    <Input
                        type="text"
                        name="spO2"
                        placeholder="%"
                        onChange={(event) => numberValidation(event, PatientPersonalDataStore.changeHealthData, true)}
                        value={PatientPersonalDataStore.personalData.healthData.spO2 || ""}
                        autoComplete="off"
                        disabled={PatientPersonalDataStore.isLoading}
                    />
                </FormGroup>
                <Label>Ali redno jemljete kakšna zdravila?</Label>
                <FormGroup>
                    <Input
                        type="text" name="medication" placeholder="Naštejte zdravila"
                        onChange={PatientPersonalDataStore.changeHealthData} autoComplete="off"
                        value={PatientPersonalDataStore.personalData.healthData.medication || ""}
                        maxLength={2000}
                    />
                </FormGroup>
                <Label>Naštejte bolezni, ki jih imate.</Label>
                <FormGroup>
                    <Input
                        type="text" name="diseases" placeholder="Naštejte bolezni"
                        onChange={PatientPersonalDataStore.changeHealthData} autoComplete="off"
                        value={PatientPersonalDataStore.personalData.healthData.diseases || ""}
                        maxLength={2000}
                    />
                </FormGroup>
                <Label>Ali imate kakšne alergije?</Label>
                <FormGroup>
                    <Input
                        type="text" name="allergies" placeholder="Naštejte alergije"
                        onChange={PatientPersonalDataStore.changeHealthData} autoComplete="off"
                        value={PatientPersonalDataStore.personalData.healthData.allergies || ""}
                        maxLength={2000}
                    />
                </FormGroup>
                <h5>PODATKI O OSEBNEM ZDRAVNIKU</h5>
                <Label>Ime in priimek zdravnika</Label>
                <FormGroup className={PatientPersonalDataStore.status.personalDoctor.fullName}>
                    <Input
                        type="text" name="fullName" onChange={PatientPersonalDataStore.changePersonalDoctor}
                        autoComplete="off"
                        onBlur={() => PatientPersonalDataStore.checkToRemoveCombinedErrors("personalDoctor")}
                        disabled={PatientPersonalDataStore.isLoading}
                        value={PatientPersonalDataStore.personalData.personalDoctor.fullName || ""}
                        maxLength={100}
                    />
                </FormGroup>
                <Label>Kraj</Label>
                <FormGroup className={PatientPersonalDataStore.status.personalDoctor.city}>
                    <Input
                        type="text" name="city" onChange={PatientPersonalDataStore.changePersonalDoctor}
                        autoComplete="off"
                        onBlur={() => PatientPersonalDataStore.checkToRemoveCombinedErrors("personalDoctor")}
                        disabled={PatientPersonalDataStore.isLoading}
                        value={PatientPersonalDataStore.personalData.personalDoctor.city || ""}
                        maxLength={100}
                    />
                </FormGroup>
                {(
                    PatientPersonalDataStore.status.personalDoctor.fullName === "has-danger" ||
                    PatientPersonalDataStore.status.personalDoctor.city === "has-danger") ? (
                        <FormGroup className="has-danger">
                            <label className="error">
                            Za shranjevanja osebnega zdranivka sta potrebna oba polja
                            </label>
                        </FormGroup>
                    ) : null}
            </Col>}</Observer>
        );
    }
}

PatientRegistrationForm.defaultProps = {
    isEditing: false,
};

PatientRegistrationForm.propTypes = {
    isEditing: PropTypes.bool,
};

export default PatientRegistrationForm;
