import {action, computed, observable, runInAction} from "mobx";
import {SETTINGS_URL} from "../config";
import httpService from "../services/httpService";

class SettingsStore {
    @observable isLoading = false;
    @observable registrationEnabled = null;
    @observable android = {latestVersion: "", minVersion: ""};
    @observable ios = {latestVersion: "", minVersion: ""};
    @observable error = null;
    @observable serverSettings = null;

    constructor() {
    }

    @action
    async getSettings() {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: SETTINGS_URL,
        };

        // eslint-disable-next-line no-useless-catch
        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.isLoading = false;
                this.registrationEnabled = response.registrationEnabled;
                this.android = response.android;
                this.ios = response.ios;
                this.serverSettings = response;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }

    }

    @action
    async postSettings() {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: SETTINGS_URL,
            body: {
                registrationEnabled: this.registrationEnabled,
                android: this.android,
                ios: this.ios,
            }
        };

        try {
            const response = await httpService.fetch(options);

            runInAction(() => {
                this.isLoading = false;
                this.registrationEnabled = response.registrationEnabled;
                this.android = response.android;
                this.ios = response.ios;
                this.serverSettings = response;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    setRegistration(value) {
        this.registrationEnabled = value;
    };

    @action
    setDeviceVersion(device, name, value) {
        this[device][name] = value;
    }

    @action
    isInputVersionValid(device, name) {
        return this.isFormatValid(this[device][name]);
    }

    isFormatValid(version) {
        const versionFormatRegex = /^(\d+\.){3}\d+$/;
        return versionFormatRegex.test(version);
    }

    @computed
    get InvalidData() {
        if (this.isLoading) return true;
        if (this.serverSettings === null) return true;
        if (!this.isFormatValid(this.android.latestVersion)) return true;
        if (!this.isFormatValid(this.android.minVersion)) return true;
        if (!this.isFormatValid(this.ios.latestVersion)) return true;
        if (!this.isFormatValid(this.ios.minVersion)) return true;
        return this.isSameAsServerCopy;
    };


    get isSameAsServerCopy() {
        return this.serverSettings.registrationEnabled === this.registrationEnabled &&
            this.serverSettings.android.latestVersion === this.android.latestVersion &&
            this.serverSettings.android.minVersion === this.android.minVersion &&
            this.serverSettings.ios.latestVersion === this.ios.latestVersion &&
            this.serverSettings.ios.minVersion === this.ios.minVersion;
    }

    @computed
    get shouldShowWarningOnBackNavigation() {
        return !this.isSameAsServerCopy;
    }

    @action
    clear() {
        this.isLoading = false;
        this.registrationEnabled = null;
        this.android = {latestVersion: "", minVersion: ""};
        this.ios = {latestVersion: "", minVersion: ""};
        this.error = null;
        this.serverSettings = null;
    }
}

// singletone
export default new SettingsStore();
