import React from "react";
import { Input } from "reactstrap";
import { Observer } from "mobx-react-lite";
import ColorPicker from "./ColorPicker";
import SortableTable from "./SortableTable";

const MultiStateForm = ({ data, setData, addClick, removeClick, disabled = false }) => {

    return (
        <div className="my-3">
            <SortableTable
                data={data}
                setData={setData}
                headers={getHeaders(disabled, removeClick)}
                isDragDisabled={disabled}
                columnsTemplate={"50px 30fr 30fr 30fr 100px"}
                minWidth={"500px"}
            />
            {!disabled &&
                <div onClick={addClick} style={{ cursor: "pointer", borderBottom: "1px solid #C0C0C0", padding: "4px", textAlign: "center" }}>
                    <i className="w-100 nc-icon nc-simple-add p-2" />
                </div>
            }
        </div>
    );
};

function getHeaders(disabled, removeClick) {

    let headers = [
        {
            Header: "Opis",
            Cell: props =>
                <Observer>{() =>
                    <Input
                        value={props.original.value}
                        type="text"
                        onChange={(event) => props.original.value = event.target.value}
                        style={{ minWidth: "100px" }}
                        maxLength={100}
                        disabled={disabled}
                    />
                }</Observer>
        },
        {
            Header: "Barva",
            Cell: props => <Observer>{() => <ColorPicker disabled={disabled} color={props.original.color} onChange={(event) => props.original.color = event.target.value} />}</Observer>
        },
        {
            Header: "Vrednost",
            Cell: props =>
                <Observer>{() =>
                    <Input
                        value={props.original.intValue}
                        type="number"
                        onChange={(event) => props.original.intValue = event.target.value}
                        style={{ minWidth: "100px" }}
                        maxLength={10}
                        disabled={disabled}
                    />
                }</Observer>
        }
    ]

    if (disabled === false) {
        headers.push({
            Header: "Akcije",
            Cell: props => <i disabled={disabled} className="nc-icon nc-simple-remove text-danger ml-3" style={{ cursor: "pointer" }} onClick={() => removeClick(props.original.id)} />
        });
    }

    return headers;
}

export default MultiStateForm;
