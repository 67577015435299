import React, { useEffect } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { observer } from "mobx-react-lite";
import ReadingsStore from "stores/ReadingsStore";
import "components/readingsAndAnamnesisForms/ReadingsForm.css";
import Loader from "components/Loader";
import { Link } from "react-router-dom";
import ButtonWithConfirmAlert from "components/ButtonWithConfirmAlert";
import SortableTable from "components/readingsAndAnamnesisForms/SortableTable";
import { hist } from "index";
import MedModulesStore from "stores/MedModulesStore";
import MedModuleStore from "stores/MedModuleStore";
import EPermission from "enums/EPermission";
import { getModuleId } from "variables/fetchHelper";
import ModuleLinks from "components/ModuleLinks";

const ReadingsForm = observer(({ history }) => {
    const moduleId = getModuleId(hist);
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await ReadingsStore.getAllTypes();
        await MedModuleStore.get(moduleId);
        return await ReadingsStore.getReadings();
    };

    return (
        <React.Fragment>
            <Loader isLoading={ReadingsStore.isLoading} />
            <ModuleLinks tabPath={location.pathname} moduleId={moduleId}></ModuleLinks>
            <Card>
                <CardHeader className="d-flex justify-content-between">
                    <CardTitle tag="h4">Kronična bolezen - Specialnost</CardTitle>
                    {ReadingsStore.mainReadings.length === 0 && MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_EDIT) &&
                        <Button
                            className="btn-round"
                            color="default"
                            outline
                            onClick={() => history.push(hist.location.pathname + "/add-main-reading")}
                        >
                            + Dodaj novo meritev
                        </Button>
                    }
                </CardHeader>
                <CardBody>
                    <SortableTable
                        data={ReadingsStore.mainReadings}
                        setData={(data) => { ReadingsStore.mainReadings = data; ReadingsStore.postReadings(); }}
                        headers={headers(ReadingsStore.MAIN_READING_ACCESSOR)}
                        isDragDisabled={ReadingsStore.disabledDragReorder}
                    />
                </CardBody>
            </Card>
            <Card>
                <CardHeader className="d-flex justify-content-between">
                    <CardTitle tag="h4">Meritve - Vitalni znaki</CardTitle>
                    {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_EDIT) &&
                        <Button
                            className="btn-round"
                            color="default"
                            outline
                            onClick={() => history.push(hist.location.pathname + "/add-primary-reading")}
                        >
                            + Dodaj novo meritev
                        </Button>
                    }
                </CardHeader>
                <CardBody>
                    <div>
                        <div>
                            <p style={{ float: "left", lineHeight: "50px", verticalAlign: "middle" }}>
                                <span>Tip izračuna: {ReadingsStore.getCalculationType(MedModuleStore.primaryCalculationType?.value)}</span>
                            </p>
                            <div style={{ height: "50px", marginLeft: "10px", display: "inline-block", clear: "both" }}>
                                {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_EDIT) &&
                                    <div>
                                        <Button
                                            className="btn-round btn-sm"
                                            color="default"
                                            outline
                                            onClick={() => history.push(hist.location.pathname + "/edit-primary-calculation")}
                                        >
                                            Uredi
                                        </Button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                        <SortableTable
                            data={ReadingsStore.primaryReadings}
                            setData={(data) => { ReadingsStore.primaryReadings = data; ReadingsStore.postReadings(); }}
                            headers={headers(ReadingsStore.PRIMARY_READING_ACCESSOR)}
                            isDragDisabled={ReadingsStore.disabledDragReorder}
                        />
                    </div>
                </CardBody>
            </Card>
            <Card>
                <CardHeader className="d-flex justify-content-between">
                    <CardTitle tag="h4">Meritve - Opisne</CardTitle>
                    {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_EDIT) &&
                        <Button
                            className="btn-round"
                            color="default"
                            outline
                            onClick={() => history.push(hist.location.pathname + "/edit-secondary-sections")}
                        >
                            Uredi sekcije
                        </Button>
                    }
                </CardHeader>
                <CardBody>

                    <div>
                        {ReadingsStore.secondaryReadings.map((section, index) =>
                            <span style={{ fontSize: "16px" }} onClick={() => ReadingsStore.selectedSecondarySection = index} key={index}><b style={{ cursor: "pointer", textDecoration: ReadingsStore.selectedSecondarySection === index ? 'underline' : '' }}>{index + 1}. Sekcija</b>{index + 1 != ReadingsStore.secondaryReadings.length ? ' | ' : ''}</span>
                        )}
                        {ReadingsStore.secondaryReadings.map((section, ind) =>
                            <div key={ind}>
                                {ReadingsStore.selectedSecondarySection === ind &&
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            
                                                <div>
                                                    <div style={{ float: "left", height: "50px", verticalAlign: "middle", lineHeight: "50px" }}>
                                                        <p>{section.text}{ReadingsStore.getCalculationType(section.calculationType) != '' && <span> - {ReadingsStore.getCalculationType(section.calculationType)}</span>}</p>
                                                    </div>
                                                    <div style={{ float: "left", height: "50px", marginLeft: "10px" }}>

                                                        {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_EDIT) && <Button
                                                            className="btn-round btn-sm"
                                                            color="default"
                                                            outline
                                                            onClick={() => history.push(hist.location.pathname + `/edit-secondary-sections-direct/edit/${ind}`)}
                                                        >
                                                            Uredi sekcijo
                                                        </Button>
                                                        }
                                                    </div>
                                                    <div style={{ clear: "both" }} />
                                                    <p>{section.subText}</p>
                                                </div>
                                                <div>
                                                    {MedModulesStore.hasPermissionForModule(moduleId, EPermission.CAN_EDIT) &&
                                                        <Button
                                                            style={{width: "210px"}}
                                                            className="btn-round btn-sm"
                                                            color="default"
                                                            outline
                                                            onClick={() => history.push(hist.location.pathname + `/add-secondary-reading?section=${ind}`)}
                                                        >
                                                            + Dodaj novo meritev
                                                        </Button>
                                                    }
                                                </div>
                                            
                                        </div>
                                        <SortableTable
                                            data={section.readings}
                                            setData={(data) => { section.readings = data; ReadingsStore.postReadings(); }}
                                            headers={headers(ReadingsStore.SECONDARY_READING_ACCESSOR)}
                                            isDragDisabled={section.disabledDragReorder}
                                        />
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
});

const headers = (accessor) => {
    return [
        ...accessor === ReadingsStore.MAIN_READING_ACCESSOR ? [{
            Header: "Naziv",
            accessor: "title",
            style: { "whiteSpace": "unset" }
        }] : [],
        ...accessor !== ReadingsStore.MAIN_READING_ACCESSOR ? [{
            Header: "Naziv meritve",
            accessor: "title",
            style: { "whiteSpace": "unset" }
        }] : [],
        ...accessor === ReadingsStore.PRIMARY_READING_ACCESSOR ? [{
            Header: "Opis meritve",
            accessor: "description",
        }] : [],
        {
            Header: "Statusni tekst",
            Cell: props => <div style={{ "whiteSpace": "break-spaces" }}>{props.original.statusText}</div>
        },
        {
            Header: "Tip odgovora",
            Cell: props => <div>{ReadingsStore.getReadingTypeLabel(props.original)}</div>
        },
        {
            Header: "Obvezno",
            Cell: props => <div>{props.original.mandatory ? "DA" : "NE"}</div>
        },
        ...MedModulesStore.hasPermissionForModule(getModuleId(hist), EPermission.CAN_EDIT) ? [{
            Header: "Akcije",
            Cell: props =>
                <div>
                    {accessor === ReadingsStore.MAIN_READING_ACCESSOR &&
                        <Link to={`${hist.location.pathname}/main-readings/${props.original.id}`}>
                            <i className="nc-icon nc-settings-gear-65 mx-1" />
                        </Link>
                    }
                    {accessor === ReadingsStore.PRIMARY_READING_ACCESSOR &&
                        <Link to={`${hist.location.pathname}/primary-readings/${props.original.id}`}>
                            <i className="nc-icon nc-settings-gear-65 mx-1" />
                        </Link>
                    }
                    {accessor === ReadingsStore.SECONDARY_READING_ACCESSOR &&
                        <Link to={`${hist.location.pathname}/secondary-readings/${props.original.id}`}>
                            <i className="nc-icon nc-settings-gear-65 mx-1" />
                        </Link>
                    }
                    <ButtonWithConfirmAlert
                        text={"Želite izbrisati meritev?"}
                        successAction={() => ReadingsStore.removeItem(accessor, props.original)}
                        openAction={() => ReadingsStore.disabledDragReorder = true}
                        closeAction={() => ReadingsStore.disabledDragReorder = false}
                    >
                        <i className="nc-icon nc-simple-remove text-danger mx-1" style={{ cursor: "pointer" }} />
                    </ButtonWithConfirmAlert>
                </div>,
        }] : [],
    ];
};

export default ReadingsForm;
