import {action, computed, observable, runInAction} from "mobx";
import {GET_CMS_USERS, POST_USER_PROFILE} from "../config";
import httpService from "../services/httpService";
import isEqual from "lodash/isEqual";
import {sortBy} from "lodash";
import {emailRegex} from "../enums/regex";
import AuthStore from "./AuthStore";

class UserStore {
    @observable isLoading = false;
    @observable error = null;
    @observable isEditing = false;
    @observable isChangingPassword = false;
    @observable onlyPasswordWasChanged = false;
    @observable onlyUserDataWasChanged = false;
    @observable forcePasswordChange = false;
    @observable status = {
        password: "",
        repeatedPassword: "",
        fullName: "",
        userName: "",
        email: "",
    };

    @observable id = "";
    @observable password = "";
    @observable repeatedPassword = "";
    @observable userName = "";
    @observable fullName = "";
    @observable roles = [];
    @observable organization = {
        id: "",
    };
    @observable email = "";

    @observable serverCopy = {
        userName: "",
        fullName: "",
        roles: [],
        organization: {
            id: "",
        },
        email: "",
    };

    @computed
    get isSameAsServerCopy() {
        if (this.isChangingPassword && this.password) return false;
        if (this.fullName !== this.serverCopy.fullName) return false;
        if (this.userName !== this.serverCopy.userName) return false;
        if (this.email !== this.serverCopy.email) return false;
        if (this.organization.id.toString() !== this.serverCopy.organization.id.toString()) return false;
        if (!isEqual(sortBy(this.roles), sortBy(this.serverCopy.roles))) return false;
        return true;
    }

    isPasswordValid(password) {
        return password && password.length >= 8;
    }

    isRepeatedPasswordValid(password, repeatedPassword) {
        return password === repeatedPassword;
    }

    isEmailValid(email) {
        if(!email)
            return false;
        return emailRegex.test(email.toLowerCase());
    }

    @computed
    get canCreate() {
        return this.fullName
            && this.userName
            && this.roles.length > 0
            && this.organization.id
            && this.isPasswordValid(this.password)
            && this.isRepeatedPasswordValid(this.password, this.repeatedPassword)
            && this.isEmailValid(this.email);
    }

    @computed
    get canUpdate() {
        return this.fullName
            && this.userName
            && this.roles.length > 0
            && this.organization.id
            && this.isEmailValid(this.email)
            && (!this.password || (this.isPasswordValid(this.password) && this.isRepeatedPasswordValid(this.password, this.repeatedPassword)));
    }

    @computed
    get isValid() {
        return this.isEditing ? this.canUpdate : this.canCreate;
    }

    @computed
    get canSubmit() {
        if (this.isEditing) {
            return this.isValid && !this.isSameAsServerCopy;
        } else {
            return this.isValid;
        }
    }

    @computed
    get canSubmitProfile() {
        return this.fullName
            && this.email
            && this.isEmailValid(this.email)
            && (!this.password || (this.isPasswordValid(this.password) && this.isRepeatedPasswordValid(this.password, this.repeatedPassword)))
            && !this.isSameAsServerCopy;

    }

    @action
    setEditing(id) {
        this.id = id;
        this.isEditing = true;
        this.get(id);
    }

    @action
    setEditingFromAuthStore() {
        this.id = AuthStore.id;
        this.serverCopy.id = AuthStore.id;
        this.fullName = AuthStore.fullName;
        this.serverCopy.fullName = AuthStore.fullName;
        this.email = AuthStore.email;
        this.serverCopy.email = AuthStore.email;
        this.isEditing = true;
    }

    @action
    async get(id) {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: GET_CMS_USERS + `/${id}`,
        };

        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.mapServerToStore(response);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    mapServerToStore(response) {
        this.id = response.id;
        this.userName = response.userName;
        this.fullName = response.fullName;
        this.roles = response.roles;
        this.organization = response.organization;
        this.email = response.email;
        this.serverCopy = {
            id: response.id,
            userName: response.userName,
            fullName: response.fullName,
            roles: response.roles,
            organization: response.organization,
            email: response.email
        };
    }

    @action
    async postUser() {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: GET_CMS_USERS,
            body: await this.mapStoreToServer()
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.isLoading = false;
            });

            return true;
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });

            return false;
        }
    }

    @action
    async postUserProfile() {
        this.isLoading = true;

        const payload = {
            fullName: this.fullName,
            email: this.email,
        };

        if(this.isChangingPassword){
            payload.password = this.password;
        }

        if(this.isChangingPassword && this.email === this.serverCopy.email && this.fullName === this.serverCopy.fullName){
            this.onlyPasswordWasChanged = true;
        }
        else if(!this.isChangingPassword){
            this.onlyUserDataWasChanged = true;
        }

        const options = {
            method: "POST",
            url: POST_USER_PROFILE,
            body: payload
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.isChangingPassword = false;
                this.isLoading = false;
            });

            return true;
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });

            return false;
        }
    }

    mapStoreToServer() {
        const payload = {
            password: this.password,
            userName: this.userName,
            fullName: this.fullName,
            roles: this.roles,
            organization: {
                id: this.organization.id
            },
            email: this.email,
            forcePasswordChange: this.forcePasswordChange
        };

        if (this.isEditing) {
            payload.id = this.id;
            if (!this.isChangingPassword) {
                delete payload.password;
            }
        }

        return payload;
    }

    @action
    handleChange = (event) => {
        const {name, value} = event.target;

        this[name] = value;
    };

    @action
    clear() {
        this.isLoading = false;
        this.error = null;
        this.isEditing = false;
        this.isChangingPassword = false;
        this.status = {
            password: "",
            repeatedPassword: "",
            fullName: "",
            userName: "",
            email: "",
        };

        this.id = "";
        this.password = "";
        this.repeatedPassword = "";
        this.userName = "";
        this.fullName = "";
        this.roles = [];
        this.organization = {
            id: ""
        };
        this.email = "";
        this.serverCopy = {
            userName: "",
            fullName: "",
            roles: [],
            organization: {
                id: "",
            },
            email: "",
        };
    }

    @action
    changeRoles(value, changedRole) {
        if (!value) {
            this.roles.remove(changedRole);
        } else if (!this.roles.includes(changedRole)) {
            this.roles.push(changedRole);
        }
    }

    @action
    setChangingPassword(value) {
        this.password = "";
        this.repeatedPassword = "";
        this.status.password = "";
        this.status.repeatedPassword = "";
        this.isChangingPassword = value;
    }

    @action
    setForcePasswordChange(value) {
        this.forcePasswordChange = value;
    }

    @action
    changeOrganization(event) {
        this.organization = {
            id: event.value,
            name: event.label
        };
    }

    @action
    validateField(field) {
        switch (field) {
            case("password"):
                this.status.password = !this.isPasswordValid(this.password) ? "has-danger" : "";
                break;
            case("repeatedPassword"):
                this.status.repeatedPassword = !this.isRepeatedPasswordValid(this.password, this.repeatedPassword) ? "has-danger" : "";
                break;
            case("fullName"):
                this.status.fullName = !this.fullName ? "has-danger" : "";
                break;
            case("userName"):
                this.status.userName = !this.userName ? "has-danger" : "";
                break;
            case("email"):
                this.status.email = !this.isEmailValid(this.email) ? "has-danger" : "";
                break;
        }
    }

    @computed
    get selectedOrganization() {
        return {value: this.organization.id, label: this.organization.name};
    }

    get isEmpty() {
        if(this.roles.length !== 0) return false;
        if(this.organization.id !== "" && AuthStore.hasAdminAccess) return false;
        return !this.fullName
             && !this.userName
             && !this.email
             && !this.password
             && !this.repeatedPassword;
    }

    @computed
    get shouldShowWarningOnBackNavigation() {
        if(this.isEditing && !this.isSameAsServerCopy) return true;
        return !this.isEditing && !this.isEmpty;
    }
}

export default new UserStore();
