import {action, computed, observable, runInAction} from "mobx";
import {CMS_SCHEDULE} from "../config";
import httpService from "../services/httpService";
import moment from "moment";

class ScheduleStore {
    @observable isLoading = false;
    @observable error = null;
    @observable isEditing = false;
    @observable status = {
        title: "",
        text: ""
    };

    @observable id = "";
    @observable title = "";
    @observable text = "";
    @observable every = 1;
    @observable hour = "10:00";
    @observable validForever = true;
    @observable schedule = { 
        repeat: { 
            every: 1,
            unit: 'day'
        },
        hours: ['10:00'],
        days: ['MON']
    };

    @observable duration = {
        value: 1,
        unit: 'forever'
    }

    @observable validFrom = '';
    @observable validTo = '';

    @observable serverCopy = {
        schedule : {
            repeat: {
                every: 1,
                unit: 'day'
            },
            hours: ['10:00'],
            days: ['MON']
        }
    };

    @computed
    get isSameAsServerCopy() {
        if (this.title !== this.serverCopy.title) return false;
        if (this.text !== this.serverCopy.text) return false;
        if (this.serverCopy.duration && this.duration.value != this.serverCopy.duration.value) return false;
        if (this.serverCopy.duration && this.duration.unit != this.serverCopy.duration.unit) return false;
        if (this.serverCopy.schedule && this.schedule.repeat.every != this.serverCopy.schedule.repeat.every) return false;
        if (this.serverCopy.schedule && this.schedule.repeat.unit != this.serverCopy.schedule.repeat.unit) return false;
        if (this.serverCopy.schedule && this.schedule.hours && this.serverCopy.schedule.hours && JSON.stringify(this.schedule.hours.slice().sort(this.sortFunction)) != JSON.stringify(this.serverCopy.schedule.hours.slice().sort(this.sortFunction))) return false;
        if (this.schedule.repeat.unit === 'month' && this.serverCopy.schedule && this.schedule.day != this.serverCopy.schedule.day) return false;
        if (this.schedule.repeat.unit === 'week' && this.serverCopy.schedule && this.schedule.days && this.serverCopy.schedule.days && this.schedule.days.length != this.serverCopy.schedule.days.length) return false;
        if (this.schedule.repeat.unit === 'week' && this.serverCopy.schedule && this.schedule.days && this.serverCopy.schedule.days && JSON.stringify(this.schedule.days.slice().sort(this.sortFunction)) != JSON.stringify(this.serverCopy.schedule.days.slice().sort(this.sortFunction))) return false;
        if (!this.validFrom.isSame(this.serverCopy.validFrom) || !this.validTo.isSame(this.serverCopy.validTo)) return false;
        if (this.validForever !== this.serverCopy.validForever) return false;
        return true;
    }

    sortFunction(a, b) {
        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
    }

    isTextValid(value) {
        return value && value.length > 2;
    }

    isNumberValid(value) {
        return value != null && value != undefined && !isNaN(value) && value > 0
    }

    isDayValid() {
        return this.schedule.repeat.unit === 'month'
        && (this.schedule && this.schedule.repeat && this.isNumberValid(this.schedule.day) && this.schedule.day > 0 && this.schedule.day < 32)
    }

    @computed
    get canCreate() {

        let canCreate = this.title
                        && this.text
                        && this.isTextValid(this.title)
                        && this.isTextValid(this.text)
                        && this.isNumberValid(this.schedule.repeat.every)
                        && this.schedule && this.schedule.repeat && this.isNumberValid(this.schedule.repeat.every)
                        && this.schedule && this.schedule.hours && this.schedule.hours.length > 0
                        && this.duration && this.duration.value && this.isNumberValid(this.duration.value);

        if(this.schedule.repeat.unit === 'month')
            return canCreate && this.isDayValid();

        if(this.schedule.repeat.unit === 'week')
            return canCreate && this.schedule && this.schedule.days && this.schedule.days.length > 0;

        return canCreate
    }

    @computed
    get canUpdate() {
        return this.canCreate;
    }

    @computed
    get isValid() {
        return this.isEditing ? this.canUpdate : this.canCreate;
    }

    @computed
    get canSubmit() {
        if (this.isEditing) {
            return this.isValid && !this.isSameAsServerCopy;
        } else {
            return this.isValid;
        }
    }

    @computed
    get canSubmitProfile() {
        return this.title
            && this.text
            && !this.isSameAsServerCopy;
    }

    @computed
    get selectedRepeat() {
        return {
            value: this.schedule.repeat.unit,
            label: this.getStringForUnit(this.schedule.repeat.unit)
        }
    }

    @computed
    get selectedDurationUnit() {
        return {
            value: this.duration.unit,
            label: this.getStringForUnit(this.duration.unit)
        }
    }

    getStringForUnit(unit) {
        switch(unit) {
            case 'day':
                return 'Dan';
            case 'week':
                return 'Teden';
            case 'month':
                return 'Mesec';
            case 'year':
                return 'Leto';
            case 'forever':
                return 'Neskončno';
            default:
                return '--'
        }
    }

    @action
    addHour() {
        this.schedule.hours.push('10:00');
    }

    @action
    removeHour(index) {
        this.schedule.hours.splice(index, 1);
    }

    @action
    changeUnit(event) {
        this.schedule.repeat.unit = event.value;
    }

    @action
    changeDurationUnit(event) {
        this.duration.unit = event.value; 
    }

    @action
    changeEvery(event) {
        this.schedule.repeat.every = event.target.value;
    }

    @action
    changeDuration(event) {
        this.duration.value = event.target.value;
    }

    @action
    changeDay(event) {
        this.schedule.day = event.target.value;
    }

    @action
    changeValidForever(checked) {
        this.validForever = checked;
    }

    @action
    changeDays(checked, day) {
        if (this.schedule.days.includes(day.value) && !checked) {
            this.schedule.days.splice(this.schedule.days.indexOf(day.value), 1);
        } else if (!this.schedule.days.includes(day.value) && checked) {
            this.schedule.days.push(day.value);
        }

        this.validateField('days');
    }

    @action
    setFromTime(time) {
        this.validFrom = time.startOf('day');
    }

    @action
    setToTime(time) {
        this.validTo = time.endOf('day');
    }

    @action
    setEditing(moduleId, id, profileId) {
        this.id = id;
        this.isEditing = true;
        this.get(moduleId, id, profileId);
    }

    get repeatUnits() {
        return [{
            options: [
                {value: 'day', label: 'Dan'},
                {value: 'week', label: 'Teden'},
                {value: 'month', label: 'Mesec'}
        ]}];
    }

    get durationUnits() {
        return [{
            options: [
                {value: 'forever', label: 'Neskončno'},
                {value: 'day', label: 'Dan'},
                {value: 'week', label: 'Teden'},
                {value: 'month', label: 'Mesec'},
                {value: 'year', label: 'Leto'}
        ]}];
    }

    get days() {
        return [
            {value: 'MON', label: this.dayString('MON')},
            {value: 'TUE', label: this.dayString('TUE')},
            {value: 'WED', label: this.dayString('WED')},
            {value: 'THU', label: this.dayString('THU')},
            {value: 'FRI', label: this.dayString('FRI')},
            {value: 'SAT', label: this.dayString('SAT')},
            {value: 'SUN', label: this.dayString('SUN')}
        ];
    }

    @action
    dayString = (value) => {
        switch(value) {
            case 'MON':
                return 'Ponedeljek';
            case 'TUE':
                return 'Torek';
            case 'WED':
                return 'Sreda';
            case 'THU':
                return 'Četrtek';
            case 'FRI':
                return 'Petek';
            case 'SAT':
                return 'Sobota';
            case 'SUN':
                return 'Nedelja'
            default:
                return '--';

        }
    }

    @action
    async get(moduleId, id, profileId) {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: profileId ? `${CMS_SCHEDULE}/${moduleId}/profile/${profileId}` : `${CMS_SCHEDULE}/${moduleId}/${id}`
        };

        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.mapServerToStore(response);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    mapServerToStore(response) {
        this.id = response.id;
        this.title = response.title;
        this.text = response.text;
        this.schedule = response.schedule;
        this.validForever = response.validForever;

        this.duration = response.duration ? response.duration : {value: 1, unit:'forever'};

        this.validFrom = new moment(response.validFrom);
        this.validTo = new moment(response.validTo);

        this.serverCopy = {
            id: response.id,
            title: response.title,
            text: response.text,
            schedule: response.schedule,
            duration: response.duration ? response.duration : {value: 1, unit:'forever'},
            validFrom: new moment(response.validFrom),
            validTo: new moment(response.validTo),
            validForever: response.validForever
        };

        if (!this.schedule.days) {
            this.schedule.days = [];
        }
    }

    @action
    async postSchedule(moduleId, profileId) {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: profileId ? `${CMS_SCHEDULE}/${moduleId}/profile/${profileId}` : `${CMS_SCHEDULE}/${moduleId}`,
            body: this.mapStoreToServer()
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.isLoading = false;
            });

            return true;
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });

            return false;
        }
    }

    mapStoreToServer() {
        const payload = {
            title: this.title,
            text: this.text,
            schedule: JSON.parse(JSON.stringify(this.schedule)),
            duration: JSON.parse(JSON.stringify(this.duration)),
            validForever: this.validForever
        };

        if (this.isEditing) {
            payload.id = this.id;
        }

        if (payload.schedule.repeat.unit === 'day') {
            delete payload.schedule.days;
            delete payload.schedule.day;
        }

        if (payload.schedule.repeat.unit === 'week') {
            delete payload.schedule.day;

            //adding days in sort order by days
            if (this.schedule.days) {
                let selDays = [];

                for(let i=0; i<this.days.length; i++) {
                    let cur = this.days[i];
                    if (this.schedule.days.includes(cur.value)) {
                        selDays.push(cur.value);
                    }
                }
                payload.schedule.days = selDays;
            }
        }

        payload.validFrom = this.validFrom;
        payload.validTo = this.validTo;

        return payload;
    }

    @action
    handleChange = (event) => {
        const {name, value} = event.target;
        this[name] = value;
    };

    @action
    handleTimeChange = (event, index) => {
        this.schedule.hours[index] = event.target.value;
    };

    @action
    clear() {
        this.isLoading = false;
        this.error = null;
        this.isEditing = false;
        this.status = {
            title: "",
            text: "",
            day: "",
            every: "",
            days: "",
            duration: ""
        };

        this.id = "";
        this.title = "";
        this.text = "";
        this.validForever = true;
        this.schedule = {
            repeat: {
                every: 1,
                unit: 'day'
            },
            hours: ['10:00'],
            days: ['MON'],
            day: 1
        },
        this.duration = {
            value: 1,
            unit: 'forever'
        }
        this.serverCopy = {
            title: "",
            text: "",
            schedule: {
                repeat: {
                    every: -1,
                    unit: 'day'
                },
                hours: ['10:00'],
                days: ['MON'],
                day: 1
            },
            duration: {
                value: 1,
                unit: 'forever'
            }
        }
    }

    @action
    validateField(field) {
        switch (field) {
            case("title"):
                this.status.title = !this.isTextValid(this.title) ? "has-danger" : "";
                break;
            case("text"):
                this.status.text = !this.isTextValid(this.text) ? "has-danger" : "";
                break;
            case("day"):
                this.status.day = !this.isDayValid() ? "has-danger" : "";
                break;
            case("every"):
                this.status.every = !this.schedule || !this.schedule.repeat || !this.isNumberValid(this.schedule.repeat.every) ? "has-danger" : "";
                break;
            case("duration"):
                this.status.duration = !this.duration || !this.isNumberValid(this.duration.value) ? "has-danger" : "";
                break;
            case("days"):
                this.status.days = !this.schedule || !this.schedule.days || this.schedule.days.length < 1 ? "has-danger" : "";
                break;
        }
    }

    get isEmpty() {
        
        return !this.title
             && !this.text
    }

    @computed
    get shouldShowWarningOnBackNavigation() {
        if(this.isEditing && !this.isSameAsServerCopy) return true;
        return !this.isEditing && !this.isEmpty;
    }
}

export default new ScheduleStore();
