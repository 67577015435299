import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row} from "reactstrap";
import {observer} from "mobx-react-lite";
import {hist} from "../index";
import {getAgeFromEmso, getBirthDateFromEmso} from "../variables/dateHelpers";
import LinkButton from "./LinkButton";
import styled from "styled-components";
import AnamnesisStore from "../stores/AnamnesisStore";
import ReadingsStore from "../stores/ReadingsStore";
import {getModuleId} from "../variables/fetchHelper";

const CustomLabel = styled.label`
    font-size: 14px !important;
    margin-right: 4px;
`;

const SectionHeader = styled.p`
    margin-bottom: 2px;
`;


const UserInfo = observer(({patient, moduleId}) => {
    const [showAll, setShowAll] = useState(false);

    const PairValue = (value, unit) => {
        return value && value.from && value.to ? value.from + " - " + value.to + " " + unit : "-";
    };

    useEffect(() => {
        if(getModuleId(hist)){
            AnamnesisStore.getAnamnesis();
            ReadingsStore.getReadings();

            return () => {
                AnamnesisStore.clear();
                ReadingsStore.clear();
            };
        }
    },[]);

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h4">
                    <Row>
                        <Col md={9}>
                            {`${patient.healthId} ${patient.firstName || patient.lastName ? "-" : ""} ${patient.firstName ? patient.firstName : ""} ${patient.lastName ? patient.lastName : ""} `}
                        </Col>
                        <Col md={3}>
                            <i
                                className="float-right nc-icon nc-settings mt-1 mr-2"
                                style={{"cursor": "pointer", color: "#51bcda"}}
                                onClick={() => hist.push(`${hist.location.pathname}/personalDetails`)}/>
                            <i
                                className="float-right nc-icon nc-lock-circle-open mt-1 mr-2"
                                style={{"cursor": "pointer", color: "#51bcda"}}
                                onClick={() => hist.push(`${hist.location.pathname}/resetPin`)}/>
                        </Col>
                    </Row>
                </CardTitle>
            </CardHeader>
            <CardBody className="d-flex flex-column">
                <SectionHeader>OSEBNI PODATKI</SectionHeader>
                <div>
                    <CustomLabel>Starost:</CustomLabel>
                    {getAgeFromEmso(patient.emso) ? getAgeFromEmso(patient.emso) : "-"}
                </div>
                <div>
                    <CustomLabel>Naslov:</CustomLabel>
                    {patient.address ? patient.address : "-"}
                </div>
                {showAll &&
                   <div>
                       <div>
                           <CustomLabel>EMŠO:</CustomLabel>
                           {patient.emso ? patient.emso : "-"}
                       </div>
                       <div>
                           <CustomLabel>E-pošta:</CustomLabel>
                           {patient.email ? patient.email : "-"}
                       </div>
                       <div>
                           <CustomLabel>Telefon:</CustomLabel>
                           {patient.phone ? patient.phone : "-"}
                       </div>
                       <div>
                           <CustomLabel>Datum rojstva:</CustomLabel>
                           {getBirthDateFromEmso(patient.emso) ? getBirthDateFromEmso(patient.emso) : "-"}
                       </div>
                       <div>
                           <CustomLabel>Telefon 2:</CustomLabel>
                           {patient.phone1 ? patient.phone1 : "-"}
                       </div>
                       <div>
                           <CustomLabel>Telefon 3:</CustomLabel>
                           {patient.phone2 ? patient.phone2 : "-"}
                       </div>
                       <div>
                           <CustomLabel>Telefon 4:</CustomLabel>
                           {patient.phone3 ? patient.phone3 : "-"}
                       </div>
                       <div>
                           <CustomLabel>Skype:</CustomLabel>
                           {patient.skype ? patient.skype : "-"}
                       </div>
                       <div>
                           <CustomLabel>Viber:</CustomLabel>
                           {patient.viber ? patient.viber : "-"}
                       </div>
                   </div>
                }

                <div>
                    <SectionHeader className="mt-4">ZDRAVSTVENI PODATKI</SectionHeader>
                    <div>
                        <CustomLabel>Zdravila:</CustomLabel>
                        {patient.healthData && patient.healthData.medication ? patient.healthData.medication : "-"}
                    </div>
                    <div>
                        <CustomLabel>Spremljajoče bolezni:</CustomLabel>
                        {patient.healthData && patient.healthData.diseases ? patient.healthData.diseases : "-"}
                    </div>
                    <div>
                        <CustomLabel>Alergije:</CustomLabel>
                        {patient.healthData && patient.healthData.allergies ? patient.healthData.allergies : "-"}
                    </div>
                </div>
                {showAll &&
                    <div>
                        <div>
                            <CustomLabel>Visok krvni tlak:</CustomLabel>
                            {patient.healthData && patient.healthData.pressure && PairValue(patient.healthData.pressure.upper, "mmHg")}
                        </div>
                        <div>
                            <CustomLabel>Nizek krvni tlak:</CustomLabel>
                            {patient.healthData && patient.healthData.pressure && PairValue(patient.healthData.pressure.lower, "mmHg")}
                        </div>
                        <div>
                            <CustomLabel>Srčni utrip:</CustomLabel>
                            {patient.healthData && PairValue(patient.healthData.heartRate, "bpm")}
                        </div>
                        <div>
                            <CustomLabel>
                                SpO2 v mirovanju:
                            </CustomLabel> {patient.healthData && patient.healthData.spO2 ? patient.healthData.spO2 + "%" : "-"}
                        </div>
                    </div>
                }

                {showAll &&
                    <div>
                        <SectionHeader className="mt-4">OSEBNI ZDRAVNIK</SectionHeader>
                        <div>
                            <CustomLabel>Osebni zdravnik:</CustomLabel>
                            {patient.personalDoctor && patient.personalDoctor.fullName ? patient.personalDoctor.fullName : "-"}
                        </div>
                        <div>
                            <CustomLabel>Kraj:</CustomLabel>
                            {patient.personalDoctor && patient.personalDoctor.city ? patient.personalDoctor.city : "-"}
                        </div>

                        <SectionHeader className="mt-4">OBRAVNAVA PACIENTA</SectionHeader>
                        <div>
                            <CustomLabel>Organizacija:</CustomLabel>
                            {patient.organization && patient.organization.name ? patient.organization.name : "-"}
                        </div>
                    </div>
                }
                <div style={{marginTop: "15px"}}>
                    <LinkButton
                        action={() => setShowAll(!showAll)}
                        text={showAll ? "Pokaži manj" : "Pokaži več"}
                        appendIconClass={showAll ? "nc-minimal-up" : "nc-minimal-down"}
                    />
                </div>
            </CardBody>
            {moduleId &&
                <CardFooter>
                    <Button
                        disabled={!AnamnesisStore.anamnesis?.length > 0}
                        className="btn-info mt-1 mr-2"
                        onClick={() => hist.push(`${hist.location.pathname}/addAnamnesis`)}
                        style={{width: "169px"}}
                    >Dodaj anamnezo</Button>
                    <Button
                        disabled={!ReadingsStore.primaryReadings?.length > 0 && !ReadingsStore.secondaryReadings?.length > 0}
                        className="btn-info mt-1"
                        onClick={() => hist.push(`${hist.location.pathname}/addMeasurement`)}
                        style={{width: "169px"}}
                    >Dodaj meritve</Button>
                </CardFooter>
            }
        </Card>
    );
});

export default UserInfo;
