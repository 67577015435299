import {action, computed, observable, runInAction} from "mobx";
import httpService from "../../services/httpService";
import {
    NOTIFICATION_TO_ALL,
    NOTIFICATION_TO_USER,
    NOTIFICATIONS,
    PAGE_COUNT,
    UPGRADE_NOTIFICATION,
    NOTIFICATION_TO_MODULE
} from "../../config";
import {getModuleId, queryParams} from "../../variables/fetchHelper";
import {hist} from "../../index";


class NotificationsStore {
    @observable isLoading = false;
    @observable notifications = [];
    @observable error = null;
    @observable currentPage = 1;
    @observable query = null;
    @observable order = null;

    constructor() {
    }

    @action
    async getNotifications(query, count, page, order) {
        this.isLoading = true;
        const moduleId = getModuleId(hist);

        let url = NOTIFICATIONS + queryParams([query, count, page, order], ["search", "count", "page", "order"]);
        if(moduleId)
            url = NOTIFICATIONS + "/" + moduleId + queryParams([query, count, page, order], ["search", "count", "page", "order"]);

        const options = {
            method: "GET",
            url: url,
        };

        // eslint-disable-next-line no-useless-catch
        try {
            const response = await httpService.fetch(
                options,
            );
            runInAction(() => {
                this.notifications = response.notifications;
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    async getNextPage() {
        this.currentPage += 1;
        await this.getNotifications(this.query, PAGE_COUNT, this.currentPage, this.order);
    }

    @action
    async getPreviousPage() {
        if(this.currentPage > 1){
            this.currentPage -= 1;
            await this.getNotifications(this.query, PAGE_COUNT, this.currentPage, this.order);
        }
    }

    @computed
    get isFirstPage() {
        return this.currentPage === 1;
    }

    @computed
    get isLastPage() {
        return this.notifications.length <= PAGE_COUNT;
    }

    @action
    async sendNotificationForUpdate(body) {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: UPGRADE_NOTIFICATION,
            body: body
        };

        // eslint-disable-next-line no-useless-catch
        try {
            await httpService.fetch(
                options,
            );
            this.getNotifications();
            runInAction(() => {
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    async sendNotificationToUsers(body) {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: NOTIFICATION_TO_USER,
            body: body
        };

        // eslint-disable-next-line no-useless-catch
        try {
            await httpService.fetch(
                options,
            );
            this.getNotifications();
            runInAction(() => {
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    async sendNotificationToAll(body) {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: NOTIFICATION_TO_ALL,
            body: body
        };

        // eslint-disable-next-line no-useless-catch
        try {
            await httpService.fetch(
                options,
            );
            this.getNotifications();
            runInAction(() => {
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    async sendNotificationOnModule(body) {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: NOTIFICATION_TO_MODULE,
            body: body
        };

        try {
            await httpService.fetch(
                options,
            );
            this.getNotifications();
            runInAction(() => {
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    @action
    clear() {
        this.isLoading = false;
        this.notifications = [];
        this.error = null;
        this.currentPage = 1;
        this.query = "";
    }
}

// singletone
export default new NotificationsStore();
