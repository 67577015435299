import {onlyDigits} from "../enums/regex";

/**
 * Validates emso by length, date and checksum
 * @param emso
 */
export default function validateEmso(emso: string): boolean {
    if (!emso) return false;
    if (emso.length !== 13) return false;
    if (onlyDigits.test(emso)) return false;

    const day = emso.slice(0, 2);
    const month = emso.slice(2, 4);
    const partialYear = emso.slice(4, 7);
    const year = (partialYear[0] === "9" ? "1" : "2") + partialYear;

    const dateIn = `${year}-${month}-${day}`;

    const date = new Date(dateIn);

    if (date.toString() === "Invalid Date") return false;

    if (date.toISOString().slice(0, 10) !== dateIn) return false;

    if (new Date() < date) return false;

    let checkSum = 0;

    for (let i = 7; i > 1; i--) {
        checkSum += i * (parseInt(emso.slice(7 - i, 8 - i)) + parseInt(emso.slice(13 - i, 14 - i)));
    }

    const controlDigit = checkSum % 11 == 0 ? 0 : 11 - (checkSum % 11);

    return emso.toString().slice(12, 13) === controlDigit.toString();
}
