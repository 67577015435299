import {action, computed, observable, runInAction} from "mobx";
import {GET_USER_BY_EMAIL, USER_ASSIGN} from "../config";
import {emailRegex} from "../enums/regex";
import {hist} from "../index";
import {IOrganization} from "../interfaces/IOrganization";
import {PermissionTypes} from "../interfaces/IPermissions";
import {IUser} from "../interfaces/IUser";
import httpService from "../services/httpService";
import {getModuleId} from "../variables/fetchHelper";
import ResettableStore from "./ResettableStore";

class AssignUserToModuleStore extends ResettableStore {
	@observable isLoading: boolean = false;
	@observable email: string = "";
	@observable id: number | undefined;
	@observable fullName: string | undefined = "";
	@observable organization: IOrganization | undefined;
	@observable error = null;
	@observable userExists: boolean = false;

	@observable owner: boolean = false;
	@observable canUse: boolean = false;
	@observable canEdit: boolean = false;
	@observable canShare: boolean = false;
	@observable canDelete: boolean = false;

	@observable serverCopy: IUser | undefined;

	constructor() {
	    super();
	    this.setInitialState();
	}

	@action
	handleChange = (event: any) => {
	    this.userExists = false;
	    const {name, value} = event.target;
	    // @ts-ignore
	    this[name] = value;
	};

	@action
	async getUserByEmail() {
	    this.isLoading = true;

	    const options = {
	        method: "POST",
	        url: GET_USER_BY_EMAIL,
	        body: {
	            email: this.email,
	            medModuleId: getModuleId(hist),
	        },
	    };

	    try {
	        const response = await httpService.fetch(
	            options,
	        );

	        runInAction(() => {
	            this.mapServerToStore(response);
	            this.isLoading = false;
	        });
	    } catch (err) {
	        runInAction(() => {
	            this.isLoading = false;
	            this.error = err;
	        });
	    }
	}

	mapServerToStore(response: any) {
	    if (response.id) {
	        this.userExists = true;
	        this.email = response.email;
	        this.fullName = response.fullName;
	        this.id = response.id;
	        this.organization = response.organization;
	    } else {
	        this.userExists = false;
	    }
	}

	@action
	async assignUser(): Promise<boolean> {
	    const moduleId = getModuleId(hist);
	    if (!moduleId || !this.id) return false;
	    this.isLoading = true;

	    const options = {
	        method: "POST",
	        url: USER_ASSIGN,
	        body: {
	            canUse: this.canUse || this.canEdit || this.canShare,
	            canEdit: this.canEdit || this.canShare,
	            canShare: this.canShare,
	            webProfileId: this.id,
	            medModuleId: moduleId,
	        },
	    };

	    try {
	        const response = await httpService.fetch(options);

	        runInAction(() => {
	            this.isLoading = false;
	            if (response) {
	                this.clear();
	            }
	        });
	        return true;
	    } catch (err) {
	        runInAction(() => {
	            this.isLoading = false;
	            this.error = err;
	        });
	        return false;
	    }
	}

	@action
	isChecked(permission: PermissionTypes) {
	    try {
	        return this[permission];
	    } catch (e) {
	        return false;
	    }
	}

	@action
	setUser(user: IUser | null) {
	    if (!user) return;
	    this.id = user.id;
	    this.canUse = user.medModulePersmissinons?.canUse;
	    this.canEdit = user.medModulePersmissinons?.canEdit;
	    this.canShare = user.medModulePersmissinons?.canShare;
	    this.serverCopy = user;
	}

	@action
	togglePermission(permission: PermissionTypes) {
	    this[permission] = !this[permission];
	}

	@computed
	get canSearch() {
	    return emailRegex.test(this.email.toLowerCase());
	}

	@computed
	get canAssign() {
	    return this.canShare || this.canEdit || this.canUse;
	}

	@computed
	get canUpdate() {
	    return (this.canShare || this.canEdit || this.canUse) && !this.isSameAsServerCopy;
	}

	@computed
	get isSameAsServerCopy() {
	    if (this.id !== this.serverCopy?.id) return false;
	    if (this.canUse !== this.serverCopy?.medModulePersmissinons.canUse) return false;
	    if (this.canEdit !== this.serverCopy?.medModulePersmissinons.canEdit) return false;
	    if (this.canShare !== this.serverCopy?.medModulePersmissinons.canShare) return false;
	    return true;
	}

	@computed
	get shouldShowWarningOnBackNavigation() {
	    return !this.isSameAsServerCopy;
	}
}

export default new AssignUserToModuleStore();