import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";
import AuthStore from "../stores/AuthStore";
import LinkButton from "../components/LinkButton";
import role from "../enums/ERole";
import {observer} from "mobx-react-lite";

const Login = observer(({history}) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        AuthStore.logout();
    }, []);

    const handleChange = (field, value) => {
        switch (field) {
            case "username":
                setUsername(value);
                break;
            case "password":
                setPassword(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await AuthStore.login(username, password);
        if (response) {
            if(AuthStore.roles.includes(role.ADMIN) || AuthStore.roles.includes(role.ORGANIZATION_ADMIN))
                history.push("/users");
            if(AuthStore.roles.includes(role.MEDIC))
                history.push("/add-patient");
        } else {
            setPassword("");
        }
    };

    const handleForgotPassword = () => {
        history.push("/forgot-password");
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") handleSubmit(event);
    };
  
    return (
        <Form onKeyPress={handleKeyPress}>
            <Card className="card-signup text-center">
                <CardHeader>
                    <CardTitle tag="h4">Prijava</CardTitle>
                </CardHeader>
                <CardBody>
                    <p className="text-danger text-center">
                        {typeof AuthStore.error !== "object" && AuthStore.error}
                    </p>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="nc-icon nc-single-02"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Uporabniško ime"
                            type="text"
                            onChange={(e) =>
                                handleChange("username", e.target.value)
                            }
                            value={username}
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="nc-icon nc-key-25"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Geslo"
                            type="password"
                            autoComplete="off"
                            onChange={(e) =>
                                handleChange("password", e.target.value)
                            }
                            value={password}
                        />
                    </InputGroup>
                    <br/>
                </CardBody>
                <CardFooter>
                    <Button
                        block
                        className="btn-round mb-3"
                        color="warning"
                        onClick={handleSubmit}
                    >
                        Prijavi se
                    </Button>
                    <LinkButton action={handleForgotPassword} text={"Pozabljeno geslo?"}/>
                </CardFooter>
            </Card>
        </Form>
    );
});

export default Login;