import React, {useState} from "react";
import Select from "react-select";
import {Card, CardHeader, CardTitle} from "reactstrap";
import {IPatient} from "../interfaces/IPatient";
import AssignPatientsToModuleStore from "../stores/AssignPatientsToModuleStore";
import PatientProfileStore from "../stores/PatientProfileStore";
// @ts-ignore
import ReactBSAlert from "react-bootstrap-sweetalert";

interface IModuleAssigner {
	patient: IPatient,
}

const ModuleAssigner = ({patient}: IModuleAssigner) => {
    const [assigningAlert, setAssigningAlert] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [module, setModule] = useState({label: "", value: ""});

    const assign = async () => {
	    setAssigningAlert(false);
	    setModule({label: "", value: ""});
	    AssignPatientsToModuleStore.assignPatient(module.value, patient.healthId).then(() => {
	    	setSuccessAlert(true);
		    PatientProfileStore.getPatientProfile(patient.id);
	    });
    };
	
    return (
	    <Card>
		    {successAlert &&
	            <ReactBSAlert
	                success
	                style={{display: "block"}}
	                title="Pacient je bil uspešno dodan"
	                onConfirm={() => setSuccessAlert(false)}
	                confirmBtnBsStyle="info"
	            />
		    }
		    {assigningAlert &&
	            <ReactBSAlert
	                warning
	                style={{ display: "block", whiteSpace: "normal"}}
	                title={`Želite dodati pacienta na modul ${module.label}?`}
	                onConfirm={assign}
	                onCancel={()=> {
	                    setAssigningAlert(false);
	                    setModule({label: "", value: ""});
	                }}
	                confirmBtnBsStyle="info"
	                cancelBtnBsStyle="danger"
	                confirmBtnText="Potrdi"
	                cancelBtnText="Prekliči"
	                showCancel
	            />
		    }
		    <CardHeader style={{display: "flex"}}>
			    <CardTitle tag="h5">Dodaj pacienta na modul: </CardTitle>
			    <Select
				    className="react-select primary mt-1 mb-3 ml-3 w-25 bg-white"
				    classNamePrefix="react-select"
				    id="organizations"
				    placeholder="Izberi modul"
				    onChange={(module: any) => {
				    	setAssigningAlert(true);
				    	setModule(module);
				    }}
				    value={module.value ? module : null}
				    options={PatientProfileStore.medModulesForAssigning}
				    noOptionsMessage={() => "Ni modulov"}
			    />
		    </CardHeader>
	    </Card>
    );
};

export default ModuleAssigner;