import React, {useEffect} from "react";
import {hist} from "index";
import MedModulesStore from "stores/MedModulesStore";
import {getModuleId} from "variables/fetchHelper";
import Loader from "components/Loader";

const SelectModule = () => {
    const moduleId =(getModuleId(hist));
    useEffect(() => {
        MedModulesStore.activeModuleId = moduleId;
	    hist.push(`/module/${moduleId}/patients`);
    }, []);
    return <Loader isLoading={true}/>;
};

export default SelectModule;