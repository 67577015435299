import React from "react";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {observer} from "mobx-react-lite";
import PatientProfileStore from "../../stores/PatientProfileStore";
import DisplayReadingAnswer from "../DisplayReadingAnswer";

const PatientAnamnesisRecord = observer(() => {

    if(!PatientProfileStore.latestReadings?.anamnesis?.readings)
        return null;
    const questionCount = PatientProfileStore.latestReadings.anamnesis.readings?.length;
    let halfCount;
    if (questionCount) {
        halfCount = questionCount / 2 + questionCount % 2;
    }

    return (
        <Card>
            <CardHeader><h5>Anamneza</h5></CardHeader>
            {PatientProfileStore.latestReadings && PatientProfileStore.latestReadings.anamnesis &&
            <CardBody>
                <Row>
                    <Col>
                        {PatientProfileStore.latestReadings.anamnesis.readings?.slice(0, halfCount).map((reading) =>
                            <DisplayReadingAnswer key={reading.id} reading={reading}/>,
                        )}
                    </Col>
                    <Col>
                        {PatientProfileStore.latestReadings.anamnesis.readings?.slice(halfCount).map((reading) =>
                            <DisplayReadingAnswer key={reading.id} reading={reading}/>,
                        )}
                    </Col>
                </Row>
            </CardBody>}
        </Card>
    );
});

export default PatientAnamnesisRecord;
