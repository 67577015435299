import React, {useEffect} from "react";
import {Col, Row} from "reactstrap";
import {IConversation} from "interfaces/IConversation";
import styled from "styled-components";
import ChatStore from "stores/chat/ChatStore";
import {observer} from "mobx-react-lite";
import ButtonWithConfirmAlert from "components/ButtonWithConfirmAlert";

interface IProps {
    conversationInfo: IConversation,
    selectedUserId: string
}

const ClickableRow = styled(Row)`
  cursor: pointer;
  padding: 8px 0;
  background-color: ${(props: IProps) => props.conversationInfo.userId === props.selectedUserId ? "#e1e1e1" : ""};
  font-weight: ${(props: IProps) => props.conversationInfo.answered ? "normal" : "bold"};
  border-bottom: 1px solid lightgray;
`;

const Avatar = styled.i`
  font-size: 30px;
`;

const ChatUserSelector = observer(({conversationInfo, selectedUserId}: IProps) => {

    useEffect(() => {

    }, [conversationInfo.answered]);

    const isNamePresent = !!(conversationInfo.firstName && conversationInfo.lastName);

    const getDisplayName = () => {
        return `${conversationInfo.firstName} ${conversationInfo.lastName}`;
    };

    return (
        <ButtonWithConfirmAlert
            text={"Ali ste prepričani, da želite zapustiti trenutno stran? Sporočilo ni bilo poslano in bo izbrisano."}
            successAction={() => ChatStore.setConversationUserId(conversationInfo.userId)}
            reactBSAlertProperties={{btnSize: "lg", type: "info", style: {fontSize: "8px"}}}
            disabled={!ChatStore.hasUnsentMessage}
            style={{width: "100%"}}
        >
            <ClickableRow
                onClick={() => !ChatStore.hasUnsentMessage ? ChatStore.setConversationUserId(conversationInfo.userId) : null}
                conversationInfo={conversationInfo}
                selectedUserId={selectedUserId}
            >
                <Col md="9" xs="9" className="d-flex align-items-center">
                    <Avatar className="nc-icon nc-circle-10 mr-3"/>
                    <div className="d-flex flex-column">
                    <span>
                        {isNamePresent ? getDisplayName() : conversationInfo.healthId}
                    </span>
                        <span className="text-muted">
                        <small>{isNamePresent ? `Št. zdr. izk.: ${conversationInfo.healthId}` : ""}</small>
                    </span>
                    </div>
                </Col>
            </ClickableRow>
        </ButtonWithConfirmAlert>
    );
});

export default ChatUserSelector;
