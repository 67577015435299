import React from "react";
import {observer} from "mobx-react-lite";
import MedModulesStore from "../../stores/MedModulesStore";
import AuthStore from "../../stores/AuthStore";
import {Card, CardBody, CardHeader, CardTitle, Row, Col, Button} from "reactstrap";
import TrafficLight from "../../components/TrafficLight";
import {Link} from "react-router-dom";
import ButtonWithConfirmAlert from "../../components/ButtonWithConfirmAlert";
import Loader from "../../components/Loader";
import EPermission from "../../enums/EPermission";
import ReactTable from "react-table";

const tableHeaders = () => [
    {
        Header: "ID",
        Cell: props =>
            <span style={{display: "flex"}}>
                <div style={{paddingTop: "5px", paddingRight: "10px"}}>
                    <TrafficLight status={props.original.isEnabled}/>
                </div>
                <div>{props.original.id}</div>
            </span>,
    },
    {
        Header: "Ime",
        accessor: "name",
        Cell: props =>
        <span>
            {props.original.isEnabled &&
            <Link to={`/select-module/${props.original.id}`} style={{color: "#007bff", marginBottom: "0"}}>
                {props.original.name}
            </Link>}

            {!props.original.isEnabled &&
                <span>{props.original.name}</span>
            }
        </span>,
    },
    {
        Header: "Opis",
        accessor: "description",
    },
    {
        Header: "Akcije",
        Cell: props =>
            <div>
                {MedModulesStore.hasPermissionForModule(props.original.id, EPermission.CAN_EDIT) &&
                <Link to={`/medModules/${props.original.id}`}>
                    <i className="nc-icon nc-settings-gear-65 mx-2"/>
                </Link>
                }
                {MedModulesStore.hasPermissionForModule(props.original.id, EPermission.CAN_DELETE) &&
                <ButtonWithConfirmAlert text={"Ali ste prepričani, da želite izbrisati modul?"} successAction={async () => MedModulesStore.removeModule(props.original.id)}>
                    <i style={{cursor: "pointer"}} className="nc-icon nc-simple-remove text-danger "/>
                </ButtonWithConfirmAlert>
                }
            </div>,
    },
];

const MedModuleList = observer(({history}) => {
    return (
        <div>
            <Loader isLoading={MedModulesStore.isLoading}/>
            <Card>
                <CardHeader>
                    <Row>
                        <Col md={6}>
                            <CardTitle tag="h5">Seznam modulov</CardTitle>
                        </Col>
                        <Col md={6}>
                            {(AuthStore.hasAdminAccess || AuthStore.hasOrganizationAdminAccess) &&
                            <Button
                                className="btn-round"
                                color="default"
                                outline
                                style={{float: "right", marginBottom: "0px", marginTop: "5px"}}
                                onClick={() => history.push(`/add-medModules`)}
                            >
                                + Dodaj modul
                            </Button>
                            }
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactTable
                        // ref={ref}
                        loading={MedModulesStore.isLoading}
                        data={MedModulesStore.modules}
                        resizable={false}
                        filterable={false}
                        sortable
                        columns={tableHeaders()}
                        pageSize={MedModulesStore.modules.length}
                        showPagination={false}
                        minRows={MedModulesStore.modules && MedModulesStore.modules.length > 0 ? 1 : 3}
                        className="-striped -highlight primary-pagination"

                        // Text
                        previousText={"Nazaj"}
                        nextText={"Naprej"}
                        loadingText={"Nalagam..."}
                        noDataText={"Ni zapisov"}
                        pageText={"Stran"}
                        ofText={"od"}
                        rowsText={"vrstic"}
                    />
                </CardBody>
            </Card>
        </div>
    );
});

export default MedModuleList;
