import {action, observable} from "mobx";

class SidebarStore {
	@observable scrollPosition: number = 0;
	@observable sidebarClosedOnWideScreens: boolean = false;

	@action
	closeSidebar() {
	    this.sidebarClosedOnWideScreens = document.body.classList.contains("sidebar-mini");
	    document.body.classList.add("sidebar-mini");
	}

	@action
	openSidebar() {
	    if(!this.sidebarClosedOnWideScreens)
	        document.body.classList.remove("sidebar-mini");
	}
}

export default new SidebarStore();
