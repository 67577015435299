import {action, toJS} from "mobx";

export default class ResettableStore {
    // @ts-ignore
    _superInitialState: Object | null;

    @action.bound
    setInitialState() {
        this._superInitialState = toJS(this)
    }

    @action.bound
    clear() {
        if (!this._superInitialState) {
            console.warn("reset() does not work unless you call this.setInitialState() at the end of the constructor.")
            return
        }

        const initialState = {...this._superInitialState}
        for (const key in initialState) {
            if (!initialState.hasOwnProperty(key)) continue
            if (key === "_superInitialState") continue
            // @ts-ignore
            this[key] = initialState[key]
        }
    }
}
