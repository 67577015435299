import React, {useCallback, useEffect} from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Form, Input} from "reactstrap";
import {observer} from "mobx-react-lite";
import {Conversation} from "../../stores/chat/Conversation";
import ChatWindowMessage from "./ChatWindowMessage";
import styled from "styled-components";
import {CHAT_POOL_INTERVAL} from "../../config";
import {Link} from "react-router-dom";
import ChatLoader from "./ChatLoader";
import AuthStore from "../../stores/AuthStore";
import role from "../../enums/ERole";

interface IProps {
    conversation: Conversation,
    updateScroll: () => void,
}

const StyledCard = styled(Card)`
  height: 100%;
`;

const ButtonsRow = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  & > button {
    margin-right: 8px !important;
  }
`;

const ChatWrapper = styled.div`
  max-height: 450px; 
  height: 450px;
  overflow: auto;
  padding: 0 15px 0 15px;
  position: relative;
`;

const StyledCardHeader = styled(CardHeader)`
  display:flex;
  align-items: flex-start;
  flex-direction: column;
`;

const StyledCardBody = styled(CardBody)`
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 0 !important;
`;

const StyledCardTitle = styled(CardTitle)`
  margin-right: 12px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
`;

const AssignedDoctor = styled.div`
  margin-right: 12px;
`;

export const RefreshIcon = styled.i`
  cursor:pointer;
`;

const ChatWindow = observer(({conversation, updateScroll}: IProps) => {
    let interval: any;

    useEffect(() => {
        fetchData();

        interval = setInterval(() => getNewMessages(false), CHAT_POOL_INTERVAL);
        const textBox = document.getElementById("textarea");

        if (textBox) {
            textBox.addEventListener("keydown", onSubmit);

            return () => {
                textBox.removeEventListener("keydown", onSubmit);
                clearInterval(interval);
            };
        }
        return () => {
            clearInterval(interval);
        };
    }, [conversation.userId]);

    const fetchData = useCallback(
        async () => getNewMessages(true),
        [conversation]
    );

    const getNewMessages = async (isInitialLoad: boolean) => {
        await conversation.getMessages(isInitialLoad);
        updateScroll();
    };

    const closeConversation = async () => {
        await conversation.closeConversation();
        updateScroll();
    };

    const onSubmit = useCallback(
        async e => {
            if (e.key === "Enter" && !e.shiftKey && conversation.isMessageValid) {
                e.preventDefault();
                await postMessage();
            }
        },
        [conversation.newMessage, conversation.userId]
    );

    const postMessage = async () => {
        await conversation.postMessage();
        updateScroll();
    };

    const isNamePresent = !!(conversation.firstName && conversation.lastName);

    const getDisplayName = () => {
        return `${conversation.firstName} ${conversation.lastName}`;
    };

    const getNameInitial = () => {
        return conversation.firstName ? conversation.firstName.slice(0, 1) : "";
    };

    const [lastMessage] = conversation.messages.slice(-1);
    const isConversationAssigned = lastMessage && lastMessage.assignedTo;
    const isConversationAssignedToMe = isConversationAssigned && isConversationAssigned.id == AuthStore.id;

    return (
        <StyledCard className="card">
            <StyledCardHeader>
                <div className="d-flex align-items-center">
                    <StyledCardTitle tag="h5">
                        <Link to={`/patients/${conversation.userId}`} style={{color: "#007bff"}}>
                            {isNamePresent ? getDisplayName() : conversation.healthId}
                        </Link>
                    </StyledCardTitle>
                </div>
                {isConversationAssigned &&
                <AssignedDoctor>
                    Dodeljen zdravniku: <b>{isConversationAssigned.fullName}</b>
                </AssignedDoctor>
                }
            </StyledCardHeader>
            <StyledCardBody>
                <ChatWrapper id="chatWrapper">
                    {conversation.isLoading ?
                        <ChatLoader isLoading={conversation.isLoading}/>
                        :
                        conversation.messages.map(message =>
                            <ChatWindowMessage key={message.created} message={message}
                                initialLetter={getNameInitial()}/>
                        )
                    }
                </ChatWrapper>
            </StyledCardBody>
            <CardFooter>
                <Form onSubmit={(event) => event.preventDefault()}>
                    <Input type="textarea"
                        id="textarea"
                        value={conversation.newMessage}
                        style={{padding: "0 5px"}}
                        disabled={isConversationAssigned && !isConversationAssignedToMe}
                        placeholder={(isConversationAssigned && !isConversationAssignedToMe) ? "Pošiljanje sporočil osebam, ki imajo že dodeljeno odgovorno osebo, ni mogoče." : ""}
                        onChange={(event) => conversation.handleChange(event.target.value)}
                        maxLength={2000}
                    />
                    <ButtonsRow>
                        <Button className="my-1" type="submit" color="info" onClick={() => postMessage()}
                            disabled={!conversation.isMessageValid || conversation.isLoading}>
                            Pošlji
                        </Button>
                        {isConversationAssigned && (isConversationAssigned.id === AuthStore.id || AuthStore.roles.includes(role.ORGANIZATION_ADMIN)) &&
                        <Button
                            className="my-1"
                            type="button"
                            color="warning"
                            onClick={() => conversation.unAssignConversation()}
                            disabled={!conversation.canClose || conversation.isLoading}>
                            Predaj pacienta
                        </Button>
                        }
                        {!isConversationAssigned &&
                        <Button
                            className="my-1"
                            type="button"
                            color="warning"
                            onClick={() => conversation.assignConversation()}
                            disabled={!conversation.canClose || conversation.isLoading}>
                            Prevzemi pacienta
                        </Button>
                        }
                        <Button className="my-1" type="button" color="danger"
                            disabled={!conversation.canClose || conversation.isLoading}
                            onClick={() => closeConversation()}>Zaključi pogovor</Button>
                    </ButtonsRow>
                </Form>

            </CardFooter>
        </StyledCard>
    );
});

export default ChatWindow;
