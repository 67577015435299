import ResettableStore from "../ResettableStore";
import {action, computed, observable, runInAction} from "mobx";
import {PAGE_COUNT, SOS_SIGNAL_MARK_AS_DONE, SOS_SIGNALS} from "../../config";
import httpService from "../../services/httpService";
import {ISosCall} from "../../interfaces/sos/ISosCall";
import {ISosSignalList} from "../../interfaces/sos/ISosSignalList";
import {queryParams} from "../../variables/fetchHelper";

class SosStore extends ResettableStore {
    @observable isLoading = false;
    @observable data: ISosCall[] = [];
    @observable error: string | undefined;
    @observable currentPage: number = 1;
    @observable query: string = "";
    @observable order: string | null = null;

    constructor() {
        super();

        this.setInitialState();
    }

    @computed
    get sosSignalsForListView(): ISosSignalList[] {
        return this.data.map(item => this.mapISosCallToISosSignalList(item));
    }

    mapISosCallToISosSignalList(item: ISosCall): ISosSignalList {
        return {
            healthId: item.user.healthId,
            patientFullName: `${item.user.firstName ?? ""} ${item.user.lastName ?? ""}`,
            userId: item.userId,
            isDone: item.isDone,
            id: item.id,
            created: item.created,
            location: item.location ? {
                accuracy: item.location.accuracy,
                latitude: item.location.latitude,
                longitude: item.location.longitude,
                timeStamp: item.location.timeStamp,
                altitude: item.location.altitude,
            } : null,
        };
    }

    @action
    async getAll(query?: string, count?: number, page?: number, order?: string | null): Promise<void> {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: SOS_SIGNALS + queryParams([query, count, page, order], ["search", "count", "page", "order"]),
        };

        try {
            const response = await httpService.fetch(options);

            runInAction(() => {
                // @ts-ignore
                this.data = response.locations;
                this.isLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
                this.error = error;
            });
        }
    }

    @action
    async getNextPage() {
        this.currentPage += 1;
        await this.getAll(this.query, PAGE_COUNT, this.currentPage, this.order);
    }

    @action
    async getPreviousPage() {
        if(this.currentPage > 1){
            this.currentPage -= 1;
            await this.getAll(this.query, PAGE_COUNT, this.currentPage, this.order);
        }
    }

    @computed
    get isFirstPage() {
        return this.currentPage === 1;
    }

    @computed
    get isLastPage() {
        return this.data.length <= PAGE_COUNT;
    }

    @action
    async markAsDone(id: string): Promise<void> {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: SOS_SIGNAL_MARK_AS_DONE.replace("{id}", id),
        };

        try {
            await httpService.fetch(options);
            this.getAll();
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
                this.error = error;
            });
        }
    }
}

export default new SosStore();
