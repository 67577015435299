import React, {useEffect} from "react";
import {Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label} from "reactstrap";
import Loader from "components/Loader";
import {observer} from "mobx-react-lite";
import OrganizationsStore from "stores/organizations/OrganizationsStore";
import Select from "react-select";
import OrganizationStore from "stores/organizations/OrganizationStore";
import RegionsStore from "stores/organizations/RegionsStore";
import AdminNavbarStore from "stores/common/AdminNavbarStore";

interface IProps {
    match: any,
}

const OrganizationForm = observer(({match}: IProps) => {
    const editingId = match.params.id;

    useEffect(() => {
        fetchData();
        return () => OrganizationStore.clear();

    }, []);

    const fetchData = async () => {
        if (!OrganizationsStore.regions || OrganizationsStore.regions.length == 0) {
            await RegionsStore.getAllRegions();
        }
        if (!OrganizationsStore.organizations || OrganizationsStore.organizations.length === 0) {
            await OrganizationsStore.getAllOrganizations();
        }
        if (editingId) {
            OrganizationStore.setEditing(editingId);
        }
    };

    const postOrganization = async () => {
        const response = await OrganizationStore.postOrganizaiton();
        if (response) AdminNavbarStore.redirectWithoutWarning("/organizations");
    };

    const organizationId = match.params.id;
    return (
        <Card className="col-md-10 mx-auto">
            <Loader isLoading={false}/>
            <CardHeader>
                <CardTitle tag="h3" className="text-center">
                    {organizationId ? "Uredi organizacijo" : "Dodaj organizacijo"}
                </CardTitle>
            </CardHeader>
            <CardBody className="col-md-9 m-auto">
                <Label>Ime*</Label>
                <FormGroup className={OrganizationStore.status.name}>
                    <Input
                        name="name"
                        value={OrganizationStore.name}
                        onChange={OrganizationStore.handleChange}
                        onBlur={() => OrganizationStore.validateField("name")}
                        autoComplete="off"
                        maxLength={100}
                    />
                    {OrganizationStore.status.name === "has-danger" &&
                    <label className="error">
                        Ime je obvezno
                    </label>}
                </FormGroup>
                <Label>Naslov*</Label>
                <FormGroup className={OrganizationStore.status.address}>
                    <Input
                        name="address"
                        value={OrganizationStore.address}
                        onChange={OrganizationStore.handleChange}
                        onBlur={() => OrganizationStore.validateField("address")}
                        autoComplete="off"
                        maxLength={500}
                    />
                    {OrganizationStore.status.address === "has-danger" &&
                    <label className="error">
                        Naslov je obvezen
                    </label>}
                </FormGroup>
                <Label>Regija*</Label>
                <Select
                    className="react-select primary mb-2"
                    classNamePrefix="react-select"
                    id="organizations"
                    onChange={(event: any) => OrganizationStore.changeRegion(event)}
                    value={OrganizationStore.selectedRegion}
                    options={RegionsStore.regionsForDropdown}
                    noOptionsMessage={() => "Ni regij"}
                />
                <Button disabled={!OrganizationStore.canSubmit} className="btb btn-info mt-4"
                    onClick={postOrganization}>Potrdi</Button>
            </CardBody>
        </Card>
    );
});

export default OrganizationForm;