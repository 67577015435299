import {colorHex} from "../enums/regex";

export function hexNumberValidation(event, callback) {
    let {value, name} = event.target;

    let match = value.match(colorHex);

    if(match && match.length > 0)
        value = match[0];
    else
        value = "";


    callback({target: {value: value, name: name}});
}
