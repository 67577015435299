import { action, computed, observable, runInAction } from "mobx";
import { TEMPLATE } from "config";
import httpService from "../../services/httpService";
import ResettableStore from "../ResettableStore";
import { ITemplate } from "interfaces/ITemplate";
import { readingTypes, ReadingType } from "components/readingTypes";
import { selectReadingType } from "variables/selectHelper";
import {v4 as uuidv4} from "uuid";

class TemplateStore extends ResettableStore {
    @observable isLoading = false;
    @observable isEditing = false;
    @observable error: any = "";

    @observable id = "";
    @observable name = "";
    @observable readingType: any = "";
    @observable items = Array<any>();

    @observable serverCopy = {
        id: "",
        name: "",
        readingType: "",
        items: []
    };

    @observable status = {
        name: "",
        type: ""
    };

    constructor() {
        super();
        this.setInitialState();
    }

    @computed
    get isSameAsServerCopy() {
        if (this.id !== this.serverCopy.id) return false;
        if (this.name !== this.serverCopy.name) return false;
        if (this.readingType.value !== this.serverCopy.readingType) return false;
        if (!this.isMultiStateEqual(this.items, this.serverCopy.items)) return false;
        
        return true;
    }

    @action
    setEditing(id: string) {
        this.id = id;
        this.isEditing = true;
        this.get(id);
    }

    @action
    async get(id: string) {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: TEMPLATE + `/${id}`,
        };

        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.mapServerToStore(response as ITemplate);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    mapServerToStore(response: ITemplate) {
        this.id = response.id;
        this.name = response.name;
        this.readingType = selectReadingType(response.readingType);
        this.items = response.items;
        this.serverCopy = {
            id: response.id,
            name: response.name,
            readingType: response.readingType,
            items: JSON.parse(JSON.stringify(response.items))
        };
    }

    @computed
    get isValid() {
        if (!this.name) return false;
        else if (!this.readingType) return false;
        else if ((this.readingType.value === ReadingType.MULTI_STATE ||
            this.readingType.value === ReadingType.MULTIPLE_SELECTION ||
            this.readingType.value === ReadingType.LIKERT_SCALE_5PT) && this.items.length === 0) return false;
        else if (this.items.filter((option) => !option.value).length !== 0) return false;

        return true;
    }

    @computed
    get canSubmit() {
        if (this.isEditing) {
            return this.isValid && !this.isSameAsServerCopy;
        } else {
            return this.isValid;
        }
    }

    @action
    handleChange = (event: any) => {
        const { name, value } = event.target;
        // @ts-ignore
        this[name] = value;
    };

    @action
    async postTemplate() {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: TEMPLATE,
            body: await this.mapStoreToServer(),
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.isLoading = false;
            });

            return true;
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });

            return false;
        }
    }

    mapStoreToServer() {
        const payload = {
            name: this.name,
            readingType: this.readingType.value,
            items: this.mapItemsForServer(this.items)

        } as ITemplate;

        if (this.isEditing) {
            payload.id = this.id;
        }

        return payload;
    }

    mapItemsForServer(items: Array<any>) {
        return items.map(itm => {
            const response:any = {};
    
            if (itm.id && !itm.id.startsWith("temp-id")) response.id = itm.id;
            response.value = itm.value;
            response.color = itm.color;
            response.intValue = itm.intValue;
    
            return response;
        });
    }

    isMultiStateEqual(arr1: any, arr2: any) {
        if (arr1.length === arr2.length)
        {
            for(let i=0; i<arr1.length; i++) {
                let cur1 = arr1[i];
                let cur2 = arr2[i];
                let res = false;

                if (cur1.value === cur2.value &&
                   (cur1.intValue == cur2.intValue || (cur1.intValue.trim() === '' && !cur2.intValue))
                   && cur1.color === cur2.color) {
                       res = true;
                }

                if (!res) {
                    return false;
                }
            }
        } else {
            return false;
        }

        return true;
    }

    @computed
    get shouldShowWarningOnBackNavigation() {
        return !this.isSameAsServerCopy;
    }

    @action
    validateField(fieldName: string) {
        switch (fieldName) {
            case "name":
                this.status.name = !!this.name ? "" : "has-danger";
                break;
        }
    }

    @action
    changeReadingType(event: any) {
        this.readingType = readingTypes.find(ty => ty.value == event.value);
    }

    @action
    addMultipleOption = () => {
        this.items.push({
            id: "temp-id-" + uuidv4(),
            value: "",
        });
    };

    @action
    overrideMultipleSelection = (data: any) => {
        this.items = data;
    };

    @action
    removeMultipleSelectOption = (id: string) => {
        this.items = this.items.filter(item => item.id !== id);
    };
}

export default new TemplateStore();