import React, {useEffect} from "react";
import Select from "react-select";
import {Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label} from "reactstrap";
import {IMatch} from "interfaces/IMatch";
import CountriesStore from "stores/organizations/CountriesStore";
import Loader from "components/Loader";
import {observer} from "mobx-react-lite";
import RegionsStore from "stores/organizations/RegionsStore";
import RegionStore from "stores/organizations/RegionStore";
import {numberValidation} from "_utils/numberValidation";
import AdminNavbarStore from "stores/common/AdminNavbarStore";

interface IProps {
    match: IMatch,
}

const RegionForm = observer(({match}: IProps) => {
    const editingId = match.params.id;

    useEffect(() => {
        fetchData();
        return () => RegionStore.clear();

    }, []);

    const fetchData = async () => {
        await CountriesStore.getCountries();
        if (!RegionsStore.regions || RegionsStore.regions.length == 0) {
            await RegionsStore.getAllRegions();
        }
        if (editingId) {
            RegionStore.setEditing(editingId);
        }
    };

    const postRegion = async () => {
        const response = await RegionStore.postRegion();
        if (response) AdminNavbarStore.redirectWithoutWarning("/regions");
    };

    const regionId = match.params.id;
    return (
        <Card className="col-md-10 mx-auto">
            <Loader isLoading={false}/>
            <CardHeader>
                <CardTitle tag="h3" className="text-center">
                    {regionId ? "Uredi regijo" : "Dodaj regijo"}
                </CardTitle>
            </CardHeader>
            <CardBody className="col-md-9 m-auto">
                <Label>Ime*</Label>
                <FormGroup className={RegionStore.status.name}>
                    <Input
                        name="name"
                        value={RegionStore.name}
                        onChange={RegionStore.handleChange}
                        onBlur={() => RegionStore.validateField("name")}
                        autoComplete="off"
                        maxLength={100}
                    />
                    {RegionStore.status.name === "has-danger" &&
                    <label className="error">
                        Ime je obvezno
                    </label>}
                </FormGroup>
                <Label>Država</Label>
                <Select
                    className="react-select primary mb-2"
                    classNamePrefix="react-select"
                    id="organizations"
                    onChange={(county) => RegionStore.changeCountry(county)}
                    value={CountriesStore.selectedCountryForDropdown(RegionStore.countryId)}
                    options={CountriesStore.countriesForDropdown}
                    noOptionsMessage={() => "Ni držav"}
                />
                <Label>Poštna št.*</Label>
                <FormGroup className={RegionStore.status.postNumber}>
                    <Input
                        name="postNumber"
                        value={RegionStore.postNumber}
                        onChange={(event) => numberValidation(event, RegionStore.handleChange)}
                        onBlur={() => RegionStore.validateField("postNumber")}
                        autoComplete="off"
                        maxLength={100}
                    />
                    {RegionStore.status.postNumber === "has-danger" &&
                    <label className="error">
                        {RegionStore.postNumber ? "Napačna poštna številka." : "Poštna številka je obvezna."}
                    </label>}
                </FormGroup>
                <Button disabled={!RegionStore.canSubmit} className="btb btn-info mt-4"
                    onClick={postRegion}>Potrdi</Button>
            </CardBody>
        </Card>
    );
});

export default RegionForm;