import PatientProfileStore from "../stores/PatientProfileStore";

export const localNumberFormat = (number) => {
    return new Intl.NumberFormat(navigator.language).format(number);
};

export const multipleSelectString = (readingId, item) => {

    if(item.value && item.value.length > 0) {
        const optionsList = item.value.map((optionValue) => PatientProfileStore.getMultiStateAnswer(readingId, optionValue)?.value).filter((answer) => answer);
        return optionsList.join(", ");
    }
    else
        return "/";
};

export const multipleSelectSum = (readingId, item) => {

    if(item.value && item.value.length > 0) {
        const optionsList = item.value.map((optionValue) => PatientProfileStore.getMultiStateAnswer(readingId, optionValue)?.intValue).filter((answer) => answer);

        let sum = 0;

        if (optionsList && optionsList.length > 0) {
            optionsList.forEach(el => {
                sum = sum + el;
            });
        }

        return sum;
    }
    else
        return null;
};
