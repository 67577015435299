import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Label} from "reactstrap";
import OrganizationsStore from "stores/organizations/OrganizationsStore";
import {observer} from "mobx-react-lite";
import AuthStore from "stores/AuthStore";
import ERole, {getSloRole} from "enums/ERole";
import UserStore from "stores/UserStore";
import Loader from "components/Loader";
import Select from "react-select";
import AdminNavbarStore from "stores/common/AdminNavbarStore";
import ReactBSAlert from "react-bootstrap-sweetalert";

const UserForm = observer((props) => {
    const editingId = props.match.params.id;
    const [userSaved, setUserSaved] = useState(false);

    useEffect(() => {
        if (editingId) {
            UserStore.setEditing(editingId);
        }
        fetchData();
        return () => UserStore.clear();
    }, []);

    const fetchData = async () => {
        if (AuthStore.hasAdminAccess) {
            await OrganizationsStore.getAllOrganizationsWithRegions();
        }

        if (!UserStore.isEditing) {
            if (!AuthStore.hasAdminAccess) {
                UserStore.organization.id = AuthStore.organizationId;
            }
        }
    };

    const postUser = async () => {
        const response = await UserStore.postUser();
        if (response) {
            setUserSaved(true);
        }
    };

    const RedirectToList = async () => {
        setUserSaved(false);
        AdminNavbarStore.redirectWithoutWarning("/users");
    };

    return (
        <React.Fragment>
            <Loader isLoading={UserStore.isLoading} />
            {userSaved &&
                <ReactBSAlert
                    success
                    style={{ display: "block" }}
                    title={UserStore.isEditing ? "Spremembe so shranjene. Uporabnik naj se znova prijavi." : "Uporabnik je bil uspešno ustvarjen"}
                    onConfirm={RedirectToList}
                    confirmBtnBsStyle="info"
                />
            }
            <Card className="col-md-10 mx-auto">
                <CardHeader>
                    <CardTitle tag="h3" className="text-center">
                        {UserStore.isEditing ? "Uredi uporabnika" : "Dodaj uporabnika"}
                    </CardTitle>
                </CardHeader>
                <CardBody className="col-md-9 m-auto">
                    <Label>Ime in priimek*</Label>
                    <FormGroup className={UserStore.status.fullName}>
                        <Input type="text"
                            name="fullName"
                            value={UserStore.fullName}
                            onChange={UserStore.handleChange}
                            onBlur={() => UserStore.validateField("fullName")}
                            autoComplete="off"
                            maxLength={100}/>
                        {UserStore.status.fullName === "has-danger" &&
                        <label className="error">
                            Ime in priimek sta obvezna
                        </label>}
                    </FormGroup>
                    <Label>Uporabniško ime*</Label>
                    <FormGroup className={UserStore.status.userName}>
                        <Input type="text"
                            name="userName"
                            value={UserStore.userName}
                            onChange={UserStore.handleChange}
                            autoComplete="off"
                            disabled={UserStore.isEditing}
                            onBlur={() => UserStore.validateField("userName")}
                            maxLength={50}/>
                        {UserStore.status.userName === "has-danger" &&
                        <label className="error">
                            Uporabniško ime je obvezno
                        </label>}
                    </FormGroup>
                    <Label>Tip uporabnika*</Label>
                    <FormGroup check>
                        <RoleCheckbox checkboxRole={ERole.MEDIC} changeRoles={UserStore.changeRoles}/>
                        <RoleCheckbox checkboxRole={ERole.ORGANIZATION_ADMIN}
                            changeRoles={UserStore.changeRoles}/>
                        <RoleCheckbox checkboxRole={ERole.SOS_USER} changeRoles={UserStore.changeRoles}/>
                        <RoleCheckbox checkboxRole={ERole.GENERAL_CHAT_USER} changeRoles={UserStore.changeRoles}/>
                        <RoleCheckbox checkboxRole={ERole.READING_TEMPLATE} changeRoles={UserStore.changeRoles}/>
                        {AuthStore.hasAdminAccess &&
                        <RoleCheckbox checkboxRole={ERole.ADMIN} changeRoles={UserStore.changeRoles}/>
                        }
                    </FormGroup>
                    <Label>E-mail*</Label>
                    <FormGroup className={UserStore.status.email}>
                        <Input type="text"
                            name="email"
                            value={UserStore.email}
                            onChange={UserStore.handleChange}
                            onBlur={() => UserStore.validateField("email")}
                            autoComplete="off"
                            maxLength={200}/>
                        {UserStore.status.email === "has-danger" &&
                        <label className="error">
                            Email ni veljaven
                        </label>}
                    </FormGroup>
                    {AuthStore.hasAdminAccess &&
                    <>
                        <Label>Organizacija*</Label>
                        <Select
                            className="react-select primary mb-2"
                            classNamePrefix="react-select"
                            id="organizations"
                            onChange={(event) => UserStore.changeOrganization(event)}
                            value={UserStore.selectedOrganization}
                            options={OrganizationsStore.organizationsWithRegionsForDropdown}
                            noOptionsMessage={() => "Ni organizacij"}
                        />
                    </>
                    }
                    {UserStore.isEditing &&
                    <FormGroup className="mt-3" check>
                        <Label check>
                            <Input checked={UserStore.isChangingPassword} type="checkbox"
                                onChange={event => UserStore.setChangingPassword(event.target.checked)}/>
                            Spremeni geslo
                            <span className="form-check-sign"/>
                        </Label>
                    </FormGroup>
                    }
                    {(!UserStore.isEditing || UserStore.isChangingPassword) &&
                    <>
                        <Label>{UserStore.isEditing ? "Novo geslo*" : "Geslo*"}</Label>
                        <FormGroup className={UserStore.status.password}>
                            <Input type="password"
                                name="password"
                                onChange={UserStore.handleChange}
                                onBlur={() => UserStore.validateField("password")}
                                autoComplete="new-password"
                                value={UserStore.password}
                                maxLength={200}
                            />
                            {UserStore.status.password === "has-danger" &&
                            <label className="error">
                                Geslo mora imeti vsaj 8 znakov
                            </label>}
                        </FormGroup>
                        <Label>Ponovi geslo*</Label>
                        <FormGroup className={UserStore.status.repeatedPassword}>
                            <Input type="password"
                                name="repeatedPassword"
                                onChange={UserStore.handleChange}
                                onBlur={() => UserStore.validateField("repeatedPassword")}
                                autoComplete="off"
                                value={UserStore.repeatedPassword}
                                maxLength={200}
                            />
                            {UserStore.status.repeatedPassword === "has-danger" &&
                            <label className="error">
                                Gesli se ne ujemata
                            </label>}
                        </FormGroup>
                        <FormGroup className="mt-3" check>
                            <Label check>
                                <Input checked={UserStore.forcePasswordChange} type="checkbox"
                                    onChange={event => UserStore.setForcePasswordChange(event.target.checked)}/>
                                Zahtevaj spremembo gesla ob prvi prijavi
                                <span className="form-check-sign"/>
                            </Label>
                        </FormGroup>
                    </>
                    }
                    <Button disabled={!UserStore.canSubmit} className="btb btn-info mt-4"
                        onClick={postUser}>Potrdi</Button>
                </CardBody>
            </Card>
        </React.Fragment>
    );
});

const RoleCheckbox = observer(({checkboxRole}) => {
    return (
        <Label check className="mr-3">
            <Input checked={UserStore.roles.includes(checkboxRole)} type="checkbox"
                onChange={event => UserStore.changeRoles(event.target.checked, checkboxRole)}
            />
            {getSloRole(checkboxRole)}
            <span className="form-check-sign"/>
        </Label>
    );
});

export default UserForm;
