import React from "react";
import {IReading} from "../interfaces/readings/IReading";
import {Col, Row} from "reactstrap";
import {EReadingType} from "../enums/EReadingType";
import {getReadingValueByReadingType} from "../stores/CommonAnamnesisReadings";
import PatientProfileStore from "../stores/PatientProfileStore";

const getReadingAnswer = (reading: IReading) => {
    switch (reading.type) {
        case EReadingType.INT:
        case EReadingType.BREATHING_FREQUENCY:
        case EReadingType.HEART_RATE:
        case EReadingType.FLOAT:
        case EReadingType.BODY_TEMPERATURE:
        case EReadingType.BLOOD_SUGAR:
        case EReadingType.SATURATION:
        case EReadingType.INT_PAIR:
        case EReadingType.BLOOD_PRESSURE:
        case EReadingType.FLOAT_PAIR:
        case EReadingType.DATE:
        case EReadingType.DATE_TIME:
        case EReadingType.MULTIPLE_SELECTION:
            return <div>{getReadingValueByReadingType(reading)}</div>;
        case EReadingType.BOOL:
            return <div>
                {reading.latestRecord?.bool ? "Da" : "Ne"}
            </div>;
        case EReadingType.FREE_TEXT:
            return <div>
                {reading.latestRecord?.string || "/"}
            </div>;
        case EReadingType.LIKERT_SCALE_5PT:
        case EReadingType.MULTI_STATE:
            const answerId = reading.latestRecord?.multiStateId ? reading.latestRecord?.multiStateId : "";
            const answer = PatientProfileStore.getMultiStateAnswer(reading.id, answerId);
            return <div style={{color: answer?.color, marginBottom: 0}}>{answer?.value ? answer.value : "/"}</div>;
        case EReadingType.IMAGE:
            return <img src={getReadingValueByReadingType(reading)}></img>;
    }
    return;
};

const DisplayReadingAnswer = (props: { reading: IReading }) => {
    const {reading} = props;
    return (
        <React.Fragment>
            <Row className="mb-2"
                key={reading.id}>
                <Col lg="6" md="6" xs="6">
                    <div className="text-muted">{reading.title}</div>
                </Col>
                <Col lg="4" md="4" xs="4">
                    <div className="text-uppercase">{getReadingAnswer(reading)}</div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DisplayReadingAnswer;
