import {action, computed, observable} from "mobx";
import ReadingsStore from "./ReadingsStore";
import ResettableStore from "./ResettableStore";
import {ECalculationType, calculationTypes} from "../enums/CalculationType";

class ReadingSectionStore extends ResettableStore {
   
    @observable error = null;
    @observable isEditing = false;
    @observable loadingRequests = [];

    @observable text = "";
    @observable subText = "";
    @observable calculationType = calculationTypes[0];

    @observable calculationTypes = calculationTypes;

    @observable serverCopy = {
        text: "",
        subText: "",
        calculationType: null
    }

    constructor() {
        super();
        this.setInitialState();
    }

    requestFinished(promise) {
        this.loadingRequests.splice(this.loadingRequests.indexOf(promise),1);
    }

    @computed
    get isLoading() {
        return this.loadingRequests.length > 0;
    }

    get isSameAsServerCopy() {
        if (this.text !== this.serverCopy.text) return false;
        if (this.subText !== this.serverCopy.subText) return false;
        if (this.calculationType !== this.serverCopy.calculationType) return false;
        return true;
    }

    @action
    setEditing(section) {
        if (!section) return;

        this.isEditing = true;
        this.text = section.text;
        this.subText = section.subText;
        let findType = this.calculationTypes.find(i => i.value === section.calculationType);
        this.calculationType = findType ? findType : this.calculationTypes[0];
    }

    @action
    getSection() {
        return {
            text: this.text,
            description: this.description,
            subText: this.subText,
            calculationType: (!this.calculationType || this.calculationType.value === -1) ? null : this.calculationType.value
        }
    }

    @action
    saveSecondarySection = async (index) => {

        let section = this.getSection();
        section.index = index;

        let promise = ReadingsStore.saveSecondarySection(section);
        this.loadingRequests.push(promise);
        await promise;        
        this.requestFinished(promise);
    }

    @computed
    get shouldShowWarningOnBackNavigation() {
        if(this.isEditing && !this.isSameAsServerCopy) return true;
        return !this.isEditing && !this.isEmpty;
    }
}

export default new ReadingSectionStore();
