import {useEffect} from "react";
import AuthStore from "../stores/AuthStore";
import AdminNavbarStore from "../stores/common/AdminNavbarStore";

const useInactiveUserLogout = () => {
    const INACTIVE_USER_TIME_THRESHOLD = AuthStore.cookieExpireTime;
    let userActivityTimeout = null;

    const resetUserActivityTimeout = () => {
        AuthStore.refreshAuthCookies();
        if(!AuthStore.token)
            return;
        clearTimeout(userActivityTimeout);
        userActivityTimeout = setTimeout(() => {
            inactiveUserAction();
        }, INACTIVE_USER_TIME_THRESHOLD);
    };

    const inactiveUserAction = () => {
        if(AuthStore.token) {
            AuthStore.logout();
            AdminNavbarStore.redirectWithoutWarning("/login");
        }
    };

    const activateActivityTracker = () => {
        window.addEventListener("mousemove", resetUserActivityTimeout);
        window.addEventListener("scroll", resetUserActivityTimeout);
        window.addEventListener("keydown", resetUserActivityTimeout);
        window.addEventListener("resize", resetUserActivityTimeout);
    };

    const deactivateActivityTracker = () => {
        window.removeEventListener("mousemove", resetUserActivityTimeout);
        window.removeEventListener("scroll", resetUserActivityTimeout);
        window.removeEventListener("keydown", resetUserActivityTimeout);
        window.removeEventListener("resize", resetUserActivityTimeout);
    };

    useEffect(() => {
        activateActivityTracker();
        return () => {
            deactivateActivityTracker();
        };
    }, []);
};

export default useInactiveUserLogout;