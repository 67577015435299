export enum EReadingType {
    INT = 0,
    BREATHING_FREQUENCY = 1,
    HEART_RATE = 2,
    FLOAT = 3,
    BODY_TEMPERATURE = 4,
    BLOOD_SUGAR = 5,
    SATURATION = 6,
    BOOL = 7,
    INT_PAIR = 8,
    BLOOD_PRESSURE = 9,
    FLOAT_PAIR = 10,
    FREE_TEXT = 11,
    MULTI_STATE = 12,
    DATE = 13,
    DATE_TIME = 14,
    MULTIPLE_SELECTION = 15,
    IMAGE = 16,
    MULTI_IMAGE = 17,
    WEIGHT = 18,
    RAPID_TEST = 19,
    LIKERT_SCALE_5PT = 20,
    SLIDER = 21
}
