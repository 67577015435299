import React from "react";
import {NavLink} from "react-router-dom";
import {Collapse, Nav} from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";

import covit from "assets/img/covit.svg";
import AuthStore from "../../stores/AuthStore";
import {Observer} from "mobx-react-lite"; // 6.x or mobx-react-lite@1.4.0
import styled from "styled-components";
import {routes} from "../../routes";
import SidebarStore from "../../stores/common/SidebarStore";
import MedModulesStore from "../../stores/MedModulesStore";
import {getModuleFromRoute} from "../../variables/fetchHelper";

const StyledUserName = styled.span`
    color: white !important;
    cursor: pointer;
`;

const AvaterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledSubitemText = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 140px;
`;

const StyledItemText = styled.span`
    margin: 0;
    lineHeight: 30px;
    position: relative;
    display: block;
    white-space: nowrap;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
`;

var ps;

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getCollapseStates(routes());
        this.sidebar = React.createRef();
        this.handleScroll = this.handleScroll.bind(this);
    }

    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = routes => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }

    hasRoles = (neededRoles, moduleId) => {
        if (neededRoles === undefined) return true;
        return neededRoles.filter(role => AuthStore.roles.includes(role) || MedModulesStore.hasPermissionForModule(moduleId, role)).length > 0;
    };

    createLinksOuter = () => {
        return this.createLinks(routes());
    };

    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = routes => {
        return routes.filter(route => this.hasRoles(route.roles, getModuleFromRoute(route.path))).filter(route => route.name).map((prop, key) => {
            if (prop.redirect) {
                return null;
            }
            if (prop.collapse) {
                var st = {};
                st[prop["state"]] = !this.state[prop.state];
                return (
                    <li
                        className={this.getCollapseInitialState(prop.views) ? "active" : ""}
                        key={key}
                    >
                        <a
                            href="#pablo"
                            data-toggle="collapse"
                            aria-expanded={this.state[prop.state]}
                            onClick={e => {
                                e.preventDefault();
                                this.setState(st);
                            }}
                        >
                            {prop.icon !== undefined ? (
                                <>
                                    <i className={prop.icon} style={{color: prop.color}}/>
                                    <p>
                                        <StyledItemText style={{color: prop.color}}>{prop.name}</StyledItemText>
                                        <b className="caret"/>
                                    </p>
                                </>
                            ) : (
                                <>
                                    <span className="sidebar-mini-icon">{prop.mini}</span>
                                    <StyledSubitemText className="sidebar-normal">
                                        {prop.name}
                                        <b className="caret"/>
                                    </StyledSubitemText>
                                </>
                            )}
                        </a>
                        <Collapse isOpen={this.state[prop.state]}>
                            <ul className="nav">{this.createLinks(prop.views)}</ul>
                        </Collapse>
                    </li>
                );
            }
            return (
                <li className={this.activeRoute(prop.path)} key={key}>
                    <NavLink to={prop.path} activeClassName="">
                        {prop.icon !== undefined ? (
                            <>
                                <i className={prop.icon}/>
                                <p>{prop.name}</p>
                            </>
                        ) : (
                            <>
                                <span className="sidebar-mini-icon">{prop.mini}</span>
                                <StyledSubitemText className="sidebar-normal">{prop.name}</StyledSubitemText>
                            </>
                        )}
                    </NavLink>
                </li>
            );
        });
    };
    // verifies if routeName is the one active (in browser input)
    activeRoute = routeName => {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };

    handleScroll(event) {
        SidebarStore.scrollPosition = event.target.scrollTop;
    }

    componentDidMount() {
        // if you are using a Windows Machine, the scrollbars will have a Mac look
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.sidebar.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }

        this.sidebar.current.scrollTop = SidebarStore.scrollPosition;
    }

    componentWillUnmount() {
        // we need to destroy the false scrollbar when we navigate
        // to a page that doesn't have this component rendered
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }

    handleLogout = () => {
        AuthStore.logout();
    };

    render() {
        return (
            <div
                className="sidebar"
                data-color={this.props.bgColor}
                data-active-color={this.props.activeColor}
            >
                <div className="logo">
                    <NavLink
                        to="/"
                        className="simple-text logo-mini"
                    >
                        <div className="logo-img">
                            <img src={covit} alt="covit-logo"/>
                        </div>
                    </NavLink>
                    <NavLink
                        to="/"
                        className="ml-2 simple-text logo-normal"
                    >
                        iMEDIS CMS
                    </NavLink>
                </div>

                <div className="sidebar-wrapper" ref={this.sidebar} onScroll={this.handleScroll}>
                    <div className="user">
                        <AvaterWrapper className="photo">
                            <i className="nc-icon nc-single-02 text-center" style={{color: "LightGray", fontSize: "20px"}}/>
                        </AvaterWrapper>
                        <div className="info">
                            <NavLink to="/profile">
                                <StyledUserName>
                                    <Observer>{() => AuthStore.fullName || ""}</Observer>
                                </StyledUserName>
                            </NavLink>
                            <Collapse isOpen={this.state.openAvatar}>
                                <ul className="nav">
                                    <li>
                                        <NavLink to="/profile">
                                            <span className="sidebar-mini-icon">P</span>
                                            <span className="sidebar-normal">Profil</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/login">
                                            <span className="sidebar-mini-icon">O</span>
                                            <span className="sidebar-normal">Odjava</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                        </div>
                    </div>
                    <Observer>{() => <Nav>{this.createLinksOuter()}</Nav>}</Observer>
                </div>
            </div>
        );
    }
}

export default Sidebar;
