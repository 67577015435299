import {action, computed, observable, runInAction} from "mobx";
import {GET_CMS_USERS, ORGANIZATIONS} from "../../config";
import httpService from "../../services/httpService";
import {IOrganization} from "../../interfaces/IOrganization";
import ResettableStore from "../ResettableStore";

class OrganizationStore extends ResettableStore {
    @observable isLoading = false;
    @observable isEditing = false;
    @observable error = "";

    @observable id = "";
    @observable name = "";
    @observable address = "";
    @observable region = {
        id: "",
        name: "",
    };

    @observable serverCopy = {
        id: "",
        name: "",
        address: "",
        region: {
            id: "",
            name: "",
        },
    };

    @observable status = {
        name: "",
        address: "",
    };

    constructor() {
        super();
        this.setInitialState();
    }

    @computed
    get isSameAsServerCopy() {
        if (this.id !== this.serverCopy.id) return false;
        if (this.name !== this.serverCopy.name) return false;
        if (this.address !== this.serverCopy.address) return false;
        if (this.region.id.toString() !== this.serverCopy.region.id.toString()) return false;
        return true;
    }

    @action
    setEditing(id: string) {
        this.id = id;
        this.isEditing = true;
        this.get(id);
    }

    @action
    async get(id: string) {
        this.isLoading = true;

        const options = {
            method: "GET",
            url: ORGANIZATIONS + `/${id}`,
        };

        try {
            const response = await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.mapServerToStore(response as IOrganization);
                this.isLoading = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });
        }
    }

    mapServerToStore(response: IOrganization) {
        this.id = response.id;
        this.name = response.name;
        this.address = response.address;
        this.region = response.region;
        this.serverCopy = {
            id: response.id,
            name: response.name,
            address: response.address,
            region: response.region,
        };
    }

    @computed
    get selectedRegion() {
        return {value: this.region.id, label: this.region.name};
    }

    @action
    changeRegion(event: any) {
        this.region = {
            id: event.value,
            name: event.label,
        };
    }

    @computed
    get isValid() {
        return this.name
            && this.address
            && this.region
            && this.region.id;
    }

    @computed
    get canSubmit() {
        if (this.isEditing) {
            return this.isValid && !this.isSameAsServerCopy;
        } else {
            return this.isValid;
        }
    }

    @action
    handleChange = (event: any) => {
        const {name, value} = event.target;
        // @ts-ignore
        this[name] = value;
    };

    @action
    async postOrganizaiton() {
        this.isLoading = true;

        const options = {
            method: "POST",
            url: ORGANIZATIONS,
            body: await this.mapStoreToServer(),
        };

        try {
            await httpService.fetch(
                options,
            );

            runInAction(() => {
                this.isLoading = false;
            });

            return true;
        } catch (err) {
            runInAction(() => {
                this.isLoading = false;
                this.error = err;
            });

            return false;
        }
    }

    mapStoreToServer() {
        const payload = {
            name: this.name,
            address: this.address,
            region: {
                id: this.region.id,
            },
        } as IOrganization;

        if (this.isEditing) {
            payload.id = this.id;
        }

        return payload;
    }

    @computed
    get shouldShowWarningOnBackNavigation() {
        return !this.isSameAsServerCopy;
    }

    @action
    validateField(fieldName: string) {
        switch (fieldName) {
            case "name":
                this.status.name = !!this.name ? "" : "has-danger";
                break;
            case "address":
                this.status.address = !!this.address ? "" : "has-danger";
                break;
        }
    }
}

export default new OrganizationStore();