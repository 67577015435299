import React, {useEffect, useState} from "react";
import {Col} from "reactstrap";
import PatientPersonalDataStore from "../../stores/PatientPersonalDataStore";
import PatientRegistrationGdpr from "./PatientRegistrationGdpr";
import ReactCustomWizard from "../../components/ReactCustomWizard";
import PatientRegistrationForm from "./PatientRegistrationForm";
import AdminNavbarStore from "../../stores/common/AdminNavbarStore";
import {getModuleId} from "../../variables/fetchHelper";
import {hist} from "../../index";

export const PatientRegistrationWizard = (props) => {
    const healthId = props.match.params.healthId;
    const moduleId = getModuleId(hist);

    const [resetForm, setResetForm] = useState(false);

    useEffect(() => {
        PatientPersonalDataStore.clear();
        if (healthId) PatientPersonalDataStore.setHealthId(healthId);
        return () => PatientPersonalDataStore.clear();
    }, []);

    const registration = async () => {
        PatientPersonalDataStore.validateAll();
        const result = await PatientPersonalDataStore.postRegistration();
        if (result && moduleId) AdminNavbarStore.redirectWithoutWarning(`/module/${moduleId}/patients/${PatientPersonalDataStore.id}`);
        else if (result) AdminNavbarStore.redirectWithoutWarning(`/patients/${PatientPersonalDataStore.id}`);
    };

    const steps = [
        {
            stepName: "Osebni podatki",
            stepIcon: "nc-icon nc-single-02",
            component: PatientRegistrationForm,
        },
        {
            stepName: "GDPR",
            stepIcon: "nc-icon nc-single-02",
            component: PatientRegistrationGdpr,
            nextButtonClickCallback: registration,
        },
    ];

    return (
        <Col className="mr-auto ml-auto" md="10">
            <ReactCustomWizard
                steps={steps}
                validate
                title="Dodaj pacienta"
                headerTextCenter
                finishButtonClasses="btn-wd btn-info"
                nextButtonClasses="btn-wd btn-info"
                previousButtonClasses="btn-wd btn-info"
                nextButtonText="Naslednji korak"
                previousButtonText="Prejšni korak"
                finishButtonText="Shrani"
                finishButtonClick={registration}
                resetForm={resetForm}
            />
        </Col>
    );

};